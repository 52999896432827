import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, mergeMap, Observable, throwError } from 'rxjs';
import { MaintenanceNavigationRequest } from '@mobile-data-access-models';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { Router } from '@angular/router';

@Injectable()
export class UnderMaintenanceInterceptor implements HttpInterceptor {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _router: Router
  ) {}

  //#endregion

  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // System is under maintenance.
        if (error.status === HttpStatusCode.ServiceUnavailable) {
          const navigationRequest = new MaintenanceNavigationRequest();
          const maintenanceUrl = this._navigationService
            .buildUrlTree(
              navigationRequest.code,
              navigationRequest.routeParams,
              navigationRequest.extras
            )
            .toString();

          // User is navigating to maintenance screen.
          const currentNavigation = this._router.getCurrentNavigation();
          if (currentNavigation?.finalUrl?.toString() === maintenanceUrl) {
            return throwError(() => error);
          }

          return this._navigationService
            .navigateToScreenAsync(new MaintenanceNavigationRequest())
            .pipe(mergeMap(() => throwError(() => error)));
        }
        return throwError(() => error);
      })
    );
  }

  //#endregion
}
