import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { IFeatureSentinelService } from '@ui-tool/core';
import { Store } from '@ngrx/store';
import { FeatureFlagSelector } from '@mobile-data-access-stores';

@Injectable()
export class FeatureSentinelService implements IFeatureSentinelService {
  //#region Properties

  protected readonly _doValidationEvent: Subject<void>;

  //#endregion

  //#region Constructor

  public constructor(private readonly __store: Store) {
    this._doValidationEvent = new ReplaySubject(1);
  }

  //#endregion

  //#region Methods

  public doValidation(): void {
    this._doValidationEvent.next();
  }

  public hookValidationEventAsync(): Observable<void> {
    return this._doValidationEvent.asObservable();
  }

  public ableToAccessFeaturesAsync(
    designatedFeatures: string[]
  ): Observable<boolean> {
    if (!designatedFeatures || !designatedFeatures.length) {
      return of(true);
    }

    return this.__store
      .select(FeatureFlagSelector.featureFlags)
      .pipe(
        map((featureFlags) => {
          if (!featureFlags || !featureFlags.length) {
            return false;
          }

          const itemIndex = featureFlags.findIndex((assignedFeature) => {
            return (designatedFeatures.findIndex((designatedFeature) => assignedFeature.code === designatedFeature && assignedFeature.enabled) !== -1
            );
          });
          return itemIndex !== -1;
        }),
        catchError(() => of(false))
      );
  }

  //#endregion
}
