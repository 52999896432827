import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { IVideo } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent {
  //#region Properties

  @ViewChild('video')
  public readonly videoElement!: ElementRef<HTMLVideoElement>;

  @Input()
  public data: IVideo | undefined;

  @Input()
  public loading = false;

  @Input()
  public hiddenInformation = false;

  protected _autoplay = true;

  public get autoplay(): boolean {
    return this._autoplay;
  }

  public play = false;

  public fullScreen = false;

  @Input()
  public set autoplay(value: boolean) {
    this._autoplay = value;
    if (value) {
      this.play = true;
    }
  }

  //#endregion

  //#region Methods

  public playClick(): void {
    if (this.fullScreen) return;
    this.play = !this.play;
    const video = this.videoElement.nativeElement;

    if (this.play) {
      video.play();
    } else {
      video.pause();
    }
  }

  public playDbClick(): void {
    this.play = !this.play;
    this.fullScreen = this.play;
    const video = this.videoElement.nativeElement;

    if (this.play) {
      video.play();
      video.requestFullscreen({
        navigationUI: 'show',
      });
    } else {
      video.pause();
      video.requestFullscreen({
        navigationUI: 'hide',
      });
    }
  }

  public onVideoPlay(): void {
    this.play = true;
  }

  //#endregion
}
