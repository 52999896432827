import { IQrCodeService } from './qr-code-service.interface';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';
import { ReadQrCodeNativeMethod } from '@mobile-data-access-models';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IPlatformService, PLATFORM_SERVICE } from '../platform';
import { PhysicalPlatforms } from '@shared-enums';

@Injectable()
export class QrCodeService implements IQrCodeService {
  //#region Constructor

  public constructor(
    @Inject(RPC_SERVICE)
    protected readonly _rpcService: IRpcService,
    @Inject(PLATFORM_SERVICE)
    protected readonly _platformService: IPlatformService,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public captureAsync(): Observable<string> {
    return this._rpcService.sendRequestAsync(
      new ReadQrCodeNativeMethod(),
      void 0
    );
  }

  public isUsableAsync(): Observable<boolean> {
    return this._platformService.getPlatformAsync().pipe(
      map((platform) => {
        if (platform === PhysicalPlatforms.WEB) {
          return false;
        }

        return true;
      })
    );
  }

  //#endregion
}
