import { NgModule } from '@angular/core';
import { VideoComponent } from './video.component';
import { CommonModule } from '@angular/common';
import { ToAnyModule } from '@shared-pipes';
import { ToTrustedResourceUrlPipeModule } from '@ui-tool/core';
import { IconModule } from "@shared-components";
import { SkeletonModule } from "../../../shared";

@NgModule({
  imports: [CommonModule, ToAnyModule, ToTrustedResourceUrlPipeModule, IconModule, SkeletonModule],
  declarations: [VideoComponent],
  exports: [VideoComponent],
})
export class VideoModule {}
