import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { RelatedTopicsComponent } from './related-topics.component';
import { SwiperModule } from 'swiper/angular';
import { RelatedTopicCardModule } from '../../../cards';
import { SkeletonModule } from "../../../shared";

const CONTAINERS = [RelatedTopicsComponent];
const MODULES = [
  CommonModule,
  IconModule,
  SwiperModule,
  RelatedTopicCardModule,
];

@NgModule({
  declarations: [...CONTAINERS],
  exports: [...CONTAINERS],
  imports: [...MODULES, SkeletonModule]
})
export class RelatedTopicsModule {}
