import { NgModule } from '@angular/core';
import { DynamicDirective } from './dynamic.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [DynamicDirective],
  exports: [DynamicDirective],
})
export class DynamicDirectiveModule {}
