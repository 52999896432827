import {
  Directive,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';
import { addHours, addMinutes, addSeconds } from 'date-fns';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'toSystemPlusTimeAsync',
})
export class ToSystemPlusTimeAsyncPipe implements PipeTransform {
  //#region Constructor
  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingService: IAppSettingsService,
    protected readonly _elementRef: ElementRef
  ) {}

  //#endregion

  //#region Methods

  public transform(time: string | Date): Observable<Date> {
    return this._appSettingService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        const systemTimeDifference = appSettings.systemTimeDifference ?? null;

        let actualTime = new Date(time);

        switch (systemTimeDifference?.unit) {
          case 'hour':
            return addHours(actualTime, systemTimeDifference?.value);
          case 'minute':
            return addMinutes(actualTime, systemTimeDifference?.value);
          case 'second':
            return addSeconds(actualTime, systemTimeDifference?.value);
          default:
            return actualTime;
        }
      })
    );
  }

  //#endregion
}
