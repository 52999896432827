import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  TrackerEffectPriorities,
  TrackerEffectServeCodes,
} from '@mobile-data-access-enums';
import {
  ISeverityDefinition,
  ITrackerEffectServe,
} from '@mobile-data-access-interfaces';
import { map, Observable } from 'rxjs';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';

@Component({
  selector: 'ncis-tracker-record-warning-card',
  templateUrl: './tracker-record-warning-card.component.html',
  styleUrls: ['./tracker-record-warning-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerRecordWarningCardComponent {
  //#region Properties

  @Output()
  public readonly submitEvent = new EventEmitter();

  @Output()
  public readonly closeEvent = new EventEmitter();

  @Input()
  public code: TrackerEffectServeCodes | null = TrackerEffectServeCodes.G1;

  @Input()
  public severeLevels: ITrackerEffectServe[] = [];

  @Input()
  public ctcaeCode: string | null = null;

  public get svgLink$(): Observable<string> {
    return this.__getSeveritiesAsync().pipe(
      map((severities) => {
        const severity = severities?.settings.find(
          (def) => def.code === (this.code || TrackerEffectServeCodes.G1)
        );

        if (
          severity &&
          severity.code === TrackerEffectServeCodes.G1 &&
          this.ctcaeCode === severities?.ctcaeCode
        ) {
          return 'ncis-assets/svgs/tracker/tracker-warning-low__fever.svg';
        }

        return this.codeToSvgLink(this.code || TrackerEffectServeCodes.G1);
      })
    );
  }

  public get title$(): Observable<string> {
    return this.__getSeveritiesAsync().pipe(
      map((severities) => {
        const severity = severities?.settings.find(
          (def) => def.code === (this.code || TrackerEffectServeCodes.G1)
        );
        return severity && severity.title
          ? severity.title
          : `TRACKER_RECORD.${this.code || TrackerEffectServeCodes.G1}_TITLE`;
      })
    );
  }
  //#endregion

  //#region Constructor

  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService
  ) {}
  //#endregion

  //#region Methods

  public get title(): string {
    return `TRACKER_RECORD.${this.code}_TITLE`;
  }

  public get description(): string {
    const des = this.severeLevels.find((x) => x.code === this.code)?.message;
    return des ?? `TRACKER_RECORD.${this.code}_DESCRIPTION`;
  }

  public get button(): string {
    return `TRACKER_RECORD.G2_BUTTON`;
  }
  public codeToPriority(
    code: TrackerEffectServeCodes
  ): TrackerEffectPriorities {
    switch (code) {
      case TrackerEffectServeCodes.G2:
        return TrackerEffectPriorities.MEDIUM;
      case TrackerEffectServeCodes.G3:
        return TrackerEffectPriorities.HIGH;
      default:
        return TrackerEffectPriorities.LOW;
    }
  }

  public codeToSvgLink(code: TrackerEffectServeCodes): string {
    return `ncis-assets/svgs/tracker/tracker-warning-${this.codeToPriority(
      code
    ).toLowerCase()}.svg`;
  }

  private __getSeveritiesAsync(): Observable<ISeverityDefinition | undefined> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        const feverSeverities = appSettings.severities?.find(
          (x) => x.ctcaeCode === this.ctcaeCode
        );
        return feverSeverities;
      })
    );
  }
  //#endregion
}
