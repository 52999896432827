import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { IMeetingDetail } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-meeting-detail',
  templateUrl: './meeting-detail.component.html',
  styleUrls: ['./meeting-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingDetailComponent {
  //#region Properties

  private __loading = false;

  @Input()
  public data: IMeetingDetail | undefined;

  //#endregion

  //#region Accessors

  public get loading(): boolean {
    return this.__loading;
  }

  @Input()
  public set loading(value: boolean) {
    this.__loading = value;
  }

  //#endregion
}
