import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, mapTo, mergeMap, Observable, of, tap } from 'rxjs';
import {
  BOOKMARK_SERVICE,
  IBookmarkService,
  IUserService,
  USER_SERVICE,
} from '@mobile-data-access-services';
import { Store } from '@ngrx/store';
import { BookmarkActions, IBookmarkState } from '@mobile-data-access-stores';
import { IBookmarkCard } from '@mobile-data-access-interfaces';
import { ScreenCodes } from '@mobile-data-access-enums';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';

@Injectable()
export class HomeGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(BOOKMARK_SERVICE)
    protected readonly _bookmarkService: IBookmarkService,
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _store: Store<IBookmarkState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._userService.getProfileAsync().pipe(
      mergeMap((profile) => {
        if (profile?.acceptTermCondition) {
          const { page, limit } = { page: 1, limit: 1 };
          return this._bookmarkService.searchAsync({ page, limit }).pipe(
            map((searchResult) => {
              this._store.dispatch(
                BookmarkActions.totalRecords({
                  totalRecords: searchResult.totalRecords,
                })
              );
              return searchResult?.items || [];
            }),
            tap((items) =>
              this._store.dispatch(
                BookmarkActions.changeBookmarks({
                  bookmarks: items.map(
                    (bookmark) =>
                      ({
                        ...bookmark,
                        itemId: bookmark.itemId,
                        id: bookmark.id,
                      } as unknown as IBookmarkCard)
                  ),
                })
              )
            ),
            mapTo(true),
            catchError(() => {
              this._store.dispatch(
                BookmarkActions.changeBookmarks({
                  bookmarks: [],
                })
              );
              return of(void 0).pipe(mapTo(true));
            })
          );
        }
        const urlTree = this._navigationService.buildUrlTree(
          ScreenCodes.ONBOARDING,
          void 0
        );
        return of(urlTree);
      })
    );
  }

  //#endregion
}
