import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  FEATURE_FLAG_SERVICE,
  I18N_SERVICE, IAuthenticationService,
  IFeatureFlagService,
  II18nService, IUiService, UI_SERVICE,
} from '@mobile-data-access-services';
import { TranslocoService } from '@ngneat/transloco';
import { Languages } from '@mobile-data-access-enums';
import { FeatureFlagAction, IRootState } from '@mobile-data-access-stores';
import { Store } from '@ngrx/store';

@Injectable()
export class AppGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(I18N_SERVICE)
    protected readonly _i18nService: II18nService,
    @Inject(FEATURE_FLAG_SERVICE)
    protected readonly _featureFlagService: IFeatureFlagService,
    protected readonly _translateService: TranslocoService,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(UI_SERVICE)
    protected readonly _uiService: IUiService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    const getAvailableLanguagesObservable =
      this._i18nService.getAvailableLanguagesAsync();

    const getAvailableFeatureFlagsObservable =
      this._featureFlagService.getAsync();

    const getThemeObservable = this._authenticationService.getThemeAsync();

    return forkJoin([getAvailableLanguagesObservable, getAvailableFeatureFlagsObservable, getThemeObservable])
      .pipe(
        map(([availableLanguages, availableFeatureFlags, themeOption]) => {
          this._store.dispatch(FeatureFlagAction.saveFeatureFlags({featureFlags: availableFeatureFlags}));
          this._translateService.setAvailableLangs(availableLanguages);
          if (themeOption) {
            this._uiService.setUpTheme(themeOption);
          }
          return true;
        }),
        catchError(() => {
          this._translateService.setAvailableLangs([Languages.EN]);
          return of(true);
        })
      );
    }

  //#endregion
}
