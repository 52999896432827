import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap, Observable } from 'rxjs';
import { IVersionService } from './version-service.interface';
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from '@mobile-data-access-resolvers';
import { IVersionUpdatesVM } from '@mobile-data-access-view-models';

@Injectable()
export class VersionService implements IVersionService {
  //#region Constructor

  public constructor(
    protected readonly _httpClient: HttpClient,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointService: IApiEndpointResolver
  ) {}

  //#endregion

  //#region Methods

  public getCurrentVersionAsync(): Observable<IVersionUpdatesVM> {
    return this._apiEndpointService.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/update/features`;

        return this._httpClient.get<IVersionUpdatesVM>(apiUrl);
      })
    );
  }

  //#endregion
}
