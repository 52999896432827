<ng-container *ngIf="!loading; else skeletonTemplate">
  <button
    [disabled]="disabled"
    class="button"
  >
    <h5>{{ label || 'N/A' }}</h5>
    <div class="button__icon">
      <ncis-common-icon
        [folder]="CommonIconFolder.GENERAL"
        [name]="CommonIconGeneral.CATEGORY_ARROW"
        class="text-ncis-neutral-100 w-[26px] h-[26px]"
      ></ncis-common-icon>
    </div>
  </button>
</ng-container>
<ng-template #skeletonTemplate>
  <ncis-skeleton class="w-[298px] h-[86px] rounded-[50px]"></ncis-skeleton>
</ng-template>
