export enum ArticleCategories {
  UNKNOWN = 'UNKNOWN',
  PAXMAN_ARTICLE = 'PAXMAN',
  PAXMAN_ARTICLE_RELATED_TOPIC = 'PAXMAN_ARTICLE_RELATED_TOPIC',
  PROCEDURE_ARTICLE = 'PROCEDURE_ARTICLE',
  PROCEDURE_ARTICLE_WHAT_NEXT = 'PROCEDURE_ARTICLE_WHAT_NEXT',
  PROCEDURE_ARTICLE_RELATED_TOPIC = 'PROCEDURE_ARTICLE_RELATED_TOPIC',
  PROCEDURE_RELATED_TOPIC = 'PROCEDURE_RELATED_TOPIC',
  PROCEDURE_RELATED_TOPIC_RELATED_TOPIC = 'PROCEDURE_RELATED_TOPIC_RELATED_TOPIC',
  PROCEDURE_DIAGNOSIS = 'PROCEDURE_DIAGNOSIS',
  CHEMOTHERAPY_ARTICLE_DOCTOR = 'CHEMOTHERAPY_ARTICLE_DOCTOR',
  CHEMOTHERAPY_ARTICLE_ORAL_MED = 'CHEMOTHERAPY_ARTICLE_ORAL_MED',
  CHEMOTHERAPY_DIAGNOSIS = 'CHEMOTHERAPY_DIAGNOSIS',
  CHEMOTHERAPY_DIAGNOSIS_WHAT_NEXT = 'CHEMOTHERAPY_DIAGNOSIS_WHAT_NEXT',
  CHEMOTHERAPY_RELATED_TOPIC = 'CHEMOTHERAPY_RELATED_TOPIC',
  CHEMOTHERAPY_RELATED_TOPIC_RELATED_TOPIC = 'CHEMOTHERAPY_RELATED_TOPIC_RELATED_TOPIC',
  CHEMOTHERAPY_ARTICLE_RELATED_TOPIC = 'CHEMOTHERAPY_ARTICLE_RELATED_TOPIC',
  DRUG_DETAIL_GENERAL_PRECAUTION = 'DRUG_DETAIL_GENERAL_PRECAUTION',
  DRUG_DETAIL_ARTICLE_DOCTOR = 'DRUG_DETAIL_ARTICLE_DOCTOR',
  DRUG_DETAIL_ORAL_MED = 'DRUG_DETAIL_ORAL_MED',
  DRUG_DETAIL_OCCASION_SIDE_EFFECT = 'DRUG_DETAIL_OCCASION_SIDE_EFFECT',
  DRUG_DETAIL_RARE_SIDE_EFFECT = 'DRUG_DETAIL_RARE_SIDE_EFFECT',
  DRUG_DETAIL_LONG_TERM_SIDE_EFFECT = 'DRUG_DETAIL_LONG_TERM_SIDE_EFFECT',
  DRUG_DETAIL_RELATED_TOPIC = 'DRUG_DETAIL_RELATED_TOPIC',
  RADIATION_ARTICLE_WHAT_NEXT = 'RADIATION_WHAT_NEXT',
  RADIATION_WHAT_NEXT_RELATED_TOPIC = 'RADIATION_WHAT_NEXT_RELATED_TOPIC',
  SYMPTOMS_ARTICLE_GENERAL_PRECAUTION = 'SYMPTOMS_ARTICLE_GENERAL_PRECAUTION',
  SYMPTOMS_ARTICLE_RARE_SIDE_EFFECT = 'SYMPTOMS_ARTICLE_RARE_SIDE_EFFECT',
  SYMPTOMS_ARTICLE_OCCASIONAL_SIDE_EFFECT = 'SYMPTOMS_ARTICLE_OCCASTIONAL_SIDE_EFFECT',
  SYMPTOMS_ARTICLE_LONG_TERM_SIDE_EFFECT = 'SYMPTOMS_LONG_TERM_SIDE_EFFECT',
  SYMPTOMS_ARTICLE_IMMUNO_SIDE_EFFECT = 'SYMPTOMS_IMMUNO_SIDE_EFFECT',
  SYMPTOM_DETAIL_ARTICLE = 'SYMPTOM_DETAIL_ARTICLE',
  SYMPTOM_DETAIL_ARTICLE_RELATED_TOPIC = 'SYMPTOM_DETAIL_ARTICLE_RELATED_TOPIC',
  CHEMOTHERAPY_APPOINTMENT = 'CHEMOTHERAPY_APPOINTMENT',
}
