import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonIconFolderEnum, CommonIconGeneralEnum } from "@shared-enums";

@Component({
  selector: 'ncis-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input()
  public label = '';

  @Input()
  public disabled = false;

  @Input()
  public loading = false;

  public readonly CommonIconGeneral = CommonIconGeneralEnum;
  public readonly CommonIconFolder = CommonIconFolderEnum;
}
