import { NgModule } from '@angular/core';
import { NutritionsComponent } from './nutritions.component';
import { IconModule } from '@shared-components';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NutritionsComponent],
  imports: [IconModule, CommonModule],
  exports: [NutritionsComponent],
})
export class NutritionsModule {}
