import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ncis-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryComponent {
  @Input()
  public title!: string;

  @Input()
  public rounded = '4px';

  @Input()
  public paddingX = '4px';

  @Input()
  public backgroundClass = 'bg-ncis-neutral-200';

  @Input()
  public colorClass = 'text-ncis-neutral-600';

  @Input()
  public textClass = 'text-nunito-sans-100';
}
