import { NgModule } from '@angular/core';
import { ToDaySessionCodePipe } from './to-day-session-code.pipe';

@NgModule({
  declarations: [
    ToDaySessionCodePipe
  ],
  exports: [
    ToDaySessionCodePipe
  ]
})
export class ToDaySessionCodeModule {
}
