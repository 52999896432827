import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, from, Observable, of, throwError } from 'rxjs';
import {
  ARTICLE_SERVICE,
  DRUG_DETAIL_SERVICE,
  IArticleService,
  IDrugDetailService,
  IPaxmanService,
  IProcedureService,
  IRadiationService,
  IRegimenService,
  ISymptomService,
  IUiService,
  PAXMAN_SERVICE,
  PROCEDURE_SERVICE,
  RADIATION_SERVICE,
  REGIMEN_SERVICE,
  SYMPTOM_SERVICE,
  UI_SERVICE,
} from '@mobile-data-access-services';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { ToastController } from '@ionic/angular';
import { ArticleDetailComponentStore } from '@mobile-data-access-stores';
import { ArticleDetailQueryParams } from '@mobile-data-access-models';
import { IArticle } from '@mobile-data-access-interfaces';
import {
  ArticleCategories,
  SymptomsArticleTypes,
} from '@mobile-data-access-enums';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ArticleDetailGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(ARTICLE_SERVICE)
    protected readonly _articleService: IArticleService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(REGIMEN_SERVICE)
    protected readonly _regimenService: IRegimenService,
    @Inject(PROCEDURE_SERVICE)
    protected readonly _procedureService: IProcedureService,
    @Inject(RADIATION_SERVICE)
    protected readonly _radiationService: IRadiationService,
    @Inject(PAXMAN_SERVICE)
    protected readonly _paxmanService: IPaxmanService,
    @Inject(SYMPTOM_SERVICE)
    protected readonly _symptomService: ISymptomService,
    @Inject(DRUG_DETAIL_SERVICE)
    protected readonly _drugDetailService: IDrugDetailService,
    @Inject(UI_SERVICE)
    protected readonly _uiService: IUiService,
    protected readonly _toastController: ToastController,
    protected readonly _translocoService: TranslocoService,
    protected readonly _componentStore: ArticleDetailComponentStore
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const queryParams =
      activatedRouteSnapshot.queryParams as ArticleDetailQueryParams;

    const articleId = queryParams.articleId;
    const source = queryParams.source;
    const sourceId = queryParams.sourceId;
    let category: ArticleCategories | undefined = queryParams.category;

    if (!articleId || !source) {
      return false;
    }

    let getArticlesObservable: Observable<IArticle | undefined> = of(undefined);

    switch (category) {
      case ArticleCategories.PAXMAN_ARTICLE:
        getArticlesObservable =
          this._paxmanService.getArticleByIdAsync(articleId);
        break;

      case ArticleCategories.PAXMAN_ARTICLE_RELATED_TOPIC:
        getArticlesObservable =
          this._paxmanService.getRelatedTopicDetailByIdAsync(articleId);
        break;

      case ArticleCategories.RADIATION_ARTICLE_WHAT_NEXT:
        getArticlesObservable =
          this._radiationService.getWhatNextDetailAsync(articleId);
        break;

      case ArticleCategories.RADIATION_WHAT_NEXT_RELATED_TOPIC:
        getArticlesObservable =
          this._radiationService.getRelatedTopicDetailAsync(articleId);
        break;

      case ArticleCategories.CHEMOTHERAPY_ARTICLE_ORAL_MED:
        getArticlesObservable =
          this._regimenService.getArticleOralAsync(articleId);
        break;

      case ArticleCategories.CHEMOTHERAPY_ARTICLE_DOCTOR:
        getArticlesObservable =
          this._regimenService.getArticleDoctorAsync(articleId);
        break;

      case ArticleCategories.CHEMOTHERAPY_RELATED_TOPIC:
      case ArticleCategories.CHEMOTHERAPY_ARTICLE_RELATED_TOPIC:
      case ArticleCategories.CHEMOTHERAPY_RELATED_TOPIC_RELATED_TOPIC:
      case ArticleCategories.CHEMOTHERAPY_DIAGNOSIS:
        getArticlesObservable =
          this._regimenService.getChemoRelatedTopicDetailAsync(
            articleId,
            category
          );
        break;

      case ArticleCategories.DRUG_DETAIL_GENERAL_PRECAUTION:
        getArticlesObservable =
          this._drugDetailService.getDrugPrecautionArticleByIdAsync(articleId);
        break;

      case ArticleCategories.DRUG_DETAIL_ARTICLE_DOCTOR:
        getArticlesObservable =
          this._drugDetailService.getDrugDoctorArticleByIdAsync(articleId);
        break;

      case ArticleCategories.DRUG_DETAIL_ORAL_MED:
        getArticlesObservable =
          this._drugDetailService.getDrugOralArticleByIdAsync(articleId);
        break;

      case ArticleCategories.DRUG_DETAIL_OCCASION_SIDE_EFFECT:
        getArticlesObservable =
          this._drugDetailService.getDrugOccasionalSideEffectArticleByIdAsync(
            articleId
          );
        break;

      case ArticleCategories.DRUG_DETAIL_RARE_SIDE_EFFECT:
        getArticlesObservable =
          this._drugDetailService.getDrugRareSideEffectArticleByIdAsync(
            articleId
          );
        break;

      case ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT:
        getArticlesObservable =
          this._drugDetailService.getDrugRareSideEffectArticleByIdAsync(
            articleId
          );
        break;

      case ArticleCategories.DRUG_DETAIL_RELATED_TOPIC:
        getArticlesObservable =
          this._drugDetailService.getRelatedTopicDetailByIdAsync(articleId);
        break;

      case ArticleCategories.SYMPTOMS_ARTICLE_GENERAL_PRECAUTION:
        getArticlesObservable = this._symptomService.getArticleByTypeAsync(
          articleId,
          SymptomsArticleTypes.GENERAL_PRECAUTION
        );
        break;

      case ArticleCategories.SYMPTOMS_ARTICLE_RARE_SIDE_EFFECT:
        getArticlesObservable = this._symptomService.getArticleByTypeAsync(
          articleId,
          SymptomsArticleTypes.RARE_SIDE_EFFECT
        );
        break;

      case ArticleCategories.SYMPTOMS_ARTICLE_OCCASIONAL_SIDE_EFFECT:
        getArticlesObservable = this._symptomService.getArticleByTypeAsync(
          articleId,
          SymptomsArticleTypes.OCCASIONAL_SIDE_EFFECT
        );
        break;

      case ArticleCategories.SYMPTOMS_ARTICLE_LONG_TERM_SIDE_EFFECT:
        getArticlesObservable = this._symptomService.getArticleByTypeAsync(
          articleId,
          SymptomsArticleTypes.LONG_TERM_SIDE_EFFECT
        );
        break;

      case ArticleCategories.SYMPTOMS_ARTICLE_IMMUNO_SIDE_EFFECT:
        getArticlesObservable = this._symptomService.getArticleByTypeAsync(
          articleId,
          SymptomsArticleTypes.IMMUNO
        );
        break;

      case ArticleCategories.PROCEDURE_ARTICLE:
        getArticlesObservable =
          this._procedureService.getArticleDetailAsync(articleId);
        break;

      case ArticleCategories.PROCEDURE_ARTICLE_WHAT_NEXT:
        getArticlesObservable =
          this._procedureService.getWhatNextDetailAsync(articleId);
        break;

      case ArticleCategories.PROCEDURE_ARTICLE_RELATED_TOPIC:
      case ArticleCategories.PROCEDURE_RELATED_TOPIC:
      case ArticleCategories.PROCEDURE_RELATED_TOPIC_RELATED_TOPIC:
      case ArticleCategories.PROCEDURE_DIAGNOSIS:
        getArticlesObservable =
          this._procedureService.getRelatedTopicDetailAsync(
            articleId,
            category
          );
        break;

      case ArticleCategories.SYMPTOM_DETAIL_ARTICLE:
        getArticlesObservable =
          this._symptomService.getArticleDetailAsync(articleId);
        break;

      case ArticleCategories.SYMPTOM_DETAIL_ARTICLE_RELATED_TOPIC:
        getArticlesObservable =
          this._symptomService.getRelatedTopicDetailAsync(articleId);
        break;

      default:
        getArticlesObservable = throwError(
          () => new Error('ARTICLE_NOT_FOUND')
        );
        break;
    }

    const asyncHandler = async () => {
      const translatedMessage = await firstValueFrom(
        this._translocoService.selectTranslate(
          'ARTICLE_NOT_FOUND',
          {},
          { scope: 'SYSTEM_MESSAGE' }
        )
      );
      const toastInstance = await this._toastController.create({
        duration: 3000,
        color: 'danger',
        position: 'top',
        message: translatedMessage,
      });

      try {
        const article = await firstValueFrom(getArticlesObservable);

        if (article) {
          this._componentStore.patchState({
            article: { ...article, category },
            loading: false,
          });
          return true;
        }

        throw new Error('ARTICLE_NOT_FOUND');
      } catch (exception) {
        await toastInstance.present();
        return Promise.resolve(false);
      }
    };

    return from(asyncHandler());
  }

  //#endregion
}
