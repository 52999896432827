import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import SwiperCore, { SwiperOptions, Virtual } from 'swiper';
import {
  IMedicationCalendarMedicineTime,
  IMedicationCalendarOptions,
} from '@mobile-data-access-interfaces';
import { ResizeSensor } from 'css-element-queries';

SwiperCore.use([Virtual]);

@Component({
  selector: 'ncis-swipeable-calendar',
  templateUrl: 'swipeable-calendar.component.html',
  styleUrls: ['swipeable-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipeableCalendarComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  //#region Properties

  private __medicineTimes: IMedicationCalendarMedicineTime[];

  private __medicineTime?: IMedicationCalendarMedicineTime;

  private __itemsPerView = 5;

  private __itemWidth: number | undefined = undefined;

  private __itemHeight: number | undefined = undefined;

  private __itemSpace: number | undefined = undefined;

  private __oldWidth: number = -1;

  // Whether component is being loaded or not.
  private __loading = false;

  private __sizeChangeSensor: ResizeSensor | null = null;

  @Output()
  public readonly selectMedicineTime = new EventEmitter<
    IMedicationCalendarMedicineTime | undefined
  >();

  //#endregion

  //#region Accessors

  public get itemWidth(): number | undefined {
    return this.__itemWidth;
  }

  public get itemHeight(): number | undefined {
    return this.__itemHeight;
  }

  public get itemsPerView(): number {
    return this.__itemsPerView;
  }

  public get itemSpace(): number | undefined {
    return this.__itemSpace;
  }

  @Input()
  public set itemsPerView(value: number) {
    if (value < 1) {
      this.__itemsPerView = 1;
      return;
    }
    this.__itemsPerView = value;
  }

  public get medicineTimes(): IMedicationCalendarMedicineTime[] {
    return this.__medicineTimes;
  }

  @Input()
  public set medicineTimes(value: IMedicationCalendarMedicineTime[]) {
    this.__medicineTimes = value;
  }

  public get chosenMedicineTime(): IMedicationCalendarMedicineTime | undefined {
    return this.__medicineTime;
  }

  @Input()
  public set chosenMedicineTime(
    value: IMedicationCalendarMedicineTime | undefined | null
  ) {
    this.__medicineTime = value || undefined;
  }

  public get loading(): boolean {
    return this.__loading;
  }

  @Input()
  public set loading(value: boolean) {
    this.__loading = value;
  }

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    protected readonly _ngZone: NgZone
  ) {
    this.__medicineTimes = [];
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.__sizeChangeSensor = new ResizeSensor(
      this._elementRef.nativeElement,
      ({ height, width }) => {
        if (this.__oldWidth === width) {
          return;
        }

        this.__oldWidth = width;

        // Base on the items per view to calculate item size & space between items.
        const itemSize = Math.floor(width / this.itemsPerView);
        const space = width - itemSize * this.itemsPerView;

        this.__itemWidth = itemSize;
        this.__itemHeight = height;
        this.__itemSpace = Math.floor(space / this.itemsPerView);

        this._changeDetectorRef.markForCheck();
      }
    );
  }

  public ngOnDestroy(): void {
    this.__sizeChangeSensor?.detach();
  }

  //#endregion

  //#region Methods

  public chooseMedicineTime(
    item: IMedicationCalendarMedicineTime | undefined
  ): void {
    this.__medicineTime = item;
    this.selectMedicineTime.next(item);
  }

  //#endregion
}
