import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {map, mergeMap, Observable} from 'rxjs';
import {LocalForageService} from 'ngx-localforage';
import {OAuthCallbackQueryParams} from '@mobile-data-access-models';
import {Languages, StorageKeys} from '@mobile-data-access-enums';
import {I18N_SERVICE, I18nService} from '@mobile-data-access-services';
import {TranslocoService} from '@ngneat/transloco';

@Injectable()
export class OAuthCallbackGuard implements CanActivate {

    //#region Constructor

    public constructor(
        @Inject(I18N_SERVICE)
        protected readonly _i18nService: I18nService,
        protected readonly _translateService: TranslocoService,
        protected readonly _storage: LocalForageService) {
    }

    //#endregion

    //#region Methods

    public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const queryParams = activatedRouteSnapshot.queryParams as OAuthCallbackQueryParams;
        const language = queryParams.language || Languages.EN;

        if (!queryParams.language || !queryParams.language.length) {
            return  this._storage.setItem(StorageKeys.LANGUAGE, queryParams.language || Languages.EN)
                .pipe(
                    map(() => {
                        this._translateService.setActiveLang(language);
                        return true;
                    })
                );
        }

        return this._i18nService.getAvailableLanguagesAsync()
            .pipe(
                mergeMap(availableLanguages => {
                    const itemIndex = (availableLanguages || []).indexOf(queryParams.language!);
                    const language = itemIndex !== -1 ? availableLanguages[itemIndex] : Languages.EN;
                    this._translateService.setActiveLang(language);
                    return this._storage.setItem(StorageKeys.LANGUAGE, language);
                }),
                map(() => true)
            );
    }


    //#endregion

}
