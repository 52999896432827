import { ChemotherapyBaseWithoutLoading, IChemotherapyBaseState } from "./base.state";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, EMPTY, filter, finalize, map, Observable, pipe, switchMap, take, tap } from "rxjs";
import { ActivatedRoute } from "@angular/router";

export abstract class ChemotherapyBaseComponentStore<TState extends IChemotherapyBaseState> extends ComponentStore<TState> {

  protected constructor(
    protected readonly _activatedRoute: ActivatedRoute,
    protected readonly _initState: TState,
  ) {
    super({
      ..._initState
    });
  }

  public get state(): TState {
    return this.get();
  }

  public readonly load = <TKey extends ChemotherapyBaseWithoutLoading<TState>>(key: TKey, call: (id: string) => Observable<TState[TKey]>) => {
    const effect = this.effect<never>(
      pipe(
        tap(() => this.updateProperty(`${key}Loading` as keyof TState, true as unknown as TState[TKey])),
        switchMap(() =>
          call(this.state.id as string)
            .pipe(
              tap((data) => this.updateProperty(key, data)),
              finalize(() => this.updateProperty(`${key}Loading` as keyof TState, false as unknown as TState[TKey]))
            )
        ),
        catchError((err) => {
          this.updateProperty(`${key}Loading` as keyof TState, false as unknown as TState[TKey]);
          console.error(err);
          return EMPTY;
        })
      )
    );
    effect();
  };

  public readonly updateProperty = <TKey extends keyof TState, TValue extends TState[TKey] = TState[TKey]>(key: TKey, value: TValue) => {
    this.updater<TValue>(
      (state, value) => ({
        ...state,
        [key]: value
      })
    )(value);
  };

  public readonly initialize = this.effect<never>(
    pipe(
      switchMap(() => this.getIdAsync()),
      filter((id) => !!id),
      tap((id) => this.updateProperty('id', id)),
      tap(() => this.setup())
    )
  );

  public getIdAsync(): Observable<string> {
    return this._activatedRoute.queryParams.pipe(
      map((queryParams) => queryParams['id']),
      take(1)
    )
  }

  public abstract setup(): void;
}
