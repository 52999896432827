import { NgModule } from '@angular/core';
import { TreatmentCycleComponent } from './treatment-cycle.component';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import {ToTrustedHtmlPipeModule} from "@ui-tool/core";

@NgModule({
  declarations: [TreatmentCycleComponent],
    imports: [CommonModule, TranslocoModule, ToTrustedHtmlPipeModule],
  exports: [TreatmentCycleComponent],
})
export class TreatmentCycleModule {}
