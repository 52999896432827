import { Inject, Injectable } from '@angular/core';
import { IChemotherapyTreatmentDetailState } from './treatment-detail.state';
import {
  ARTICLE_SERVICE,
  BOOKMARK_SERVICE,
  IArticleService,
  IBookmarkService,
  IRegimenService,
  REGIMEN_SERVICE,
} from '@mobile-data-access-services';
import { ActivatedRoute } from '@angular/router';
import { ChemotherapyBaseComponentStore } from '../../base';
import {
  ArticleCategories,
  ChemotherapyControlCodes,
  ScreenCodes,
} from '@mobile-data-access-enums';
import {
  catchError,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  IArticle,
  IChemotherapyQueryParams,
  IRelatedTopicCard,
} from '@mobile-data-access-interfaces';
import {
  ChemotherapyArticleDoctorNavigationRequest,
  ChemotherapyArticleOralMedNavigationRequest,
  ChemotherapyQueryParams,
  ChemotherapyRelatedTopicNavigationRequest,
} from '@mobile-data-access-models';
import { NavController } from '@ionic/angular';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';

@Injectable()
export class ChemotherapyTreatmentDetailComponentStore extends ChemotherapyBaseComponentStore<IChemotherapyTreatmentDetailState> {
  //#region Constructor

  public constructor(
    @Inject(REGIMEN_SERVICE)
    protected readonly _regimenService: IRegimenService,
    @Inject(ARTICLE_SERVICE)
    protected readonly _articleService: IArticleService,
    @Inject(BOOKMARK_SERVICE)
    protected readonly _bookmarkService: IBookmarkService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _navController: NavController,
    protected override readonly _activatedRoute: ActivatedRoute
  ) {
    super(_activatedRoute, {
      id: null,
      basicInfoLoading: false,
      basicInfo: null,
      overviewLoading: false,
      overview: null,
      treatmentLoading: false,
      treatment: null,
      articlesLoading: false,
      articles: null,
      sideEffectLoading: false,
      sideEffect: null,
      topicsLoading: false,
      topics: null,
      tagsLoading: false,
      tags: null,
    });
  }

  //#endregion

  //#region Methods

  public setup(): void {
    this.load('overview', (id) =>
      this._regimenService.getChemoAsync<
        IChemotherapyTreatmentDetailState['overview']
      >(id, ChemotherapyControlCodes.OVERVIEW)
    );
    this.load('treatment', (id) =>
      this._regimenService
        .getChemoAsync<IChemotherapyTreatmentDetailState['treatment']>(
          id,
          ChemotherapyControlCodes.TREATMENT
        )
        .pipe(
          mergeMap((treatment) => {
            let actualCycle = parseInt(`${treatment?.cycle}`);
            if (isNaN(actualCycle)) {
              actualCycle = 0;
            }

            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            return this._regimenService
              .getCycleCalculationAsync({
                deviceTime: new Date().toISOString(),
                timezone: timezone,
              })
              .pipe(
                map((calculationResult) => {
                  return {
                    ...treatment,
                    cycle: actualCycle,
                    dayOfCycle: calculationResult.dayOfCycle,
                    currentCycle: calculationResult.currentCycle,
                  } as IChemotherapyTreatmentDetailState['treatment'];
                })
              );
          })
        )
    );
    this.load('articles', (id) =>
      this._regimenService.getArticlesByChemotherapyIdAsync(id).pipe(
        tap(() => this.updateProperty('articlesLoading', false)),
        catchError(() => {
          this.updateProperty('articlesLoading', false);
          return of([]);
        })
      )
    );
    this.load('sideEffect', (id) =>
      this._regimenService.getChemoAsync<
        IChemotherapyTreatmentDetailState['sideEffect']
      >(id, ChemotherapyControlCodes.SIDE_EFFECT)
    );
    this.load('topics', (id) =>
      this._regimenService.getChemoAsync<
        IChemotherapyTreatmentDetailState['topics']
      >(id, ChemotherapyControlCodes.RELATED_TOPICS)
    );
    this.load('tags', (id) =>
      this._regimenService.getChemoAsync<
        IChemotherapyTreatmentDetailState['tags']
      >(id, ChemotherapyControlCodes.TAGS)
    );
  }

  public getChemotherapyIdAsync(): Observable<string> {
    return this._activatedRoute.queryParams.pipe(
      take(1),
      map((x) => x as IChemotherapyQueryParams),
      map((x) => x.id)
    );
  }

  public toArticlePage = this.effect<IArticle>(
    pipe(
      switchMap((article) => {
        return this._activatedRoute.queryParams.pipe(
          take(1),
          map((queryParams) => queryParams as ChemotherapyQueryParams),
          map((queryParams) => {
            switch (article.category) {
              case ArticleCategories.CHEMOTHERAPY_ARTICLE_ORAL_MED:
                return new ChemotherapyArticleOralMedNavigationRequest(
                  ScreenCodes.CHEMOTHERAPY,
                  queryParams.id,
                  article.id
                );

              case ArticleCategories.CHEMOTHERAPY_ARTICLE_DOCTOR:
                return new ChemotherapyArticleDoctorNavigationRequest(
                  ScreenCodes.CHEMOTHERAPY,
                  queryParams.id,
                  article.id
                );

              default:
                return null;
            }
          }),
          filter(
            (navigationRequest) =>
              navigationRequest !== null && navigationRequest !== undefined
          ),
          mergeMap((navigationRequest) => {
            const urlTree = this._navigationService.buildUrlTree(
              navigationRequest!.code,
              navigationRequest!.routeParams || {},
              navigationRequest!.extras
            );
            return from(this._navController.navigateForward(urlTree));
          })
        );
      })
    )
  );

  public toRelatedTopicPage = this.effect<IRelatedTopicCard>(
    pipe(
      switchMap((relatedTopic) => {
        return this._activatedRoute.queryParams.pipe(
          take(1),
          map((queryParams) => queryParams as ChemotherapyQueryParams),
          map((queryParams) => {
            return new ChemotherapyRelatedTopicNavigationRequest(
              ScreenCodes.CHEMOTHERAPY,
              queryParams.id,
              relatedTopic.id,
              relatedTopic.category as any
            );
          }),
          filter(
            (navigationRequest) =>
              navigationRequest !== null && navigationRequest !== undefined
          ),
          mergeMap((navigationRequest) => {
            const urlTree = this._navigationService.buildUrlTree(
              navigationRequest!.code,
              navigationRequest!.routeParams || {},
              navigationRequest!.extras
            );
            return from(this._navController.navigateForward(urlTree));
          })
        );
      })
    )
  );

  //#endregion
}
