import { IDiagnosisService } from "./diagnosis-service.interface";
import { Inject, Injectable } from "@angular/core";
import { map, mergeMap, Observable } from "rxjs";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "@shared-interfaces";
import { DiagnosisControlCodes } from "@mobile-data-access-enums";

@Injectable()
export class DiagnosisService implements IDiagnosisService {

  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {
  }

  //#endregion

  //#region Chemo

  public getChemoAsync<T>(id: string, code: DiagnosisControlCodes): Observable<T> {
    return this._getDataAsync<T>(code, id);
  }

  //#endregion Chemo

  //#region Methods

  protected _getDataAsync<T>(controlCode: string, id: string): Observable<T> {
    return this._getUrlAsync(`diagnosis/detail/${controlCode}`).pipe(
      mergeMap((apiUrl) => {
        const httpParams: Record<string, string> = {};
        httpParams["id"] = id;

        return this._httpClient
          .get<IApiResult<T>>(apiUrl, { params: httpParams })
          .pipe(map(({ data }) => data));
      })
    );
  }

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._apiEndpointResolver.loadEndPointAsync("", "").pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion
}
