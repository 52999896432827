import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPaxmanState } from './paxman.state';
import { paxmanStoreKey } from './paxman.reducer';

const paxmanStoreSelector = createFeatureSelector<IPaxmanState>(
  paxmanStoreKey
);

export class PaxmanSelectors {

  //#region Properties

  public static readonly available = createSelector(
      paxmanStoreSelector,
      (state) => state.available
  );

  public static readonly activated = createSelector(
      paxmanStoreSelector,
      (state) => state.activated
  );

  public static readonly checked = createSelector(
      paxmanStoreSelector,
      (state) => state.checked
  );

  public static readonly info = createSelector(
      paxmanStoreSelector,
      (state) => state.info
  );

  //#endregion

}
