import { createReducer, on } from "@ngrx/store";
import { UserActions } from "./user.action";
import { userStoreInitialState } from "./user.adapter";

export const userStoreKey = 'user';

export const userReducer = createReducer(
  userStoreInitialState,
  on(UserActions.saveProfile, (state, action) => ({
    ...state,
    profile: action.data,
  })),
  on(UserActions.clearProfile, (state, action) => ({
    ...state,
    profile: null
  })),
);
