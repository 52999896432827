import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';
import {ArticleDetailNavigationRequest} from '../article-detail-navigation-request';

export class PaxmanArticleNavigationRequest extends ArticleDetailNavigationRequest {

    //#region Constructor

    public constructor(source: ScreenCodes | undefined, articleId: string) {
        super(source, '', ArticleCategories.PAXMAN_ARTICLE, articleId);
    }

    //#endregion

}
