<section class="version__container">
  <div class="version__backdrop"></div>
  <article class="version">
    <header>
      <img alt="NUHS" class="object-cover w-full relative -top-8" src="/ncis-assets/images/version/logo-NUHS.png" />
      <button (click)="continueEvent.emit()">
        <ncis-common-icon
          [folder]="'general'"
          [name]="'close'"
          [extraClass]="'h-5 w-5 text-ncis-neutral-800'"
        ></ncis-common-icon>
      </button>
    </header>
    <main>
      <h2 [innerHTML]="'VERSION_PAGE.HEADER' | transloco"></h2>
      <section>
        <ng-container *ngFor="let feature of versionUpdates.features">
          <article>
            <h5 [innerHTML]="feature.title | unescape | toTrustedHtml"></h5>
            <ul *ngIf="feature.descriptions.length">
              <li
                *ngFor="let description of feature.descriptions"
                [innerHTML]="description | unescape | toTrustedHtml"
              ></li>
            </ul>
          </article>
        </ng-container>
      </section>
      <p *ngIf="showBottom">
        {{ 'VERSION_PAGE.DESCRIPTION' | transloco }}
      </p>
    </main>
    <footer>
      <button (click)="continueEvent.emit()">
        {{ 'VERSION_PAGE.BUTTONS_CONTINUE' | transloco }}
      </button>
    </footer>
  </article>
</section>
