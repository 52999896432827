import { NgModule } from '@angular/core';
import { TrackerRecordWarningCardComponent } from './tracker-record-warning-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { AssetModule, CategoryModule, SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { TranslocoModule } from '@ngneat/transloco';
import { InlineSVGModule } from "ng-inline-svg-2";
import { UnescapePipeModule } from "@shared-pipes";
import { ToTrustedHtmlPipeModule } from "@ui-tool/core";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    CategoryModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    AssetModule,
    TranslocoModule,
    InlineSVGModule,
    UnescapePipeModule,
    ToTrustedHtmlPipeModule
  ],
  declarations: [TrackerRecordWarningCardComponent],
  exports: [TrackerRecordWarningCardComponent],
})
export class TrackerRecordWarningCardModule {}
