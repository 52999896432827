import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { ISystemService, SYSTEM_SERVICE } from '@mobile-data-access-services';
import { SystemStatuses } from '@mobile-data-access-enums';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { DashboardNavigationRequest } from '@mobile-data-access-models';

@Injectable()
export class UnderMaintenanceGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(SYSTEM_SERVICE) protected readonly _systemService: ISystemService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._systemService.getStatusAsync().pipe(
      map((status) => {
        if (status === SystemStatuses.UNDER_MAINTENANCE) {
          return true;
        }

        const dashboardNavigationRequest = new DashboardNavigationRequest();
        return this._navigationService.buildUrlTree(
          dashboardNavigationRequest.code,
          dashboardNavigationRequest.routeParams,
          dashboardNavigationRequest.routeParams
        );
      }),
      catchError((exception) => {
        return of(true);
      })
    );
  }

  //#endregion
}
