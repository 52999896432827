import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAsset } from '@mobile-data-access-interfaces';
import { SwiperOptions } from 'swiper';
import { AssetTypes } from '@mobile-data-access-enums';

@Component({
  selector: 'ncis-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetComponent {
  //#region Properties
  @Input()
  public data: IAsset[] | null = null;

  @Input()
  public fullWidth = false;

  @Input()
  public fullHeight = false;

  @Input()
  public showOnlyType: AssetTypes | null = null;
  public readonly swiperOptions: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 1,
  };
  public readonly AssetTypes = AssetTypes;

  public get assets(): IAsset[] | null {
    if (!this.data || !this.showOnlyType) {
      return this.data;
    }

    return this.data.filter((item) => item.type === this.showOnlyType);
  }

  //#endregion
}
