import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IUserState } from "./user.state";
import { userStoreKey } from "./user.reducer";

const userStoreSelector = createFeatureSelector<IUserState>(
  userStoreKey
);

export class UserSelectors {

  //#region Properties

  public static readonly profile = createSelector(
      userStoreSelector,
      (state) => state.profile
  );

  //#endregion

}
