import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IOverview } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewComponent {
  //#region Properties

  @Input()
  public title = '';

  @Input()
  public data: IOverview | undefined;

  @Input()
  public loading = false;

  @Input()
  public strapiRenderDescription = false;

  @Input()
  public strapiRenderTitle = false;
  //#endregion
}
