import { NgModule } from '@angular/core';
import { CONTAINER_SERVICE } from './container-service.token';
import { ContainerService } from './container.service';

@NgModule({
  providers: [
    {
      provide: CONTAINER_SERVICE,
      useClass: ContainerService,
    },
  ],
})
export class ContainerServiceModule {}
