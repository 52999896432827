import { NgModule } from '@angular/core';
import { REGIMEN_SERVICE } from './regimen-service.token';
import { RegimenService } from './regimen.service';

@NgModule({
  providers: [
    {
      provide: REGIMEN_SERVICE,
      useClass: RegimenService,
    },
  ],
})
export class RegimenServiceModule {}
