import { NgModule } from '@angular/core';
import { ToSessionGreetingMessagePipe } from './to-session-greeting-message.pipe';

@NgModule({
  declarations: [
    ToSessionGreetingMessagePipe
  ],
  exports: [
    ToSessionGreetingMessagePipe
  ]
})
export class ToSessionGreetingMessageModule {

}
