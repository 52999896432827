import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import {
  ITreatment,
  ITreatmentCycleOptions,
  ITreatmentCycleTime,
  ITreatmentScheduleOptions,
  ITreatmentWithCycle
} from "@mobile-data-access-interfaces";
import { ChemotherapyAdministrations, COLORS } from "@mobile-data-access-enums";
import { ITreatmentColorService, TREATMENT_COLOR_SERVICE } from "@mobile-data-access-services";
import { TRANSLOCO_SCOPE, TranslocoService } from "@ngneat/transloco";
import { of, Subject, Subscription, switchMap, tap } from "rxjs";

@Component({
  selector: "ncis-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: "TREATMENT_CYCLE",
        alias: "TREATMENT_CYCLE"
      }
    }
  ]
})
export class ScheduleComponent implements OnInit, OnDestroy {
  //#region Properties

  @Input()
  public title = "";

  private readonly __buildTreatmentCycleMessage$: Subject<void>;

  private __dayOfCycle?: number;

  private __currentCycle?: number;

  protected _subscription: Subscription;

  @Input()
  public loading = false;
  @Input()
  public description = "";

  public readonly cycleLoadingOptions: ITreatmentCycleOptions = {
    color: "#E3E3E3",
    backgroundColor: "#FFFFFF",
    padding: 10,
    lineWidth: 28,
    cycle: 30,
    dimension: "width",
    lines: [
      {
        color: "rgba(248, 248, 248, 0.8)",
        days: Array.from({ length: 24 }).map((_, i) => (i + 1).toString())
      }
    ]
  };

  public cycleOptions!: ITreatmentCycleOptions;

  public scheduleOptions!: ITreatmentScheduleOptions;

  protected _data: ITreatment | undefined;

  //#endregion

  //#region Accessors

  public get data(): ITreatment | undefined {
    return this._data;
  }
  private __treatmentCycleMessage = "";

  public constructor(
    @Inject(TREATMENT_COLOR_SERVICE)
    protected readonly _treatmentColorService: ITreatmentColorService,
    protected readonly _translateService: TranslocoService
  ) {
    this.__buildTreatmentCycleMessage$ = new Subject<void>();
    this._subscription = new Subscription();
  }

  public get treatmentCycle(): { word: string, cycle: number } {
    const cycle = this.data?.cycle ? this.data?.cycle : 0;
    if (cycle === 1) {
      return {
        word: "TREATMENT_CYCLE.TITLE_DAY",
        cycle
      };
    } else {
      return {
        word: "TREATMENT_CYCLE.TITLE_DAYS",
        cycle
      };
    }
  }

  public get dayOfCycle(): number | undefined {
    return this.__dayOfCycle;
  }

  public get treatmentCycleMessage(): string {
    return this.__treatmentCycleMessage;
  }

  //#endregion

  //#region Constructor

  @Input()
  public set data(data: ITreatmentWithCycle | undefined) {
    this._data = data;
    this.cycleOptions = {
      color: this._treatmentColorService.getByCode(ChemotherapyAdministrations.REST),
      backgroundColor: "#FFFFFF",
      padding: 10,
      lineWidth: 28,
      cycle: parseInt(`${data?.cycle}`),
      dimension: "width",
      lines: data
        ? data.groups.map((group) => {
          return {
            color: group.color,
            days: group.days
          } as ITreatmentCycleTime;
        })
        : []
    };
    this.scheduleOptions = {
      color: COLORS.TREATMENT_REST_AT_HOME,
      blocks: 7,
      gap: 3,
      cycle: parseInt(`${data?.cycle}`),
      blockSize: 26,
      blockRadius: 6,
      alignRight: true,
      lines: data
        ? data.groups.map((group) => {
          return {
            color: this._treatmentColorService.getByCode(
              group.administration
            ),
            days: group.days
          } as ITreatmentCycleTime;
        })
        : []
    };

    this.__dayOfCycle = data?.dayOfCycle || 0;
    this.__currentCycle = data?.currentCycle;
    this.__buildTreatmentCycleMessage$.next();
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnInit(): void {

    const buildTreatmentCycleMessageSubscription = this.__buildTreatmentCycleMessage$
      .pipe(
        switchMap(() => {

          if (this.__dayOfCycle === undefined || this.__currentCycle === undefined || this.__currentCycle < 1) {
            return of("");
          }

          if (this.__dayOfCycle > 0) {
            return this._translateService
              .selectTranslate("YOU_ARE_CURRENTLY_ON_X_DAY", { day: this.__dayOfCycle }, { scope: "TREATMENT_CYCLE" });
          }

          return this._translateService
            .selectTranslate("CYCLE_X_WILL_START_IN_X_DAY",
              { cycle: this.__currentCycle, daysToStart: Math.abs(this.__dayOfCycle) }, { scope: "TREATMENT_CYCLE" });
        }),
        tap(message => this.__treatmentCycleMessage = message)
      )
      .subscribe();
    this._subscription.add(buildTreatmentCycleMessageSubscription);

    this.__buildTreatmentCycleMessage$.next();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  //#endregion

  //#region Internal methods


  //#endregion
}
