import { NgModule } from '@angular/core';
import { RadiationService } from './radiation.service';
import { RADIATION_SERVICE } from './radiation-service.token';

@NgModule({
  providers: [
    {
      provide: RADIATION_SERVICE,
      useClass: RadiationService,
    },
  ],
})
export class RadiationServiceModule {}
