<section *ngIf="!hidden" class="tracker-section">
  <header>
    <h3>{{ title }}</h3>
    <button *ngIf="ableToViewAll" (click)="viewAllEvent.emit()">
      <span>VIEW ALL</span>
      <ncis-common-icon
        class="text-ncis-neutral-600 w-2.5 h-2.5"
        folder="general"
        name="right-arrow"
      ></ncis-common-icon>
    </button>
  </header>
  <main>
    <swiper [config]="swiperOptions" class="tracker-section__swiper">
      <ng-container *ngIf="!loading; else skeletonTemplate">
        <ng-container *ngFor="let item of data || []">
          <ng-template swiperSlide>
            <ncis-tracker-effect-card
              (click)="viewItemEvent.emit(item)"
              [data]="item"
            ></ncis-tracker-effect-card>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #skeletonTemplate>
        <ng-container *ngFor="let item of [1, 2, 3]">
          <ng-template swiperSlide>
            <ncis-tracker-effect-card
              [loading]="true"
            ></ncis-tracker-effect-card>
          </ng-template>
        </ng-container>
      </ng-template>
    </swiper>
  </main>
</section>
