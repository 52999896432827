import { Inject, Injectable } from '@angular/core';
import { NavigatorDiagnosisState } from './navigator-diagnosis.state';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import {
  NavigatorContainerCodes,
  ScreenCodes,
} from '@mobile-data-access-enums';
import { catchError, EMPTY, exhaustMap, pipe } from "rxjs";
import { ChemotherapyNavigationRequest } from '@mobile-data-access-models';
import { NavigatorBaseComponentStore } from '../base';
import { NavController } from '@ionic/angular';

@Injectable()
export class NavigatorDiagnosisComponentStore extends NavigatorBaseComponentStore<
  NavigatorContainerCodes.DIAGNOSIS,
  NavigatorDiagnosisState
> {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected override readonly _navigationService: ISmartNavigatorService,
    protected override readonly _navController: NavController
  ) {
    super(
      _navigationService,
      _navController,
      ScreenCodes.CHEMOTHERAPY_DIAGNOSIS
    );
  }

  //#endregion

  //#region Properties

  public readonly goToDetailPage = this.effect<string>(
    pipe(
      exhaustMap((id) => {
        return this._navigationService.navigateToScreenAsync(
          new ChemotherapyNavigationRequest(this._screenCode, {
            id,
          })
        ).pipe(
          catchError((err) => {
            console.error(err);
            return EMPTY;
          })
        );
      }),
    )
  );

  //#endregion
}
