import {NavigateToScreenRequest} from '@ui-tool/core';
import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';
import {Any} from '@shared-types';
import {ArticleDetailQueryParams} from '../query-params';

export class ArticleDetailNavigationRequest extends NavigateToScreenRequest<Any> {
    //#region Constructor

    public constructor(source: ScreenCodes | undefined, sourceId: string, category: ArticleCategories, articleId: string) {
        super(
            ScreenCodes.DETAILED_ARTICLE,
            {},
            {
                queryParams: new ArticleDetailQueryParams(source, sourceId, articleId, category),
            }
        );
    }

    //#endregion
}
