import { INotificationService } from './notification-service.interface';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ui-tool/core';
import { Observable } from 'rxjs';
import { IRpcService, RPC_SERVICE, RpcMessage } from '@message-bus/core';
import { MessageNamespaces, NotificationMethods } from '@shared-enums';
import { Any } from '@shared-types';
import { PlatformService } from '../../common';
import { CreateNotificationNativeMethod } from '@mobile-data-access-models';
import { DeleteNotificationByIdNativeMethod } from '@mobile-data-access-models';
import {
  GetNotificationByIdNativeMethod,
  UpdateNotificationNativeMethod,
} from '@mobile-data-access-models';

@Injectable()
export class NotificationService
  extends PlatformService
  implements INotificationService
{
  //#region Constructor

  public constructor(
    @Inject(WINDOW) window: Window,
    @Inject(RPC_SERVICE) protected readonly _rpcService: IRpcService
  ) {
    super(window);
  }

  //#endregion

  //#region Methods

  public createAsync(
    time: Date,
    title: string,
    body: string
  ): Observable<string> {
    const method = new CreateNotificationNativeMethod(
      time.toISOString(),
      title,
      body
    );
    return this._rpcService.sendRequestAsync<
      CreateNotificationNativeMethod,
      string
    >(method);
  }

  public deleteAsync(id: string): Observable<void> {
    const message = new DeleteNotificationByIdNativeMethod(id);
    return this._rpcService.sendRequestAsync<
      DeleteNotificationByIdNativeMethod,
      void
    >(message, 30000);
  }

  public getByIdAsync(id: string): Observable<any> {
    const message = new GetNotificationByIdNativeMethod(id);
    return this._rpcService.sendRequestAsync<
      GetNotificationByIdNativeMethod,
      void
    >(message, 30000);
  }

  public updateAsync(
    id: string,
    time?: Date,
    title?: string,
    body?: string
  ): Observable<string> {
    const method = new UpdateNotificationNativeMethod(
      id,
      time?.toISOString(),
      title,
      body
    );
    return this._rpcService.sendRequestAsync<
      UpdateNotificationNativeMethod,
      string
    >(method, 30000);
  }

  //#endregion
}
