import { ModuleWithProviders, NgModule } from '@angular/core';
import { REQUIREMENT_HANDLER, RequirementSentinelModule } from '@ui-tool/core';
import { AbleToUseMedicationModuleRequirementHandler } from './able-to-use-medication-module.requirement-handler';
import { AbleToUseTrackerModuleRequirementHandler } from './able-to-use-tracker-module.requirement-handler';
import { AbleToViewBottomBarRequirementHandler } from './able-to-view-bottom-bar.requirement-handler';

@NgModule({
  imports: [RequirementSentinelModule.forRoot()],
})
export class RequirementHandlersModule {
  //#region Static methods

  public static forRoot(): ModuleWithProviders<RequirementHandlersModule> {
    return {
      ngModule: RequirementHandlersModule,
      providers: [
        {
          provide: REQUIREMENT_HANDLER,
          useClass: AbleToUseMedicationModuleRequirementHandler,
          multi: true,
        },
        {
          provide: REQUIREMENT_HANDLER,
          useClass: AbleToUseTrackerModuleRequirementHandler,
          multi: true,
        },
        {
          provide: REQUIREMENT_HANDLER,
          useClass: AbleToViewBottomBarRequirementHandler,
          multi: true,
        },
      ],
    };
  }

  //#endregion
}
