<div class="health-tracker-card">
  <ng-container *ngIf="!loading; else skeletonTemplate">
    <div class="health-tracker-card__information">
      <ncis-category [title]="data.category"></ncis-category>
      <h3>{{ data.title || 'N/A' }}</h3>
      <p>{{ data.description || 'N/A' }}</p>
    </div>

    <div class="health-tracker-card__listing">
      <ng-container *ngFor="let symptom of data.symptoms; let i = index">
        <div
          class="health-tracker-card__symptom"
          (click)="selectItemEvent.emit(symptom)"
        >
          <span>{{ i | toSymptomNumber }}</span>
          <div class="health-tracker-card__symptom__content">
            <h3>{{ symptom.title || 'N/A' }}</h3>
            <p>{{ symptom.description || 'N/A' }}</p>
          </div>
          <ncis-common-icon
            [folder]="CommonIconFolder.GENERAL"
            [name]="CommonIconGeneral.RIGHT_ARROW"
            class="text-ncis-neutral-800 absolute right-0 top-1 h-[22px] w-[22px]"
          ></ncis-common-icon>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #skeletonTemplate>
    <div class="health-tracker-card__information">
      <ncis-skeleton class="w-16 h-4"></ncis-skeleton>
      <ncis-skeleton class="w-[100px] h-[22px]"></ncis-skeleton>
      <ncis-skeleton class="w-[300px] h-[20px]"></ncis-skeleton>
    </div>
    <div class="health-tracker-card__listing">
      <ng-container *ngFor="let symptom of [1, 2, 3, 4, 5]; let i = index">
        <div class="health-tracker-card__symptom">
          <ncis-skeleton class="w-[30px] h-[34px]"></ncis-skeleton>
          <div class="health-tracker-card__symptom__content">
            <ncis-skeleton class="w-[100px] h-[22px]"></ncis-skeleton>
            <ncis-skeleton class="w-[266px] h-[20px]"></ncis-skeleton>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
