import { NgModule } from '@angular/core';
import { ActionCardComponent } from './action-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';

@NgModule({
  imports: [CommonModule, IconModule, SkeletonModule, HiddenImageOnErrorModule],
  declarations: [ActionCardComponent],
  exports: [ActionCardComponent],
})
export class ActionCardModule {}
