import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IRelatedTopicCard } from '@mobile-data-access-interfaces';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'ncis-related-topic-card',
  templateUrl: './related-topic-card.component.html',
  styleUrls: ['./related-topic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'RELATED_TOPIC_COMPONENT',
        alias: 'RELATED_TOPIC_COMPONENT',
      },
    },
  ],
})
export class RelatedTopicCardComponent {
  //#region Properties

  private __loading = false;

  @Input()
  public data!: IRelatedTopicCard;

  public readonly IconFolders = CommonIconFolderEnum;
  public readonly Icons = CommonIconGeneralEnum;

  //#endregion

  //#region Accessors

  public get loading(): boolean {
    return this.__loading;
  }

  @Input()
  public set loading(value: boolean) {
    this.__loading = value;
  }

  //#endregion
}
