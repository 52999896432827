import { NgModule } from '@angular/core';
import { AUTHENTICATION_SERVICE } from './authentication-service.token';
import { AuthenticationService } from './authentication.service';
import { ApiEndpointResolverModule } from '@mobile-data-access-resolvers';

@NgModule({
  imports: [ApiEndpointResolverModule],
  providers: [
    {
      provide: AUTHENTICATION_SERVICE,
      useClass: AuthenticationService,
    },
  ],
})
export class AuthenticationServiceModule {}
