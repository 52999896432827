import { NgModule } from '@angular/core';
import { VersionComponent } from './version.component';
import { CommonModule } from '@angular/common';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { UnescapePipeModule } from '@shared-pipes';
import { IconModule } from '@shared-components';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [VersionComponent],
  exports: [VersionComponent],
  imports: [
    CommonModule,
    ToTrustedHtmlPipeModule,
    UnescapePipeModule,
    IconModule,
    TranslocoModule
  ]
})
export class VersionComponentModule {}
