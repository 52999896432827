import {II18nService} from './i18n-service.interface';
import {Inject, Injectable} from '@angular/core';
import {mergeMap, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_SETTINGS_SERVICE, IAppSettingsService} from '@mobile-data-access-services';

@Injectable()
export class I18nService implements II18nService {

    //#region Constructor

    public constructor(@Inject(APP_SETTINGS_SERVICE)
                       protected readonly _appSettingService: IAppSettingsService,
                       protected readonly _httpClient: HttpClient) {
    }

    //#endregion

    //#region Methods

    public getAvailableLanguagesAsync(): Observable<string[]> {
        return this._appSettingService.loadSettingsAsync(false)
            .pipe(
                mergeMap(appSettings => {
                    const apiUrl = `${appSettings.i18n?.apiUrl}/i18n/locales`;
                    return this._httpClient.get<string[]>(apiUrl);
                })
            );
    }

    //#endregion
}
