import { DashboardState } from "./dashboard.state";
import { dashboardInitialState } from "./dashboard.constants";
import { Inject, Injectable } from "@angular/core";
import { DashboardContainerCodes } from "@mobile-data-access-enums";
import { PageComponentStore } from "../../shared";
import { ActivatedRoute, Params } from "@angular/router";
import { map, OperatorFunction, pipe } from "rxjs";
import { ISpinnerService, SPINNER_SERVICE } from "@ui-tool/core";

@Injectable()
export class DashboardComponentStore extends PageComponentStore<
  DashboardContainerCodes,
  DashboardState
> {
  //#region Constructor

  public constructor(
    protected override readonly _activatedRoute: ActivatedRoute,
    @Inject(SPINNER_SERVICE) protected override readonly _spinnerService: ISpinnerService
  ) {
    super(dashboardInitialState, _activatedRoute, _spinnerService, false);
  }

  //#endregion
  public override flow(): OperatorFunction<Params, void> {
    return pipe(map(() => void 0));
  }
}
