import { NgModule } from '@angular/core';
import { RelatedTopicCardComponent } from './related-topic-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { SkeletonModule } from '../../shared';
import {
  AspectRatioDirectiveModule,
  HiddenImageOnErrorModule,
} from '@shared-directives';
import { UnescapePipeModule } from '@shared-pipes';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    HiddenImageOnErrorModule,
    SkeletonModule,
    UnescapePipeModule,
    ToTrustedHtmlPipeModule,
    AspectRatioDirectiveModule,
    TranslocoModule,
  ],
  declarations: [RelatedTopicCardComponent],
  exports: [RelatedTopicCardComponent],
})
export class RelatedTopicCardModule {}
