import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';

export class MaintenanceNavigationRequest extends NavigateToScreenRequest<any> {
  //#region Constructor

  public constructor() {
    super(ScreenCodes.MAINTENANCE);
  }

  //#endregion
}
