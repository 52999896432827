import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import Swiper, { EffectCoverflow, SwiperOptions } from 'swiper';
import { ITopicCard } from '@mobile-data-access-interfaces';

Swiper.use([EffectCoverflow]);

@Component({
  selector: 'ncis-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicsComponent {
  //#region Properties

  public readonly swiperOptions: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 0,
      slideShadows: false,
      stretch: 0,
      depth: 0,
      modifier: 1,
    },
  };

  @Input()
  public data: ITopicCard[] | undefined;

  @Input()
  public loading = false;

  //#endregion
}
