import { NgModule } from '@angular/core';
import { DateService } from './date.service';
import { DATE_SERVICE } from './date-service.token';

@NgModule({
  providers: [
    {
      provide: DATE_SERVICE,
      useClass: DateService,
    },
  ],
})
export class DateServiceModule {}
