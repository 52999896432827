import { ComponentStore } from "@ngrx/component-store";
import { IPageState } from "./page.state";
import { catchError, EMPTY, finalize, iif, mergeMap, Observable, of, OperatorFunction, pipe, take, tap } from "rxjs";
import { ActivatedRoute, Params } from "@angular/router";
import { ISpinnerService } from "@ui-tool/core";
import { SpinnerContainerIds } from "@mobile-data-access-enums";

export abstract class PageComponentStore<K,
  T extends IPageState<K>> extends ComponentStore<T> {
  public readonly updateLoading = this.updater<boolean>((state, loading) => ({
    ...state,
    loading
  }));
  public readonly initialize = this.effect<void>(
    pipe(
      mergeMap(() => {
        const displaySpinnerRequestId = this._spinnerService.displaySpinner(SpinnerContainerIds.APPLICATION);
        return of("").pipe(
          tap(() => {
            this.updateLoading(true);
          }),
          mergeMap(() => this._getParams().pipe(this.flow())),
          catchError((err) => {
            console.error(err);
            return EMPTY;
          }),
          finalize(() => {
            this._spinnerService.deleteSpinner(SpinnerContainerIds.APPLICATION, displaySpinnerRequestId);
            this.updateLoading(false);
          })
        );
      })
    )
  );

  protected constructor(
    protected readonly _initState: T,
    protected readonly _activatedRoute: ActivatedRoute,
    protected readonly _spinnerService: ISpinnerService,
    protected readonly _withParams: boolean = false
  ) {
    super(_initState);
  }

  public abstract flow(): OperatorFunction<Params, void>;

  private _getParams(): Observable<Params> {
    return iif(
      () => this._withParams,
      this._activatedRoute.queryParams.pipe(take(1)),
      of({} as Params)
    );
  }
}
