export enum VersionFeatureCodes {
  NAVIGATOR = 'NAVIGATOR',
  TRACKER = 'TRACKER',
  MEDICATION = 'MEDICATION',
  CHEMOTHERAPY = 'CHEMOTHERAPY',
  DRUG = 'DRUG',
  ARTICLE = 'ARTICLE',
  CAREGIVER = 'CAREGIVER',
  ONBOARDING = 'ONBOARDING',
  PATIENTS = 'PATIENTS',
  PROFILE = 'PROFILE',
  SIDE_EFFECT = 'SIDE_EFFECT',
}
