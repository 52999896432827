import { NgModule } from '@angular/core';
import { StrapiComponent } from './strapi.component';
import { UnescapePipeModule } from '@shared-pipes';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [StrapiComponent],
  imports: [UnescapePipeModule, ToTrustedHtmlPipeModule, CommonModule],
  exports: [StrapiComponent],
})
export class StrapiModule {}
