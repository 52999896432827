import { NgModule } from '@angular/core';
import { CHEMOTHERAPY_SERVICE } from './chemotherapy-service.token';
import { ChemotherapyService } from './chemotherapy.service';
import { ApiEndpointResolverModule } from '@mobile-data-access-resolvers';

@NgModule({
  imports: [ApiEndpointResolverModule],
  providers: [
    {
      provide: CHEMOTHERAPY_SERVICE,
      useClass: ChemotherapyService,
    },
  ],
})
export class ChemotherapyServiceModule {}
