import { NgModule } from '@angular/core';
import { NOTIFICATION_SERVICE } from './notification-service.token';
import { NotificationService } from './notification.service';

@NgModule({
  providers: [
    {
      provide: NOTIFICATION_SERVICE,
      useClass: NotificationService,
    },
  ],
})
export class NotificationServiceModule {}
