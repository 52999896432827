import { NgModule } from '@angular/core';
import { MeetingOverviewComponent } from './meeting-overview.component';
import { CommonModule } from '@angular/common';
import { ToSystemTimeModule } from '@mobile-ui-pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { SkeletonModule } from '../../../shared';

const MODULES = [CommonModule, ToSystemTimeModule, TranslocoModule];
@NgModule({
  declarations: [MeetingOverviewComponent],
  exports: [MeetingOverviewComponent],
  imports: [MODULES, SkeletonModule],
})
export class MeetingOverviewModule {}
