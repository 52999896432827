import { Inject, Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from "@angular/common/http";
import { catchError, mergeMap, Observable, throwError } from "rxjs";
import { Store } from "@ngrx/store";
import { AuthenticationActions, IRootState, UserActions } from "@mobile-data-access-stores";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { AuthenticationNavigationRequest, MaintenanceNavigationRequest } from "@mobile-data-access-models";
import { DOCUMENT } from "@angular/common";
import { ScreenCodes, StorageKeys } from "@mobile-data-access-enums";
import { LocalForageService } from "ngx-localforage";
import { IOpenAuthenticationResult } from "@mobile-data-access-interfaces";

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(DOCUMENT) protected readonly _document: Document,
    protected readonly _localForageService: LocalForageService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this._localForageService
      .getItem(StorageKeys.AUTHENTICATION_RESULT)
      .pipe(
        mergeMap((authenticationResult: IOpenAuthenticationResult) => {
          const headers: Record<string, string> = {};
          const idToken = authenticationResult?.idToken;
          if (idToken != null && idToken.length > 0) {
            headers['Authorization'] = `Bearer ${idToken}`;
          }

          const clonedRequest = request.clone({
            reportProgress: true,
            setHeaders: headers,
          });

          return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === HttpStatusCode.Unauthorized) {
                this._store.dispatch(AuthenticationActions.expired());
                this._store.dispatch(UserActions.clearProfile());
                const { href, origin, pathname } = this._document.location;

                const sessionExpiredUrl = this._navigationService.loadRawUrl(
                  ScreenCodes.SESSION_EXPIRED
                );
                if (pathname.startsWith(sessionExpiredUrl)) {
                  return throwError(() => error);
                }

                return this._localForageService
                  .removeItem(StorageKeys.AUTHENTICATION_RESULT)
                  .pipe(
                    mergeMap(() =>
                      this._navigationService.navigateToScreenAsync(
                        new AuthenticationNavigationRequest(
                          href.replace(origin + '/', '')
                        )
                      )
                    ),
                    mergeMap(() => throwError(() => error))
                  );
              }
              return throwError(() => error);
            })
          );
        })
      );
  }

  //#endregion
}
