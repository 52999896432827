import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toSymptomNumber',
})
export class ToSymptomNumberPipe implements PipeTransform {
  public transform(value: number): string {
    value = value + 1;
    return value < 10 ? '0' + value.toString() : value.toString();
  }
}
