import { NgModule } from '@angular/core';
import { SIDE_EFFECT_SERVICE } from './side-effect-service.token';
import { SideEffectService } from './side-effect.service';

@NgModule({
  providers: [
    {
      provide: SIDE_EFFECT_SERVICE,
      useClass: SideEffectService,
    },
  ],
})
export class SideEffectServiceModule {}
