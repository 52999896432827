import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITopicCard } from '@mobile-data-access-interfaces';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';

@Component({
  selector: 'ncis-topic-card',
  templateUrl: './topic-card.component.html',
  styleUrls: ['./topic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicCardComponent {
  //#region Properties

  @Input()
  public data!: ITopicCard;

  @Input()
  public loading = false;

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;

  //#endregion
}
