import { NgModule } from '@angular/core';
import { SYMPTOM_SERVICE } from './symptom-service.token';
import { SymptomService } from './symptom.service';
import { ContainerServiceModule } from '../container';

@NgModule({
  providers: [
    {
      provide: SYMPTOM_SERVICE,
      useClass: SymptomService,
    },
  ],
  imports: [ContainerServiceModule],
})
export class SymptomServiceModule {}
