import { Inject, Injectable } from "@angular/core";
import { ITermsAndConditionsService } from "./terms-and-conditions-service.interface";
import { map, Observable, switchMap } from "rxjs";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "@shared-interfaces";

@Injectable()
export class TermsAndConditionsService implements ITermsAndConditionsService {

  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getTermsAndConditionsAsync(): Observable<Record<'provider' | 'content', string>> {
    return this._getUrlAsync('term-condition')
      .pipe(
        switchMap((url) =>
          this._httpClient.get<IApiResult<Record<'provider' | 'content', string>>>(url).pipe(
            map((res) => res.data)
          )
        )
      );
  }

  public acceptTermsAndConditionsAsync(): Observable<never> {
    return this._getUrlAsync('onboarding/term-condition/accept')
      .pipe(
        switchMap((url) =>
          this._httpClient.post<never>(url, {})
        )
      );
  }
  //#endregion Methods

  //#region Internal Methods

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion Internal Methods
}
