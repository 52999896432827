import { NgModule } from '@angular/core';
import { BOOKMARK_SERVICE } from './bookmark-service.token';
import { BookmarkService } from './bookmark.service';
import { ApiEndpointResolverModule } from '@mobile-data-access-resolvers';

@NgModule({
  imports: [ApiEndpointResolverModule],
  providers: [
    {
      provide: BOOKMARK_SERVICE,
      useClass: BookmarkService,
    },
  ],
})
export class BookmarkServiceModule {}
