import { IArticleService } from "./article-service.interface";
import { Inject, Injectable } from "@angular/core";
import {forkJoin, map, mergeMap, Observable} from "rxjs";
import { IArticle, IArticleStatusViewed } from "@mobile-data-access-interfaces";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { ArticleCategories, DrugDetailControlCodes } from "@mobile-data-access-enums";
import { IApiResult } from "@shared-interfaces";

@Injectable()
export class ArticleService implements IArticleService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const uri = `${endpoint}/container/level2_article`;
        return this._httpClient.post<IArticle>(uri, {
          id: parseInt(id),
        });
      })
    );
  }

  public getViewedListByIdsAndCategoryAsync(ids: string[], category: ArticleCategories): Observable<IArticleStatusViewed[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((apiUrl: string) => {
        const fullUrl = `${apiUrl}/article/viewed-status?category=${category}&${ids.map((e) => `itemIds=${e}`).join('&')}`;
        return this._httpClient.get<IApiResult<IArticleStatusViewed[]>>(fullUrl);
      }),
      map((apiResult) => {
        return apiResult.data;
      })
    );
  }

    public getCategoriesWithViewedItemsAsync(categoryWithIds: Record<string, string[]>): Observable<Record<string, string[]>> {
      const categories = Object.keys(categoryWithIds);
      const viewStatusObservables: Observable<IArticleStatusViewed[]>[] = [];
      for (const category of categories) {
          const itemIds = categoryWithIds[category];
          if (!itemIds || !itemIds.length) {
              continue;
          }

          const viewStatusObservable = this.getViewedListByIdsAndCategoryAsync(itemIds, category as ArticleCategories);
          viewStatusObservables.push(viewStatusObservable);
      }

      return forkJoin(viewStatusObservables)
          .pipe(
              map(viewStatusResults => {
                  const instance: Record<string, string[]> = {};
                  for (const viewStatuses of viewStatusResults) {
                      for (const viewStatus of viewStatuses) {

                          if (!viewStatus.viewed) {
                              continue;
                          }

                          if (!instance[viewStatus.category]) {
                              instance[viewStatus.category] = [];
                          }

                          instance[viewStatus.category].push(viewStatus.itemId);
                      }
                  }

                  return instance;
              })
          )
    }



  public viewArticleAsync(itemId: string, category: ArticleCategories): Observable<never> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((apiUrl: string) => {
        const fullUrl = `${apiUrl}/article/viewed-status`;
        return this._httpClient.post<never>(fullUrl, {
          category,
          itemId
        });
      }),
    );
  }

  //#endregion
}
