import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';
import { IChemotherapyQueryParams } from '@mobile-data-access-interfaces';

export class ChemotherapyNavigationRequest extends NavigateToScreenRequest<
  Record<string, string>
> {
  //#region Constructor

  public constructor(
    code: ScreenCodes,
    queryParams?: Partial<IChemotherapyQueryParams>
  ) {
    super(
      code,
      {},
      {
        queryParams: queryParams,
      }
    );
  }

  //#endregion
}
