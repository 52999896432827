<ng-container *ngIf="data">
  <div class="interaction-card">
    <ncis-category [title]="data.category || 'N/A'"></ncis-category>
    <h3>{{data.title || 'N/A'}}</h3>
    <p class="line-clamp-3">{{data.description || 'N/A'}}</p>
    <div class="interaction-card__actions">
      <button (click)="begin()">{{beginLabel}}</button>
      <button disabled>{{laterLabel}}</button>
    </div>
  </div>
</ng-container>
