import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonIconFolder, CommonIconName } from '@shared-types';

@Component({
  selector: 'ncis-common-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonIconComponent<
  TFolder extends CommonIconFolder,
  TName extends CommonIconName<TFolder>
> {
  @Input()
  public folder!: TFolder;

  @Input()
  public name!: TName;

  @Input()
  public extraClass!: string;

  public get path(): string {
    return `ncis-assets/icons/${this.folder}/${this.name}.svg`;
  }
}
