import { NgModule } from "@angular/core";
import { LoadingComponent } from "./loading.component";
import { IonicModule } from "@ionic/angular";
import { LoadingDirective } from "./loading.directive";

@NgModule({
  imports: [
    IonicModule
  ],
  declarations: [LoadingComponent, LoadingDirective],
  exports: [LoadingComponent, LoadingDirective]
})
export class LoadingModule { }
