import { createAction, props } from "@ngrx/store";
import { IUserProfile } from "@mobile-data-access-interfaces";

export class UserActions {

  //#region Methods

  public static readonly clearProfile = createAction('[USER] CLEAR PROFILE');

  public static readonly saveProfile = createAction('[USER] SAVE PROFILE', props<{data: IUserProfile}>());

  //#endregion
}
