import { NgModule } from '@angular/core';
import { ButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { IconModule } from "@shared-components";
import { SkeletonModule } from "../skeleton";

@NgModule({
  imports: [CommonModule, IconModule, SkeletonModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonModule {}
