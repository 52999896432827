import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { IWhatNext, IWhatNextCategory } from "@mobile-data-access-interfaces";

@Component({
  selector: 'ncis-what-next',
  templateUrl: './what-next.component.html',
  styleUrls: ['./what-next.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'what-next',
        alias: 'WHAT_NEXT',
      },
    },
  ],
})
export class WhatNextComponent {
  //#region Properties

  @Input()
  public title = 'What’s Next';

  @Input()
  public data: IWhatNext | undefined;

  @Input()
  public loading = false;

  @Input()
  public topLeftImage = 'ncis-assets/svgs/what-next/top-left.svg';

  @Input()
  public topRightImage = 'ncis-assets/svgs/what-next/top-right.svg';

  @Input()
  public bottomLeftImage = 'ncis-assets/svgs/what-next/bottom-left.svg';

  @Input()
  public bottomRightImage = 'ncis-assets/svgs/what-next/bottom-right.svg';

  @Input()
  public topLeftColorClass = 'text-ncis-primary-100';

  @Input()
  public topRightColorClass = 'text-ncis-primary-200';

  @Input()
  public bottomLeftColorClass = 'text-ncis-primary-200';

  @Input()
  public bottomRightColorClass = 'text-ncis-primary-100';

  @Output()
  public readonly clickCategoryEvent = new EventEmitter<IWhatNextCategory>();

  //#endregion
}
