import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OnboardingPinInterceptor } from './onboarding-pin.interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: OnboardingPinInterceptor,
    },
  ],
})
export class OnboardingPinModule {}
