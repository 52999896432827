import { Inject, Injectable } from '@angular/core';
import { APP_SETTINGS_SERVICE, IAppSettingsService } from '@mobile-data-access-services';
import { Observable, map } from 'rxjs';
import { IEnvironmentService } from './environment-service.interface';

@Injectable()
export class EnvironmentService implements IEnvironmentService {

    //#region Constructor

    public constructor(
        @Inject(APP_SETTINGS_SERVICE)
        protected readonly _appSettingService: IAppSettingsService
    ) { }

    //#endregion

    //#region Methods

    public getEnvironmentAsync(): Observable<string> {
        return this._appSettingService.loadSettingsAsync(false)
            .pipe(
                map(appSettings => {
                    return appSettings.environment as string;
                })
            );
    }

    //#endregion
}
