import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unescape',
})
export class UnescapePipe implements PipeTransform {
  //#region Methods

  public transform(value: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = value?.replace(/\\"/gi, '"');

    return txt.value;
  }

  //#endregion
}
