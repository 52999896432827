import {Inject, Injectable} from "@angular/core";
import {IChemotherapyProcedureDetailState} from "./procedure-detail.state";
import {
    ARTICLE_SERVICE,
    BOOKMARK_SERVICE,
    IArticleService,
    IBookmarkService,
    IProcedureService,
    PROCEDURE_SERVICE
} from "@mobile-data-access-services";
import {ActivatedRoute} from "@angular/router";
import {ChemotherapyBaseComponentStore} from "../../base";
import {ProcedureControlCodes} from "@mobile-data-access-enums";
import {catchError, map, of, tap} from "rxjs";

@Injectable()
export class ChemotherapyProcedureDetailComponentStore extends ChemotherapyBaseComponentStore<IChemotherapyProcedureDetailState> {

    //#region Constructor

    public constructor(
        @Inject(PROCEDURE_SERVICE)
        protected readonly _procedureService: IProcedureService,
        @Inject(ARTICLE_SERVICE)
        protected readonly _articleService: IArticleService,
        @Inject(BOOKMARK_SERVICE)
        protected readonly _bookmarkService: IBookmarkService,
        protected override readonly _activatedRoute: ActivatedRoute,
    ) {
        super(_activatedRoute, {
            id: null,
            basicInfoLoading: false,
            basicInfo: null,
            overviewLoading: false,
            overview: null,
            articlesLoading: false,
            articles: null,
            topicsLoading: false,
            topics: null,
            tagsLoading: false,
            tags: null,
            whatNext: null,
            whatNextLoading: false
        });
    }

    //#endregion

    //#region Methods

    public setup(): void {
        this.load('overview', (id) => this._procedureService.getChemoAsync<IChemotherapyProcedureDetailState['overview']>(id, ProcedureControlCodes.OVERVIEW));
        this.load('whatNext', (id) => this._procedureService.getChemoAsync<IChemotherapyProcedureDetailState['whatNext']>(id, ProcedureControlCodes.WHAT_NEXT));
        this.load("articles",
            (id) =>
                this._procedureService.getArticlesByProcedureIdAsync(id)
                    .pipe(
                        tap(() => this.updateProperty('articlesLoading', false)),
                        catchError(() => {
                            this.updateProperty('articlesLoading', false);
                            return of([]);
                        })
                    )
        );
        this.load('topics', (id) => this._procedureService.getChemoAsync<IChemotherapyProcedureDetailState['topics']>(id, ProcedureControlCodes.RELATED_TOPICS));
        this.load('tags', (id) => this._procedureService.getChemoAsync<IChemotherapyProcedureDetailState['tags']>(id, ProcedureControlCodes.TAGS));
    }

    //#endregion
}
