import { AfterViewInit, Directive, ElementRef, NgZone } from "@angular/core";

@Directive({
  selector: '[basicInfoContent]',
  exportAs: 'basicInfoContent'
})
export class BasicInfoContentDirective implements AfterViewInit {

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _ngZone: NgZone
  ) { }

  public ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      const height = 56;
      let newHeight = height;
      const timeout = setInterval(() => {
        const element = this._elementRef.nativeElement as HTMLElement;
        const calcHeight = element.offsetHeight / 2;
        newHeight = calcHeight > height ? calcHeight : height;
        element.style.setProperty('--half', newHeight + 'px');
        element.parentElement?.style.setProperty('--half', newHeight + 'px');

        if (newHeight > height) {
          clearInterval(timeout);
        }
      }, 100)
    });

  }

}
