import { NgModule } from '@angular/core';
import { PLATFORM_SERVICE } from './platform-service.token';
import { PlatformService } from './platform.service';

@NgModule({
  providers: [
    {
      provide: PLATFORM_SERVICE,
      useClass: PlatformService,
    },
  ],
})
export class PlatformServiceModule {}
