import { v4 as uuid } from 'uuid';

export class NativeMessage<T> {
  //#region Properties

  public readonly id: string;

  public success = true;

  //#endregion

  //#region Constructor

  constructor(
    public readonly source: string,
    public readonly namespace: string,
    public readonly method: string,
    public readonly data?: T,
    id?: string
  ) {
    let actualId = id;
    if (!actualId) {
      actualId = uuid();
    }
    this.source = source;
    this.data = data;
    this.id = actualId;
  }

  //#endregion
}
