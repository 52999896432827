import { RpcRequest } from '@message-bus/core';
import { MessageNamespaces, NotificationMethods } from '@shared-enums';

@RpcRequest(MessageNamespaces.LOCAL_NOTIFICATION, NotificationMethods.UPDATE)
export class UpdateNotificationNativeMethod {
  //#region Constructor

  public constructor(
    public readonly id: string,
    public showTime?: string,
    public readonly title?: string,
    public readonly body?: string
  ) {}

  //#endregion
}
