import { NgModule } from '@angular/core';
import { VersionService } from './version.service';
import { VERSION_SERVICE } from './version-service.token';
import { ApiEndpointResolverModule } from '@mobile-data-access-resolvers';

@NgModule({
  imports: [ApiEndpointResolverModule],
  providers: [
    {
      provide: VERSION_SERVICE,
      useClass: VersionService,
    },
  ],
})
export class VersionServiceModule {}
