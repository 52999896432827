import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ncis-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  //#region Properties

  @Output()
  public readonly activeChange = new EventEmitter<(string | number)[]>();
  @Input()
  public active: (string | number)[] = [];

  @Input()
  public mappingIcon: Record<string, string> = {};

  @Input()
  public iconClass = 'h-3 mt-[5px]';

  @Input()
  public customClass = '';

  @Input()
  public allowSelection = true;

  @Input()
  public allowMultiple = false;

  @Input()
  public noPaddingX = false;

  @Input()
  public allowStyling = true;

  public readonly items$ = new BehaviorSubject<
    (Record<'key' | 'label', string | number> &
      Partial<Record<'title', string>>)[]
  >([]);

  @Input()
  public set items(
    value: (Record<'key' | 'label', string | number> &
      Partial<Record<'title', string>>)[]
  ) {
    this.items$.next(value);
  }

  //#endregion

  //region Properties

  public selectItem(key: string): void {
    if (!this.allowSelection) {
      return;
    }

    if (this.allowMultiple) {
      if (this.active.includes(key)) {
        this.active = this.active.filter((k) => k != key);
      } else {
        if (this.active.length < 2) {
          this.active.push(key);
        } else {
          this.active = this.active.slice(-1);
          this.active.push(key);
        }
      }
    } else {
      this.active = [];
      this.active.push(key);
    }
    this.activeChange.emit(this.active);
  }

  //#endregion
}
