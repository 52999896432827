<swiper
  *ngIf="assets?.length"
  [class.full-height]="fullHeight"
  [class.full-width]="fullWidth"
  [config]="swiperOptions"
>
  <ng-container *ngFor="let asset of assets">
    <ng-template swiperSlide>
      <ng-container [ngSwitch]="asset.type">
        <ng-container *ngSwitchCase="AssetTypes.VIDEO">
          <video controls>
            <source [src]="asset.url | toTrustedResourceUrl" type="video/mp4" />
          </video>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTypes.IMAGE">
          <img [alt]="asset.url + asset.type" [lazyLoadImage]="asset.url" />
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>
</swiper>
