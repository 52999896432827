import { NgModule } from '@angular/core';
import { AssetComponent } from './asset.component';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { ToTrustedResourceUrlPipeModule } from '@ui-tool/core';
import { LazyLoadImageModule } from '@mobile-ui-directives';

@NgModule({
  declarations: [AssetComponent],
  imports: [
    CommonModule,
    SwiperModule,
    ToTrustedResourceUrlPipeModule,
    LazyLoadImageModule,
  ],
  exports: [AssetComponent],
})
export class AssetModule {}
