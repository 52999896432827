import { NgModule } from '@angular/core';
import { FEATURE_FLAG_SERVICE } from './feature-flag-service.token';
import { FeatureFlagService } from './feature-flag.service';
import { FEATURE_SENTINEL_SERVICE } from '@ui-tool/core';
import { FeatureSentinelService } from './feature-sentinel.service';

@NgModule({
  providers: [
    {
      provide: FEATURE_FLAG_SERVICE,
      useClass: FeatureFlagService,
    },
    {
      provide: FEATURE_SENTINEL_SERVICE,
      useClass: FeatureSentinelService,
    },
  ],
})
export class FeatureFlagServiceModule {}
