import { NgModule } from '@angular/core';
import { PlanCardComponent } from './plan-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToCurrentTimeModule, ToSystemTimeModule } from '@mobile-ui-pipes';
import { CategoryModule, SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';

@NgModule({
  imports: [
    CommonModule,
    ToSystemTimeModule,
    IconModule,
    CategoryModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    ToTrustedHtmlPipeModule,
    ToCurrentTimeModule,
  ],
  declarations: [PlanCardComponent],
  exports: [PlanCardComponent],
})
export class PlanCardModule {}
