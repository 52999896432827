import { NavigatorState } from './navigator.state';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ContainerComponentStore } from '../../../shared';
import {
  INavigatorService,
  NAVIGATOR_SERVICE,
} from '@mobile-data-access-services';
import {
  catchError,
  EMPTY, filter,
  finalize,
  mergeMap,
  pipe,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
  timer, withLatestFrom
} from 'rxjs';
import { isEqual } from 'lodash-es';
import { HttpStatusCode } from '@angular/common/http';
import { HeaderComponentStore } from '../../header';
import { Router } from '@angular/router';

@Injectable()
export class NavigatorComponentStore
  extends ContainerComponentStore<NavigatorState, NavigatorState['data']>
  implements OnDestroy
{
  //#region Properties

  public static readonly INTERVAL_TIME = 1000 * 30;

  private readonly __destroy$: Subject<boolean> = new Subject<boolean>();

  public override readonly runSkeleton = this.effect<void>(
    pipe(
      tap(() => this.updateLoading(true)),
      switchMap(() =>
        timer(0, NavigatorComponentStore.INTERVAL_TIME).pipe(
          withLatestFrom(this._homeStore.select((state) => state.status)),
          filter(([,status]) => status && this._router.url.includes('dashboard')),
          takeUntil(this.__destroy$),
          mergeMap(() =>
            this._navigatorService.loadAsync().pipe(
              tap((data) => {
                const oldData = this.get().data;
                const isDataConsistent = isEqual(data, oldData);
                this.patchState({
                  message: data?.length
                    ? null
                    : 'DASHBOARD_PAGE.NAVIGATOR_LOAD_EMPTY',
                });
                if (isDataConsistent) {
                  return;
                }
                this.updateData(data);
              }),
              catchError((err) => {
                this.patchState({
                  message: 'DASHBOARD_PAGE.NAVIGATOR_LOAD_FAILED',
                });
                if (err.status === HttpStatusCode.ServiceUnavailable) {
                  return throwError(() => err);
                }
                console.log(err);
                return EMPTY;
              }),
              finalize(() => this.updateLoading(false))
            )
          ),
          catchError((err) => {
            console.log(err);
            return EMPTY;
          })
        )
      )
    )
  );

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(NAVIGATOR_SERVICE)
    protected readonly _navigatorService: INavigatorService,
    protected readonly _homeStore: HeaderComponentStore,
    protected readonly _router: Router
  ) {
    super({
      data: [],
      loading: false,
      message: null,
    });
  }

  //#endregion

  //#region Life cycle hooks

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.__destroy$.next(true);
    this.__destroy$.unsubscribe();
  }

  //#endregion
}
