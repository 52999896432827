import { createReducer, on } from '@ngrx/store';
import { BookmarkActions } from './bookmarks.action';
import { IBookmarkState } from './bookmarks.state';

export const bookmarksStoreKey = 'bookmarks';

export const bookmarksInitialState: IBookmarkState = {
  bookmarks: [],
  loadingBookmark: false,
  totalRecords: 1,
};

export const bookmarksReducer = createReducer(
  bookmarksInitialState,
  on(BookmarkActions.changeBookmarks, (state, action) => ({
    ...state,
    bookmarks: action.bookmarks,
  })),
  on(BookmarkActions.changeLoadingStatus, (state, action) => ({
    ...state,
    loadingBookmark: action.loading,
  })),
  on(BookmarkActions.totalRecords, (state, action) => ({
    ...state,
    totalRecords: action.totalRecords,
  }))
);
