import { NgModule } from '@angular/core';
import { DIAGNOSIS_SERVICE } from './diagnosis-service.token';
import { DiagnosisService } from './diagnosis.service';

@NgModule({
  providers: [
    {
      provide: DIAGNOSIS_SERVICE,
      useClass: DiagnosisService,
    },
  ],
})
export class DiagnosisServiceModule {}
