import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { ISymptomService } from './symptom-service.interface';
import { CONTAINER_SERVICE, IContainerService } from '../container';
import {
  ArticleCategories,
  SymptomArticleCodes,
  SymptomDetailControlCodes,
  TrackerEffectServeCodes,
} from '@mobile-data-access-enums';
import {
  IArticle,
  ISymptomStatistic,
  ITrackerEffectItem,
} from '@mobile-data-access-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';

@Injectable()
export class SymptomService implements ISymptomService {
  //#region Constructor

  public constructor(
    @Inject(CONTAINER_SERVICE)
    protected readonly _containerService: IContainerService,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endPointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getStatisticAsync(
    id: string,
    startTime: Date | string,
    endTime: Date | string
  ): Observable<ISymptomStatistic[]> {
    const queryParams = new Map<string, string>();
    queryParams.set('startTime', new Date(startTime).toISOString());
    queryParams.set('endTime', new Date(endTime).toISOString());

    return this._getDataDetailAsync<ISymptomStatistic[]>(
      SymptomDetailControlCodes.STATISTICS,
      id
    );
  }

  public getContentAsync<T>(
    id: string,
    code: SymptomDetailControlCodes
  ): Observable<T> {
    return this._getDataDetailAsync<T>(code, id);
  }

  public getArticleDetailAsync(id: string): Observable<IArticle> {
    return this._getDataDetailAsync<IArticle>(SymptomArticleCodes.ARTICLE, id);
  }

  public getRelatedTopicDetailAsync(id: string): Observable<IArticle> {
    return this._getDataDetailAsync<IArticle>(
      SymptomArticleCodes.ARTICLE_RELATED_TOPICS,
      id
    );
  }

  public getLatestEffectAsync(): Observable<ITrackerEffectItem[]> {
    return this._getUrlAsync('symptom-tracking/latest').pipe(
      mergeMap((apiUrl) => {
        return this._httpClient
          .get<IApiResult<ITrackerEffectItem[]>>(apiUrl)
          .pipe(map((res) => res?.data || []));
      })
    );
  }

  public submitTrackingAsync(
    ctcaeCode: string,
    severe: TrackerEffectServeCodes
  ): Observable<unknown> {
    return this._getUrlAsync('symptom-tracking/submit').pipe(
      mergeMap((apiUrl) => {
        return this._httpClient.post(apiUrl, { ctcaeCode, severe });
      })
    );
  }

  public getArticleByTypeAsync(id: string, type: string): Observable<IArticle> {
    return this._getUrlAsync(
      `symptom/list/symptom_article_detail_${type}`
    ).pipe(
      mergeMap((apiUrl) => {
        const httpParams: Record<string, string> = {};
        httpParams['id'] = id;

        return this._httpClient
          .get<IApiResult<IArticle>>(apiUrl, { params: httpParams })
          .pipe(map(({ data }) => data));
      })
    );
  }

  public getArticlesBySymptomIdAsync(
    symptomId: string
  ): Observable<IArticle[]> {
    return this._getDataDetailAsync<IArticle[]>(
      SymptomDetailControlCodes.ARTICLES,
      symptomId
    ).pipe(
      map((articles) => {
        return articles.map((article) => {
          article.category = ArticleCategories.SYMPTOM_DETAIL_ARTICLE;
          return article;
        });
      })
    );
  }

  //#endregion

  //#region Internal methods

  protected _getDataDetailAsync<T>(
    containerCode: string,
    id: string,
    queryParams?: Map<string, string>
  ): Observable<T> {
    return this._getUrlAsync(`symptom/detail/${containerCode}`).pipe(
      mergeMap((apiUrl) => {
        const httpParams: Record<string, string> = {};
        httpParams['id'] = id;

        if (queryParams) {
          for (const key of queryParams.keys()) {
            httpParams[key] = queryParams.get(key) || '';
          }
        }

        return this._httpClient
          .get<IApiResult<T>>(apiUrl, { params: httpParams })
          .pipe(map(({ data }) => data));
      })
    );
  }

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion
}
