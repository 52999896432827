import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { IInteractionCard } from "@mobile-data-access-interfaces";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { PaxmanNavigationRequest } from "@mobile-data-access-models";
import { ScreenCodes } from "@mobile-data-access-enums";

@Component({
  selector: 'ncis-interaction-card',
  templateUrl: './interaction-card.component.html',
  styleUrls: ['./interaction-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionCardComponent {
  //#region Properties

  @Input()
  public data: IInteractionCard | null = null;

  @Input()
  public beginLabel = '';

  @Input()
  public laterLabel = '';

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
  ) { }

  //#endregion

  //#region Methods

  public begin(): void {
    this._navigationService.navigateToScreenAsync(
      new PaxmanNavigationRequest(ScreenCodes.PAXMAN)
    ).subscribe();
  }

  //#endregion
}
