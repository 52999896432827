<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data">
    <div class="schedule">
      <div class="schedule__content">
        <ng-container *ngIf="!loading; else skeletonTemplate">
          <h2>{{ title || 'N/A' }}</h2>
          <p
            [innerHTML]="description || 'N/A'"
          ></p>
        </ng-container>
        <ng-template #skeletonTemplate>
          <ncis-skeleton class="w-[200px] h-[22px] "></ncis-skeleton>
          <ncis-skeleton class="w-full h-[44px]"></ncis-skeleton>
        </ng-template>
      </div>
      <div class="schedule__cycle">
        <ncis-treatment-cycle [currentTreatmentDay]="dayOfCycle || 0"
                              [heading]="'TREATMENT_CYCLE.HEADING' | transloco"
                              [mainTitle]="treatmentCycle.word | transloco: {cycle: treatmentCycle.cycle}"
                              [options]="cycleOptions"
                              [subTitle]="treatmentCycleMessage">
        </ncis-treatment-cycle>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <div class="schedule__content">
    <ncis-skeleton class="w-[200px] h-[22px] "></ncis-skeleton>
    <ncis-skeleton class="w-full h-[44px]"></ncis-skeleton>
  </div>
  <div class="schedule">
    <div class="schedule__cycle">
      <ncis-treatment-cycle [currentTreatmentDay]="24"
                            [heading]="'TREATMENT_CYCLE.HEADING' | transloco"
                            [mainTitle]="'TREATMENT_CYCLE.TITLE_DAYS' | transloco: {cycle: 30}"
                            [options]="cycleLoadingOptions"
                            [subTitle]="treatmentCycleMessage">
      </ncis-treatment-cycle>
    </div>
  </div>
</ng-template>
