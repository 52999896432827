import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { IUserState } from "./user.state";

export const userAdapter: EntityAdapter<never> =
  createEntityAdapter<never>();

export const userStoreInitialState: IUserState =
  userAdapter.getInitialState({
    profile: null,
  });
