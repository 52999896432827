<div
  class="treatment-cycle"
  [style.z-index]="1"
  [style.height.px]="canvasSize"
  [style.width.px]="canvasSize"
>
  <div class="treatment-cycle__cycle">

    <!--Heading-->
    <div class="treatment-cycle__cycle__heading"
         *ngIf="heading && heading.length">
      {{heading}}
    </div>

    <!--Main title-->
    <div class="treatment-cycle__cycle__main-title"
         *ngIf="mainTitle && mainTitle.length">
      {{mainTitle}}
    </div>

    <!--Sub title-->
    <div class="treatment-cycle__cycle__sub-title"
         [innerHTML]="subTitle | toTrustedHtml">
    </div>

  </div>
</div>

<div class="circle-container"></div>
