import { DefaultScreenCodeResolver } from '@ui-tool/core';
import { Injectable } from '@angular/core';
import { ScreenCodes } from '@mobile-data-access-enums';

@Injectable()
export class ScreenCodeResolver extends DefaultScreenCodeResolver {
  //#region Constructor

  constructor() {
    const codeToUrl: Record<string, string> = {};
    codeToUrl[ScreenCodes.DASHBOARD] = '/home/dashboard';
    codeToUrl[ScreenCodes.ONBOARDING] = '/onboarding';
    codeToUrl[ScreenCodes.PAXMAN] = '/paxman';
    codeToUrl[ScreenCodes.PAXMAN_MEMBER] = '/paxman/member';
    codeToUrl[ScreenCodes.PAXMAN_INTRODUCTION] = '/paxman/introduction';
    codeToUrl[ScreenCodes.TRACKER] = '/home/tracker';
    codeToUrl[ScreenCodes.TRACKER_RECORD] = '/tracker';
    codeToUrl[ScreenCodes.TRACKER_WARNING] = '/tracker-warning';
    codeToUrl[ScreenCodes.MEDICATION] = '/home/medication';
    codeToUrl[ScreenCodes.DETAILED_ARTICLE] = '/article';
    codeToUrl[ScreenCodes.CHEMOTHERAPY] = '/chemotherapy';
    codeToUrl[ScreenCodes.CHEMOTHERAPY_PROCEDURE] = '/chemotherapy/procedure';
    codeToUrl[ScreenCodes.CHEMOTHERAPY_APPOINTMENT] =
      '/chemotherapy/appointment';
    codeToUrl[ScreenCodes.CHEMOTHERAPY_DIAGNOSIS] =
      '/chemotherapy/diagnosis-detail';
    codeToUrl[ScreenCodes.CHEMOTHERAPY_RADIATION] = '/chemotherapy/radiation';
    codeToUrl[ScreenCodes.DRUG_DETAIL] = '/drug-detail';
    codeToUrl[ScreenCodes.SESSION_EXPIRED] = '/session-expired';
    codeToUrl[ScreenCodes.OAUTH] = '/oauth-callback';
    codeToUrl[ScreenCodes.SYMPTOMS] = '/symptoms';
    codeToUrl[ScreenCodes.SYMPTOM_DETAIL] = '/symptom/{{id}}';
    codeToUrl[ScreenCodes.RESOURCE_NOT_FOUND] = '/404';
    codeToUrl[ScreenCodes.BOOKMARKS] = '/bookmarks';
    codeToUrl[ScreenCodes.MAINTENANCE] = '/maintenance';
    codeToUrl[ScreenCodes.FORBIDDEN] = '/403';
    super(codeToUrl);
  }

  //#endregion

  //#region Methods

  public override loadUrl(code: string): string | null {
    return this._codeToUrl[code];
  }

  //#endregion
}
