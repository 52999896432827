import { ComponentStore } from '@ngrx/component-store';
import {
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  mergeMap,
  of,
  pipe,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  BookmarkActions,
  BookmarksSelectors,
  IRootState,
} from '@mobile-data-access-stores';
import {
  IBookmarkCard,
  IComponentInitialization,
} from '@mobile-data-access-interfaces';
import { Inject, Injectable } from '@angular/core';
import {
  BOOKMARK_SERVICE,
  IBookmarkService,
} from '@mobile-data-access-services';
import { Store } from '@ngrx/store';
import { RefresherCustomEvent } from '@ionic/angular';
import { ArticleCategories } from '@mobile-data-access-enums';

@Injectable()
export class BookmarksStore extends ComponentStore<{
  page: number;
  limit: number;
  bookmarks: IBookmarkCard[];
  exceptionCode: string | undefined;
}> {
  //#region Constructor

  public constructor(
    @Inject(BOOKMARK_SERVICE)
    protected readonly _bookmarkService: IBookmarkService,
    protected readonly _store: Store<IRootState>
  ) {
    super({
      page: 1,
      limit: 20,
      bookmarks: [],
      exceptionCode: undefined,
    });
  }

  public readonly exceptionCode$ = this.select((state) => state.exceptionCode);

  //#endregion

  //#region Methods
  public readonly loadBookmarks = this.effect<IComponentInitialization>(
    pipe(
      switchMap((initialization) => {
        const { page, limit, bookmarks } = this.get();

        if (page === 1) {
          this._store.dispatch(
            BookmarkActions.changeLoadingStatus({ loading: true })
          );
        }

        this.patchState({ exceptionCode: undefined });

        return this._bookmarkService.searchAsync({ page, limit }).pipe(
          switchMap(({ items, totalRecords }) => {
            const totalPages = Math.ceil(totalRecords / limit);

            return this._bookmarkService
              .getArticlePreviewByIdsAsync(items)
              .pipe(
                catchError(() => EMPTY),
                switchMap((articlePreviews) => {
                  const initialBookmarks = items.map((item) => {
                    const articlePreview = articlePreviews.find(
                      (x) =>
                        x.id.toString() === item.itemId &&
                        x.category === item.category
                    );
                    return {
                      id: `${item.itemId}`,
                      imageUrl: articlePreview?.imageUrl || '',
                      description: articlePreview?.description || '',
                      title: articlePreview?.title || '',
                      savedOn: item.createdTime,
                      category: item.category,
                    };
                  });

                  if (totalPages > 1) {
                    const additionalPages = Array.from(
                      { length: totalPages - 1 },
                      (_, i) => i + 2
                    );

                    return forkJoin(
                      additionalPages.map((p) =>
                        this._bookmarkService
                          .searchAsync({ page: p, limit })
                          .pipe(
                            retry(3),
                            catchError(() => EMPTY),
                            switchMap(({ items: pageItems }) =>
                              this._bookmarkService
                                .getArticlePreviewByIdsAsync(pageItems)
                                .pipe(
                                  retry(3),
                                  catchError(() => EMPTY),
                                  map((articlePreviews) => ({
                                    items: pageItems,
                                    articlePreviews,
                                  }))
                                )
                            )
                          )
                      )
                    ).pipe(
                      map((results) => {
                        const allAdditionalItems = results.flatMap(
                          (result) => result.items
                        );
                        const allAdditionalPreviews = results.flatMap(
                          (result) => result.articlePreviews
                        );

                        const additionalBookmarks = allAdditionalItems.map(
                          (item) => {
                            const articlePreview = allAdditionalPreviews.find(
                              (x) =>
                                x.id.toString() === item.itemId &&
                                x.category === item.category
                            );
                            return {
                              id: `${item.itemId}`,
                              imageUrl: articlePreview?.imageUrl || '',
                              description: articlePreview?.description || '',
                              title: articlePreview?.title || '',
                              savedOn: item.createdTime,
                              category: item.category,
                            };
                          }
                        );

                        return {
                          bookmarks: [
                            ...bookmarks,
                            ...initialBookmarks,
                            ...additionalBookmarks,
                          ],
                        };
                      })
                    );
                  } else {
                    return of({
                      bookmarks: [...bookmarks, ...initialBookmarks],
                    });
                  }
                }),
                tap(({ bookmarks }) => {
                  this.patchState({ bookmarks });
                  this._store.dispatch(
                    BookmarkActions.changeBookmarks({ bookmarks })
                  );
                }),
                finalize(() => {
                  if (page === 1) {
                    this._store.dispatch(
                      BookmarkActions.changeLoadingStatus({ loading: false })
                    );
                  }
                  initialization.done?.();
                })
              );
          }),
          catchError(() => {
            initialization.done?.();
            this.patchState({
              exceptionCode: 'SOMETHING_WENT_WRONG_WHILE_LOADING_BOOKMARKS',
            });
            this._store.dispatch(
              BookmarkActions.changeLoadingStatus({ loading: false })
            );
            return EMPTY;
          })
        );
      })
    )
  );

  public readonly deleteBookmark = this.effect<IBookmarkCard>(
    pipe(
      mergeMap((item) =>
        this._bookmarkService
          .deleteAsync({
            itemId: item.id,
            category: item.category as ArticleCategories,
          })
          .pipe(
            mergeMap(() =>
              this._store.select(BookmarksSelectors.selectBookmarks).pipe(
                take(1),
                tap((bookmarks) => {
                  this._store.dispatch(
                    BookmarkActions.changeBookmarks({
                      bookmarks: bookmarks.filter(
                        (bookmark) => bookmark.id !== item.id
                      ),
                    })
                  );
                })
              )
            ),
            catchError((err) => EMPTY)
          )
      )
    )
  );

  public readonly initialize = this.effect<never>(
    pipe(
      tap(() => {
        this.patchState({
          page: 1,
          bookmarks: [],
        });
        this.loadBookmarks({});
      })
    )
  );

  public readonly reload = this.effect<RefresherCustomEvent>(
    pipe(
      tap((event) =>
        this.loadBookmarks({
          done: () => {
            event.detail.complete();
          },
        })
      )
    )
  );

  //#endregion
}
