import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import {
  CONTAINER_SERVICE,
  IContainerService,
} from '@mobile-data-access-services';
import { RadiationControlCodes, ScreenCodes } from '@mobile-data-access-enums';
import { HttpErrorResponse } from '@angular/common/http';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';

@Injectable()
export class ChemotherapyGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(CONTAINER_SERVICE)
    protected readonly _containerService: IContainerService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigatorService: ISmartNavigatorService
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const id =
      activatedRouteSnapshot.data['container'] ===
      RadiationControlCodes.BASIC_INFO
        ? null
        : activatedRouteSnapshot.queryParams['id'];
    return this._containerService
      .loadDataAsync(activatedRouteSnapshot.data['container'], {
        ...activatedRouteSnapshot.queryParams,
        id,
      })
      .pipe(
        map(() => true),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          if (httpErrorResponse.status === 404) {
            const urlTree = this._navigatorService.buildUrlTree(
              ScreenCodes.RESOURCE_NOT_FOUND,
              {
                previousUrl: activatedRouteSnapshot.url.toString(),
              }
            );
            return of(urlTree);
          }

          return of(false);
        })
      );
  }

  //#endregion
}
