import { NgModule } from '@angular/core';
import { USER_SERVICE } from './user-service.token';
import { UserService } from './user.service';

@NgModule({
  providers: [
    {
      provide: USER_SERVICE,
      useClass: UserService,
    },
  ],
})
export class UserServiceModule {}
