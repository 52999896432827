import { createAction, props } from '@ngrx/store';

export class FeatureFlagAction {
  //#region Methods

  private static readonly __prefix = `FEATURE FLAGS`;

  public static readonly saveFeatureFlags = createAction(
    `[${FeatureFlagAction.__prefix}] SAVE`,
    props<{ featureFlags: {code: string; enabled: boolean}[] }>()
  );

  //#endregion
}
