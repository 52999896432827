import { IContainerState } from './container.state';
import { ComponentStore } from '@ngrx/component-store';
import { delay, filter, pipe, switchMap, take, tap } from 'rxjs';

export abstract class ContainerComponentStore<
  TContainerState extends IContainerState<TContainerData>,
  TContainerData
> extends ComponentStore<TContainerState> {
  //#region Constructor

  public readonly updateData = this.updater<TContainerData>((state, data) => ({
    ...state,
    data,
  }));

  //#endregion

  //#region Methods
  public readonly updateLoading = this.updater<boolean>((state, loading) => ({
    ...state,
    loading,
  }));
  public readonly runSkeleton = this.effect<void>(
    pipe(
      switchMap(() => this.select((state) => state.loading).pipe(take(1))),
      filter((loading) => !loading),
      tap(() => this.updateLoading(true)),
      delay(1000),
      tap(() => this.updateLoading(false))
    )
  );

  protected constructor(protected readonly _initState: TContainerState) {
    super(_initState);
  }

  //#endregion
}
