import { IConnectivityService } from './connectivity-service.interface';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InternetStatuses } from '@mobile-data-access-enums';

@Injectable()
export class ConnectivityService implements IConnectivityService, OnDestroy {
  //#endregion Properties
  protected readonly _status$ = new BehaviorSubject<InternetStatuses>(
    InternetStatuses.ONLINE
  );
  //#region Properties

  //#region Constructor
  public constructor() {
    this.__bind();
    this.__addEventListener();
  }
  //#endregion Constructor

  //#region Life Cycles
  public ngOnDestroy(): void {
    this.__removeEventListener();
  }
  //#endregion Life Cycles

  //#region Public Methods

  public getStatusAsync(): Observable<InternetStatuses> {
    return this._status$.asObservable();
  }

  public getCurrentStatus(): InternetStatuses {
    if (window.navigator.onLine) {
      return InternetStatuses.ONLINE;
    }

    return InternetStatuses.OFFLINE;
  }
  //#endregion Public Methods

  //#region Internal Methods
  private __addEventListener(): void {
    window.addEventListener(InternetStatuses.ONLINE, this.__onOnline);
    window.addEventListener(InternetStatuses.OFFLINE, this.__onOffline);
  }

  private __removeEventListener(): void {
    window.removeEventListener(InternetStatuses.ONLINE, this.__onOnline);
    window.removeEventListener(InternetStatuses.OFFLINE, this.__onOffline);
  }

  private __bind(): void {
    this.__onOffline.bind(this);
    this.__onOnline.bind(this);
  }

  private __onOnline() {
    this._status$.next(InternetStatuses.ONLINE);
  }

  private __onOffline() {
    this._status$.next(InternetStatuses.OFFLINE);
  }
  //#endregion Internal Methods
}
