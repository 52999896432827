<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data">
    <div class="symptom-horizontal">
      <swiper [config]="swiperOptions">
        <ng-container *ngFor="let symptom of data; let i = index">
          <ng-template swiperSlide>
            <ncis-effect-card
              (click)="selectedSymptomEvent.emit(symptom)"
              [data]="symptom"
            ></ncis-effect-card>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <div class="symptom-horizontal">
    <swiper [config]="swiperOptions">
      <ng-container *ngFor="let item of [1,2,3]">
        <ng-template swiperSlide>
          <ncis-effect-card [loading]="true"></ncis-effect-card>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
</ng-template>
