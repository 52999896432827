import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DRUG_DETAIL_SERVICE, IDrugDetailService, IRegimenService, REGIMEN_SERVICE,} from '@mobile-data-access-services';
import {ComponentStore} from '@ngrx/component-store';
import {
    catchError,
    EMPTY,
    finalize,
    forkJoin,
    from,
    map,
    mergeMap,
    Observable,
    of,
    pipe,
    switchMap,
    take,
    tap
} from 'rxjs';
import {ArticleCategories, DrugDetailControlCodes, ScreenCodes} from '@mobile-data-access-enums';
import {Store} from '@ngrx/store';
import {IRootState} from '../root.state';
import {DrugDetailActions} from './drug-detail.action';
import {IArticle, IDrugDetailQueryParams, IRelatedTopicCard} from '@mobile-data-access-interfaces';
import {
    DrugArticleDoctorNavigationRequest,
    DrugArticleGeneralPrecautionNavigationRequest,
    DrugArticleOralNavigationRequest,
    DrugArticleOseNavigationRequest, DrugArticleRelatedTopicNavigationRequest,
    DrugArticleRseNavigationRequest
} from '@mobile-data-access-models';
import {
    DrugArticleLtseNavigationRequest
} from '../../../../models/src/navigation-requests/article-details/drug-article-ltse-navigation-request';
import {ISmartNavigatorService, SMART_NAVIGATOR_SERVICE} from '@ui-tool/core';
import {NavController} from '@ionic/angular';

@Injectable()
export class DrugDetailComponentStore extends ComponentStore<{}> {

    //#region Constructor

    public constructor(
        @Inject(DRUG_DETAIL_SERVICE)
        protected readonly _drugDetailService: IDrugDetailService,
        @Inject(REGIMEN_SERVICE)
        protected readonly _regimenService: IRegimenService,
        @Inject(SMART_NAVIGATOR_SERVICE)
        protected readonly _navigationService: ISmartNavigatorService,
        protected readonly _navController: NavController,
        protected readonly _activatedRoute: ActivatedRoute,
        protected readonly _store: Store<IRootState>
    ) {
        super({});
    }

    //#endregion

    //#region Methods

    public readonly loadSideEffect = this.effect<never>(
        pipe(
            switchMap(() => {
                return this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.SIDE_EFFECT, true);
                            return this._drugDetailService.getSideEffectByDrugIdAsync(id).pipe(
                                tap((sideEffect) => {

                                    this._store.dispatch(
                                        DrugDetailActions.changeSideEffect(sideEffect)
                                    );
                                }),
                                finalize(() =>
                                    this._changeLoadingStatus(DrugDetailControlCodes.SIDE_EFFECT, false)
                                )
                            );
                        })
                    )
            })
        )
    );

    public readonly loadRelatedTopics = this.effect<never>(
        pipe(
            switchMap(() => {
                return this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.RELATED_TOPICS, true);
                            return this._drugDetailService.getRelatedTopicsAsync(id).pipe(
                                tap((relatedTopics) => {
                                    this._store.dispatch(
                                        DrugDetailActions.changeRelatedTopics({items: relatedTopics})
                                    );
                                }),
                                finalize(() =>
                                    this._changeLoadingStatus(DrugDetailControlCodes.RELATED_TOPICS, false)
                                )
                            );
                        })
                    )
            })
        )
    );

    public readonly loadBasicInfo = this.effect<never>(
        pipe(
            switchMap(() =>
                this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.HEADER_DRUG, true);
                            return this._drugDetailService.getBasicInfoByDrugIdAsync(id)
                                .pipe(
                                    tap(basicInfo => this._store.dispatch(DrugDetailActions.changeBasicInfo(basicInfo))),
                                    finalize(() => {
                                        this._changeLoadingStatus(DrugDetailControlCodes.HEADER_DRUG, false);
                                    })
                                )
                        })
                    )
            )
        )
    )

    public readonly loadOverview = this.effect<never>(
        pipe(
            switchMap(() => {
                return this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.OVERVIEW, true);
                            return this._drugDetailService.getOverviewByDrugIdAsync(id).pipe(
                                tap(overview => {
                                    this._store.dispatch(
                                        DrugDetailActions.changeOverview(overview)
                                    );
                                }),
                                finalize(() =>
                                    this._changeLoadingStatus(DrugDetailControlCodes.OVERVIEW, false)
                                )
                            );
                        })
                    )
            })
        )
    );


    public readonly loadArticles = this.effect<never>(
        pipe(
            switchMap((id) => {
                return this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.ARTICLES, true);
                            return this._drugDetailService.getArticlesByDrugIdAsync(id).pipe(
                                tap((articles) => {
                                    this._store.dispatch(
                                        DrugDetailActions.changeArticles({data: articles})
                                    );
                                }),
                                finalize(() =>
                                    this._changeLoadingStatus(DrugDetailControlCodes.ARTICLES, false)
                                )
                            );
                        })
                    )
            })
        )
    );


    public readonly initialize = this.effect<never>(
        pipe(
            tap(() => {
                this.loadBasicInfo();
                this.loadOverview();
                this.loadArticles();
                this.loadRelatedTopics();
                this.loadSideEffect();
                this.loadTags();
            })
        )
    );

    public readonly loadTags = this.effect<never>(
        pipe(
            switchMap(() => {
                return this._getDrugDetailIdAsync()
                    .pipe(
                        mergeMap(id => {
                            this._changeLoadingStatus(DrugDetailControlCodes.TAGS, true);
                            return this._drugDetailService.getTagsAsync(id).pipe(
                                tap((tags) => {
                                    this._store.dispatch(
                                        DrugDetailActions.changeTags({items: tags})
                                    );
                                }),
                                finalize(() =>
                                    this._changeLoadingStatus(DrugDetailControlCodes.TAGS, false)
                                )
                            )
                        })
                    );
            })
        )
    );

    public toArticlePage = this.effect<IArticle>(
        pipe(
            switchMap(article => forkJoin([of(article), this._getDrugDetailIdAsync()])),
            map(([article, drugId]) => {
                switch (article.category) {
                    case ArticleCategories.DRUG_DETAIL_ARTICLE_DOCTOR:
                        return new DrugArticleDoctorNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    case ArticleCategories.DRUG_DETAIL_GENERAL_PRECAUTION:
                        return new DrugArticleGeneralPrecautionNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    case ArticleCategories.DRUG_DETAIL_ORAL_MED:
                        return new DrugArticleOralNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    case ArticleCategories.DRUG_DETAIL_OCCASION_SIDE_EFFECT:
                        return new DrugArticleOseNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    case ArticleCategories.DRUG_DETAIL_RARE_SIDE_EFFECT:
                        return new DrugArticleRseNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    case ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT:
                        return new DrugArticleLtseNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, article.id);
                    default:
                        throw new Error('INVALID_ARTICLE');
                }
            }),
            mergeMap(navigationRequest => {
                const urlTree = this._navigationService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams || {}, navigationRequest.extras);
                return from(this._navController.navigateForward(urlTree));
            }),
            catchError(exception => {
                console.error(exception);
                return EMPTY;
            })
        )
    )

    public readonly toRelatedTopic = this.effect<IRelatedTopicCard>(
        pipe(
            switchMap(topic => forkJoin([of(topic), this._getDrugDetailIdAsync()])),
            mergeMap(([topic, drugId]) => {
                const navigationRequest = new DrugArticleRelatedTopicNavigationRequest(ScreenCodes.DRUG_DETAIL, drugId, topic.id);
                const urlTree = this._navigationService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams || {}, navigationRequest.extras);
                return from(this._navController.navigateForward(urlTree));
            })
        )
    )

    //#endregion

    //#region Internal methods

    protected _getDrugDetailIdAsync(): Observable<string> {
        return this._activatedRoute.queryParams.pipe(
            take(1),
            map((params) => params as IDrugDetailQueryParams),
            map((queryParams) => queryParams.id)
        );
    }

    protected _changeLoadingStatus(
        controlCode: DrugDetailControlCodes,
        status: boolean
    ): void {
        this._store.dispatch(
            DrugDetailActions.changeLoadingStatus({
                code: controlCode,
                loading: status,
            })
        );
    }

    //#endregion
}
