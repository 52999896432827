export class SymptomsQueryParams {

    //#region Constructor

    public constructor(public readonly type: string,
                       public readonly id: string) {
    }

    //#endregion

}
