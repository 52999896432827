import { NgModule } from '@angular/core';
import { CircleMenuItemComponent } from './circle-menu-item.component';
import { AspectRatioDirectiveModule } from '@shared-directives';

@NgModule({
  declarations: [CircleMenuItemComponent],
  imports: [AspectRatioDirectiveModule],
  exports: [CircleMenuItemComponent],
})
export class CircleMenuItemModule {}
