import {Inject, Injectable} from "@angular/core";
import {IArticleDetailState} from "./article-detail.state";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, catchError, EMPTY, exhaustMap, filter, finalize, mergeMap, pipe, switchMap, tap} from "rxjs";
import {
    ARTICLE_SERVICE,
    BOOKMARK_SERVICE,
    IArticleService,
    IBookmarkService,
    IUiService,
    UI_SERVICE
} from "@mobile-data-access-services";
import {ArticleCategories} from "@mobile-data-access-enums";
import {IArticle, IUiToastOptions} from '@mobile-data-access-interfaces';
import {ComponentStore} from '@ngrx/component-store';

@Injectable()
export class ArticleDetailComponentStore extends ComponentStore<IArticleDetailState> {

    //#region Properties


    private readonly _toastOptions: IUiToastOptions = {
        position: 'top',
        duration: 5000,
        purge: true
    };

    private readonly __doingBookmark$ = new BehaviorSubject(false);

    public readonly bookmarked$ = new BehaviorSubject(false);

    //#endregion

    //#region Commands

    public readonly markAsView = this.effect<IArticle>(
        pipe(
            switchMap(article => this._articleService.viewArticleAsync(
                article.id as string,
                article.category as ArticleCategories
            )),
            catchError(() => EMPTY)
        )
    );

    public readonly bookmark = this.effect<IArticle>(
        pipe(
            exhaustMap(article => {

                // Mark a busy status.
                this.__doingBookmark$.next(true);

                const bookmarked = this.bookmarked$.value;
                if (bookmarked) {
                    return this._uiService.showToastAsync({
                        ...this._toastOptions,
                        message: 'REMOVING_THIS_ARTICLE_FROM_BOOKMARKS',
                        color: 'warning'
                    }, 'ARTICLE_DETAIL_PAGE')
                        .pipe(
                            mergeMap((_) => {
                                return this._bookmarkService.deleteAsync({
                                    itemId: `${article.id}`,
                                    category: article.category as ArticleCategories
                                })
                                    .pipe(
                                        mergeMap(() => {
                                            this.bookmarked$.next(false);
                                            return this._uiService.showToastAsync({
                                                ...this._toastOptions,
                                                message: 'THIS_ARTICLE_REMOVED_FROM_BOOKMARK_LIST',
                                                color: 'success'
                                            }, 'ARTICLE_DETAIL_PAGE')
                                        }),
                                        catchError(() => {
                                            return this._uiService.showToastAsync({
                                                ...this._toastOptions,
                                                message: 'ERROR_WHILE_UNBOOKMARKING_THIS_ARTICLE',
                                                color: 'danger'
                                            }, 'ARTICLE_DETAIL_PAGE')
                                        }),
                                        finalize(() => {
                                            this.__doingBookmark$.next(false);
                                        })
                                    );
                            })
                        )
                }

                return this._uiService.showToastAsync({
                    ...this._toastOptions,
                    message: 'ARTICLE_BEING_BOOKMARKED',
                    color: 'primary'
                }, 'ARTICLE_DETAIL_PAGE')
                    .pipe(
                        mergeMap(() => {
                            return this._bookmarkService.addAsync({
                                itemId: article.id as string,
                                category: article.category as ArticleCategories
                            }).pipe(
                                mergeMap(() => {
                                    return this._uiService.showToastAsync({
                                        ...this._toastOptions,
                                        message: 'THIS_ARTICLE_BOOKMARKED_SUCCESSFULLY',
                                        color: 'success'
                                    }, 'ARTICLE_DETAIL_PAGE')
                                }),
                                tap(() => {
                                    this.bookmarked$.next(true);
                                }),
                                catchError(() => {
                                    return this._uiService.showToastAsync({
                                        ...this._toastOptions,
                                        message: 'ERROR_WHILE_BOOKMARKING_THIS_ARTICLE',
                                        color: 'danger'
                                    }, 'ARTICLE_DETAIL_PAGE')
                                }),
                                finalize(() => {
                                    this.__doingBookmark$.next(false);
                                })
                            );
                        })
                    );
            })
        )
    );

    public readonly checkBookmark = this.effect<IArticle>(
        pipe(
            filter(article => article.id !== null && article.id !== undefined
                && article.category !== null && article.category !== undefined),
            switchMap(article => {
                    return this._bookmarkService.searchStatusesAsync([
                        {
                            itemId: article.id,
                            category: article.category!
                        }
                    ])
                }
            ),
            tap((data) => {
                this.bookmarked$.next(!!data.length);
            }),
            catchError(() => {
                this.bookmarked$.next(false);
                return EMPTY;
            })
        )
    );

    public readonly initialize = this.effect<never>(
        pipe(
            switchMap(() => this.select(x => x.article)),
            filter(article => article?.id !== null && article?.id !== undefined),
            tap(article => {
                this.markAsView(article!);
                this.checkBookmark(article!);
            })
        )
    );

    //#endregion

    //#region Constructor

    public constructor(
        protected readonly _activatedRoute: ActivatedRoute,
        @Inject(ARTICLE_SERVICE)
        protected readonly _articleService: IArticleService,
        @Inject(BOOKMARK_SERVICE)
        protected readonly _bookmarkService: IBookmarkService,
        @Inject(UI_SERVICE)
        protected readonly _uiService: IUiService
    ) {
        super({
            loading: false
        })
    }

    //#endregion
}
