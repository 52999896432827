import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';

export class ArticleDetailQueryParams {
  //#region Constructor

  public constructor(
    public readonly source: ScreenCodes | undefined,
    public readonly sourceId: string,
    public readonly articleId: string,
    public readonly category?: ArticleCategories
  ) {}

  //#endregion
}
