import { ITreatmentColorService } from "./treatment-color-service.interface";
import { Injectable } from "@angular/core";
import { COLORS } from "@mobile-data-access-enums";
import { TreatmentResolve } from "@mobile-data-access-models";

@Injectable()
export class TreatmentColorService implements ITreatmentColorService {
  //#region Properties

  private __codeToColor: Record<string, string>;

  //#endregion

  //#region Constructor

  public constructor() {
    this.__codeToColor = {};
  }

  //#endregion

  //#region Methods


  public getByCode(code: string | null): string {
    if (!code) return COLORS.TREATMENT_REST_AT_HOME;
    const actualCode = code.toLocaleLowerCase();
    let szColor = this.__codeToColor[actualCode];

    if (!szColor) {
      szColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      this.__codeToColor[actualCode] = szColor;
    }
    return szColor;
  }

  //#endregion
}
