import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISymptomCard } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-effect-card',
  templateUrl: './effect-card.component.html',
  styleUrls: ['./effect-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EffectCardComponent {
  //#region properties

  @Input()
  public data: ISymptomCard | null = null;

  @Input()
  public loading = false;

  //#endregion
}
