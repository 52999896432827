import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'ncis-datetime-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {

  //#region Properties

  @Output()
  public readonly next = new EventEmitter<never>();

  @Output()
  public readonly prev = new EventEmitter<never>();

  @Input()
  public currentDate: Date = new Date();

  public readonly monthWords: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  public get current(): Record<'year' | 'month' | 'day', number> {
    return this.resolveMonthAndYear({
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    });
  }

  //#endregion

  //#region Methods

  public resolveMonthAndYear(value: Record<'year' | 'month' | 'day', number>): Record<'year' | 'month' | 'day', number> {
    return {
      day: value.day,
      month: value.month < 1 ? 12 : (value.month > 12 ? 1 : value.month),
      year: value.month < 1 ? value.year - 1 : (value.month > 12 ? value.year + 1 : value.year),
    }
  }

  //#endregion
}
