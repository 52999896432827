import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom, from, Observable } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { ToastController } from "@ionic/angular";
import { IRootState, PaxmanComponentStore, UserSelectors } from "@mobile-data-access-stores";
import { Store } from "@ngrx/store";
import { IPaxmanService, PAXMAN_SERVICE } from "@mobile-data-access-services";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { PaxmanIntroductionNavigationRequest } from "@mobile-data-access-models";

@Injectable()
export class PaxmanMemberGuard implements CanActivate {
  //#region Constructor

  public constructor(
    protected readonly _store: Store<IRootState>,
    protected readonly _toastController: ToastController,
    protected readonly _translateService: TranslocoService,
    protected readonly _componentStore: PaxmanComponentStore,
    protected readonly _router: Router,
    @Inject(PAXMAN_SERVICE)
    protected readonly _paxmanService: IPaxmanService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
  ) {
  }

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree> {
    const asyncHandler = async () => {

      try {

        const profile = await firstValueFrom(
          this._store.select(UserSelectors.profile));

        // Profile is not found.
        if (!profile) {
            return false;
        }

        // User has not activated paxman before.
        if (!profile.activatedPaxman) {
            const navigationRequest = new PaxmanIntroductionNavigationRequest();
            return this._navigationService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams as any, navigationRequest.extras);
        }

        // User has not joined paxman program, but this program is available to him/her.
          this._componentStore.initMember();
          return true;
      } catch (exception) {
        const toast = await this._toastController.create({
          position: "top",
          color: "danger"
        });
        toast.message = this._translateService.translate(
          "SYSTEM_MESSAGE.CONTENT_NOT_FOUND"
        );
        await toast.present();
        return false;
      } finally {
      }
    }

    return from(asyncHandler());
  }

  //#endregion
}
