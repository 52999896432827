import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IRecommendation } from '@mobile-data-access-interfaces';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'ncis-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsComponent {
  //#region Properties

  private __items: IRecommendation[];

  private readonly __option: SwiperOptions;

  //#endregion

  //#region Accessors

  public get items(): IRecommendation[] {
    return this.__items;
  }

  @Input()
  public set items(value: IRecommendation[]) {
    this.__items = value || [];
  }

  public get option(): SwiperOptions {
    return this.__option;
  }

  //#endregion

  //#region Constructor

  public constructor() {
    this.__items = [];

    this.__option = {
      grabCursor: true,
      slidesPerView: 'auto',
      effect: 'coverflow',
      spaceBetween: 16,
      coverflowEffect: {
        rotate: 0,
        slideShadows: false,
        stretch: 0,
        depth: 0,
        modifier: 1,
      },
    };
  }

  //#endregion
}
