import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import {
  ISeverityDefinition,
  ITrackerEffectServe,
} from '@mobile-data-access-interfaces';
import {
  TrackerEffectPriorities,
  TrackerEffectServeCodes,
} from '@mobile-data-access-enums';
import { map, Observable } from 'rxjs';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';

@Component({
  selector: 'ncis-tracker-record-card',
  templateUrl: './tracker-record-card.component.html',
  styleUrls: ['./tracker-record-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerRecordCardComponent {
  //#region Properties
  @Input()
  public data: ITrackerEffectServe | null = null;

  @Input()
  public ctcaeCode: string | null = null;

  @Input()
  public isSelected = false;

  @Input()
  public loading = false;

  public get svgLink$(): Observable<string> {
    return this.__getSeveritiesAsync().pipe(
      map((severities) => {
        const severity = severities?.settings.find(
          (def) => def.code === (this.data?.code || TrackerEffectServeCodes.G1)
        );

        let actualIcon = `ncis-assets/svgs/tracker/tracker-effect-${this.codeToPriority(
          this.data?.code || TrackerEffectServeCodes.G1
        ).toLowerCase()}.svg`;
        if (severity && severity.icon) {
          actualIcon = `ncis-assets/svgs/tracker/${severity.icon}`;
        }
        return actualIcon;
      })
    );
  }

  public get title$(): Observable<string> {
    return this.__getSeveritiesAsync().pipe(
      map((severities) => {
        const severity = severities?.settings.find(
          (def) => def.code === (this.data?.code || TrackerEffectServeCodes.G1)
        );

        let actualTitle = `TRACKER_RECORD.${
          this.data?.code || TrackerEffectServeCodes.G1
        }_TITLE`;

        if (severity && severity.title) {
          actualTitle = severity.title;
        }
        return actualTitle;
      })
    );
  }
  //#endregion

  //#region Constructor

  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService
  ) {}
  //#endregion

  //#region Methods
  public get hidden(): boolean {
    return !this.loading && !this.data;
  }

  public get circleLink(): string {
    return `ncis-assets/svgs/tracker/tracker-record-circle-${
      this.isSelected ? 'selected' : 'default'
    }.svg`;
  }

  public codeToPriority(
    code: TrackerEffectServeCodes
  ): TrackerEffectPriorities {
    switch (code) {
      case TrackerEffectServeCodes.G2:
        return TrackerEffectPriorities.MEDIUM;
      case TrackerEffectServeCodes.G3:
        return TrackerEffectPriorities.HIGH;
      default:
        return TrackerEffectPriorities.LOW;
    }
  }

  private __getSeveritiesAsync(): Observable<ISeverityDefinition | undefined> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        const severities = appSettings.severities?.find(
          (x) => x.ctcaeCode === this.ctcaeCode
        );
        return severities;
      })
    );
  }
  //#endregion
}
