import { Inject, Injectable } from '@angular/core';
import { IAuthenticationService } from './authentication-service.interface';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, mergeMap, Observable, tap, throwError } from 'rxjs';
import { APP_SETTINGS_SERVICE, IAppSettingsService } from '../../common';
import { NavController } from '@ionic/angular';
import { WINDOW } from '@ui-tool/core';
import {
  IOpenAuthenticationRequest,
  IOpenAuthenticationResult,
  IThemeOption,
} from '@mobile-data-access-interfaces';
import { SsoStatusesEnum } from '@mobile-data-access-enums';

@Injectable()
export class AuthenticationService implements IAuthenticationService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService,
    @Inject(WINDOW) protected readonly _window: Window,
    protected readonly _navController: NavController,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public doOpenAuthenticationAsync(provider: string): Observable<void> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((settings) => settings.openAuthenticationOptions),
      filter((items) => items && items.length > 0),
      map((items) => items.find((x) => x.provider === provider)),
      filter((option) => option != null),
      tap((option) => {
        const queryStrings = new URLSearchParams();
        queryStrings.set('response_type', 'code');
        queryStrings.set('client_id', option!.clientId);
        queryStrings.set(
          'redirect_uri',
          `${option!.redirectUrl}?provider=${provider}`
        );
        queryStrings.set('scope', option!.scope);

        const url = `${option!.baseUrl}?${queryStrings.toString()}`;
        this._window.location.href = url;
      }),
      map(() => void 0)
    );
  }

  public getOpenAuthenticationResultAsync(
    provider: string,
    code: string
  ): Observable<IOpenAuthenticationResult> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      mergeMap((appSettings) => {
        const authenticationOptions =
          appSettings.openAuthenticationOptions?.find(
            (x) => x.provider === provider
          );
        if (!authenticationOptions) {
          return throwError('AUTHENTICATION_FAILED');
        }

        const fullUrl = `${appSettings.apiUrl}/oauth/authorize`;
        const data: IOpenAuthenticationRequest = {
          code,
          provider,
          redirectUri: authenticationOptions!.redirectUrl,
        };
        return this._httpClient.post<IOpenAuthenticationResult>(fullUrl, data);
      })
    );
  }

  public validateSsoTokenAsync(idToken: string): Observable<SsoStatusesEnum> {
    return this._apiEndpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((apiUrl: string) => {
        const fullUrl = `${apiUrl}/authentication/validate`;
        const headers = new HttpHeaders({}).append(
          'Authorization',
          `Bearer ${idToken}`
        );
        return this._httpClient.post<{ status: SsoStatusesEnum }>(
          fullUrl,
          {},
          {
            headers,
          }
        );
      }),
      map((apiResult) => {
        return apiResult.status;
      })
    );
  }

  public getThemeAsync(): Observable<IThemeOption | undefined> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        return appSettings.theme;
      })
    );
  }

  public getTokenLocationAsync(): Observable<string | undefined> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        return appSettings.authentication?.token.location;
      })
    );
  }

  //#endregion
}
