<ng-template #loadingTemplate>
  <div class="saved-item-card">
    <div class="saved-item-card__image"></div>
    <div class="saved-item-card__information w-[100%]">
      <h2>
        <ncis-skeleton></ncis-skeleton>
      </h2>
      <ncis-skeleton></ncis-skeleton>
      <ncis-skeleton></ncis-skeleton>
      <ncis-skeleton></ncis-skeleton>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loadingTemplate">
  <ng-container *ngIf="data">
    <div class="saved-item-card">
      <div (click)="bookmarkDetailClick.emit()" class="saved-item-card__image">
        <img
          [attr.data-test-id]="'bookmark-image'"
          *ngIf="data.imageUrl"
          [alt]="data.title"
          [src]="data.imageUrl"
        />
      </div>
      <ncis-common-icon
        class="h-[26px] text-ncis-neutral-800 absolute right-4 top-6"
        folder="general"
        name="bookmark-active"
        (click)="unBookmark()"
      ></ncis-common-icon>
      <div
        (click)="bookmarkDetailClick.emit()"
        class="saved-item-card__information"
      >
        <h2 [attr.data-test-id]="'bookmark-title'">
          {{ data.title || 'N/A' }}
        </h2>
        <p class="line-clamp-2">{{(data.description | toPlainText) || ''}}</p>
        <span>saved on {{ (data.savedOn || '') | date: 'dd/MM/yyyy' }}</span>
      </div>
    </div>
  </ng-container>
</ng-container>
