import {Translation, TranslocoLoader, TranslocoService} from '@ngneat/transloco';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslocoLoaderData} from '@ngneat/transloco/lib/transloco.loader';
import {catchError, forkJoin, map, mergeMap, Observable, of, switchMap, tap} from 'rxjs';
import {APP_SETTINGS_SERVICE, IAppSettingsService} from "../app-settings";
import {Languages, StorageKeys, TranslationSources} from "@mobile-data-access-enums";
import {LocalForageService} from 'ngx-localforage';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {
    //#region Constructor

    public constructor(
        @Inject(APP_SETTINGS_SERVICE)
        protected readonly _appSettingsService: IAppSettingsService,
        protected readonly _localForage: LocalForageService,
        protected readonly _httpClient: HttpClient) {
    }

    //#nedregion

    //#region  Methods

    public getTranslation(
        lang: string,
        data?: TranslocoLoaderData
    ): Observable<Translation> | Promise<Translation> {

        const context = data?.scope;
        return forkJoin([
            this._localForage.getItem(StorageKeys.LANGUAGE),
            this._appSettingsService.loadSettingsAsync(false)
        ])
            .pipe(
                mergeMap(([language, appSettings]) => {

                    let getTranslationsObservable = of({});
                    const i18n = appSettings.i18n;
                    const locale = language || Languages.EN;

                    if (i18n?.source?.toLocaleLowerCase() === TranslationSources.ONLINE.toLocaleLowerCase()) {
                        const httpParams: Record<string, string> = {};
                        httpParams['locale'] = locale;

                        if (!context) {
                            httpParams['withNullContext'] = 'true'
                        } else {
                            httpParams['contexts[0]'] = context;
                        }

                        getTranslationsObservable =  this._httpClient.get<Translation>(`${i18n.apiUrl}/i18n`, {
                            params: httpParams
                        });
                    } else {
                        getTranslationsObservable = this._httpClient.get<Translation>(`/ncis-assets/i18n/${context}/${locale}.json`);
                    }

                    return getTranslationsObservable
                        .pipe(
                            catchError(exception => {
                                // By pass the exception.
                                console.log(exception);
                                return of({});
                            })
                        )
                })
            );
    }

    //#endregion
}
