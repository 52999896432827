import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ITrackerRecordState } from './tracker-record.state';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
  ISymptomService,
  SYMPTOM_SERVICE
} from '@mobile-data-access-services';
import {
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  ScreenCodes,
  SpinnerContainerIds,
  TrackerEffectServeCodes,
} from '@mobile-data-access-enums';
import { Router } from '@angular/router';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import {
  SymptomDetailNavigationRequest,
  TrackerNavigationRequest,
} from '@mobile-data-access-models';

@Injectable()
export class TrackerRecordComponentStore extends ComponentStore<ITrackerRecordState> {
  //#region Methods
  public readonly submit = this.effect<{
    onSubmittedAsyncHandler?: () => Observable<void>;
  }>(
    pipe(
      switchMap(({ onSubmittedAsyncHandler }) => {
        let displaySpinnerRequestId = this._spinnerService.displaySpinner(
          SpinnerContainerIds.APPLICATION
        );
        const { selectedCode, ctcaeCode } = this.get();
        return this._symptomService
          .submitTrackingAsync(
            ctcaeCode as string,
            selectedCode as TrackerEffectServeCodes
          )
          .pipe(
            mergeMap(() => {
              if (!onSubmittedAsyncHandler) {
                return of(void 0);
              }

              return onSubmittedAsyncHandler();
            }),
            catchError(() => EMPTY),
            finalize(() =>
              this._spinnerService.deleteSpinner(
                SpinnerContainerIds.APPLICATION,
                displaySpinnerRequestId
              )
            )
          );
      })
    )
  );

  public readonly redirect = this.effect<never>(
    pipe(
      switchMap(() => {
        return this.select((x) => ({
          ctCaeCode: x.ctcaeCode,
          selectedCode: x.selectedCode,
        })).pipe(take(1));
      }),
      mergeMap(({ selectedCode, ctCaeCode }) => {
        return this._appSettingsService.loadSettingsAsync(false)
          .pipe(
            mergeMap(appSettings => {
              const severities = appSettings.severities || [];
              let severityCode = severities.find(x => x.ctcaeCode === ctCaeCode)?.settings?.find(x => x.code === selectedCode)?.behavior as TrackerEffectServeCodes | null;
              if (!severityCode) {
                severityCode = selectedCode;
              }

              if (severityCode === TrackerEffectServeCodes.G1) {
                return this._navigationService.navigateToScreenAsync(
                  new SymptomDetailNavigationRequest(ctCaeCode!)
                );
              }

              return this._navigationService.navigateToScreenAsync(
                new TrackerNavigationRequest()
              );
            })
          )

      })
    )
  );
  //#endregion

  //#region Constructor
  public constructor(
    @Inject(SYMPTOM_SERVICE)
    protected readonly _symptomService: ISymptomService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService,
  ) {
    super({
      data: null,
      selectedCode: null,
      ctcaeCode: null,
    });
  }
  public readonly updateData = this.updater<ITrackerRecordState['data']>(
    (state, data) => ({
      ...state,
      data,
    })
  );
  public readonly updateCtcaeCode = this.updater<
    ITrackerRecordState['ctcaeCode']
  >((state, ctcaeCode) => ({
    ...state,
    ctcaeCode,
  }));
  public readonly updateSelectedCode = this.updater<
    ITrackerRecordState['selectedCode']
  >((state, selectedCode) => ({
    ...state,
    selectedCode,
  }));

  //#endregion
}
