import { Inject, Injectable } from "@angular/core";
import { IChemotherapyDiagnosisDetailState } from "./diagnosis-detail.state";
import { DIAGNOSIS_SERVICE, IDiagnosisService } from "@mobile-data-access-services";
import { ActivatedRoute } from "@angular/router";
import { ChemotherapyBaseComponentStore } from "../../base";
import { DiagnosisControlCodes } from "@mobile-data-access-enums";

@Injectable()
export class ChemotherapyDiagnosisDetailComponentStore extends ChemotherapyBaseComponentStore<IChemotherapyDiagnosisDetailState> {

  public constructor(
    @Inject(DIAGNOSIS_SERVICE)
    protected readonly _diagnosisService: IDiagnosisService,
    protected override readonly _activatedRoute: ActivatedRoute,
  ) {
    super(_activatedRoute, {
      id: null,
      basicInfoLoading: false,
      basicInfo: null,
      overviewLoading: false,
      overview: null,
      topicsLoading: false,
      topics: null,
      tagsLoading: false,
      tags: null,
      whatNext: null,
      whatNextLoading: false
    });
  }

  public setup(): void {
    this.load('overview', (id) => this._diagnosisService.getChemoAsync<IChemotherapyDiagnosisDetailState['overview']>(id, DiagnosisControlCodes.OVERVIEW));
    this.load('whatNext', (id) => this._diagnosisService.getChemoAsync<IChemotherapyDiagnosisDetailState['whatNext']>(id, DiagnosisControlCodes.WHAT_NEXT));
    this.load('topics', (id) => this._diagnosisService.getChemoAsync<IChemotherapyDiagnosisDetailState['topics']>(id, DiagnosisControlCodes.RELATED_TOPICS));
    this.load('tags', (id) => this._diagnosisService.getChemoAsync<IChemotherapyDiagnosisDetailState['tags']>(id, DiagnosisControlCodes.TAGS));
  }
}
