<ng-container *ngIf="data">
  <div class="what-next">
    <span
      class="absolute left-0 top-0 {{topLeftColorClass}}"
      [inlineSVG]="topLeftImage"
    ></span>
    <span
      class="absolute right-0 top-0 {{topRightColorClass}}"
      [inlineSVG]="topRightImage"
    ></span>
    <span
      class="absolute left-0 bottom-0 {{bottomLeftColorClass}}"
      [inlineSVG]="bottomLeftImage"
    ></span>
    <span
      class="absolute right-0 bottom-0 {{bottomRightColorClass}}"
      [inlineSVG]="bottomRightImage"
    ></span>
    <div class="what-next__container">
      <ng-container *ngIf="!loading; else skeletonTemplate">
        <div class="what-next__content">
          <h2>{{ title || 'N/A' }}</h2>
          <p class="line-clamp-3">{{ data.description || 'N/A' }}</p>
        </div>
        <ng-container *ngIf="data.categories && data.categories.length">
          <div class="what-next__category">
            <ng-container *ngFor="let item of data.categories">
              <ncis-button (click)="clickCategoryEvent.emit(item)" [label]="item.title || 'N/A'"></ncis-button>
            </ng-container>
          </div>
        </ng-container>
        <ng-content></ng-content>
      </ng-container>
      <ng-template #skeletonTemplate>
        <div class="what-next__content">
          <ncis-skeleton class="w-[132px] h-[30px]"></ncis-skeleton>
          <ncis-skeleton class="w-[222px] h-[66px]"></ncis-skeleton>
        </div>
        <div class="what-next__category">
          <ncis-button [loading]="true"></ncis-button>
          <ncis-button [loading]="true"></ncis-button>
          <ncis-button [loading]="true"></ncis-button>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
