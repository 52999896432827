import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, from, map, Observable, tap } from 'rxjs';
import { IRegimenService, REGIMEN_SERVICE } from '@mobile-data-access-services';
import { IBasicInfo } from '@mobile-data-access-interfaces';
import { ChemotherapyControlCodes } from '@mobile-data-access-enums';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { ChemotherapyTreatmentDetailComponentStore } from '@mobile-data-access-stores';

@Injectable()
export class TreatmentDetailGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(REGIMEN_SERVICE)
    protected readonly _regimenService: IRegimenService,
    protected readonly _toastController: ToastController,
    protected readonly _translateService: TranslocoService,
    protected readonly _componentStore: ChemotherapyTreatmentDetailComponentStore
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const id = activatedRouteSnapshot.queryParams['id'];

    if (!id || !id.length) {
      return false;
    }

    const asyncHandler = async () => {
      try {
        return firstValueFrom(
          this._regimenService
            .getChemoAsync<IBasicInfo>(id, ChemotherapyControlCodes.BASIC_INFO)
            .pipe(
              tap((data) => {
                if (!!data) {
                  const { title } = data;
                  this._componentStore.updateProperty('basicInfo', data);
                  this._componentStore.updateProperty(
                    'basicInfoLoading',
                    false
                  );
                } else {
                  throw new Error('EMPTY');
                }
              }),
              map((data) => !!data),
              catchError(() => from(this._showError()))
            )
        );
      } catch (exception) {
        return this._showError();
      } finally {
      }
    };

    return from(asyncHandler());
  }

  //#endregion

  //#region Internal Methods

  protected async _showError(): Promise<boolean> {
    const toast = await this._toastController.create({
      position: 'top',
      color: 'danger',
      duration: 5000,
    });
    toast.message = await firstValueFrom(
      this._translateService.selectTranslate(
        'CONTENT_NOT_FOUND',
        {},
        { scope: 'SYSTEM_MESSAGE' }
      )
    );
    await toast.present();

    return Promise.resolve(false);
  }

  //#endregion
}
