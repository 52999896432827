import { Inject, Injectable } from "@angular/core";
import { NavigatorBaseState } from "./base.state";
import { catchError, EMPTY, filter, from, map, mergeMap, pipe, switchMap } from "rxjs";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { ChemotherapyNavigationRequest } from "@mobile-data-access-models";
import { ContainerComponentStore } from "../../../shared";
import { ScreenCodes } from "@mobile-data-access-enums";
import { INavigator, NavigatorMapping } from "@mobile-data-access-interfaces";
import { NavController } from "@ionic/angular";

@Injectable()
export class NavigatorBaseComponentStore<
  TCode extends keyof NavigatorMapping,
  TState extends NavigatorBaseState<TCode>
> extends ContainerComponentStore<TState, TState['data']> {
  //#region Properties

  public readonly detail = this.effect<never>(
    pipe(
      switchMap(() => this.select((state) => state.data)),
      filter((data) => data != null),
      map((data) => (data as INavigator<TCode>).data),
      mergeMap((data: NavigatorMapping[TCode]) => {
        const navigationRequest = new ChemotherapyNavigationRequest(
          this._screenCode,
          {
            id: data.id,
          }
        );

        const urlTree = this._navigationService.buildUrlTree(
          navigationRequest.code,
          navigationRequest.routeParams,
          navigationRequest.extras
        );
        return from(this._navController.navigateForward(urlTree))
          .pipe(
            catchError((err) => {
              console.error(err);
              return EMPTY;
            })
          );
      }),
    )
  );

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _navController: NavController,
    protected readonly _screenCode: ScreenCodes
  ) {
    super({
      data: undefined,
      loading: false,
    } as TState);
  }

  //#endregion
}
