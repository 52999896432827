import { NgModule } from '@angular/core';
import { BookmarkCardComponent } from './bookmark-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { TranslocoModule } from '@ngneat/transloco';
import { SkeletonModule } from '../../shared';
import { ToPlainTextPipeModule } from '@shared-directives';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TranslocoModule,
    SkeletonModule,
    ToPlainTextPipeModule,
  ],
  declarations: [BookmarkCardComponent],
  exports: [BookmarkCardComponent],
})
export class BookmarkCardModule {}
