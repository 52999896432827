<section class="tracker-record-warning-card">
  <header>
    <div class="tracker-record-warning-card__information">
      <span [inlineSVG]="svgLink$ | async"></span>
      <h5>{{title$ | async | transloco}}</h5>
    </div>
    <ncis-common-icon (click)="closeEvent.emit()" [folder]="'general'" [name]="'close'" class="w-6 h-6 text-ncis-neutral-800 cursor-pointer"></ncis-common-icon>
  </header>
  <main>
    <p [innerHTML]="description | unescape | transloco | toTrustedHtml"></p>
  </main>
  <footer>
    <button (click)="submitEvent.emit()">{{button | transloco}}</button>
  </footer>
</section>
