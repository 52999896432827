import { NgModule } from '@angular/core';
import { ApiEndpointResolver } from './api-endpoint.resolver';
import { API_ENDPOINT_RESOLVER } from './api-endpoint-resolver.token';

@NgModule({
  providers: [
    {
      provide: API_ENDPOINT_RESOLVER,
      useClass: ApiEndpointResolver,
    },
  ],
})
export class ApiEndpointResolverModule {}
