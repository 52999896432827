import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';

export class AuthenticationNavigationRequest extends NavigateToScreenRequest<
  Record<string, unknown>
> {
  //#region Constructor

  public constructor(redirect: string | null) {
    super(
      ScreenCodes.SESSION_EXPIRED,
      {},
      {
        queryParams: { redirect },
      }
    );
  }

  //#endregion
}
