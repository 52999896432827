import { NgModule } from '@angular/core';
import { FaqItemComponent } from './faq-item.component';
import { CommonModule } from '@angular/common';
import { ToTrustedHtmlPipeModule } from "@ui-tool/core";
import { UnescapePipeModule } from "@shared-pipes";

@NgModule({
  imports: [CommonModule, ToTrustedHtmlPipeModule, UnescapePipeModule],
  declarations: [FaqItemComponent],
  exports: [FaqItemComponent],
})
export class FaqItemModule {}
