import { NgModule } from '@angular/core';
import { EffectCardComponent } from './effect-card.component';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';

@NgModule({
  imports: [CommonModule, SkeletonModule, HiddenImageOnErrorModule],
  declarations: [EffectCardComponent],
  exports: [EffectCardComponent],
})
export class EffectCardModule {}
