export enum ChemotherapyControlCodes {
  BASIC_INFO = 'level2_basic_info',
  RATING = 'level2_page_rating',
  OVERVIEW = 'level2_overview',
  TREATMENT = 'level2_regimens',
  RELATED_TOPICS = 'level2_related_topics',
  ARTICLES = 'level2_article',
  SIDE_EFFECT = 'level2_possible_side_effect',
  TAGS = 'level2_tags',
  SCHEDULE = 'SCHEDULE',
  REGIMENS = 'REGIMENS'
}
