import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import {catchError, forkJoin, mapTo, mergeMap, Observable, of, switchMap, take, throwError} from "rxjs";
import { LocalForageService } from "ngx-localforage";
import { OnboardingStatuses, ScreenCodes, StorageKeys } from "@mobile-data-access-enums";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { IOpenAuthenticationResult, IUserProfile } from "@mobile-data-access-interfaces";
import { DOCUMENT } from "@angular/common";
import { IRootState, PaxmanActions, UserActions, UserSelectors } from "@mobile-data-access-stores";
import { Store } from "@ngrx/store";
import {
    AUTHENTICATION_SERVICE,
    IAuthenticationService,
    IUiService,
    IUserService,
    UI_SERVICE,
    USER_SERVICE
} from "@mobile-data-access-services";
import { OnboardingNavigationRequest } from "@mobile-data-access-models";

@Injectable()
export class AuthenticatedUserGuard implements CanActivate {
  //#region Properties

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _localForage: LocalForageService,
    @Inject(DOCUMENT) protected readonly _document: Document,
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    @Inject(UI_SERVICE)
    protected readonly _uiService: IUiService,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    protected readonly _store: Store<IRootState>
  ) { }

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._localForage.getItem(StorageKeys.AUTHENTICATION_RESULT).pipe(
      mergeMap((authenticationResult: IOpenAuthenticationResult) => {
        if (!authenticationResult) {
          return throwError(() => new Error("INVALID_AUTHENTICATION_RESULT"));
        }

        return this._store.select(UserSelectors.profile).pipe(
          take(1),
          switchMap((profile) => {
            if (profile) {
              return this.__ableToAccessUrlAsync(profile, state.url);
            }

            return forkJoin([this._authenticationService.getThemeAsync(), this._userService.getProfileAsync()])
              .pipe(
                switchMap(([themeOption, profile]) => {
                  this._store.dispatch(PaxmanActions.saveActivation({ data: profile.activatedPaxman }));
                  this._store.dispatch(UserActions.saveProfile({ data: profile }));
                  if (themeOption) {
                      this._uiService.setUpTheme(themeOption);
                  }
                  return this.__ableToAccessUrlAsync(profile, state.url);
                })
              );
          })
        );
      }),
      catchError(() => {
        this._store.dispatch(UserActions.clearProfile());
        const urlParams = new URLSearchParams(this._document.location.search);
        const idToken = urlParams.get("idToken");
        const provider = urlParams.get("provider");
        const urlTree = this._navigationService.buildUrlTree(ScreenCodes.SESSION_EXPIRED,
          void 0,
          {
            queryParams: idToken
              ? {
                idToken,
                provider,
                redirect: state.url
              }
              : { redirect: state.url }
          }
        );
        return this._localForage
          .removeItem(StorageKeys.AUTHENTICATION_RESULT)
          .pipe(mergeMap(() => of(urlTree)));
      })
    );
  }

  private __ableToAccessUrlAsync(profile: IUserProfile, url: string): Observable<boolean> {
    if (url.includes(ScreenCodes.ONBOARDING)) return of(true);

    const isOnboarded = profile.onboarding.status === OnboardingStatuses.ONBOARDED;

    if (isOnboarded) {
      return of(true);
    }

    return this._navigationService.navigateToScreenAsync(
      new OnboardingNavigationRequest()
    ).pipe(
      mapTo(false)
    );
  }

  //#endregion
}
