<ng-container *ngIf="data">
  <div class="meeting-detail">
    <ng-container [ngSwitch]="loading">
      <ng-container *ngSwitchCase="true">
        <ncis-overview [loading]="true"></ncis-overview>
        <ncis-meeting-goal-card [loading]="true"></ncis-meeting-goal-card>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ncis-overview [data]="{title: data.title, description: data.description}"></ncis-overview>
        <ng-container *ngIf="data.meetingGoals && data.meetingGoals.length > 0">
          <ng-container *ngFor="let goal of data.meetingGoals; let i = index">
            <ncis-meeting-goal-card
              [data]="goal"
              [index]="i"
            ></ncis-meeting-goal-card>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
