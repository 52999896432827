import { IPlanService } from './plan-service.interface';
import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { IPlan } from '@mobile-data-access-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';

@Injectable()
export class PlanService implements IPlanService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getTodayAsync(): Observable<IPlan[]> {
    // Get user timezone.
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const queryStringParams: Record<string, string> = {};
        queryStringParams['timeZone'] = timezone;
        const queryString = Object.keys(queryStringParams)
          .map((key) => `${key}=${encodeURIComponent(queryStringParams[key])}`)
          .join('&');
        const fullUrl = [`${baseUrl}/home/today/plan`, queryString].join('?');
        return this._httpClient
          .get<IApiResult<IPlan[]>>(fullUrl)
          .pipe(map(({ data }) => data));
      })
    );
  }

  //#endregion
}
