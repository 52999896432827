import { createAction, props } from "@ngrx/store";
import { IPaxmanInfo } from "@mobile-data-access-interfaces";

const checkAvailableAsync = createAction('[PAXMAN] CHECK AVAILABLE');

const saveAvailable = createAction('[PAXMAN] SAVE AVAILABLE', props<{data: boolean}>());

const saveActivation = createAction('[PAXMAN] SAVE ACTIVATION', props<{data: boolean}>());

const saveInfo = createAction('[PAXMAN] SAVE INFO', props<{data: IPaxmanInfo}>());

export const PaxmanActions = {
  checkAvailableAsync,
  saveInfo,
  saveAvailable,
  saveActivation,
};
