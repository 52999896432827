import {IUiService} from './ui-service.interface';
import {Inject, Injectable} from '@angular/core';
import {ToastController, ToastOptions} from '@ionic/angular';
import {forkJoin, from, map, mergeMap, Observable, of, take} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {ToastRequest} from '@mobile-data-access-models';
import {TranslocoService} from '@ngneat/transloco';
import {Any} from '@shared-types';
import {IThemeOption, IUiToastOptions} from '@mobile-data-access-interfaces';
import {DOCUMENT} from "@angular/common";

@Injectable()
export class UiService implements IUiService {

    //#region Properties

    private readonly __toastRequests: ToastRequest[];

    //#endregion

    //#region Constructor

    public constructor(protected readonly _toastController: ToastController,
                       protected readonly _translateService: TranslocoService,
                       @Inject(DOCUMENT)
                       private readonly _document: Document) {
        this.__toastRequests = [];
    }

    //#endregion

    //#region Methods

    public showToastAsync(options: IUiToastOptions, scope?: string, translationInstance?: Any): Observable<string> {
        const toastId = options.id || uuid();
        const requestId = uuid();
        const type = options.type || uuid();

        if (options.purge) {
            let index = 0;
            while (index < this.__toastRequests.length) {
                const toastRequest = this.__toastRequests[index];
                if (toastRequest.type !== type) {
                    index++;
                    continue;
                }
                void toastRequest.instance.dismiss();
                this.__toastRequests.splice(index, 1);
            }
        }

        return from(this._toastController.create({
            ...options,
            id: toastId
        }))
            .pipe(
                mergeMap((instance: HTMLIonToastElement) => {

                    const getTranslationObservable = this._translateService.selectTranslate(options.message as string, translationInstance, {scope: scope || ''})
                        .pipe(
                            take(1)
                        );

                    instance.addEventListener('ionToastDidDismiss', () => {
                        const index = this._getToastRequestIndexByRequestId(requestId);
                        if (index > -1) {
                            this.__toastRequests.splice(index, 1);
                        }
                    });

                    return forkJoin([of(instance), getTranslationObservable])
                }),
                mergeMap(([instance, message]) => {
                    const toastRequest = new ToastRequest(requestId, type, instance);
                    instance.message = message;
                    this.__toastRequests.push(toastRequest);
                    return from(instance.present());
                }),
                map(() => requestId)
            );
    }

    public dismissToast(requestId: string): void {
        const index = this._getToastRequestIndexByRequestId(requestId);

        const toastRequest = this.__toastRequests[index];
        if (!toastRequest) {
            return;
        }
        void toastRequest.instance?.dismiss();
        this.__toastRequests.splice(index, 1);

    }

    public cleanUpTheme(option: IThemeOption): void {

        if (!option.code || !option.code.length) {
            return;
        }
        const element = this._document.querySelector(`link[id='${option.code}'][rel='stylesheet'][is-theme='true']`);
        if (!element) {
            return;
        }

        this._document.removeChild(element);
    }

    public setUpTheme(option: IThemeOption): void {
        if (!option.code || !option.code.length) {
            return;
        }

        this.cleanUpTheme(option);

        const element = this._document.createElement('link');
        element.rel = 'stylesheet';
        element.type = 'text/css';
        element.href = `${option.url || ''}/${option.code}.css`;
        this._document.head.appendChild(element);
    }

    //#endregion


    //#region Internal methods

    protected _getToastRequestIndexByRequestId(requestId: string): number {
        const index = this.__toastRequests.findIndex(x => x.requestId === requestId);
        if (index < 0) {
            return -1;
        }

        const toastRequest = this.__toastRequests[index];
        if (!toastRequest) {
            return - 1;
        }

        return index;
    }

    //#endreigon


}
