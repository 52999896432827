export enum OnboardingSteps {
  LANDING_PAGE = 'landing-page',
}

export enum OnboardingBeginSteps {
  ENTER_PIN_CODE = 'enter-pin-code',
  TERMS_AND_CONDITIONS = 'terms-and-condition',
  CHOOSE_YOUR_DISEASE = 'choose-your-disease',
  CHOOSE_YOUR_DIAGNOSIS = 'choose-your-diagnosis',
  CHOOSE_YOUR_TREATMENT = 'choose-your-treatment',
}

export enum OnboardingSurgerySteps {
  CHOOSE_YOUR_PROCEDURE = 'choose-your-procedure',
  CHOOSE_YOUR_PROCEDURE_DATE = 'choose-your-procedure-date',
}

export enum OnboardingChemotherapySteps {
  CHOOSE_YOUR_CHEMOTHERAPY = 'chose-your-chemotherapy',
  ENTER_PIL_CODE = 'enter-pil-code',
  SCAN_QR_CODE = 'scan-qr-code',
  CHOOSE_YOUR_CHEMOTHERAPY_DATE = 'choose-your-chemotherapy-date',
}

export enum OnboardingRadiationSteps {
  CHOOSE_YOUR_RADIATION = 'choose-your-radiation',
  CHOOSE_YOUR_RADIATION_DATE = 'choose-your-radiation-date',
}
