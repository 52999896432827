import { Inject, Injectable } from '@angular/core';
import { IApiEndpointResolver } from './api-endpoint-resolver.interface';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';
import { map, Observable } from 'rxjs';
import { AppSettings } from '@mobile-data-access-models';

@Injectable()
export class ApiEndpointResolver implements IApiEndpointResolver {
  //#region Constructor

  public constructor(
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService
  ) {}

  //#endregion

  //#region Methods

  public loadEndPointAsync(
    moduleName: string,
    functionName?: string
  ): Observable<string> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings: AppSettings) => {
        return appSettings.apiUrl || '';
      })
    );
  }

  //#endregion
}
