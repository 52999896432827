import { NgModule } from '@angular/core';
import { BasicInfoComponent } from './basic-info.component';
import { ReactiveComponentModule } from '@ngrx/component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ImgFallbackModule } from 'ngx-img-fallback';
import {
  AspectRatioDirectiveModule,
  HiddenImageOnErrorModule,
} from '@shared-directives';
import { SkeletonModule, ToolbarModule } from '../../../shared';
import { BasicInfoContentDirective } from "./basic-info-content.directive";
import { IonicModule } from "@ionic/angular";

const MODULES = [
  ReactiveComponentModule,
  CommonModule,
  IconModule,
  HiddenImageOnErrorModule,
  ImgFallbackModule,
  SkeletonModule,
  AspectRatioDirectiveModule,
];
@NgModule({
  declarations: [BasicInfoComponent, BasicInfoContentDirective],
  exports: [BasicInfoComponent],
  imports: [MODULES, ToolbarModule, IonicModule]
})
export class BasicInfoModule {}
