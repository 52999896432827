import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IVersionUpdatesVM } from '@mobile-data-access-view-models';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'ncis-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'VERSION_PAGE',
        alias: 'VERSION_PAGE',
      },
    }
  ]
})
export class VersionComponent {
  @Output()
  public readonly continueEvent = new EventEmitter();

  @Input()
  public versionUpdates!: IVersionUpdatesVM;

  public get showBottom(): boolean {
    const length = this.versionUpdates?.features?.length;

    if (!length) {
      return false;
    }

    return length > 1;
  }
}
