import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
} from '@angular/core';
import {ResizeSensor} from 'css-element-queries';
import {
    ITreatmentCycleDay,
    ITreatmentCycleDayPart,
    ITreatmentCycleOptions,
    ITreatmentCycleProgressTrailOptions, ITreatmentCycleShadowOptions,
    ITreatmentCycleTime,
} from '@mobile-data-access-interfaces';
import {catchError, debounceTime, of, Subject, Subscription} from 'rxjs';
import Konva from 'konva';
import Layer = Konva.Layer;

@Component({
    selector: 'ncis-treatment-cycle',
    templateUrl: 'treatment-cycle.component.html',
    styleUrls: ['treatment-cycle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentCycleComponent implements AfterViewInit, OnDestroy {
    //#region Properties

    // What degree the drawing will be rotated.
    private readonly __rotation = -90;

    private readonly __defaultShadowOptions: ITreatmentCycleShadowOptions;

    // Background layer.
    private readonly __backgroundLayer: Layer;

    // Layer to draw the progress circle.
    private readonly __progressCircleLayer: Layer;

    // Days layer.
    private readonly __daysLayer: Layer;

    private readonly __draw$: Subject<void>;

    // Line width in pixel
    private __options?: ITreatmentCycleOptions;

    // Size of canvas.
    private __canvasSize = 0;

    // Heading text.
    private __heading = '';

    // Main title
    private __mainTitle = '';

    // Sub-title.
    private __subTitle = '';

    private __currentTreatmentDay: number;

    // Instance for sensing the resize.
    private __resizeSensor?: ResizeSensor;

    // Stage to draw cycle.
    private __stage?: Konva.Stage;

    protected _subscription: Subscription;

    //#endregion

    //#region Accessors

    public get shadowOptions(): ITreatmentCycleShadowOptions {
        return this.__options?.shadowOptions || this.__defaultShadowOptions;
    }

    @Input()
    public set currentTreatmentDay(value: number) {
        this.__currentTreatmentDay = value;
    }

    @Input()
    public set options(options: ITreatmentCycleOptions | undefined) {
        this.__options = options;
        this.__draw$.next();
    }

    public get options(): ITreatmentCycleOptions | undefined {
        return this.__options;
    }

    public get canvasSize(): number {
        return this.__canvasSize;
    }

    public get mainTitle(): string {
        return this.__mainTitle;
    }

    @Input()
    public set mainTitle(value: string) {
        this.__mainTitle = value;
    }

    public get subTitle(): string {
        return this.__subTitle;
    }

    @Input()
    public set subTitle(value: string) {
        this.__subTitle = value;
    }

    public get heading(): string {
        return this.__heading;
    }

    @Input()
    public set heading(value: string) {
        this.__heading = value;
    }

    //#endregion

    //#region Constructor

    public constructor(
        protected readonly _elementRef: ElementRef,
        protected readonly _changeDetectorRef: ChangeDetectorRef,
        protected readonly _ngZone: NgZone
    ) {
        this.__defaultShadowOptions = {
            lineWidth: 4,
            opacity: 0.5
        }

        this.__draw$ = new Subject<void>();

        this.__backgroundLayer = new Konva.Layer();
        this.__daysLayer = new Konva.Layer();
        this.__progressCircleLayer = new Konva.Layer();

        this.__currentTreatmentDay = 1;
        this._subscription = new Subscription();
    }

    //#endregion

    //#region Life cycle hooks

    public ngAfterViewInit(): void {
        const drawSubscription = this.__draw$
            .pipe(
                debounceTime(500),
                catchError(() => of(void 0))
            )
            .subscribe(() => {
                this._ngZone.runOutsideAngular(() => {
                    const htmlCircleContainer = (
                        this._elementRef.nativeElement as HTMLElement
                    ).querySelector('.circle-container') as HTMLDivElement;
                    if (!htmlCircleContainer) {
                        return;
                    }

                    this.__stage?.clearCache();
                    this.__stage?.destroyChildren();

                    if (!this.__options) {
                        return;
                    }

                    this.__stage = new Konva.Stage({
                        container: htmlCircleContainer,
                        height: this.__canvasSize,
                        width: this.__canvasSize
                    });

                    // Layer to draw controls onto.
                    this.__stage.add(this.__backgroundLayer);
                    this.__stage.add(this.__daysLayer);
                    this.__stage.add(this.__progressCircleLayer);

                    const x = this.__canvasSize / 2;
                    const y = this.__canvasSize / 2;
                    const radius = this.__canvasSize / 2;

                    const controlPadding = this.__options.padding || 0;
                    const controlRadius = radius - controlPadding;
                    const lineWidth = this.__options.lineWidth;

                    // Draw background.
                    this._drawCircleBackground(x, y, radius);

                    // Draw the days.
                    this._drawCycle(x, y, controlRadius);

                    // Draw the progress circle.
                    const progressTrailOptions = this.__options.progressTrail || {
                        padding: 4,
                        backgroundOpacity: 0.8,
                        backgroundColor: '#F8F8F8CC'
                    };

                    if (this.__currentTreatmentDay > 0) {
                        this._drawProgressTrail(x, y, controlRadius, controlRadius - lineWidth, progressTrailOptions,
                            this.__options.cycle, this.__currentTreatmentDay);
                    }
                });
            });
        this._subscription.add(drawSubscription);

        this.__resizeSensor = new ResizeSensor(
            this._elementRef.nativeElement,
            (size) => {
                if (this.__options?.dimension === 'height') {
                    this.__canvasSize = size.height;
                } else if (this.__options?.dimension === 'width') {
                    this.__canvasSize = size.width;
                } else {
                    this.__canvasSize = Math.min(size.height, size.width);
                }

                this.__draw$.next();
                this._changeDetectorRef.markForCheck();
            }
        );
    }

    public ngOnDestroy(): void {
        this.__resizeSensor?.detach();
    }

    //#endregion

    //#region Methods

    //#endregion

    //#region Internal methods

    protected _drawCircleBackground(x: number, y: number,
                                    radius: number): void {

        if (!this.__backgroundLayer) {
            return;
        }

        if (!this.__options){
            return;
        }

        this.__backgroundLayer.clear();

        const backgroundRadius = radius - this.shadowOptions.lineWidth;
        const padding = this.__options.padding || 0;

        // Background circle
        const backgroundCircle = new Konva.Circle({
            x, y,
            fill: this.__options.backgroundColor,
            shadowBlur: this.shadowOptions.lineWidth,
            radius: backgroundRadius,
            shadowOpacity: this.shadowOptions.opacity
        })
        this.__backgroundLayer.add(backgroundCircle);

        // Background ring.
        const backgroundRing = new Konva.Ring({
            x,
            y,
            outerRadius: radius - padding,
            innerRadius: radius - this.__options.lineWidth - padding,
            fill: this.__options.color,
            strokeWidth: 0
        });
        this.__backgroundLayer.add(backgroundRing);
    }

    protected _getTreatmentDays(
        lines: ITreatmentCycleTime[]
    ): ITreatmentCycleDay[] {
        const treatmentDays: ITreatmentCycleDay[] = [];
        for (const line of lines) {
            for (let dayIndex = 0; dayIndex < (line.days || []).length; dayIndex++) {
                const day = parseInt(line.days[dayIndex]);
                if (day == null || isNaN(day)) {
                    continue;
                }

                let treatmentDay = treatmentDays.find((x) => x.day === day);
                if (!treatmentDay) {
                    treatmentDay = {
                        day: day,
                        parts: [],
                    };
                    treatmentDays.push(treatmentDay);
                }

                const treatmentDayPart: ITreatmentCycleDayPart = {
                    color: line.color,
                    layer: treatmentDay.parts.length + 1,
                };
                treatmentDay.parts.push(treatmentDayPart);
            }
        }

        const sortedTreatmentDays = treatmentDays.sort(
            (previous, next) => previous.day - next.day
        );

        return sortedTreatmentDays;
    }

    // Calculate angle base on a value and the total number.
    protected _getCircleAngleFromTotal(value: number, total: number): number {
        return (360.0 * value) / total;
    }

    protected _drawProgressTrail(x: number, y: number,
                                 hostOuterRadius: number,
                                 hostInnerRadius: number,
                                 options: ITreatmentCycleProgressTrailOptions,
                                 daysInCycle: number,
                                 currentCycleDay: number): void {

        if (!this.__progressCircleLayer) {
            return;
        }

        // Clear the layer.
        this.__progressCircleLayer.clear();

        if (daysInCycle < 1) {
            return;
        }


        const innerRadius = hostInnerRadius + options.padding;
        const outerRadius = hostOuterRadius - options.padding;

        const angleInDegree = this._getCircleAngleFromTotal(currentCycleDay, daysInCycle);
        const baseCircleRadian = this.__degreesToRadians(angleInDegree + this.__rotation);

        const arc = new Konva.Arc({
            x,
            y,
            innerRadius,
            outerRadius,
            fill: options.backgroundColor,
            opacity: options.backgroundOpacity,
            angle: angleInDegree,
            rotation: this.__rotation,
            strokeWidth: 0
        });
        this.__progressCircleLayer.add(arc);

        const duration = 5000;
        const width = outerRadius - innerRadius + 2;

        const circleRadius = (innerRadius + outerRadius) / 2;
        const ox = x + (circleRadius * Math.cos(baseCircleRadian));
        const oy = y + (circleRadius * Math.sin(baseCircleRadian));

        const bigCircle = new Konva.Circle({
            x: ox,
            y: oy,
            fill: '#FFFFFF',
            visible: false,
            width: width
        });

        const smallCircleRadius = width - (6 * 2);
        const smallCircle = new Konva.Circle({
            x: ox,
            y: oy,
            fill: '#3771F5',
            visible: false,
            width: smallCircleRadius
        });

        this.__progressCircleLayer.add(bigCircle);
        this.__progressCircleLayer.add(smallCircle);

        // Start the animation
        const trailMoveAnimation = new Konva.Animation( (frame) => {
            if (!frame) {
                return;
            }

            let percentage = frame.time / duration;
            if (percentage > 1) {
                smallCircle.visible(true);
                bigCircle.visible(true);
                return;
            }

            const actualTrailAngle = angleInDegree * percentage;
            arc.angle(actualTrailAngle);
        }, this.__progressCircleLayer);
        trailMoveAnimation.start();
    }

    protected _drawCycle(x: number, y: number, radius: number): void {

        if (!this.__options) {
            return;
        }

        const lines = this.__options.lines;
        const daysInCycle = this.__options.cycle;
        const lineWidth = this.__options.lineWidth;

        if (!daysInCycle || daysInCycle < 1) {
            return;
        }

        // Clear days layer.
        this.__daysLayer.clear();

        // Basically, one cycle is 360 degree.
        // Base angle is the angle that a day in circle takes palace.
        const baseAngle = 360 / daysInCycle;

        // Calculate the treatment days.
        const treatmentDays = this._getTreatmentDays(lines);

        // Normally, the arc will start from 0 degree
        // Base on the design, it must start at -90 degree.
        for (let day = 1; day <= daysInCycle; day++) {
            const treatmentDay = treatmentDays.find((x) => x.day === day);
            if (!treatmentDay) {
                continue;
            }


            for (const [index, part] of treatmentDay.parts.entries()) {
                const baseArcLineWidth = lineWidth / treatmentDay.parts.length;
                let outerRadius = radius - baseArcLineWidth * index;
                let innerRadius = radius - baseArcLineWidth * (index + 1);

                // There are some small gaps between 2 arc.
                // Extend 1 pixel to fill the gap to make it smoother.
                // Disable this can make the circle have some lines that looks like spider web.
                if (index !== 0) {
                    outerRadius += 1;
                }

                const rotation = this.__rotation + baseAngle * (day - 1);
                let angle = baseAngle;

                if (day !== treatmentDays.length && rotation + angle < 360) {
                    angle += 1;
                }

                const arc = new Konva.Arc({
                    x,
                    y,
                    outerRadius,
                    innerRadius,
                    fill: part.color,
                    angle,
                    rotation: rotation,
                    strokeWidth: 0
                });
                this.__daysLayer.add(arc);
            }
        }
    }

    private __degreesToRadians(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    //#endregion
}
