import { NgModule } from "@angular/core";
import { TERMS_AND_CONDITIONS_SERVICE } from "./terms-and-conditions-service.token";
import { TermsAndConditionsService } from "./terms-and-conditions.service";

@NgModule({
  providers: [
    {
      provide: TERMS_AND_CONDITIONS_SERVICE,
      useClass: TermsAndConditionsService,
    },
  ],
})
export class TermsAndConditionsServiceModule {}
