import { NgModule } from '@angular/core';
import { SymptomVerticalComponent } from './symptom-vertical.component';
import { CommonModule } from '@angular/common';
import { SymptomCardModule } from '../../../cards';

@NgModule({
  imports: [CommonModule, SymptomCardModule],
  declarations: [SymptomVerticalComponent],
  exports: [SymptomVerticalComponent],
})
export class SymptomVerticalModule {}
