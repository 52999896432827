import { NgModule } from "@angular/core";
import { ONBOARDING_SERVICE } from "./onboarding-service.token";
import { OnboardingService } from "./onboarding.service";

@NgModule({
  providers: [
    {
      provide: ONBOARDING_SERVICE,
      useClass: OnboardingService,
    },
  ],
})
export class OnboardingServiceModule {}
