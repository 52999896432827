import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import { PlaceholderImages } from '@mobile-data-access-enums';
import { IBasicInfo } from '@mobile-data-access-interfaces';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'ncis-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicInfoComponent {
  //#region Properties

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;
  public readonly PlaceholderImages = PlaceholderImages;

  @Input()
  public data: IBasicInfo | undefined;

  @Input()
  public loading = false;

  @Input()
  public tag = 'by';

  //#endregion

  //#region Constructor

  constructor(protected readonly _navigationController: NavController) {}

  //#endregion

  //#region Methods

  public back(): void {
    this._navigationController.back();
  }

  //#endregion
}
