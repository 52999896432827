import { NgModule } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ENVIRONMENT_SERVICE } from './environment-service.token';

@NgModule({
  providers: [
    {
      provide: ENVIRONMENT_SERVICE,
      useClass: EnvironmentService,
    },
  ],
})
export class EnvironmentModule {}
