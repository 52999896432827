import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import {
  IMeetRequirementService,
  ISmartNavigatorService,
  REQUIREMENT_SENTINEL_SERVICE,
  SMART_NAVIGATOR_SERVICE,
} from '@ui-tool/core';
import { RequirementNames, ScreenCodes } from '@mobile-data-access-enums';

@Injectable()
export class MedicationGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(REQUIREMENT_SENTINEL_SERVICE)
    protected readonly _requirementSentinelService: IMeetRequirementService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _smartNavigatorService: ISmartNavigatorService
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._requirementSentinelService
      .shouldRequirementMetAsync(RequirementNames.ABLE_TO_USE_MEDICATION)
      .pipe(
        mergeMap((ableToView) => {
          if (!ableToView) {
            const urlTree = this._smartNavigatorService.buildUrlTree(
              ScreenCodes.DASHBOARD
            );
            return of(urlTree);
          }

          return of(true);
        })
      );
  }

  //#endregion
}
