import { NgModule } from '@angular/core';
import { DRUG_DETAIL_SERVICE } from './drug-detail-service.token';
import { DrugDetailService } from './drug-detail.service';
import { ContainerServiceModule } from '../container';

@NgModule({
  providers: [
    {
      provide: DRUG_DETAIL_SERVICE,
      useClass: DrugDetailService,
    },
  ],
  imports: [ContainerServiceModule],
})
export class DrugDetailServiceModule {}
