import { NgModule } from '@angular/core';
import { ProcedureService } from './procedure.service';
import { PROCEDURE_SERVICE } from './procedure-service.token';

@NgModule({
  providers: [
    {
      provide: PROCEDURE_SERVICE,
      useClass: ProcedureService,
    },
  ],
})
export class ProcedureServiceModule {}
