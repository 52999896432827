import { IRegimenService } from './regimen-service.interface';
import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import {
  IArticle,
  IIntakeLog,
  IIntakeSchedule,
  IIntakeTime,
  ITreatmentCycleCalculationResult,
} from '@mobile-data-access-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';
import { startOfDay } from 'date-fns';
import { parseCronExpression } from 'cron-schedule';
import {
  ArticleCategories,
  ChemotherapyArticleCodes,
  ChemotherapyArticleTypes,
  ChemotherapyControlCodes,
  DaySessions,
  ProcedureArticleCodes,
} from '@mobile-data-access-enums';

@Injectable()
export class RegimenService implements IRegimenService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getIntakeScheduleAsync(
    startTime: Date,
    endTime: Date
  ): Observable<Record<string, IIntakeTime[]>> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const httpParams = new HttpParams()
          .append('fromDate', startTime.toISOString())
          .append('toDate', endTime.toISOString());

        const fullUrl = `${baseUrl}/regimens/intake-schedules`;
        return this._httpClient.get<IApiResult<IIntakeSchedule[]>>(fullUrl, {
          params: httpParams,
        });
      }),
      map((loadIntakeScheduleResult) => loadIntakeScheduleResult.data),
      map((intakeSchedules) => {
        const dateToIntakeScheduleItems: Record<string, IIntakeTime[]> = {};

        for (const intakeSchedule of intakeSchedules) {
          const expression = parseCronExpression(intakeSchedule.recurrence);
          const iterator = expression.getNextDatesIterator(
            new Date(intakeSchedule.fromDate),
            new Date(intakeSchedule.toDate)
          );

          while (true) {
            const next = iterator.next();
            if (!next || next.done || !next) {
              break;
            }

            const startOfTheDay = startOfDay(next.value).toISOString();
            let scheduleItems = dateToIntakeScheduleItems[startOfTheDay];
            if (!scheduleItems) {
              scheduleItems = [];
              dateToIntakeScheduleItems[startOfTheDay] = scheduleItems;
            }

            scheduleItems.push({
              time: next.value,
              item: intakeSchedule,
            });
          }
        }

        return dateToIntakeScheduleItems;
      })
    );
  }

  public getIntakeLogsAsync(
    startTime: Date,
    endTime: Date
  ): Observable<IIntakeLog[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const httpParams = new HttpParams()
          .append('fromDate', startTime.toISOString())
          .append('toDate', endTime.toISOString());

        const fullUrl = `${baseUrl}/regimens/intake-schedules`;
        return this._httpClient.get<IApiResult<IIntakeLog[]>>(fullUrl, {
          params: httpParams,
        });
      }),
      map((apiResult) => apiResult.data)
    );
  }

  public toDaySession(date: Date): string {
    const hour = date.getHours();
    if (0 < hour && hour <= 12) {
      return DaySessions.MORNING;
    }

    if (12 < hour && hour <= 18) {
      return DaySessions.AFTERNOON;
    }

    return DaySessions.EVENING;
  }

  public getArticleOralAsync(id: string): Observable<IArticle> {
    return this._getDataAsync<IArticle>(ChemotherapyArticleCodes.ORAL, id).pipe(
      map((article) => {
        article.category = ArticleCategories.CHEMOTHERAPY_ARTICLE_ORAL_MED;
        return article;
      })
    );
  }

  public getArticleDoctorAsync(id: string): Observable<IArticle> {
    return this._getDataAsync<IArticle>(
      ChemotherapyArticleCodes.DOCTOR,
      id
    ).pipe(
      map((article) => {
        article.category = ArticleCategories.CHEMOTHERAPY_ARTICLE_DOCTOR;
        return article;
      })
    );
  }

  public getChemoRelatedTopicDetailAsync(
    id: string,
    source:
      | ArticleCategories.CHEMOTHERAPY_RELATED_TOPIC
      | ArticleCategories.CHEMOTHERAPY_ARTICLE_RELATED_TOPIC
      | ArticleCategories.CHEMOTHERAPY_RELATED_TOPIC_RELATED_TOPIC
      | ArticleCategories.CHEMOTHERAPY_DIAGNOSIS
  ): Observable<IArticle> {
    return this._getDataAsync<IArticle>(
      ChemotherapyArticleCodes.RELATED_TOPICS,
      id,
      { source }
    ).pipe(
      map((article) => {
        // article.category = ArticleCategories.CHEMOTHERAPY_RELATED_TOPIC;
        return article;
      })
    );
  }

  public getArticleDetailAsync(id: string): Observable<IArticle> {
    return this._getDataAsync<IArticle>(ProcedureArticleCodes.ARTICLE, id);
  }

  public getArticlesByChemotherapyIdAsync(
    chemotherapyId: string
  ): Observable<IArticle[]> {
    return this._getDataAsync<IArticle[]>(
      ChemotherapyControlCodes.ARTICLES,
      chemotherapyId
    ).pipe(
      map((articles) => {
        for (const article of articles) {
          if (article.type === ChemotherapyArticleTypes.ORAL) {
            article.category = ArticleCategories.CHEMOTHERAPY_ARTICLE_ORAL_MED;
            continue;
          }

          if (article.type === ChemotherapyArticleTypes.DOCTOR) {
            article.category = ArticleCategories.CHEMOTHERAPY_ARTICLE_DOCTOR;
            continue;
          }
        }

        return articles;
      })
    );
  }

  public getCycleCalculationAsync({
    deviceTime,
    timezone,
  }: {
    deviceTime: string;
    timezone: string;
  }): Observable<ITreatmentCycleCalculationResult> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/chemotherapy/cycle-calculation`;
        return this._httpClient.post<
          IApiResult<ITreatmentCycleCalculationResult>
        >(apiUrl, {
          deviceTime,
          timezone,
        });
      }),
      map((apiResult) => apiResult.data)
    );
  }

  //#endregion

  //#region Chemo

  public getChemoAsync<T>(
    id: string,
    code: ChemotherapyControlCodes
  ): Observable<T> {
    return this._getDataAsync<T>(code, id);
  }

  //#endregion Chemo

  //#region Internal methods

  protected _getDataAsync<T>(
    containerCode: string,
    id: string,
    customParams?: Record<string, string>
  ): Observable<T> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/regimen/detail/${containerCode}`;
        let httpParams: Record<string, string> = {};
        httpParams['id'] = id;

        if (customParams) {
          httpParams = { ...httpParams, ...customParams };
        }

        return this._httpClient
          .get<IApiResult<T>>(apiUrl, { params: httpParams })
          .pipe(
            map((apiUrl) => {
              return apiUrl?.data;
            })
          );
      })
    );
  }

  //#endregion
}
