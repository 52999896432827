import {NgModule} from '@angular/core';
import {SYSTEM_SERVICE} from './system-service.token';
import {SystemService} from './system.service';

@NgModule({
    providers: [
        {
            provide: SYSTEM_SERVICE,
            useClass: SystemService
        }
    ]
})
export class SystemServiceModule {
}
