import '@ionic/core';

export class ToastRequest {

    //#region Constructor

    public constructor(
        public readonly requestId: string,
        public readonly type: string,
        public readonly instance: HTMLIonToastElement) {
    }

    //#endregion

}
