import { createReducer, on } from '@ngrx/store';
import { FeatureFlagAction } from './feature-flag.action';
import { IFeatureFlagState } from './feature-flag.state';

export const featureFlagStoreKey = 'featureFlagStoreKey';

export const featureFlagReducer = createReducer<IFeatureFlagState>(
  {},
  on(FeatureFlagAction.saveFeatureFlags, (state, { featureFlags }) => ({
    ...state,
    featureFlags,
  }))
);
