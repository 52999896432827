import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom, from, map, Observable, tap } from "rxjs";
import { APPOINTMENT_SERVICE, IAppointmentService } from "@mobile-data-access-services";
import { AppointmentContainerCodes } from "@mobile-data-access-enums";
import { TranslocoService } from "@ngneat/transloco";
import { ToastController } from "@ionic/angular";
import { IBasicInfo } from "@mobile-data-access-interfaces";
import { ChemotherapyAppointmentDetailComponentStore } from "@mobile-data-access-stores";

@Injectable()
export class AppointmentDetailGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(APPOINTMENT_SERVICE)
    protected readonly _appointmentService: IAppointmentService,
    protected readonly _toastController: ToastController,
    protected readonly _translateService: TranslocoService,
    protected readonly _componentStore: ChemotherapyAppointmentDetailComponentStore
  ) { }

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const id = activatedRouteSnapshot.queryParams['id'];

    if (!id || !id.length) {
      return false;
    }

    const asyncHandler = async () => {
      try {

        return firstValueFrom(
          this._appointmentService.getChemoAsync<IBasicInfo>(id, AppointmentContainerCodes.BASIC_INFO)
            .pipe(
              tap((data) => {
                if (!!data) {
                  this._componentStore.updateProperty('basicInfo', data);
                  this._componentStore.updateProperty('basicInfoLoading', false);
                }
              }),
              map((data) => !!data)
            )
        );
      } catch (exception) {
        const toast = await this._toastController.create({
          position: 'top',
          color: 'danger',
        });
        toast.message = this._translateService.translate(
          'SYSTEM_MESSAGE.CONTENT_NOT_FOUND'
        );
        await toast.present();
        return Promise.resolve(false);
      } finally {
      }
    };

    return from(
      asyncHandler()
    );
  }
}
