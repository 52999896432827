import { createAction, props } from '@ngrx/store';

const granted = createAction('[Authentication] GRANTED');

const expired = createAction('[Authentication] EXPIRED');

export const AuthenticationActions = {
  granted,
  expired,
};
