import { IDateService } from './date-service.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class DateService implements IDateService {
  //#region Methods

  public constructor() {}

  //#endregion

  //#region Methods

  public getInstance(): Date {
    return new Date();
  }

  //#endregion
}
