import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {IArticle} from "@mobile-data-access-interfaces";
import {BehaviorSubject, map, mergeMap, Subject, Subscription, switchMap, take, tap} from "rxjs";
import {ARTICLE_SERVICE, BOOKMARK_SERVICE, IArticleService, IBookmarkService} from '@mobile-data-access-services';
import {ArticleCategories} from '@mobile-data-access-enums';

@Component({
    selector: 'ncis-articles',
    templateUrl: './articles.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesComponent implements OnInit, OnDestroy {

    //#region Properties

    private __articles: IArticle[];

    private __loadViewedStatus$: Subject<void>;

    private __keyToViewed: Record<string, boolean>;

    private __ableToSelectArticleHandler:
        | ((article: IArticle) => boolean)
        | undefined;

    protected _subscription: Subscription;

    @Input()
    public loading = false;

    @Output()
    public readonly selectedArticleEvent = new EventEmitter<IArticle>();

    @Input()
    public set ableToSelectArticleHandler(
        value: ((article: IArticle) => boolean) | undefined
    ) {
        this.__ableToSelectArticleHandler = value;
    }

    @Input()
    public set articles(data: IArticle[] | undefined) {
        this.__articles = data || [];
        this.__loadViewedStatus$.next();
    }

    //#endregion

    //#region Accessors

    public get articles(): IArticle[] {
        return this.__articles;
    }

    public get keyToViewed(): Record<string, boolean> {
        return this.__keyToViewed;
    }

    //#endregion

    //#region Constructor

    public constructor(@Inject(BOOKMARK_SERVICE)
                       protected readonly _bookmarkService: IBookmarkService,
                       @Inject(ARTICLE_SERVICE)
                       protected readonly _articleService: IArticleService,
                       protected readonly _changeDetectorRef: ChangeDetectorRef) {

        this.__articles = [];
        this.__keyToViewed = {};

        this.__loadViewedStatus$ = new Subject<void>();
        this._subscription = new Subscription();
    }

    //#endregion

    //#region Life cycle hooks

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public ngOnInit(): void {

        const loadViewedStatusSubscription = this.__loadViewedStatus$
            .pipe(
                mergeMap(() => {
                    const categoryWithIds: Record<string, string[]> = {};
                    for (const article of this.articles) {
                        const category = article.category as string;
                        if (!category) {
                            continue;
                        }
                        if (!categoryWithIds[category]) {
                            categoryWithIds[category] = [];
                        }
                        categoryWithIds[category].push(article.id);
                    }

                    return this._articleService.getCategoriesWithViewedItemsAsync(categoryWithIds);
                }),
                map(categoriesToIds => {
                    const keyToViewed: Record<string, boolean> = {};
                    for (const category of Object.keys(categoriesToIds)) {
                        for (const id of categoriesToIds[category]) {
                            keyToViewed[this.toKey(category, id)] = true;
                        }
                    }

                    return keyToViewed;
                })
            )
            .subscribe(keyToViewed => {
                this.__keyToViewed = keyToViewed;
                this._changeDetectorRef.markForCheck();
            });
        this._subscription.add(loadViewedStatusSubscription);
    }

    //#endregion

    //#region Methods

    public selectArticle(article: IArticle): void {
        this.selectedArticleEvent.emit(article);
    }

    public ableToSelectArticle(article: IArticle): boolean {
        if (!this.__ableToSelectArticleHandler) {
            return false;
        }

        return this.__ableToSelectArticleHandler(article);
    }

    public toKey(category: string, id: string): string {
        return `${category}__${id}`;
    }

    //#endregion
}
