import { NgModule } from "@angular/core";
import { DateTimeComponent } from "./date-time.component";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { DayComponent, MonthComponent, NavigationComponent, YearComponent } from "./components";
import { IconModule } from "@shared-components";

@NgModule({
  imports: [IonicModule, CommonModule, IconModule],
  declarations: [DateTimeComponent, MonthComponent, YearComponent, DayComponent, NavigationComponent],
  exports: [DateTimeComponent]
})
export class DateTimeModule { }
