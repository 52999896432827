import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { IRootState } from "./root.state";
import { authenticationReducer, authenticationStoreKey } from "./authentication";
import { environment } from "@mobile-resources-environments";
import { storeFreeze } from "ngrx-store-freeze";
import { paxmanReducer, paxmanStoreKey } from "./paxman";
import { bookmarksReducer, bookmarksStoreKey } from "./bookmarks";
import { userReducer, userStoreKey } from "./user";
import { drugDetailReducer, drugDetailStoreKey } from "./drug-detail";
import { featureFlagReducer, featureFlagStoreKey } from "./feature-flag";

export const reducers: ActionReducerMap<IRootState> = {
  [authenticationStoreKey]: authenticationReducer,
  [drugDetailStoreKey]: drugDetailReducer,
  [paxmanStoreKey]: paxmanReducer,
  [bookmarksStoreKey]: bookmarksReducer,
  [userStoreKey]: userReducer,
  [featureFlagStoreKey]: featureFlagReducer,
};

export const metaReducers: MetaReducer<IRootState>[] = !environment.production
    ? [storeFreeze]
    : [];

export const effects = [
];
