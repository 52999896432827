import { NgModule } from '@angular/core';
import { ConnectivityService } from './connectivity.service';
import { CONNECTIVITY_SERVICE } from './connectivity-service.token';

@NgModule({
  providers: [
    {
      provide: CONNECTIVITY_SERVICE,
      useClass: ConnectivityService,
    },
  ],
})
export class ConnectivityServiceModule {}
