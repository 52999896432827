import { createFeatureSelector, createSelector } from '@ngrx/store';
import {DrugDetailControlCodes} from '@mobile-data-access-enums';
import {IDrugDetailState} from './drug-detail.state';
import {drugDetailStoreKey} from './drug-detail.reducer';

const selectDrugDetail = createFeatureSelector<IDrugDetailState>(
  drugDetailStoreKey
);

export class DrugDetailSelectors {

  //#region Methods

  public static readonly selectBasicInfo = createSelector(
      selectDrugDetail,
    (state) => state[DrugDetailControlCodes.HEADER_DRUG]
  );

  public static readonly selectOverview = createSelector(
      selectDrugDetail,
    (state) => state[DrugDetailControlCodes.OVERVIEW]
  );

  public static readonly selectArticles = createSelector(
      selectDrugDetail,
      (state) => state[DrugDetailControlCodes.ARTICLES]
  );

  public static readonly selectSideEffect = createSelector(
    selectDrugDetail,
    (state) => state[DrugDetailControlCodes.SIDE_EFFECT]
  );

  public static readonly selectRelatedTopics = createSelector(
    selectDrugDetail,
    (state) => state[DrugDetailControlCodes.RELATED_TOPICS]
  );

  public static readonly selectTags = createSelector(
      selectDrugDetail,
    (state) => state[DrugDetailControlCodes.TAGS]
  );

  public static readonly selectControlCodeToLoadingStatus = createSelector(
    selectDrugDetail,
    (state) => state.controlCodeToLoadingStatus
  );

  //#endregion
}
