<div class="datetime-day">
  <div class="datetime-day__week-days">
    <ng-container *ngFor="let weekDay of weekDays">
      <button>
        <span>{{weekDay}}</span>
      </button>
    </ng-container>
  </div>
  <div class="datetime-day__month-weeks">
    <ng-container *ngFor="let week of weeks">
      <div class="datetime-day__week-days">
        <ng-container *ngFor="let day of week">
          <button (click)="select.emit(toDay(day))" [class.active]="day.month === current.month" [class.current]="isCurrent(day)" [class.selected]="isSelected(day)" [disabled]="isDisabled(day)">
            <span>{{toDay(day) | date: 'd'}}</span>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
