import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IArticle } from "@mobile-data-access-interfaces";
import { CommonIconFolderEnum, CommonIconGeneralEnum } from "@shared-enums";

@Component({
  selector: 'ncis-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCardComponent {
  //#region Properties

  private __loading = false;

  //#endregion

  //#region Accessors

  @Output()
  public readonly visited = new EventEmitter();

  @Input()
  public data: IArticle | undefined;

  @Input()
  public bookmarked = false;

  @Input()
  public viewed = false;

  public get loading(): boolean {
    return this.__loading;
  }

  @Input()
  public set loading(value: boolean) {
    this.__loading = value;
  }

  public readonly IconFolders = CommonIconFolderEnum;
  public readonly Icons = CommonIconGeneralEnum;

  //#endregion
}
