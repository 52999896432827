<div class="related-topic-card">
  <div class="related-topic-card__image" [aspectRatio]="1">
    <ng-container *ngIf="!loading">
      <img [alt]="data.title" [src]="data.imageUrl" />

      <!--TODO: Temporary disable this icon, this should be used as a status icon-->
<!--      <ncis-common-icon-->
<!--        [folder]="IconFolders.GENERAL"-->
<!--        [name]="Icons.BOOKMARK_INACTIVE"-->
<!--        class="text-ncis-neutral-100 h-[30px] w-[30px] absolute top-6 right-5"-->
<!--      ></ncis-common-icon>-->
    </ng-container>
  </div>

  <div class="related-topic-card__info">
    <!--Title-->
    <h2 class="line-clamp-1" [ngSwitch]="loading">
      <ng-container *ngSwitchCase="true">
        <ncis-skeleton class="w-full block"></ncis-skeleton>
      </ng-container>
      <ng-container *ngSwitchDefault>{{ data.title || 'N/A' }}</ng-container>
    </h2>

    <!--Module name-->
    <p class="line-clamp-1" [ngSwitch]="loading">
      <ng-container *ngSwitchCase="true">
        <ncis-skeleton class="w-[75%] block"></ncis-skeleton>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'RELATED_TOPIC_COMPONENT.MODULE' | transloco }}: {{ data.moduleName || 'N/A' }}
      </ng-container>
    </p>

    <div class="related-topic-card__info__likes">
      <div class="related-topic-card__info__likes__icon">
        <ncis-common-icon
          class="w-[20px]"
          [folder]="IconFolders.GENERAL"
          [name]="Icons.HEART"
        >
        </ncis-common-icon>
      </div>
      <div
        class="related-topic-card__info__likes__counter"
        [ngSwitch]="loading"
      >
        <ng-container *ngSwitchCase="true">
          <ncis-skeleton class="w-full block"></ncis-skeleton>
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ data.likes || 0 }}
        </ng-container>
      </div>
    </div>
  </div>
</div>
