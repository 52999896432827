import {NavigateToScreenRequest} from '@ui-tool/core';
import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';
import {ArticleDetailQueryParams} from '../../query-params';

export class DrugArticleLtseNavigationRequest extends NavigateToScreenRequest<void> {

    //#region Constructor

    public constructor(source: ScreenCodes, sourceId: string, articleId: string) {
        super(ScreenCodes.DETAILED_ARTICLE, void(0), {
            queryParams: new ArticleDetailQueryParams(source, sourceId, articleId, ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT)
        });
    }

    //#endregion

}
