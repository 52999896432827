import {NavigateToScreenRequest} from '@ui-tool/core';
import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';
import {ArticleDetailQueryParams} from '@mobile-data-access-models';

export class RadiationArticleWhatNextRelatedTopicNavigationRequest extends NavigateToScreenRequest<void> {

    //#region Constructor

    public constructor(source: ScreenCodes, sourceId: string, articleId: string) {
        super(ScreenCodes.DETAILED_ARTICLE, void(0), {
            queryParams: new ArticleDetailQueryParams(source, sourceId, articleId, ArticleCategories.RADIATION_WHAT_NEXT_RELATED_TOPIC)
        });
    }

    //#endregion

}
