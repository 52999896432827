import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IRegimenCard, IRegimenCardDrug } from "@mobile-data-access-interfaces";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";

@Component({
  selector: 'ncis-drug-item',
  templateUrl: './drug-item.component.html',
  styleUrls: ['./drug-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: "CHEMOTHERAPY_DETAIL_PAGE",
        alias: "CHEMOTHERAPY_DETAIL_PAGE"
      }
    },
  ]
})
export class DrugItemComponent {
  //#region Properties
  @Input()
  public data: IRegimenCardDrug | null = null;

  @Input()
  public administration: IRegimenCard['administration'] | null = null;

  @Input()
  public duration: IRegimenCard['duration'] | null = null;

  @Input()
  public isRest = false;

  @Input()
  public loading = false;

  @Input()
  public color?: string;

  //#endregion
}
