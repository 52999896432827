import { NgModule } from '@angular/core';
import { SymptomCardComponent } from './symptom-card.component';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { IconModule } from '@shared-components';
import { UnescapePipeModule } from '@shared-pipes';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';

@NgModule({
  imports: [
    CommonModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    IconModule,
    UnescapePipeModule,
    ToTrustedHtmlPipeModule,
  ],
  declarations: [SymptomCardComponent],
  exports: [SymptomCardComponent],
})
export class SymptomCardModule {}
