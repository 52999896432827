<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data">
    <div class="side-effects">
      <div *ngIf="!hiddenInformation" class="side-effects__information">
        <h2>{{ data.title || 'N/A' }}</h2>
        <p [innerHTML]="data.description || 'N/A'"></p>
      </div>
      <div class="side-effects__main">
        <div class="side-effects__content">
          <div class="side-effects__action">
            <h2>
              {{ data.trackTitle || title || 'N/A' }}
            </h2>
            <div *ngIf="!hiddenViewAll" class="side-effects__view-all">
              <span (click)="viewAllSymptoms()">{{viewAllTitle || 'N/A'}}</span>
              <ncis-common-icon
                class="text-ncis-neutral-600 w-[22px] h-[22px]"
                folder="general"
                name="right-arrow"
              ></ncis-common-icon>
            </div>
          </div>
          <p [innerHTML]="data.trackDescription || 'N/A'"></p>
        </div>
        <!--Symptoms-->
        <ncis-symptom-horizontal
          (selectedSymptomEvent)="goToSymptomDetail($event)"
          [data]="data.symptoms"
        ></ncis-symptom-horizontal>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <div class="side-effects">
    <div *ngIf="!hiddenInformation" class="side-effects__information">
      <ncis-skeleton class="w-[200px] h-[22px]"></ncis-skeleton>
      <ncis-skeleton class="w-full h-[66px]"></ncis-skeleton>
    </div>
    <div class="side-effects__main">
      <div class="side-effects__content">
        <div class="side-effects__action">
          <ncis-skeleton class="w-[150px] h-[22px]"></ncis-skeleton>
        </div>
        <ncis-skeleton class="w-full h-[66px]"></ncis-skeleton>
      </div>
      <ncis-symptom-horizontal
        [loading]="true"
      ></ncis-symptom-horizontal>
    </div>
  </div>
</ng-template>
