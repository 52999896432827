import { NgModule } from '@angular/core';
import { SMART_NAVIGATOR_SCREEN_CODE_RESOLVER } from '@ui-tool/core';
import { ScreenCodeResolver } from './screen-code.resolver';

@NgModule({
  providers: [
    {
      provide: SMART_NAVIGATOR_SCREEN_CODE_RESOLVER,
      useClass: ScreenCodeResolver,
      multi: true,
    },
  ],
})
export class ScreenCodeResolverModule {}
