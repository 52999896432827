import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { SymptomStatisticComponent } from "./symptom-statistic.component";
import { CommonModule } from "@angular/common";
import { ToolbarModule } from "@mobile-ui-components";
import { AspectRatioDirectiveModule } from "@shared-directives";
import { IconModule } from "@shared-components";
import { InlineSVGModule } from "ng-inline-svg-2";

@NgModule({
  declarations: [SymptomStatisticComponent],
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts")
    }),
    ToolbarModule,
    AspectRatioDirectiveModule,
    IconModule,
    InlineSVGModule
  ],
  exports: [SymptomStatisticComponent]
})
export class SymptomStatisticModule {}
