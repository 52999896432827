import { NgModule } from '@angular/core';
import { ARTICLE_SERVICE } from './article-service.token';
import { ArticleService } from './article.service';

@NgModule({
  providers: [
    {
      provide: ARTICLE_SERVICE,
      useClass: ArticleService,
    },
  ],
})
export class ArticleServiceModule {}
