import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import {
  debounceTime,
  filter,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';

@Directive({
  selector: 'div img[aspectRatio], div[aspectRatio]',
})
export class AspectRatioDirective implements OnInit, AfterViewInit, OnDestroy {
  //#region Properties

  private __aspectRatio?: number;

  private __resizeSensor?: ResizeSensor;

  private __resize$ = new Subject<void>();

  private __instance?: HTMLElement;

  private __imageHeight = 0;

  private __imageWidth = 0;

  protected readonly _subscription: Subscription;

  //#endregion

  //#region Accessors

  public get aspectRatio(): number | undefined {
    return this.__aspectRatio;
  }

  @Input()
  public set aspectRatio(value: number | undefined) {
    this.__aspectRatio = value;
    this.__resize$.next();
  }

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _ngZone: NgZone
  ) {
    this.__resize$ = new ReplaySubject(1);
    this._subscription = new Subscription();
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnInit(): void {
    const resizeSubscription = this.__resize$
      .pipe(
        filter(() => {
          return (
            this.__instance !== null &&
            this.__instance !== undefined &&
            this.__aspectRatio !== null &&
            this.__aspectRatio !== undefined
          );
        }),
        debounceTime(250)
      )
      .subscribe(() => {
        this._ngZone.runOutsideAngular(() => {
          // Calculate element height:
          this.__instance!.style.height = `${this.__imageHeight}px`;
          this.__instance!.style.width = `${this.__imageWidth}px`;
        });
      });
    this._subscription.add(resizeSubscription);
  }

  public ngAfterViewInit(): void {
    // Get the parent element
    const htmlElement = this._elementRef.nativeElement as HTMLElement;
    const htmlParentElement = htmlElement.parentElement!;
    this.__instance = htmlElement;

    if (!htmlParentElement) {
      return;
    }

    this.__resizeSensor?.detach();
    this.__resizeSensor = new ResizeSensor(htmlParentElement, ({ width }) => {
      if (this.__aspectRatio == null) {
        return;
      }

      this._calculateInstanceSize(htmlParentElement);
      this.__resize$.next();
    });
    this.__resizeSensor.reset();

    this._calculateInstanceSize(htmlParentElement);
    this.__resize$.next();
  }

  public ngOnDestroy(): void {
    this.__resizeSensor?.detach();
    this._subscription.unsubscribe();
  }

  //#endregion

  //#region Internal methods

  protected _calculateInstanceSize(htmlElement: HTMLElement): void {
    if (
      !htmlElement ||
      this.__aspectRatio === undefined ||
      this.__aspectRatio === null ||
      this.__aspectRatio === 0
    ) {
      return;
    }

    const width = htmlElement.offsetWidth;
    this.__imageWidth = width;
    this.__imageHeight = width / this.__aspectRatio;
  }

  //#endregion
}
