import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from "@angular/core";
import { CommonIconFolderEnum, CommonIconGeneralEnum } from "@shared-enums";
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { OtpOverride } from "@mobile-data-access-models";

@Component({
  selector: "ncis-enter-pil",
  templateUrl: "./enter-pil.component.html",
  styleUrls: ["./enter-pil.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnterPilComponent implements AfterViewInit {
  //#region Properties

  @Output()
  public readonly completedInput = new EventEmitter<string>();

  @Output()
  public readonly valueChange = new EventEmitter<string>();

  @Input()
  public loading = false;

  @Input()
  public title = "";

  @Input()
  public config: NgOtpInputConfig = {
    length: 3,
  };

  @Input()
  public showNumpad = true;

  @Input()
  public haveDot = false;

  @ViewChild(NgOtpInputComponent)
  public readonly otp!: NgOtpInputComponent;

  public value = "";

  public constructor(
    protected readonly _elementRef: ElementRef<HTMLElement>,
    protected readonly _ngZone: NgZone,
    protected readonly _cdr: ChangeDetectorRef
  ) { }

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;

  //#endregion

  //#region Constructor

  public get keys(): string[] {
    return this.haveDot ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', ''] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  }

  //#endregion

  //#region Life cycle

  public ngAfterViewInit(): void {
    this.overrideOtpComponent();
    const wrapper = document.querySelector(".ng-otp-input-wrapper");

    if (!wrapper) return;

    if (this.haveDot) {
      const circle = document.createElement("span");
      circle.className = "w-1 h-1 rounded-full bg-ncis-neutral-800";

      wrapper.insertBefore(circle, wrapper.childNodes[wrapper.childNodes.length - 2]);
    }
  }

  //#endregion

  //#region Methods

  public inputChange(value: string): void {
    if (value.length === this.config.length) {
      this.clearSelection();
      this.setValue(value);
      return;
    }
    this.setValue(value);
  }

  public clearSelection(): void {
    this._ngZone.runOutsideAngular(() => {
      const inputs = this._elementRef.nativeElement.querySelectorAll("input");
      inputs.forEach((input) => {
        input.blur();
      });
    });
  }

  public clickNumpad(value: string): void {
    const currentLength = this.value.length;

    if (currentLength === this.config.length) return;

    this.value += value;

    this.otp.setValue(this.value);
  }

  public setValue(value: string): void {
    this.value = value;
    this.valueChange.emit(this.resolveInput(value));

    if (value.length === this.config.length) {
      this.completedInput.emit(this.resolveInput(value));
    }
  }

  public reset(): void {
    Object.keys(this.otp.otpForm.controls).forEach((key) => {
      this.otp.otpForm.controls[key].setValue('');
    });
    this.otp.setValue('');
    this.clearSelection();
    this.setValue('');
  }

  public resolveInput(value: string): string {
    if (value.length > 4) {
      return `${value.slice(0, 4)}.${value.charAt(value.length - 1)}`;
    } else {
      return value;
    }
  }

  public overrideOtpComponent(): void {
    const otp = new OtpOverride(this.otp);

    this.otp.validateNumber = otp.validateNumber.bind(otp);
    this.otp.ifValidKeyCode = otp.ifValidKeyCode.bind(otp);
  }

  //#endregion
}
