import { NavigateToScreenRequest } from '@ui-tool/core';
import { ArticleCategories, ScreenCodes } from '@mobile-data-access-enums';
import { Any } from '@shared-types';

export class TrackerNavigationRequest extends NavigateToScreenRequest<Any> {
  //#region Constructor

  public constructor() {
    super(ScreenCodes.TRACKER, {});
  }

  //#endregion
}
