import { NavigateToScreenRequest } from "@ui-tool/core";
import { ScreenCodes } from "@mobile-data-access-enums";

export class PaxmanNavigationRequest extends NavigateToScreenRequest<any> {
  //#region Constructor

  public constructor(code: ScreenCodes.PAXMAN_MEMBER | ScreenCodes.PAXMAN_INTRODUCTION | ScreenCodes.PAXMAN) {
    super(code);
  }

  //#endregion
}
