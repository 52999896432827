<div class="w-full flex flex-col">
  <ng-container *ngFor="let item of items$ | async">
    <div
      (click)="selectItem(item.key)"
      [attr.data-test-id]="item.key"
      [class.no-padding-x]="noPaddingX"
      class="list-item"
    >
      <h6 *ngIf="item.title">{{ item.title }}</h6>
      <main [class.active]="active.includes(item.key) && allowStyling">
        <div class="flex gap-x-3 {{ customClass }}">
          <div
            [inlineSVG]="
              mappingIcon[item.key] ||
              'ncis-assets/icons/general/bullet-point.svg'
            "
            class="flex items-center {{ iconClass }}"
          ></div>
          <p>
            {{ item.label | transloco }}
          </p>
        </div>
      </main>
    </div>
  </ng-container>
</div>
