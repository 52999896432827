<ng-template
  [ngTemplateOutletContext]="{ $implicit: data }"
  [ngTemplateOutlet]="basicInfoTemplate"
>
</ng-template>

<ng-template #basicInfoTemplate let-data>
  <div class="basic-info">
    <ncis-toolbar
      slot="fixed"
      (backClick)="back()"
    ></ncis-toolbar>
    <div [aspectRatio]="3 / 4" class="w-full overflow-hidden bg-ncis-neutral-300">
      <img
        *ngIf="data"
        [alt]="data?.title"
        [aspectRatio]="3 / 4"
        [src-fallback]="PlaceholderImages.BLANK_4_3"
        [src]="data?.imageUrl"
        class="w-full fixed top-0 left-0 z-0 pointer-events-none select-none"
        slot="fixed"
      />
      <img
        *ngIf="data"
        [alt]="data?.title"
        [src-fallback]="PlaceholderImages.BLANK_4_3"
        [src]="data?.imageUrl"
        class="w-full opacity-0"
      />
    </div>
    <div [ngSwitch]="loading" basicInfoContent class="basic-info__content">
      <ng-container *ngSwitchDefault>
        <span>{{ data?.category || 'N/A' }}</span>
        <h2>{{ data?.title || 'N/A' }}</h2>
        <p>
          <b>{{ tag || 'by' }}</b> {{ data?.author || 'N/A' }}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <ncis-skeleton class="w-[50px] h-[18px]"></ncis-skeleton>
        <ncis-skeleton class="w-[40px] h-[22px]"></ncis-skeleton>
        <ncis-skeleton class="w-[30px] h-[18px]"></ncis-skeleton>
      </ng-container>
    </div>
  </div>
</ng-template>
