import { HeaderState } from './header.state';
import { Inject, Injectable } from '@angular/core';
import {
  CONTAINER_SERVICE,
  IContainerService,
} from '@mobile-data-access-services';
import { ContainerComponentStore } from '../../shared';
import { catchError, EMPTY, finalize, pipe, switchMap, tap } from 'rxjs';
import { HomeContainerCodes } from '@mobile-data-access-enums';

@Injectable()
export class HeaderComponentStore extends ContainerComponentStore<
  HeaderState,
  HeaderState['data']
> {
  public override readonly runSkeleton = this.effect<void>(
    pipe(
      tap(() => this.updateLoading(true)),
      switchMap(() =>
        this._containerService
          .loadDataAsync<HeaderState['data']>(HomeContainerCodes.HEADER)
          .pipe(
            tap((data) => this.updateData(data)),
            catchError((err) => {
              console.error(err);
              return EMPTY;
            }),
            finalize(() => this.updateLoading(false))
          )
      )
    )
  );

  public constructor(
    @Inject(CONTAINER_SERVICE)
    protected readonly _containerService: IContainerService
  ) {
    super({
      today: new Date(),
      data: {
        avatar: '/ncis-assets/images/profile-picture.png',
        badgeCount: 5,
      },
      loading: false,
      status: true
    });
  }
}
