import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { ChemotherapyAdministrations, ScreenCodes } from "@mobile-data-access-enums";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { NavController } from "@ionic/angular";
import { DrugDetailNavigationRequest } from "@mobile-data-access-models";
import {IRegimensCard} from '@mobile-data-access-interfaces';

@Component({
  selector: "ncis-regimens",
  templateUrl: "./regimens.component.html",
  styleUrls: ["./regimens.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegimensComponent {
  //#region Properties

  @Input()
  public loading = false;

  @Input()
  public data: IRegimensCard | undefined = undefined;

  public readonly ChemotherapyAdministrations = ChemotherapyAdministrations;

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _navController: NavController
  ) {
  }

  //#endregion

  //#region Methods

  public goToDrugDetail(id: string): void {

    const navigationRequest = new DrugDetailNavigationRequest({ id: id, source: ScreenCodes.CHEMOTHERAPY });
    const urlTree = this._navigationService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams,
      {
        queryParams: navigationRequest.extras?.queryParams as any,
        skipLocationChange: true,
        queryParamsHandling: "merge"
      }
    );

    void this._navController.navigateForward(urlTree);
  }

  //#endregion
  protected readonly undefined = undefined;
}
