import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppSettingsService } from './app-settings-service.interface';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { IAppSettings } from '@mobile-data-access-interfaces';
import { AppSettings } from '@mobile-data-access-models';
import { merge as lodashMerge } from 'lodash-es';
import { environment } from '@mobile-resources-environments';

@Injectable()
export class AppSettingsService implements IAppSettingsService {
  //#region Properties

  protected _storedSettings: IAppSettings;

  //#endregion

  //#region Constructor

  constructor(protected readonly _httpClient: HttpClient) {
    this._storedSettings = new AppSettings();
  }

  //#endregion

  //#region Methods

  public loadSettingsAsync(cleanLoad: boolean): Observable<IAppSettings> {
    if (this._storedSettings && !cleanLoad) {
      return of(this._storedSettings);
    }

    const files = environment.configurationFiles;
    if (!files || !files.length) {
      this._storedSettings = new AppSettings();
      console.warn('No setting file is defined.');
      return of(this._storedSettings);
    }

    const loadSettingObservables: Observable<AppSettings>[] = [];

    for (const file of files) {
      const loadSettingObservable = this._httpClient
        .get<AppSettings>(file)
        .pipe(catchError(() => of(new AppSettings())));

      loadSettingObservables.push(loadSettingObservable);
    }

    if (!loadSettingObservables.length) {
      this._storedSettings = new AppSettings();
      return of(this._storedSettings);
    }

    return forkJoin(loadSettingObservables).pipe(
      map((loadSettingResults) => {
        let appSetting = new AppSettings();

        for (const loadSettingResult of loadSettingResults) {
          appSetting = lodashMerge(appSetting, loadSettingResult);
        }

        this._storedSettings = appSetting;
        return appSetting;
      })
    );
  }

  //#endregion
}
