import { NgModule } from '@angular/core';
import { MedicationDrugCardComponent } from './medication-drug-card.component';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from '../../shared';
import { TranslocoModule } from '@ngneat/transloco';
import { UnescapeHtmlPipeModule } from '@mobile-ui-pipes';

@NgModule({
  declarations: [MedicationDrugCardComponent],
  imports: [
    CommonModule,
    SkeletonModule,
    TranslocoModule,
    UnescapeHtmlPipeModule,
  ],
  exports: [MedicationDrugCardComponent],
})
export class MedicationDrugCardModule {}
