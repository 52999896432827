import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBannerCard } from '@mobile-data-access-interfaces';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'ncis-banner-card',
  templateUrl: './banner-card.component.html',
  styleUrls: ['./banner-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'banner-card',
        alias: 'BANNER_CARD',
      },
    },
  ],
})
export class BannerCardComponent {
  //#region Properties

  @Input()
  public data!: IBannerCard;

  @Input()
  public loading = false;

  @Input()
  public clickable = false;

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;

  //#endregion
}
