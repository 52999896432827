import { ComponentStore } from '@ngrx/component-store';
import { SessionExpireState } from './session-expire.state';
import {finalize, firstValueFrom, from, pipe, switchMap, tap} from 'rxjs';
import { authenticationInitialState } from './session-expire.constants';
import { Inject, Injectable } from '@angular/core';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
  IPlatformService,
  PLATFORM_SERVICE,
} from '@mobile-data-access-services';
import { Store } from '@ngrx/store';
import { IRootState } from '../../../features';
import { ISpinnerService, SPINNER_SERVICE, WINDOW } from '@ui-tool/core';
import {OpenAuthenticationProviders, SpinnerContainerIds} from '@mobile-data-access-enums';
import { CloseWebviewNativeMethod } from '@mobile-data-access-models';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';

@Injectable()
export class SessionExpireComponentStore extends ComponentStore<SessionExpireState> {

  //#region Constructor

  public constructor(
      @Inject(AUTHENTICATION_SERVICE)
      protected readonly _authenticationService: IAuthenticationService,
      @Inject(WINDOW) protected readonly _window: Window,
      @Inject(SPINNER_SERVICE)
      protected readonly _spinnerService: ISpinnerService,
      protected readonly _store: Store<IRootState>,
      @Inject(PLATFORM_SERVICE)
      protected readonly _platformService: IPlatformService,
      @Inject(RPC_SERVICE)
      protected readonly _rpcService: IRpcService
  ) {
    super(authenticationInitialState);
  }

  //#endregion

  //#region Methods

  public readonly closeWebView = this.effect<undefined>(
      pipe(
          switchMap(() => {
            const displaySpinnerRequestId = this._spinnerService.displaySpinner(SpinnerContainerIds.APPLICATION);
            return this._rpcService.sendRequestAsync(new CloseWebviewNativeMethod())
                .pipe(
                    finalize(() => this._spinnerService
                        .deleteSpinner(SpinnerContainerIds.APPLICATION, displaySpinnerRequestId))
                );
          })
      )
  );

  public readonly updatePlatform = this.updater<SessionExpireState['platform']>(
    (state, platform) => ({
      ...state,
      platform,
    })
  );

  public readonly authenticate = this.effect<never>(
    pipe(
      switchMap(() =>
        this._authenticationService.doOpenAuthenticationAsync(
          OpenAuthenticationProviders.AUTH_ZERO
        )
      )
    )
  );
  public readonly checkPlatform = this.effect<never>(
    pipe(
      switchMap(() => this._platformService.getPlatformAsync()),
      tap((platform) => this.updatePlatform(platform))
    )
  );

  //#endregion
}
