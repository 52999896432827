import { NgModule } from '@angular/core';
import { SymptomHorizontalComponent } from './symptom-horizontal.component';
import { SwiperModule } from 'swiper/angular';
import { CommonModule } from '@angular/common';
import { EffectCardModule, SymptomCardModule } from '../../../cards';

@NgModule({
  imports: [SwiperModule, CommonModule, SymptomCardModule, EffectCardModule],
  declarations: [SymptomHorizontalComponent],
  exports: [SymptomHorizontalComponent],
})
export class SymptomHorizontalModule {}
