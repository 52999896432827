<div class="recommendation">
  <div class="recommendation__head">
    <div class="recommendation__head__information">
      <div class="recommendation__head__image">
        <img
          *ngIf="imageUrl"
          [src]="imageUrl"
          class="w-full h-full"
        />
      </div>
      <h3>{{ title || 'N/A' }}</h3>
    </div>
    <ncis-common-icon
      [folder]="IconFolders.GENERAL"
      [name]="Icons.QUOTE"
      class="w-8 h-6 text-ncis-neutral-400"
    >
    </ncis-common-icon>
  </div>
  <p [innerHTML]="(description | unescape | toTrustedHtml) || 'N/A'" class="line-clamp-4"></p>
</div>
