import { NgModule } from '@angular/core';
import { ArticlesComponent } from './articles.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { UnescapePipeModule } from '@shared-pipes';
import { SwiperModule } from 'swiper/angular';
import { ArticleCardModule } from '../../../cards';
import { AspectRatioDirectiveModule } from '@shared-directives';

const MODULES = [
  CommonModule,
  IconModule,
  SwiperModule,
  ArticleCardModule,
  ToTrustedHtmlPipeModule,
  UnescapePipeModule,
  AspectRatioDirectiveModule,
];

@NgModule({
  declarations: [ArticlesComponent],
  exports: [ArticlesComponent],
  imports: [MODULES],
})
export class ArticlesModule {}
