<ng-container *ngIf="!hidden">
  <figure class="tracker-effect-card">
    <nav>
      <ng-container *ngIf="!loading; else assetSkeletonTemplate">
        <ncis-asset
          [data]="data?.assets || []"
          [fullHeight]="true"
          [fullWidth]="true"
        ></ncis-asset>
      </ng-container>
      <ng-template #assetSkeletonTemplate>
        <ncis-skeleton class="w-[180px] h-[180px]"></ncis-skeleton>
      </ng-template>
    </nav>
    <figcaption>
      <ng-container *ngIf="!loading; else dataSkeletonTemplate">
        <h6>{{ data?.title }}</h6>
        <ng-container *ngIf="data?.level">
          <span [innerHTML]="'TRACKER_EFFECT_PAGE.LEVEL' | transloco: { level: toTitle$ | async | transloco }"></span>
        </ng-container>
        <span>{{ toTimeAsync() | async }}</span>
      </ng-container>
      <ng-template #dataSkeletonTemplate>
        <ncis-skeleton class="w-full h-[22px]"></ncis-skeleton>
        <ncis-skeleton class="w-full h-[18px]"></ncis-skeleton>
      </ng-template>
    </figcaption>
    <ul *ngIf="data?.tags?.length">
      <ng-container *ngFor="let tag of data?.tags || []">
        <li>{{tag}}</li>
      </ng-container>
    </ul>
  </figure>
</ng-container>
