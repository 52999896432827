<div class="toolbar">
  <!--Back button-->
  <button (click)="backClick.emit()">
    <ncis-common-icon
      [folder]="CommonIconFolder.GENERAL"
      [name]="CommonIconGeneral.RETURN_ARROW"
      class="h-[16px] text-ncis-neutral-800"
    ></ncis-common-icon>
  </button>

  <!--Option button-->
  <button (click)="bookmarkClick.emit()" *ngIf="showBookmark">
    <ncis-common-icon
      (click)="bookmarkClick.emit()"
      [folder]="CommonIconFolder.GENERAL"
      [name]="alreadyBookmark ? CommonIconGeneral.BOOKMARK_ACTIVE : CommonIconGeneral.BOOKMARK_INACTIVE"
      [attr.data-test-id]="'bookmark-icon'"
      class="h-[26px] text-ncis-neutral-800 cursor-pointer"
    ></ncis-common-icon>
  </button>
</div>
