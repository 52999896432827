import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { LocalForageService } from "ngx-localforage";
import { catchError, mergeMap, Observable, of, throwError } from "rxjs";
import { ScreenCodes, StorageKeys } from "@mobile-data-access-enums";
import { IOpenAuthenticationResult } from "@mobile-data-access-interfaces";

@Injectable()
export class UnauthenticatedUserGuard implements CanActivate {

  //#region Properties

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _localForage: LocalForageService,
  ) { }

  //#endregion

  //#region Methods

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._localForage
      .getItem(StorageKeys.AUTHENTICATION_RESULT)
      .pipe(
        mergeMap((authenticationResult: IOpenAuthenticationResult) => {
          if (authenticationResult && authenticationResult!.accessToken) {
            return throwError("HAS_AUTHENTICATION_RESULT");
          }

          return of(true);
        }),
        catchError(() => {
          const urlTree = this._navigationService.buildUrlTree(ScreenCodes.ONBOARDING, void (0));
          return of(urlTree);
        })
      );
  }

  //#endregion

}
