import { NgModule } from '@angular/core';
import { ActionsComponent } from './actions.component';
import { CommonModule } from '@angular/common';
import { ActionCardModule } from '../../../cards';
import { SkeletonModule } from '../../../shared';

@NgModule({
  imports: [CommonModule, SkeletonModule, ActionCardModule],
  declarations: [ActionsComponent],
  exports: [ActionsComponent],
})
export class ActionsModule {}
