<div class="effect-card">
  <div
    [class.bg-ncis-neutral-300]="!loading && !data?.imageUrl"
    class="effect-card__image"
  >
    <ng-container *ngIf="!loading; else skeletonImageTemplate">
      <img [alt]="data?.title" [src]="data?.imageUrl" hiddenImageOnError />
    </ng-container>
  </div>
  <div class="effect-card__information">
    <ng-container *ngIf="!loading; else skeletonInformationTemplate">
      <span [attr.data-test-id]="'description'">{{ data?.description || 'N/A' }}</span>
      <h3 [attr.data-test-id]="'title'">{{ data?.title || 'N/A' }}</h3>
    </ng-container>
  </div>
</div>
<ng-template #skeletonImageTemplate>
  <ncis-skeleton class="w-full h-full"></ncis-skeleton>
</ng-template>
<ng-template #skeletonInformationTemplate>
  <ncis-skeleton class="h-[20px] w-40"></ncis-skeleton>
  <ncis-skeleton class="h-[26px] w-40"></ncis-skeleton>
</ng-template>
