import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy } from "@angular/core";
import { CommonIconFolderEnum, CommonIconGeneralEnum } from "@shared-enums";
import { ResizeSensor } from "css-element-queries";

@Component({
  selector: 'ncis-recommendation',
  templateUrl: 'recommendation.component.html',
  styleUrls: ['recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationMenuItem implements OnDestroy {
  //#region Accessors

  private __resizeSensor?: ResizeSensor;

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public imageUrl?: string;

  public readonly IconFolders = CommonIconFolderEnum;
  public readonly Icons = CommonIconGeneralEnum;

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    this.title = '';
    this.description = '';
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnDestroy(): void {
    this.__resizeSensor?.detach();
  }

  //#endregion
}
