import { Directive, ElementRef, Input, OnChanges, ViewContainerRef } from "@angular/core";

@Directive({
  selector: 'img[lazyLoadImage]',
})
export class LazyLoadImageDirective implements OnChanges {
  //#region Properties

  @Input('lazyLoadImage')
  public src: string = '';

  public loadingSrc = 'ncis-assets/svgs/common/image-loading.svg';
  public observer: IntersectionObserver | null = null;

  //#endregion

  //#region Constructor
  public constructor(
    protected readonly _elementRef: ElementRef<HTMLImageElement>,
    protected readonly _viewContainerRef: ViewContainerRef
  ) {}

  //#endregion

  //#region Life cycle hooks

  public ngOnChanges(): void {
    this._elementRef.nativeElement.src = this.loadingSrc;

    if (this._elementRef.nativeElement && this.observer) {
      this.observer.unobserve(this._elementRef.nativeElement);
    }

    this.observer = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }

        const lazyImage = entry.target as HTMLImageElement;

        lazyImage.src = this.src;


        imgObserver.unobserve(lazyImage);
      });
    });
    this.observer.observe(this._elementRef.nativeElement);

  }

  //#endregion
}
