import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IRootState } from '@mobile-data-access-stores';
import {
  DRUG_DETAIL_SERVICE,
  IDrugDetailService,
} from '@mobile-data-access-services';

@Injectable()
export class DrugDetailGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(DRUG_DETAIL_SERVICE)
    protected readonly _drugDetailService: IDrugDetailService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const id = activatedRouteSnapshot.queryParams['id'] as string;
    if (!id || !id.length || id === 'undefined') {
      return false;
    }

    return true;
  }

  //#endregion
}
