import { NgModule } from '@angular/core';
import { SwipeableMenuComponent } from './swipeable-menu.component';
import { IconModule } from '@shared-components';
import { SwiperModule } from 'swiper/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SwipeableMenuComponent],
  imports: [IconModule, SwiperModule, CommonModule],
  exports: [SwipeableMenuComponent],
})
export class SwipeableMenuModule {}
