import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom, from, Observable } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { ToastController } from "@ionic/angular";
import { IRootState, PaxmanActions, PaxmanComponentStore, UserSelectors } from "@mobile-data-access-stores";
import { Store } from "@ngrx/store";
import { IPaxmanService, PAXMAN_SERVICE } from "@mobile-data-access-services";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";

@Injectable()
export class PaxmanGuard implements CanActivate {
    //#region Constructor

    public constructor(
        protected readonly _store: Store<IRootState>,
        protected readonly _toastController: ToastController,
        protected readonly _translateService: TranslocoService,
        protected readonly _componentStore: PaxmanComponentStore,
        @Inject(PAXMAN_SERVICE)
        protected readonly _paxmanService: IPaxmanService,
        @Inject(SMART_NAVIGATOR_SERVICE)
        protected readonly _navigationService: ISmartNavigatorService,
    ) {
    }

    //#endregion

    //#region Methods

    public canActivate(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree> {
        const asyncHandler = async () => {

            try {

                const profile = await firstValueFrom(
                    this._store.select(UserSelectors.profile));

                // Profile is not found.
                if (!profile) {
                    return false;
                }

                const activatedPaxman = await firstValueFrom(this._paxmanService.checkActivationAsync())
                    .then((m) => m.activated);
                const availableToPaxman = await firstValueFrom(this._paxmanService.checkAvailabilityAsync())
                    .then(m => m.available);

                const paxmanInfo = await firstValueFrom(this._paxmanService.getInfoAsync());
                this._store.dispatch(PaxmanActions.saveInfo({ data: paxmanInfo }));
                this._store.dispatch(PaxmanActions.saveActivation({ data: activatedPaxman }));
                this._store.dispatch(PaxmanActions.saveAvailable({ data: availableToPaxman }));

                // Only allow user with paxman activated or who is available to paxman to access the child routes.
                return activatedPaxman || availableToPaxman;
            } catch (exception) {
                return false;
            } finally {
            }
        }

        return from(asyncHandler());
    }

    //#endregion
}
