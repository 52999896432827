import { Inject, Injectable } from "@angular/core";
import { ISymptomDetailState } from "./symptom-detail.state";
import {
  ARTICLE_SERVICE,
  BOOKMARK_SERVICE,
  IArticleService,
  IBookmarkService,
  ISymptomService,
  SYMPTOM_SERVICE
} from "@mobile-data-access-services";
import { ActivatedRoute } from "@angular/router";
import { ChemotherapyBaseComponentStore } from "../base";
import { ArticleCategories, SymptomDetailControlCodes } from "@mobile-data-access-enums";
import { addDays, endOfDay, startOfDay } from "date-fns";
import {BehaviorSubject, catchError, map, mergeMap, Observable, of, tap, throwError} from "rxjs";

@Injectable()
export class SymptomDetailComponentStore extends ChemotherapyBaseComponentStore<ISymptomDetailState> {

  //#region Constructor

  public constructor(
    @Inject(SYMPTOM_SERVICE)
    protected readonly _symptomsService: ISymptomService,
    @Inject(ARTICLE_SERVICE)
    protected readonly _articleService: IArticleService,
    @Inject(BOOKMARK_SERVICE)
    protected readonly _bookmarkService: IBookmarkService,
    protected override readonly _activatedRoute: ActivatedRoute
  ) {
    super(_activatedRoute, {
      id: null,
      statisticsLoading: false,
      statistics: null,
      articlesLoading: false,
      articles: null,
      recommendationsLoading: false,
      recommendations: null,
      nutritionsLoading: false,
      nutritions: null,
      tagsLoading: false,
      tags: null,
      overviewLoading: false,
      overview: null
    });
  }

  //#endregion

  //#region Methods

  public setup(): void {
    this.load("articles",
      (id) =>
        this._symptomsService.getArticlesBySymptomIdAsync(id)
          .pipe(
              tap(() => this.updateProperty("articlesLoading", false)),
              catchError(() => {
                this.updateProperty("articlesLoading", false);
                return of([]);
              })
          )
    );
    this.load("recommendations", (id) => this._symptomsService.getContentAsync<ISymptomDetailState["recommendations"]>(id, SymptomDetailControlCodes.RECOMMENDATIONS));
    this.load("statistics", (id) => {
      const now = new Date();
      const endTime = endOfDay(now);
      const startTime = startOfDay(addDays(now, -7));
      return this._symptomsService.getStatisticAsync(id, startTime, endTime);
    });
    this.load("nutritions", (id) => this._symptomsService.getContentAsync<ISymptomDetailState["nutritions"]>(id, SymptomDetailControlCodes.NUTRITION_ITEMS));
    this.load("overview", (id) => this._symptomsService.getContentAsync<ISymptomDetailState["overview"]>(id, SymptomDetailControlCodes.OVERVIEW));
    this.load("tags", (id) => this._symptomsService.getContentAsync<ISymptomDetailState["tags"]>(id, SymptomDetailControlCodes.TAGS));
  }

  public override getIdAsync(): Observable<string> {
    return (((this._activatedRoute.firstChild as ActivatedRoute).firstChild as ActivatedRoute).firstChild as ActivatedRoute).params.pipe(
      map((params) => params["id"])
    );
  }

  //#endregion
}
