import { Inject, Injectable } from "@angular/core";
import { IChemotherapyRadiationDetailState } from "./radiation-detail.state";
import { IRadiationService, RADIATION_SERVICE } from "@mobile-data-access-services";
import { ActivatedRoute } from "@angular/router";
import { ChemotherapyBaseComponentStore } from "../../base";
import {RadiationControlCodes, ScreenCodes} from "@mobile-data-access-enums";
import {IRelatedTopicCard, IWhatNextCategory} from '@mobile-data-access-interfaces';
import {from, map, mergeMap, pipe, switchMap, take} from 'rxjs';
import {
    RadiationArticleWhatNextNavigationRequest,
    RadiationArticleWhatNextRelatedTopicNavigationRequest,
    RadiationDetailQueryParams
} from '@mobile-data-access-models';
import {NavController} from '@ionic/angular';
import {ISmartNavigatorService, SMART_NAVIGATOR_SERVICE} from '@ui-tool/core';

@Injectable()
export class ChemotherapyRadiationDetailComponentStore extends ChemotherapyBaseComponentStore<IChemotherapyRadiationDetailState> {

  //#region Constructor

  public constructor(
    @Inject(RADIATION_SERVICE)
    protected readonly _radiationService: IRadiationService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigatorService: ISmartNavigatorService,
    protected readonly _navController: NavController,
    protected override readonly _activatedRoute: ActivatedRoute,
  ) {
    super(_activatedRoute, {
      id: null,
      basicInfoLoading: false,
      basicInfo: null,
      overviewLoading: false,
      overview: null,
      sideEffectLoading: false,
      sideEffect: null,
      topicsLoading: false,
      topics: null,
      tagsLoading: false,
      tags: null,
      whatNext: null,
      whatNextLoading: false
    });
  }

  //#endregion

  //#region Methods

  public readonly goToWhatNext = this.effect<IWhatNextCategory>(
      pipe(
          switchMap(whatNext => {
            return this._activatedRoute.queryParams
                .pipe(
                    take(1),
                    map(queryParams => queryParams as RadiationDetailQueryParams),
                    mergeMap(queryParams => {
                      const navigationRequest = new RadiationArticleWhatNextNavigationRequest(ScreenCodes.CHEMOTHERAPY_RADIATION, queryParams.id, whatNext.id);
                      const urlTree = this._navigatorService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams || {}, navigationRequest.extras);
                      return from(this._navController.navigateForward(urlTree));
                    })
                )
          })
      )
  );

    public readonly goToRelatedTopic = this.effect<IRelatedTopicCard>(
        pipe(
            switchMap(topic => {
                return this._activatedRoute.queryParams
                    .pipe(
                        take(1),
                        map(queryParams => queryParams as RadiationDetailQueryParams),
                        mergeMap(queryParams => {
                            const navigationRequest = new RadiationArticleWhatNextRelatedTopicNavigationRequest(ScreenCodes.CHEMOTHERAPY_RADIATION, queryParams.id, topic.id);
                            const urlTree = this._navigatorService.buildUrlTree(navigationRequest.code, navigationRequest.routeParams || {}, navigationRequest.extras);
                            return from(this._navController.navigateForward(urlTree));
                        })
                    )
            })
        )
    )

  public setup(): void {
    this.load('overview', (id) => this._radiationService.getChemoAsync<IChemotherapyRadiationDetailState['overview']>(id, RadiationControlCodes.OVERVIEW));
    this.load('whatNext', (id) => this._radiationService.getChemoAsync<IChemotherapyRadiationDetailState['whatNext']>(id, RadiationControlCodes.WHAT_NEXT));
    this.load('sideEffect', (id) => this._radiationService.getChemoAsync<IChemotherapyRadiationDetailState['sideEffect']>(id, RadiationControlCodes.SIDE_EFFECT));
    this.load('topics', (id) => this._radiationService.getChemoAsync<IChemotherapyRadiationDetailState['topics']>(id, RadiationControlCodes.RELATED_TOPICS));
    this.load('tags', (id) => this._radiationService.getChemoAsync<IChemotherapyRadiationDetailState['tags']>(id, RadiationControlCodes.TAGS));
  }

  //#endregion
}
