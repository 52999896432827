import { IPlatformService } from './platform-service.interface';
import { Inject, Injectable } from '@angular/core';
import { PhysicalPlatforms } from '@shared-enums';
import { WINDOW } from '@ui-tool/core';
import { from, map, Observable, of, tap } from 'rxjs';
import { Any } from '@shared-types';
import { NativeMessage } from '@shared-models';

declare var Android: Any;

@Injectable()
export class PlatformService implements IPlatformService {
  //#region Constructor

  public constructor(@Inject(WINDOW) protected readonly _window: Window) {}

  //#endregion

  //#region Methods

  public getPlatformAsync(): Observable<PhysicalPlatforms> {
    // @ts-ignore
    if (this._window['Android']) {
      return of(PhysicalPlatforms.ANDROID);
    }

    // @ts-ignore
    if (window['webkit'] && window['webkit'].messageHandlers && window['webkit'].messageHandlers.ios) {
      return of(PhysicalPlatforms.IOS);
    }

    return of(PhysicalPlatforms.WEB);
  }

  public sendAsync<T>(message: NativeMessage<T>): Observable<void> {
    const window = this._window as Any;
    return from(this.getPlatformAsync()).pipe(
      tap((platform) => {
        if (PhysicalPlatforms.IOS === platform) {
          if (
            !window['webkit'] ||
            !window['webkit'].messageHandlers ||
            !window['webkit'].messageHandlers.ios
          ) {
            return;
          }
          window['webkit'].messageHandlers.ios.postMessage(message);
          return;
        }

        if (PhysicalPlatforms.ANDROID === platform) {
          const szMessage = JSON.stringify(message);
          Android.postMessage(szMessage);
          return;
        }

        window.postMessage(message);
      }),
      map(() => void 0)
    );
  }

  //#endregion
}
