import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnboardingRequestHeaderAttributes } from '@mobile-data-access-enums';

@Injectable()
export class OnboardingPinInterceptor implements HttpInterceptor {
  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers: Record<string, string> = {};
    const pin = sessionStorage.getItem(
      OnboardingRequestHeaderAttributes.X_PIN_CODE
    );
    if (pin) {
      headers[OnboardingRequestHeaderAttributes.X_PIN_CODE] = pin;
    }
    const clonedRequest = request.clone({
      reportProgress: true,
      setHeaders: headers,
    });

    return next.handle(clonedRequest);
  }

  //#endregion
}
