<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data">
    <div class="regimens">
      <ng-container *ngFor="let regimen of data.regimens">
        <ng-container *ngIf="regimen.administration !== ChemotherapyAdministrations.REST; else rest">
          <ng-container *ngFor="let drug of regimen.drugs">
            <ncis-drug-item (click)="goToDrugDetail(drug.id)" [administration]="regimen.administration"
                            [color]="regimen.color"
                            [data]="drug" [duration]="regimen.duration"></ncis-drug-item>

          </ng-container>
        </ng-container>
        <ng-template #rest>
          <ncis-drug-item [administration]="regimen.administration" [duration]="regimen.duration" [isRest]="true"
                          [color]="regimen.color"></ncis-drug-item>
        </ng-template>
      </ng-container>

    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <div class="regimens">
    <ng-container *ngFor="let item of [1,2,3]">
      <ncis-drug-item [loading]="true"></ncis-drug-item>
    </ng-container>
  </div>
</ng-template>
