import {NavigateToScreenRequest} from '@ui-tool/core';
import {ScreenCodes} from '@mobile-data-access-enums';

export class BookmarksNavigationRequest extends NavigateToScreenRequest<void> {

    //#region Constructor

    public constructor() {
        super(ScreenCodes.BOOKMARKS);
    }

    //#endregion

}
