<div class="detailed-cycle">
  <ng-container *ngIf="!loading; else skeletonTemplate">
    <ncis-button (click)="show = true" *ngIf="!show && dayContexts" [label]="'CHEMOTHERAPY_DETAIL_PAGE.SEE_DETAILED_TREATMENT' | transloco"></ncis-button>
    <ng-container *ngIf="show">
      <div class="detailed-cycle__container">
        <ng-container *ngFor="let dayContext of dayContexts; let d = index">
          <div class="detailed-cycle__item" >
            <span>{{'CHEMOTHERAPY_DETAIL_PAGE.DETAILED_DAY' | transloco}}</span>
            <span>{{d + 1 > 9 ? d + 1 : '0' + (d + 1).toString()}}</span>
            <div class="detailed-cycle__colors">
              <span [style.--color]="itemContext.color"
                    [style.--index]="colorIndex"
                    *ngFor="let itemContext of dayContext.items; let colorIndex = index"></span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #skeletonTemplate>
    <ncis-button [loading]="true"></ncis-button>
  </ng-template>
</div>
