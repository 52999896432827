import { NgModule } from '@angular/core';
import { PAXMAN_SERVICE } from './paxman-service.token';
import { PaxmanService } from './paxman.service';

@NgModule({
  providers: [
    {
      provide: PAXMAN_SERVICE,
      useClass: PaxmanService,
    },
  ],
})
export class PaxmanServiceModule {}
