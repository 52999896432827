import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import { Any } from '@shared-types';
import { ResizeSensor } from 'css-element-queries';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { SwipeableMenuOption } from './swipeable-menu-option';

@Component({
  selector: 'swipeable-menu',
  templateUrl: 'swipeable-menu.component.html',
  styleUrls: ['swipeable-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipeableMenuComponent implements AfterViewInit, OnDestroy {
  //#region Properties

  private __resizeSensor?: ResizeSensor;

  private __wrapperSize: { height: number; width: number };

  private __option: SwipeableMenuOption;

  private __slidesPerView: number;

  protected readonly _subscriptions: Subscription;

  private readonly __drawSwiper$: Subject<{ width: number; height: number }>;

  public readonly IconFolders = CommonIconFolderEnum;

  public readonly Icons = CommonIconGeneralEnum;

  //#endregion

  //#region Accessors

  public get slidesPerView(): number {
    return this.__slidesPerView;
  }

  @Input()
  public title: string;

  @Input()
  public detailTitle: string;

  @Input()
  public description: string;

  @Input()
  public items: Any[];

  @Input()
  public loading: boolean;

  @Input()
  public itemTemplate?: TemplateRef<any>;

  public get option(): SwipeableMenuOption {
    return this.__option;
  }

  @Input()
  public set option(value: SwipeableMenuOption) {
    this.__option = value;
  }

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    this.__option = new SwipeableMenuOption(102, 70, 24);
    this.__slidesPerView = 1;
    this.__wrapperSize = { width: 0, height: 0 };

    this.title = '';
    this.detailTitle = '';
    this.description = '';
    this.items = [];
    this.loading = false;

    this.__drawSwiper$ = new Subject<{ width: number; height: number }>();
    this._subscriptions = new Subscription();
  }

  //#endregion

  //#region Life cycle hooks

  public ngAfterViewInit(): void {
    const drawSwiperSubscription = this.__drawSwiper$
      .pipe(debounceTime(250))
      .subscribe((size) => {
        this.__wrapperSize = size;
        const option = this.__option;

        // Calculate the number of slides.
        const width = size.width;

        const slidesPerView = Math.floor(
          width / (option.itemWidth + option.spaceBetween)
        );
        this.__slidesPerView = slidesPerView;
        this._changeDetectorRef.markForCheck();
      });
    this._subscriptions.add(drawSwiperSubscription);

    const itemsHtmlElement =
      this._elementRef.nativeElement.querySelector('.items');

    this.__resizeSensor = new ResizeSensor(itemsHtmlElement, (size) => {
      this.__drawSwiper$.next(size);
    });
    this.__resizeSensor.reset();
  }

  public ngOnDestroy(): void {
    this.__resizeSensor?.detach();
    this.__resizeSensor = undefined;
    this._subscriptions?.unsubscribe();
  }

  //#endregion
}
