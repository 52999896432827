<ng-container *ngIf="!hidden">
  <section [class.selected]="isSelected" class="tracker-record-card">
    <header>
      <div class="tracker-record-card__information">
        <span [inlineSVG]="svgLink$ | async"></span>
        <h5>{{title$ | async | transloco}}</h5>
      </div>
      <span [inlineSVG]=circleLink></span>
    </header>
    <p [innerHTML]="(data?.definition || '') | unescape | toTrustedHtml"></p>
  </section>
</ng-container>
