<div class="enter-pil">
  <h2>{{ title }}</h2>
  <ng-otp-input
    (onInputChange)="inputChange($event)"
    [config]="config"
  ></ng-otp-input>
  <div *ngIf="showNumpad" class="enter-pil__numpad">
    <ng-container *ngFor="let key of keys">
      <span (click)="clickNumpad(key)">{{key}}</span>
    </ng-container>
  </div>
  <ng-container *ngIf="loading">
    <div class="enter-pil__loading">
      <img alt="Pil Loading" class="z-[1]" src="ncis-assets/images/loading.gif" />
    </div>
  </ng-container>
</div>
