import {NavigateToScreenRequest} from '@ui-tool/core';
import {ArticleCategories, ScreenCodes} from '@mobile-data-access-enums';
import {ArticleDetailQueryParams} from '@mobile-data-access-models';

export class ProcedureRelatedTopicNavigationRequest extends NavigateToScreenRequest<void> {

    //#region Constructor

    public constructor(source: ScreenCodes, sourceId: string, articleId: string,
                       category: ArticleCategories.PROCEDURE_ARTICLE | ArticleCategories.PROCEDURE_ARTICLE_RELATED_TOPIC | ArticleCategories.PROCEDURE_RELATED_TOPIC | ArticleCategories.PROCEDURE_RELATED_TOPIC_RELATED_TOPIC) {
        super(ScreenCodes.DETAILED_ARTICLE, void(0), {
            queryParams: new ArticleDetailQueryParams(source, sourceId, articleId, category)
        });
    }

    //#endregion

}
