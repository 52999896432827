import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import Swiper, { FreeMode, SwiperOptions } from "swiper";
import { IRelatedTopicCard } from "@mobile-data-access-interfaces";

Swiper.use([FreeMode]);

@Component({
  selector: 'ncis-related-topics',
  templateUrl: './related-topics.component.html',
  styleUrls: ['./related-topics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedTopicsComponent {
  //#region Properties

  @Input()
  public data: IRelatedTopicCard[] | undefined;

  @Input()
  public title = 'Related Topics';

  @Input()
  public loading = false;

  @Output()
  public readonly clickTopicEvent: EventEmitter<IRelatedTopicCard>;

  public readonly swiperOptions: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    freeMode: true,
  };

  //#endregion

  //#region Constructor

  public constructor() {
    this.clickTopicEvent = new EventEmitter<IRelatedTopicCard>();
  }

  //#endregion
}
