<div
  class="schedule-card"
  [class.clickable]="clickable"
  [class.cursor-pointer]="clickable"
>
  <ng-container *ngIf="!loading; else skeletonTemplate">
    <div class="schedule-card__information">
      <!--Category-->
      <ng-container
        [ngSwitch]="
          categoryDirective != null && categoryDirective.templateRef != null
        "
      >
        <ng-container *ngSwitchCase="true">
          <ng-template
            [ngTemplateOutlet]="categoryDirective!.templateRef"
            [ngTemplateOutletContext]="{ $implicit: data.category }"
          >
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ncis-category
            *ngIf="data.category?.length != null && data.category.length > 0"
            [title]="data.category"
          ></ncis-category>
        </ng-container>
      </ng-container>

      <h3>{{ data.title || 'N/A' }}</h3>
      <div
        class="schedule-card__details"
        [ngSwitch]="
          descriptionDirective != null &&
          descriptionDirective.templateRef != null
        "
      >
        <!--Description (template)-->
        <ng-container *ngSwitchCase="true">
          <ng-template
            [ngTemplateOutlet]="descriptionDirective!.templateRef"
          ></ng-template>
        </ng-container>

        <!--Description (default)-->
        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="timeTemplate; else timeDefaultTemplate">
            <span>
              <ng-container
                *ngTemplateOutlet="timeTemplate; context: { data: data }"
              ></ng-container>
            </span>
          </ng-container>
          <span>{{ data.address || 'N/A' }}</span>
        </ng-container>

        <ng-template #timeDefaultTemplate>
          <span
            [innerHTML]="data.startTime | toSystemPlusTimeAsync | async | toAppointmentTime: data.endTime | toTrustedHtml"
          ></span>
        </ng-template>
      </div>
    </div>
    <div class="schedule-card__image">
      <img
        [alt]="data.title"
        height="80"
        width="80"
        [lazyLoadImage]="data.imageUrl"
      />
    </div>
    <!--      Unsupported for now-->
<!--    <ncis-common-icon-->
<!--      [folder]="CommonFolderIcon.GENERAL"-->
<!--      [name]="CommonGeneralIcon.OPTIONS"-->
<!--      class="schedule-card__more"-->
<!--    ></ncis-common-icon>-->
  </ng-container>
  <ng-template #skeletonTemplate>
    <div class="schedule-card__information">
      <ncis-skeleton class="w-16 h-4"></ncis-skeleton>
      <ncis-skeleton class="w-[100px] h-[22px]"></ncis-skeleton>
      <div class="schedule-card__details">
        <ncis-skeleton class="w-[100px] h-[20px]"></ncis-skeleton>
        <ncis-skeleton class="w-[100px] h-[20px]"></ncis-skeleton>
      </div>
    </div>
    <div class="schedule-card__image"></div>
  </ng-template>
</div>
