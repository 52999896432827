import { NgModule } from "@angular/core";
import { InlineSVGModule } from "ng-inline-svg-2";
import { ListItemComponent } from "./list-item.component";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [InlineSVGModule, CommonModule, TranslocoModule],
  declarations: [ListItemComponent],
  exports: [ListItemComponent]
})
export class ListItemModule { }
