import { NgModule } from '@angular/core';
import { ScheduleComponent } from './schedule.component';
import { CommonModule } from '@angular/common';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { TreatmentColorServiceModule } from '@mobile-data-access-services';
import { TranslocoModule } from '@ngneat/transloco';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import {
  TreatmentCycleModule,
  TreatmentScheduleModule,
} from '../../../features';
import { SkeletonModule } from "../../../shared";

const MODULES = [
  CommonModule,
  HiddenImageOnErrorModule,
  TreatmentCycleModule,
  TreatmentScheduleModule,
  TreatmentColorServiceModule,
];

@NgModule({
  declarations: [ScheduleComponent],
  exports: [ScheduleComponent],
  imports: [MODULES, TranslocoModule, ToTrustedHtmlPipeModule, SkeletonModule]
})
export class ScheduleModule {}
