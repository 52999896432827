<div [class.rest]="isRest" class="drug-item">
  <ng-container *ngIf="!loading; else skeletonTemplate">
    <div class="drug-item__information">
      <div [style.--drug-color]="color"
           class="drug-item__color"></div>
      <span class="drug-item__type">{{isRest ? 'N/A' : (administration || 'N/A')}}</span>
      <div class="drug-item__detail">
        <span class="drug-item__name">{{isRest ? 'Rest' : (data?.name || 'N/A')}}</span>
        <span class="drug-item__duration">{{duration | transloco}}</span>
      </div>
    </div>
    <ncis-common-icon *ngIf="!isRest" class="w-[22px] h-[22px] text-ncis-neutral-800 mt-1" folder="general"
                      name="right-arrow"></ncis-common-icon>
  </ng-container>
  <ng-template #skeletonTemplate>
    <div class="drug-item__information">
      <ncis-skeleton class="w-[30px] h-[30px] rounded-full"></ncis-skeleton>
      <ncis-skeleton class="w-[50px] h-[18px] mt-1.5"></ncis-skeleton>
      <div class="drug-item__detail">
        <ncis-skeleton class="w-[80px] h-[22px]"></ncis-skeleton>
        <ncis-skeleton class="w-[100px] h-[22px]"></ncis-skeleton>
      </div>
    </div>
  </ng-template>
</div>
