import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ITrackerSectionItem } from '@mobile-data-access-interfaces';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';

@Component({
  selector: 'ncis-tracker-section',
  templateUrl: './tracker-section.component.html',
  styleUrls: ['./tracker-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerSectionComponent {
  //#region Properties

  @Output()
  public readonly viewAllEvent = new EventEmitter();

  @Output()
  public readonly viewItemEvent = new EventEmitter<ITrackerSectionItem>();

  @Input()
  public data: ITrackerSectionItem[] | null = null;

  @Input()
  public title: string | null = null;

  @Input()
  public loading = false;
  public readonly swiperOptions: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    freeMode: true,
  };

  @Input()
  public ableToViewAll = true;

  public get hidden(): boolean {
    return !this.loading && !this.data?.length;
  }
  //#endregion
}
