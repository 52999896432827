import { Inject, Injectable } from '@angular/core';
import { ISymptomsState } from './symptoms.state';
import {
  ARTICLE_SERVICE,
  BOOKMARK_SERVICE,
  DRUG_DETAIL_SERVICE,
  IArticleService,
  IBookmarkService,
  IDrugDetailService,
  ITreatmentSymptomsService,
  TREATMENT_SYMPTOMS_SERVICE,
} from '@mobile-data-access-services';
import { ActivatedRoute } from '@angular/router';
import {
  ArticleCategories,
  ScreenCodes,
  SymptomHostTypes,
  SymptomOccurrences,
  SymptomsArticleTypes,
  SymptomsControlCodes,
} from '@mobile-data-access-enums';
import {
  catchError,
  finalize,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  pipe,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentStore } from '@ngrx/component-store';
import {
  ArticleDetailNavigationRequest,
  SymptomsQueryParams,
} from '@mobile-data-access-models';
import { IArticle, ISymptomCard, ITag } from '@mobile-data-access-interfaces';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { NavController } from '@ionic/angular';

@Injectable()
export class SymptomsComponentStore extends ComponentStore<ISymptomsState> {
  //#region Constructor

  public constructor(
    @Inject(TREATMENT_SYMPTOMS_SERVICE)
    protected readonly _treatmentSymptomsService: ITreatmentSymptomsService,
    @Inject(DRUG_DETAIL_SERVICE)
    protected readonly _drugDetailService: IDrugDetailService,
    @Inject(ARTICLE_SERVICE)
    protected readonly _articleService: IArticleService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(BOOKMARK_SERVICE)
    protected readonly _bookmarkService: IBookmarkService,
    protected readonly _navController: NavController,
    protected readonly _translateService: TranslocoService,
    protected readonly _activatedRoute: ActivatedRoute
  ) {
    super({
      [SymptomsControlCodes.ARTICLES]: [],
      [SymptomsControlCodes.TAGS]: [],
      [SymptomsControlCodes.SIDE_EFFECTS]: null,
      chosenOccurrence: SymptomOccurrences.IMMEDIATE,
      controlCodeToLoadingStatus: {},
    });
  }

  //#endregion

  //#region Methods

  public readonly initialize = this.effect<never>(
    pipe(
      tap(() => {
        this.loadArticles();
        this.loadSideEffect();
        this.loadTags();
      })
    )
  );

  public readonly loadArticles = this.effect<never>(
    pipe(
      switchMap(() => this._getSymptomParamsAsync()),
      mergeMap(({ id, type }) => {
        this._changeLoadingStatus(SymptomsControlCodes.SIDE_EFFECTS, true);
        let category: ArticleCategories =
          ArticleCategories.SYMPTOM_DETAIL_ARTICLE;
        let getArticlesObservable: Observable<IArticle[]> = of([]);

        if (type === SymptomHostTypes.DRUG) {
          getArticlesObservable =
            this._drugDetailService.getSymptomArticlesByDrugIdAsync(id);
        } else {
          getArticlesObservable =
            this._treatmentSymptomsService.getArticlesByTreatmentIdAsync(id);
        }
        return getArticlesObservable.pipe(
          catchError(() => {
            return of([]);
          }),
          map((articles) => {
            for (const article of articles) {
              switch (article.type) {
                case SymptomsArticleTypes.GENERAL_PRECAUTION:
                  article.category =
                    ArticleCategories.SYMPTOMS_ARTICLE_GENERAL_PRECAUTION;
                  break;

                case SymptomsArticleTypes.RARE_SIDE_EFFECT:
                  article.category =
                    ArticleCategories.SYMPTOMS_ARTICLE_RARE_SIDE_EFFECT;
                  break;

                case SymptomsArticleTypes.OCCASIONAL_SIDE_EFFECT:
                  article.category =
                    ArticleCategories.SYMPTOMS_ARTICLE_OCCASIONAL_SIDE_EFFECT;
                  break;

                case SymptomsArticleTypes.LONG_TERM_SIDE_EFFECT:
                  article.category =
                    ArticleCategories.SYMPTOMS_ARTICLE_LONG_TERM_SIDE_EFFECT;
                  break;
                case SymptomsArticleTypes.IMMUNO:
                  article.category =
                    ArticleCategories.SYMPTOMS_ARTICLE_IMMUNO_SIDE_EFFECT;
                  break;
              }
            }
            return articles;
          }),
          tap((articles) => {
            this.patchState((state) => ({
              ...state,
              [SymptomsControlCodes.ARTICLES]: articles,
            }));
          }),
          finalize(() =>
            this._changeLoadingStatus(SymptomsControlCodes.SIDE_EFFECTS, false)
          )
        );
      })
    )
  );

  public readonly loadSideEffect = this.effect<never>(
    pipe(
      switchMap(() => this._getSymptomParamsAsync()),
      mergeMap(({ id, type }) => {
        this._changeLoadingStatus(SymptomsControlCodes.SIDE_EFFECTS, true);

        let getSymptomsObservable: Observable<ISymptomCard[]> = of([]);
        if (type == SymptomHostTypes.DRUG) {
          getSymptomsObservable = this._drugDetailService
            .getSymptomsByDrugIdAsync(id)
            .pipe(map((items) => items.map((item) => item as ISymptomCard)));
        } else {
          getSymptomsObservable =
            this._treatmentSymptomsService.getSymptomsByTreatmentIdAsync(id);
        }
        return getSymptomsObservable.pipe(
          map((symptoms) => {
            if (!symptoms) {
              return null;
            }

            return {
              symptoms,
              title: '',
              description: '',
              trackTitle: '',
              trackDescription: '',
              id: Number.MAX_VALUE,
            };
          }),
          tap((sideEffect) => {
            this.patchState((state) => ({
              ...state,
              [SymptomsControlCodes.SIDE_EFFECTS]: sideEffect,
            }));
          }),
          finalize(() =>
            this._changeLoadingStatus(SymptomsControlCodes.SIDE_EFFECTS, false)
          )
        );
      })
    )
  );

  public readonly loadTags = this.effect<never>(
    pipe(
      switchMap(() => this._getSymptomParamsAsync()),
      mergeMap(({ id, type }) => {
        this._changeLoadingStatus(SymptomsControlCodes.TAGS, true);

        let loadTagsObservable: Observable<ITag[]> = of([]);
        if (type === SymptomHostTypes.DRUG) {
          loadTagsObservable = this._drugDetailService.getTagsAsync(id);
        } else {
          loadTagsObservable =
            this._treatmentSymptomsService.getTagsByTreatmentIdAsync(id);
        }
        return loadTagsObservable.pipe(
          tap((tags) => {
            this.patchState((state) => ({
              ...state,
              [SymptomsControlCodes.TAGS]: tags,
            }));
          }),
          finalize(() =>
            this._changeLoadingStatus(SymptomsControlCodes.TAGS, false)
          )
        );
      })
    )
  );

  public selectOccurrence = this.effect<SymptomOccurrences>(
    pipe(
      tap((occurrence) => {
        this.patchState((state) => ({
          ...state,
          chosenOccurrence: occurrence,
        }));
      })
    )
  );

  public readonly viewArticle = this.effect<IArticle>(
    pipe(
      switchMap((article) =>
        forkJoin([of(article), this._getSymptomParamsAsync()])
      ),
      tap(([article, queryParams]) => {
        const navigationRequest = new ArticleDetailNavigationRequest(
          ScreenCodes.SYMPTOMS,
          queryParams.id,
          article.category as ArticleCategories,
          article.id
        );
        const urlTree = this._navigationService.buildUrlTree(
          navigationRequest.code,
          navigationRequest?.routeParams || {},
          navigationRequest.extras
        );
        void this._navController.navigateForward(urlTree);
        return of(void 0);
      })
    )
  );

  //#endregion

  //#region Internal methods

  protected _getSymptomParamsAsync(): Observable<SymptomsQueryParams> {
    return this._activatedRoute.queryParams.pipe(
      take(1),
      map((params) => params as SymptomsQueryParams)
    );
  }

  protected _changeLoadingStatus(
    controlCode: SymptomsControlCodes,
    status: boolean
  ): void {
    this.patchState((state) => ({
      ...state,
      controlCodeToLoadingStatus: {
        ...state.controlCodeToLoadingStatus,
        [controlCode]: status,
      },
    }));
  }

  //#endregion
}
