import { NgModule } from '@angular/core';
import { QR_CODE_SERVICE } from './qr-code-service.token';
import { QrCodeService } from './qr-code.service';

@NgModule({
  providers: [
    {
      provide: QR_CODE_SERVICE,
      useClass: QrCodeService,
    },
  ],
})
export class QrCodeServiceModule {}
