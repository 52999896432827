import { NgModule } from '@angular/core';
import { ScheduleCardComponent } from './schedule-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToAppointmentTimeModule, ToSystemTimeModule } from '@mobile-ui-pipes';
import { CategoryModule, SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { ScheduleCardTimeDirective } from './directives/schedule-card-time.directive';
import { ScheduleCardDescriptionDirective } from './directives';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { LazyLoadImageModule } from '@mobile-ui-directives';
import { ToPlusTimeModule } from '../../../../pipes/src/to-plus-time';
import { ToSystemPlusTimeAsyncPipeModule } from '@mobile-ui-pipes';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ToSystemTimeModule,
    CategoryModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    ToTrustedHtmlPipeModule,
    ImgFallbackModule,
    LazyLoadImageModule,
    ToAppointmentTimeModule,
    ToPlusTimeModule,
    ToSystemPlusTimeAsyncPipeModule,
  ],
  declarations: [
    ScheduleCardComponent,
    ScheduleCardTimeDirective,
    ScheduleCardDescriptionDirective,
  ],
  exports: [
    ScheduleCardComponent,
    ScheduleCardTimeDirective,
    ScheduleCardDescriptionDirective,
  ],
})
export class ScheduleCardModule {}
