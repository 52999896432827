import { NgModule } from "@angular/core";
import { EnterPilComponent } from "./enter-pil.component";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg-2";
import { ToolbarModule } from "../../shared";
import { NgOtpInputModule } from "ng-otp-input";

@NgModule({
  declarations: [EnterPilComponent],
  imports: [InlineSVGModule, ToolbarModule, CommonModule, NgOtpInputModule],
  exports: [EnterPilComponent],
})
export class EnterPilModule {}
