import { NgModule } from '@angular/core';
import { RegimensComponent } from './regimens.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToSymptomNumberModule } from '@mobile-ui-pipes';
import { DrugItemModule } from "../../../features";
import { ButtonModule } from "../../../shared";

const MODULES = [
  CommonModule,
  IconModule,
  ToSymptomNumberModule,
  DrugItemModule
];

@NgModule({
  declarations: [RegimensComponent],
  exports: [RegimensComponent],
  imports: [MODULES, ButtonModule]
})
export class RegimensModule {}
