import { IMedicationService } from './medication-service.interface';
import { Inject, Injectable } from '@angular/core';
import { ISearchResult } from '@shared-interfaces';
import { IMedicationDrug } from '@mobile-data-access-interfaces';
import { mergeMap, Observable } from 'rxjs';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicationService implements IMedicationService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endPointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getOwnedAsync(option: {
    page: number;
    size: number;
  }): Observable<ISearchResult<IMedicationDrug[]>> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endPoint) => {
        const apiUrl = `${endPoint}/medication`;
        const queryParams: Record<string, string> = {};
        if (option?.page && option?.page > 0) {
          queryParams['page'] = `${option.page}`;
        }
        if (option?.size && option?.size > 0) {
          queryParams['size'] = `${option.size}`;
        }
        return this._httpClient.get<{ data: IMedicationDrug[]; total: number }>(
          apiUrl,
          {
            params: queryParams,
          }
        );
      }),
      map(({ data, total }) => {
        //TODO: need to change format datetime
        const updatedData = data.map((medication) => ({
          ...medication,
          dispensedDate: medication.dispensedDate
            ? medication.dispensedDate.replace('Z', '')
            : medication.dispensedDate,
        }));
        return {
          items: updatedData,
          totalRecords: total,
        } as ISearchResult<IMedicationDrug[]>;
      })
    );
  }

  //#endregion
}
