import { Inject, Injectable } from '@angular/core';
import { IOnboardingService } from './onboarding-service.interface';
import {
  IOnboardingFinish,
  IOnboardingItem,
} from '@mobile-data-access-interfaces';
import { map, mergeMap, Observable, switchMap } from 'rxjs';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';

@Injectable()
export class OnboardingService implements IOnboardingService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods
  public checkAvailableAsync(): Observable<boolean> {
    return this._getUrlAsync('onboarding/available').pipe(
      switchMap((url) =>
        this._httpClient
          .get<IApiResult<Record<'available', boolean>>>(url)
          .pipe(map(({ data }) => data?.available))
      )
    );
  }
  public getFaqsAsync(): Observable<
    (Record<'question' | 'answer', string> & Record<'id', number>)[]
  > {
    return this._getUrlAsync('faq').pipe(
      switchMap((url) =>
        this._httpClient
          .get<
            IApiResult<
              (Record<'question' | 'answer', string> & Record<'id', number>)[]
            >
          >(url)
          .pipe(map(({ data }) => data || []))
      )
    );
  }
  public beginAsync(): Observable<never> {
    return this._getUrlAsync('onboarding/begin').pipe(
      switchMap((url) => this._httpClient.post<never>(url, {}))
    );
  }

  public finishAsync(data: IOnboardingFinish): Observable<never> {
    return this._getUrlAsync('onboarding/finish').pipe(
      switchMap((url) => this._httpClient.post<never>(url, data))
    );
  }

  public getItemByDiseaseAsync(
    prefix: string,
    diseaseIds: number[]
  ): Observable<IOnboardingItem[]> {
    return this._getUrlAsync(`${prefix}/search`).pipe(
      mergeMap((apiUrl) => {
        return this._httpClient
          .post<{ items: IOnboardingItem[] }>(apiUrl, { diseaseIds })
          .pipe(map(({ items }) => items));
      })
    );
  }

  public getDiseasesAsync(): Observable<IOnboardingItem[]> {
    return this._getUrlAsync(`disease/available-diseases`).pipe(
      mergeMap((apiUrl) => {
        return this._httpClient
          .get<{ items: IOnboardingItem[] }>(apiUrl)
          .pipe(map(({ items }) => items));
      })
    );
  }

  public unlockAsync(code: string): Observable<never> {
    return this._getUrlAsync(`pin/unlock`).pipe(
      mergeMap((apiUrl) => {
        return this._httpClient.post<never>(apiUrl, { code });
      })
    );
  }

  //#endregion Methods

  //#region Internal Methods

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion Internal Methods
}
