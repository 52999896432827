import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HtmlContents } from '@mobile-data-access-enums';
import { IHtmlUnescapes } from '@mobile-data-access-interfaces';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'unescapeHtmlAsync',
})
export class UnescapeHtmlPipe implements PipeTransform {
  //#region Constructor
  public constructor(
    protected readonly _sanitizer: DomSanitizer,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService
  ) {}

  //#endregion

  //#region Methods

  public transform(value: string, key: HtmlContents): Observable<SafeHtml> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((settings) => {
        const unescapeTimes = this.__getUnescapeTimes(
          settings.htmlUnescapes,
          key
        );
        let unescapedValue = value;

        for (let i = 0; i < unescapeTimes; i++) {
          unescapedValue = this.__unescapeHtml(unescapedValue);
        }

        return this._sanitizer.bypassSecurityTrustHtml(unescapedValue);
      })
    );
  }

  //#endregion

  //#region Internal Methods

  private __getUnescapeTimes(
    htmlUnescapes: IHtmlUnescapes[] | undefined,
    key: HtmlContents
  ): number {
    if (!htmlUnescapes || !htmlUnescapes.length) {
      return 1;
    }
    const found = htmlUnescapes.find((entry) => entry.key === key);
    return found ? found.time : 1;
  }

  private __unescapeHtml(htmlText: string): string {
    const span = document.createElement('span');
    span.innerHTML = htmlText;
    return span.innerText;
  }

  //#endregion
}
