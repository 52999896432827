import { NgModule } from '@angular/core';
import { HealthTrackerCardComponent } from './health-tracker-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToSymptomNumberModule } from '@mobile-ui-pipes';
import { CategoryModule, SkeletonModule } from '../../shared';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ToSymptomNumberModule,
    CategoryModule,
    SkeletonModule,
  ],
  declarations: [HealthTrackerCardComponent],
  exports: [HealthTrackerCardComponent],
})
export class HealthTrackerCardModule {}
