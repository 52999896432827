import { Inject, Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { INotFoundState } from "./not-found.state";
import { pipe, switchMap } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from "@ui-tool/core";
import { DashboardNavigationRequest } from "@mobile-data-access-models";

@Injectable()
export class NotFoundComponentStore extends ComponentStore<INotFoundState> {
  //#region Properties

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _activatedRoute: ActivatedRoute,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService
  ) {
    super();
  }

  //#endregion

  //#region Methods

  public readonly toHomePage = this.effect<never>(
    pipe(
      switchMap(() => {
        const navigationRequest = new DashboardNavigationRequest();
        return this._navigationService.navigateToScreenAsync(navigationRequest);
      })
    )
  );

  //#endregion

  //#region Internal methods

  protected readonly _setPreviousUrl = this.updater<string | undefined>(
    (state, previousUrl) => ({
      ...state,
      previousUrl,
    })
  );

  //#endregion
}
