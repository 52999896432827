import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';

export class DashboardNavigationRequest extends NavigateToScreenRequest<any> {
  //#region Constructor

  public constructor() {
    super(ScreenCodes.DASHBOARD);
  }

  //#endregion
}
