import { NgModule } from '@angular/core';
import { MeetingDetailComponent } from './meeting-detail.component';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SkeletonModule } from '../../../shared';
import { MeetingGoalCardModule } from '../../../cards';
import { OverviewModule } from "../overview";

@NgModule({
  imports: [
    MeetingGoalCardModule,
    CommonModule,
    TranslocoModule,
    SkeletonModule,
    OverviewModule
  ],
  declarations: [MeetingDetailComponent],
  exports: [MeetingDetailComponent],
})
export class MeetingDetailModule {}
