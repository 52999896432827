import { NgModule } from '@angular/core';
import { DrugItemComponent } from './drug-item.component';
import { IconModule } from '@shared-components';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from "@ngneat/transloco";
import { SkeletonModule } from "../../shared";

@NgModule({
  declarations: [DrugItemComponent],
  imports: [IconModule, CommonModule, TranslocoModule, SkeletonModule],
  exports: [DrugItemComponent],
})
export class DrugItemModule {}
