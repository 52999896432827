import { NgModule } from '@angular/core';
import { ArticleCardComponent } from './article-card.component';
import { CommonModule } from '@angular/common';
import { CategoryModule, SkeletonModule } from '../../shared';
import {
  AspectRatioDirectiveModule,
  HiddenImageOnErrorModule,
} from '@shared-directives';
import { IconModule } from '@shared-components';
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    CategoryModule,
    AspectRatioDirectiveModule,
    TranslocoModule
  ],
  declarations: [ArticleCardComponent],
  exports: [ArticleCardComponent],
})
export class ArticleCardModule {}
