import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import { AppComponentStore } from '@mobile-data-access-stores';
import { ISpinnerService, SPINNER_SERVICE, WINDOW } from '@ui-tool/core';
import { TranslocoService } from '@ngneat/transloco';
import { Languages, SpinnerContainerIds } from '@mobile-data-access-enums';
import { filter, Subscription, tap } from 'rxjs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import {
  ISystemService,
  IUiService,
  SYSTEM_SERVICE,
  UI_SERVICE,
} from '@mobile-data-access-services';

@Component({
  selector: 'ncis',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AppComponentStore],
})
export class AppComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  //#region Properties

  private readonly __navigationIdToSpinnerId: Record<string, string>;

  private readonly _subscriptions: Subscription;

  @ViewChild('ionApp', { static: false })
  public readonly app!: HTMLIonAppElement;

  public readonly state$ = this._store.state$;
  public readonly versionData$ = this._store.versionData$;

  public readonly SpinnerContainerIds = SpinnerContainerIds;

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _store: AppComponentStore,
    @Inject(WINDOW) protected readonly _window: Window,
    @Inject(UI_SERVICE) protected readonly _uiService: IUiService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(SYSTEM_SERVICE)
    protected readonly _systemService: ISystemService,
    protected readonly _router: Router,
    protected readonly _translateService: TranslocoService,
    protected readonly _renderer2: Renderer2,
  ) {
    this.__navigationIdToSpinnerId = {};
    this._subscriptions = new Subscription();
    this._translateService.setDefaultLang(Languages.EN);
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnInit(): void {
    this._store.initialize();

    const hookNavigationEventSubscription = this._router.events
      .pipe(filter((event) => event instanceof RouterEvent))
      .subscribe((event) => {
        // Navigation start.
        if (event instanceof NavigationStart) {
          // Display the spinner.
          this.__navigationIdToSpinnerId[event.id] =
            this._spinnerService.displaySpinner(
              SpinnerContainerIds.APPLICATION
            );
          return;
        }

        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          const displaySpinnerRequestId =
            this.__navigationIdToSpinnerId[event.id];
          this._spinnerService.deleteSpinner(
            SpinnerContainerIds.APPLICATION,
            displaySpinnerRequestId
          );
          this._store.updatePath();
        }
      });
    this._subscriptions.add(hookNavigationEventSubscription);
  }

  public ngAfterViewInit(): void {
    const element = (this.app as any).el;

    if (!this._appRunningOnWindowsChrome()) {
      this._renderer2.setAttribute(element, 'style', `
        --ds-max-width: 414px;
        max-width: 414px;
      `);
    } else {
      this._renderer2.setAttribute(element, 'style', `
        --ds-max-width: 431px;
        max-width: 431px;
      `);
    }
  }

  public ngAfterContentInit(): void {
    const firesMessageToNativeAppSubscription = this._systemService
      .markPwaReadyAsync()
      .subscribe();
    this._subscriptions.add(firesMessageToNativeAppSubscription);
  }

  public ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }

  public continueVersion(): void {
    this._store.continueEvent.next(new Date());
  }

  //#endregion

  //#region Internal methods

  protected _appRunningOnWindowsChrome(): boolean {
    return (
      this._window.navigator.userAgent.indexOf('Chrome') !== -1 &&
      this._window.navigator.userAgent.indexOf('Windows') !== -1
    );
  }

  //#endregion
}
