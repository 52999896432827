import { RpcRequest } from '@message-bus/core';
import { MessageNamespaces, NotificationMethods } from '@shared-enums';

@RpcRequest(MessageNamespaces.LOCAL_NOTIFICATION, NotificationMethods.READ)
export class GetNotificationByIdNativeMethod {
  //#region Constructor

  public constructor(public readonly id: string) {}

  //#endregion
}
