import {
  NativeNamespaces,
  NotificationNativeMethods,
} from '@mobile-data-access-enums';
import { RpcRequest } from '@message-bus/core';

@RpcRequest(NativeNamespaces.NOTIFICATION, NotificationNativeMethods.CREATE)
export class CreateNotificationNativeMethod {
  //#region Constructor

  public constructor(
    public readonly showTime: string,
    public readonly title: string,
    public readonly body: string
  ) {}

  //#endregion
}
