import { VersionFeatureCodes } from '@mobile-data-access-enums';


export class VersionCodeMapping {
  public static readonly mapping: Record<string, string> = {
    [VersionFeatureCodes.MEDICATION]: '/home/medication',
    [VersionFeatureCodes.TRACKER]: '/tracker',
    [VersionFeatureCodes.NAVIGATOR]: '/home/dashboard',
    [VersionFeatureCodes.DRUG]: '/drug-detail',
    [VersionFeatureCodes.ARTICLE]: '/article',
    [VersionFeatureCodes.PROFILE]: '/profile',
    [VersionFeatureCodes.ONBOARDING]: 'onboarding',
    [VersionFeatureCodes.SIDE_EFFECT]: '/symptom',
    [VersionFeatureCodes.CHEMOTHERAPY]: 'chemotherapy',
  };
}
