import { createAction, props } from '@ngrx/store';
import {
  IArticle,
  IBasicInfo,
  IOverview,
  IRelatedTopicCard,
  ISideEffect,
  ITag, ITreatment,
  IWhatNext,
} from '@mobile-data-access-interfaces';
import {DrugDetailControlCodes} from '@mobile-data-access-enums';

const prefix = '[Drug detail]';

export class DrugDetailActions {

  //#region Properties

  public static readonly changeBasicInfo = createAction(
    `${prefix} Change basic info`,
    props<IBasicInfo>()
  );

  public static readonly changeOverview = createAction(
    `${prefix} Change overview`,
    props<IOverview>()
  );

  public static readonly changeArticles = createAction(
      `${prefix} Change articles`,
      props<{data: IArticle[]}>()
  );

  public static readonly changeSideEffect = createAction(
    `${prefix} Change side-effect`,
    props<ISideEffect>()
  );

  public static readonly changeWhatNext = createAction(
    `${prefix} Change what's next`,
    props<IWhatNext>()
  );

  public static readonly changeRelatedTopics = createAction(
    `${prefix} Change related topics`,
    props<{ items: IRelatedTopicCard[] }>()
  );

  public static readonly changeTags = createAction(
    `[${prefix}] Change tags`,
    props<{ items: ITag[] }>()
  );

  public static readonly changeTreatment = createAction(`[${prefix}] Change treatment`,
      props<{treatment: ITreatment}>());

  public static readonly changeLoadingStatus = createAction(
    `[${prefix}] Mark as loading`,
    props<{ code: DrugDetailControlCodes; loading: boolean }>()
  );

  //#endregion
}
