import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppSettingsFactoryModule } from '@mobile-logics-factories';
import { AppRoutes } from './app.routing';
import { ReactiveComponentModule } from '@ngrx/component';
import {
  ApiEndpointResolverModule,
  ScreenCodeResolverModule,
} from '@mobile-data-access-resolvers';
import {
  AppointmentServiceModule,
  ArticleServiceModule,
  AuthenticationServiceModule,
  BookmarkServiceModule,
  ChemotherapyServiceModule,
  ContainerServiceModule,
  DateServiceModule,
  DrugDetailServiceModule,
  I18N_SERVICE,
  I18nService,
  ConnectivityServiceModule,
  NavigatorServiceModule,
  NotificationServiceModule,
  OnboardingServiceModule,
  PaxmanServiceModule,
  PlatformServiceModule,
  QrCodeServiceModule,
  RadiationServiceModule,
  SideEffectServiceModule,
  SymptomServiceModule,
  SystemServiceModule,
  TermsAndConditionsServiceModule,
  TranslocoHttpLoader,
  UiServiceModule,
  UserServiceModule,
  FeatureFlagServiceModule,
  RequirementHandlersModule,
  EnvironmentModule, VersionServiceModule
} from '@mobile-data-access-services';
import {
  SmartNavigatorModule,
  SpinnerContainerModule,
  WindowAccessorModule,
} from '@ui-tool/core';
import { RootStoreModule } from '@mobile-data-access-stores';
import { MessageBusModule } from '@message-bus/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { environment } from '@mobile-resources-environments';
import { LocalForageModule } from 'ngx-localforage';
import {
  AccessTokenModule,
  ForbiddenModule,
  LanguageModule,
  OnboardingPinModule,
  UnderMaintenanceModule,
} from '@mobile-logics-interceptors';
import {
  AppGuard,
  AuthenticatedUserGuard,
  HomeGuard,
  NotUnderMaintenanceGuard,
  PurgeAuthenticationGuard,
  UnauthenticatedUserGuard,
  UnderMaintenanceGuard,
} from '@mobile-logics-guards';
import { customAnimation } from './app.animation';
import { VersionComponentModule } from '@mobile-ui-components';

const MODULES = [
  BrowserModule,
  IonicModule.forRoot({ navAnimation: customAnimation }),
  BrowserAnimationsModule,
  HttpClientModule,
  SmartNavigatorModule.forRoot({}),
  AppSettingsFactoryModule,
  UnderMaintenanceModule,
  ForbiddenModule,
  OnboardingPinModule,
  AccessTokenModule,
  LanguageModule,
  AppRoutes,
  ReactiveComponentModule,
  SymptomServiceModule,
  ApiEndpointResolverModule,
  ContainerServiceModule,
  AppointmentServiceModule,
  DrugDetailServiceModule,
  PaxmanServiceModule,
  DateServiceModule,
  ArticleServiceModule,
  RootStoreModule,
  ScreenCodeResolverModule,
  SpinnerContainerModule.forRoot(),
  AuthenticationServiceModule,
  WindowAccessorModule,
  PlatformServiceModule,
  MessageBusModule.forRoot(),
  NotificationServiceModule,
  TranslocoModule,
  SideEffectServiceModule,
  LocalForageModule.forRoot(),
  NavigatorServiceModule,
  BookmarkServiceModule,
  UserServiceModule,
  OnboardingServiceModule,
  RadiationServiceModule,
  QrCodeServiceModule,
  ChemotherapyServiceModule,
  SystemServiceModule,
  TermsAndConditionsServiceModule,
  ConnectivityServiceModule,
  UiServiceModule.forRoot(),
  FeatureFlagServiceModule,
  RequirementHandlersModule.forRoot(),
  EnvironmentModule,
  VersionServiceModule
];

@NgModule({
  providers: [
    AuthenticatedUserGuard,
    UnauthenticatedUserGuard,
    HomeGuard,
    PurgeAuthenticationGuard,
    NotUnderMaintenanceGuard,
    UnderMaintenanceGuard,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: 'en',
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: I18N_SERVICE, useClass: I18nService },
    AppGuard,
  ],
  declarations: [AppComponent],
  imports: [...MODULES, VersionComponentModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
