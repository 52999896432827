import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import {
  ISideEffectService,
  IUiService,
  SIDE_EFFECT_SERVICE,
  UI_SERVICE,
} from '@mobile-data-access-services';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { ToastController } from '@ionic/angular';
import { TrackerRecordComponentStore } from '@mobile-data-access-stores';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class TrackerRecordGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(SIDE_EFFECT_SERVICE)
    protected readonly _sideEffectService: ISideEffectService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(UI_SERVICE)
    protected readonly _uiService: IUiService,
    protected readonly _toastController: ToastController,
    protected readonly _translocoService: TranslocoService,
    protected readonly _componentStore: TrackerRecordComponentStore
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const { id } = activatedRouteSnapshot.params;

    const asyncHandler = async () => {
      const translatedMessage = await firstValueFrom(
        this._translocoService.selectTranslate(
          'TRACKER_RECORD_NOT_FOUND',
          {},
          { scope: 'SYSTEM_MESSAGE' }
        )
      );
      const toastInstance = await this._toastController.create({
        duration: 3000,
        color: 'danger',
        position: 'top',
        message: translatedMessage,
      });

      try {
        const data = await firstValueFrom(
          this._sideEffectService.getRecordByIdAsync(id)
        );

        if (data) {
          this._componentStore.updateData(data);
          this._componentStore.updateCtcaeCode(id);
          this._componentStore.updateSelectedCode(null);
          return true;
        }

        throw new Error('TRACKER_RECORD_NOT_FOUND');
      } catch (exception) {
        await toastInstance.present();
        return Promise.resolve(false);
      }
    };

    return from(asyncHandler());
  }

  //#endregion
}
