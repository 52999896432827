<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data">
    <div
      (click)="playClick()"
      (dblclick)="playDbClick()"
      [class.overlay]="!play"
      class="video"
    >
      <video
        #video
        (pause)="play = false"
        (play)="onVideoPlay()"
        [autoplay]="autoplay"
        [controls]="play"
        [muted]="autoplay"
        name="media"
      >
        <source [src]="data.videoUrl | toTrustedResourceUrl" type="video/mp4" />
      </video>
      <ng-container *ngIf="!play">
        <div *ngIf="!hiddenInformation" class="video__information">
          <h4>{{ data.videoHeader || 'N/A' }}</h4>
          <p>{{ data.videoDescription || 'N/A' }}</p>
        </div>
        <div class="video__play">
          <ncis-common-icon
            class="h-3 text-ncis-neutral-100"
            folder="general"
            name="play"
          ></ncis-common-icon>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <ncis-skeleton class="w-full h-[232px] rounded-xl"></ncis-skeleton>
</ng-template>
