import { NgModule } from '@angular/core';
import { TREATMENT_COLOR_SERVICE } from './treatment-color-service.token';
import { TreatmentColorService } from './treatment-color.service';

@NgModule({
  providers: [
    {
      provide: TREATMENT_COLOR_SERVICE,
      useClass: TreatmentColorService,
    },
  ],
})
export class TreatmentColorServiceModule {}
