import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import {
  ISeverityDefinition,
  ITrackerSectionItem,
} from '@mobile-data-access-interfaces';
import { TrackerEffectServeCodes } from '@mobile-data-access-enums';
import { map, mergeMap, Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import {
  ToAppointmentTimePipe,
  ToSystemPlusTimeAsyncPipe,
} from '@mobile-ui-pipes';
import {
  APP_SETTINGS_SERVICE,
  IAppSettingsService,
} from '@mobile-data-access-services';

@Component({
  selector: 'ncis-tracker-effect-card',
  templateUrl: './tracker-effect-card.component.html',
  styleUrls: ['./tracker-effect-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerEffectCardComponent {
  //#region Properties
  @Input()
  public data: ITrackerSectionItem | null = null;

  @Input()
  public loading = false;
  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _translocoService: TranslocoService,
    protected readonly _toAppointmentTime: ToAppointmentTimePipe,
    protected readonly _toSystemPlusTimeAsyncPipe: ToSystemPlusTimeAsyncPipe,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService
  ) {}

  //#endregion

  //#region Methods
  public get hidden(): boolean {
    return !this.loading && !this.data;
  }

  public get toTitle$(): Observable<string> {
    return this.__getSeveritiesAsync().pipe(
      map((severities) => {
        const severity = severities?.settings.find(
          (def) => def.code === (this.data?.level || TrackerEffectServeCodes.G1)
        );
        return severity && severity.title
          ? severity.title
          : `TRACKER_EFFECT_PAGE.${
              this.data?.level || TrackerEffectServeCodes.G1
            }_TITLE`;
      })
    );
  }

  public toTimeAsync(): Observable<string> {
    if (!this.data || !this.data.lastUpdatedTime) {
      return of('');
    }

    return this._toSystemPlusTimeAsyncPipe
      .transform(this.data.lastUpdatedTime)
      .pipe(
        map((date) => {
          return this._toAppointmentTime.transform(date, undefined);
        }),
        mergeMap((date) => {
          return this._translocoService.selectTranslate(
            this.data?.descriptionKey || '',
            {
              date: date,
            }
          );
        })
      );
  }

  private __getSeveritiesAsync(): Observable<ISeverityDefinition | undefined> {
    return this._appSettingsService.loadSettingsAsync(false).pipe(
      map((appSettings) => {
        const feverSeverities = appSettings.severities?.find(
          (x) => x.ctcaeCode === this.data?.ctcaeCode
        );
        return feverSeverities;
      })
    );
  }
  //#endregion
}
