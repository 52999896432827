import { Inject, Injectable } from "@angular/core";
import { IChemotherapyAppointmentDetailState } from "./appointment-detail.state";
import { APPOINTMENT_SERVICE, IAppointmentService } from "@mobile-data-access-services";
import { ActivatedRoute } from "@angular/router";
import { ChemotherapyBaseComponentStore } from "../../base";
import { AppointmentContainerCodes } from "@mobile-data-access-enums";

@Injectable()
export class ChemotherapyAppointmentDetailComponentStore extends ChemotherapyBaseComponentStore<IChemotherapyAppointmentDetailState> {

  public constructor(
    @Inject(APPOINTMENT_SERVICE)
    protected readonly _appointmentService: IAppointmentService,
    protected override readonly _activatedRoute: ActivatedRoute,
  ) {
    super(_activatedRoute, {
      id: null,
      basicInfoLoading: false,
      basicInfo: null,
      meetingOverviewLoading: false,
      meetingOverview: null,
      topicsLoading: false,
      topics: null,
      tagsLoading: false,
      tags: null,
      meetingDetail: null,
      meetingDetailLoading: false
    });
  }

  public setup(): void {
    this.load('meetingDetail', (id) => this._appointmentService.getChemoAsync<IChemotherapyAppointmentDetailState['meetingDetail']>(id, AppointmentContainerCodes.MEETING_DETAIL));
    this.load('meetingOverview', (id) => this._appointmentService.getChemoAsync<IChemotherapyAppointmentDetailState['meetingOverview']>(id, AppointmentContainerCodes.MEETING_OVERVIEW));
    this.load('topics', (id) => this._appointmentService.getChemoAsync<IChemotherapyAppointmentDetailState['topics']>(id, AppointmentContainerCodes.TOPIC));
    this.load('tags', (id) => this._appointmentService.getChemoAsync<IChemotherapyAppointmentDetailState['tags']>(id, AppointmentContainerCodes.TAGS));
  }
}
