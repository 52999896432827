import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { TrackerSectionComponent } from './tracker-section.component';
import { SwiperModule } from 'swiper/angular';
import { TrackerEffectCardModule } from '../../../cards';

const CONTAINERS = [TrackerSectionComponent];
const MODULES = [
  CommonModule,
  IconModule,
  SwiperModule,
  TrackerEffectCardModule,
];

@NgModule({
  declarations: [...CONTAINERS],
  exports: [...CONTAINERS],
  imports: [...MODULES],
})
export class TrackerSectionModule {}
