import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview.component';
import { CommonModule } from '@angular/common';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { UnescapePipeModule } from '@shared-pipes';
import {TranslocoModule} from "@ngneat/transloco";
import { SkeletonModule, StrapiModule } from "../../../shared";

const MODULES = [CommonModule, ToTrustedHtmlPipeModule, UnescapePipeModule];
@NgModule({
  declarations: [OverviewComponent],
  exports: [OverviewComponent],
  imports: [MODULES, TranslocoModule, StrapiModule, SkeletonModule]
})
export class OverviewModule {}
