import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import Swiper, { FreeMode, SwiperOptions } from "swiper";
import { ISymptomCard } from "@mobile-data-access-interfaces";

Swiper.use([FreeMode]);

@Component({
  selector: 'ncis-symptom-horizontal',
  templateUrl: './symptom-horizontal.component.html',
  styleUrls: ['./symptom-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymptomHorizontalComponent {
  //#region Properties
  public readonly swiperOptions: SwiperOptions = {
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 16,
    freeMode: true,
  };

  @Output()
  public readonly selectedSymptomEvent: EventEmitter<ISymptomCard> = new EventEmitter<ISymptomCard>();

  @Input()
  public data: ISymptomCard[] | undefined;

  @Input()
  public loading = false;

  //#endregion
}
