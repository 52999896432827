import { NgModule } from '@angular/core';
import { RecommendationsComponent } from './recommendations.component';
import { SwiperModule } from 'swiper/angular';
import { CommonModule } from '@angular/common';
import { RecommendationModule } from '../recommendation/recommendation.module';

@NgModule({
  declarations: [RecommendationsComponent],
  imports: [SwiperModule, CommonModule, RecommendationModule],
  exports: [RecommendationsComponent],
})
export class RecommendationsModule {}
