import { createReducer, on } from '@ngrx/store';
import {DrugDetailControlCodes} from '@mobile-data-access-enums';
import {IDrugDetailState} from './drug-detail.state';
import {DrugDetailActions} from './drug-detail.action';

export const drugDetailStoreKey = 'drugDetailStoreKey';

//#region Initial states

export const drugDetailState: IDrugDetailState = {
  [DrugDetailControlCodes.ARTICLES]: [],
  [DrugDetailControlCodes.TAGS]: [],
  [DrugDetailControlCodes.RELATED_TOPICS]: [],
  controlCodeToLoadingStatus: {}
};

//#endregion

//#region Reducers

export const drugDetailReducer = createReducer(
    drugDetailState,
  on(DrugDetailActions.changeBasicInfo, (state, basicInfo) => ({
    ...state,
    [DrugDetailControlCodes.HEADER_DRUG]: basicInfo,
  })),
  on(DrugDetailActions.changeOverview, (state, overview) => ({
    ...state,
    [DrugDetailControlCodes.OVERVIEW]: overview,
  })),
    on(DrugDetailActions.changeArticles, (state, {data}) => ({
      ...state,
      [DrugDetailControlCodes.ARTICLES]: data,
    })),
  on(DrugDetailActions.changeRelatedTopics, (state, { items }) => ({
    ...state,
    [DrugDetailControlCodes.RELATED_TOPICS]: items,
  })),
  on(DrugDetailActions.changeSideEffect, (state, action) => ({
    ...state,
    [DrugDetailControlCodes.SIDE_EFFECT]: action,
  })),
  on(DrugDetailActions.changeTags, (state, { items }) => ({
    ...state,
    [DrugDetailControlCodes.TAGS]: items,
  })),
  on(DrugDetailActions.changeLoadingStatus, (state, { code, loading }) => {
    return {
      ...state,
      controlCodeToLoadingStatus: {
        ...state.controlCodeToLoadingStatus,
        [code]: loading,
      },
    };
  })
);

//#endregion
