import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';

@Component({
  selector: 'ncis-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @Output()
  public readonly backClick = new EventEmitter<unknown>();

  @Output()
  public readonly bookmarkClick = new EventEmitter<unknown>();

  @Input()
  public alreadyBookmark = false;

  @Input()
  public showBookmark = false;

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;
}
