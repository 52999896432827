import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';

export class ForbiddenNavigationRequest extends NavigateToScreenRequest<any> {
  //#region Constructor

  public constructor() {
    super(ScreenCodes.FORBIDDEN);
  }

  //#endregion
}
