import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UnderMaintenanceInterceptor } from './under-maintenance.interceptor';
import { SmartNavigatorModule } from '@ui-tool/core';

@NgModule({
  imports: [SmartNavigatorModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: UnderMaintenanceInterceptor,
    },
  ],
})
export class UnderMaintenanceModule {}
