export enum CommonIconGeneralEnum {
  BOOKMARK_ACTIVE = 'bookmark-active',
  BOOKMARK_INACTIVE = 'bookmark-inactive',
  HAMBURGER_MENU = 'hamburger-menu',
  LINK_ACTIVE = 'link-active',
  LINK_INACTIVE = 'link-inactive',
  MANAGE_MEDICATION = 'manage-medication',
  MEDICATION_ACTIVE = 'medication-active',
  MEDICATION_INACTIVE = 'medication-inactive',
  NAVIGATOR_ACTIVE = 'navigator-active',
  NAVIGATOR_INACTIVE = 'navigator-inactive',
  OPTIONS = 'options',
  PLAY = 'play',
  SEARCH = 'search',
  WARNING = 'warning',
  PHONE = 'phone',
  CLOSE = 'close',
  POSITION = 'position',
  PLUS = 'plus',
  EXTERNAL_ARROW = 'external-arrow',
  RETURN_ARROW = 'return-arrow',
  LEFT_ARROW = 'left-arrow',
  RIGHT_ARROW = 'right-arrow',
  RIGHT_ARROW_BOLD = 'right-arrow-bold',
  CATEGORY_ARROW = 'category-arrow',
  STAR = 'star',
  TRACKER_ACTIVE = 'tracker-active',
  TRACKER_INACTIVE = 'tracker-inactive',
  QUOTE = 'quote',
  STATISTIC = 'statistic',
  BULLET_POINT = 'bullet-point',
  HEART = 'heart',
  SEPARATOR = 'separator',
  SYMPTOM_COLLAPSE = 'symptom-collapse',
}

export enum CommonIconMedicationEnum {
  CAPSULE = 'capsule',
  CREAM = 'cream',
  EYEDROPS = 'eyedrops',
  INHALER = 'inhaler',
  PILL_CIRCLE = 'pill-circle',
  PILL_OVAL = 'pill-oval',
  POWDER = 'powder',
  SYRINGE = 'syringe',
}

export enum CommonIconFolderEnum {
  GENERAL = 'general',
  MEDICATION = 'medication',
}
