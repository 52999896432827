import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Pipe({
  name: 'toPlainText',
})
export class ToPlainTextPipe implements PipeTransform {
  //#region Constructor

  public constructor(
    @Inject(DOCUMENT)
    private readonly __document: Document
  ) {}

  //#endregion

  //#region Methods

  public transform(value: string | undefined, ...args: any[]): string {
    if (!value || !value.length) {
      return '';
    }

    return this.__toPlainText(value);
  }

  //#endregion

  //#region Internal methods

  private __toPlainText(html: string): string {
    //remove code brakes and tabs
    html = html.replace(/\n/g, '');
    html = html.replace(/\t/g, '');

    //keep html brakes and tabs
    html = html.replace(/<\/td>/g, '\t');
    html = html.replace(/<\/table>/g, '\n');
    html = html.replace(/<\/tr>/g, '\n');
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<\/h1>/g, '\n');
    html = html.replace(/<\/h2>/g, '\n');
    html = html.replace(/<\/h3>/g, '\n');
    html = html.replace(/<\/h4>/g, '\n');
    html = html.replace(/<\/h5>/g, '\n');
    html = html.replace(/<br>/g, '\n');
    html = html.replace(/<br( )*\/>/g, '\n');

    //parse html into text
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + html,
      'text/html'
    );
    return dom.body.textContent || '';
  }

  //#endregion
}
