import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';
import {SymptomsQueryParams} from '../query-params';

export class SymptomsNavigationRequest extends NavigateToScreenRequest<void> {
  //#region Constructor

  public constructor(id: string, type: string) {
    super(ScreenCodes.SYMPTOMS, void 0, {
      queryParams: new SymptomsQueryParams(type, id),
    });
  }

  //#endregion
}
