import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { ITreatmentSymptomsService } from './treatment-symptoms.interface';
import { IApiResult } from '@shared-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IArticle, ISymptomCard, ITag } from '@mobile-data-access-interfaces';
import {
  ArticleCategories,
  SymptomsArticleTypes,
} from '@mobile-data-access-enums';

@Injectable()
export class TreatmentSymptomsService implements ITreatmentSymptomsService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endPointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getSymptomsByTreatmentIdAsync(id: string): Observable<ISymptomCard[]> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/treatment_symptoms/symptoms`;
        const httpParams: Record<string, string> = {};
        httpParams['treatmentId'] = id;

        return this._httpClient
          .get<IApiResult<ISymptomCard[]>>(apiUrl, {
            params: httpParams,
          })
          .pipe(map(({ data }) => data));
      })
    );
  }

  public getArticlesByTreatmentIdAsync(
    treatmentId: string
  ): Observable<IArticle[]> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/treatment_symptoms/articles`;
        const httpParams: Record<string, string> = {};
        httpParams['treatmentId'] = treatmentId;

        return this._httpClient
          .get<IApiResult<IArticle[]>>(apiUrl, {
            params: httpParams,
          })
          .pipe(map(({ data }) => data));
      }),
      map((articles) => {
        for (const article of articles) {
          switch (article.type) {
            case SymptomsArticleTypes.GENERAL_PRECAUTION:
              article.category =
                ArticleCategories.SYMPTOMS_ARTICLE_GENERAL_PRECAUTION;
              break;

            case SymptomsArticleTypes.RARE_SIDE_EFFECT:
              article.category =
                ArticleCategories.SYMPTOMS_ARTICLE_RARE_SIDE_EFFECT;
              break;

            case SymptomsArticleTypes.OCCASIONAL_SIDE_EFFECT:
              article.category =
                ArticleCategories.SYMPTOMS_ARTICLE_OCCASIONAL_SIDE_EFFECT;
              break;

            case SymptomsArticleTypes.LONG_TERM_SIDE_EFFECT:
              article.category =
                ArticleCategories.SYMPTOMS_ARTICLE_LONG_TERM_SIDE_EFFECT;
              break;
            case SymptomsArticleTypes.IMMUNO:
              article.category =
                ArticleCategories.SYMPTOMS_ARTICLE_IMMUNO_SIDE_EFFECT;
              break;
          }
        }
        return articles;
      })
    );
  }

  public getTagsByTreatmentIdAsync(treatmentId: string): Observable<ITag[]> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/treatment_symptoms/tags`;
        const httpParams: Record<string, string> = {};
        httpParams['treatmentId'] = treatmentId;

        return this._httpClient
          .get<IApiResult<ITag[]>>(apiUrl, {
            params: httpParams,
          })
          .pipe(map(({ data }) => data));
      })
    );
  }

  //#endregion

  //#region Internal methods

  //#endregion
}
