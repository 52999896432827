import { NgModule } from '@angular/core';
import { MEDICATION_SERVICE } from './medication-service.token';
import { MedicationService } from './medication.service';

@NgModule({
  providers: [
    {
      provide: MEDICATION_SERVICE,
      useClass: MedicationService,
    },
  ],
})
export class MedicationServiceModule {}
