<swiper *ngIf="items?.length" [config]="option" class="recommendations">
  <ng-container *ngFor="let item of items">
    <ng-template swiperSlide>
      <ncis-recommendation
        [style.height.px]="245"
        [style.width.px]="340"
        [imageUrl]="item.imageUrl"
        [title]="item.author"
        [description]="item.description"
      >
      </ncis-recommendation>
    </ng-template>
  </ng-container>
</swiper>
