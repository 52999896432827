import { AnimationController } from "@ionic/angular";

const animationCtrl = new AnimationController();

export const customAnimation = (_: HTMLElement, opts: any) => {
  // create root transition
  let rootTransition = animationCtrl
    .create();

  const allowedNames = ["ncis-chemotherapy", "ncis-drug-detail", "ncis-symptom-detail"];

  if (opts.direction === "forward" && allowedNames.includes(opts.enteringEl.localName)) {
    rootTransition = rootTransition.delay(300).duration(500);
    const enterTransition = animationCtrl.create().addElement(opts.enteringEl);
    const exitTransition = animationCtrl.create().addElement(opts.leavingEl);
    enterTransition.fromTo("opacity", "1", "1");
    exitTransition.fromTo("opacity", "1", "0");
    enterTransition.fromTo("transform", "translateY(100%)", "translateY(0%)");
    rootTransition.addAnimation([enterTransition, exitTransition]);
  }

  return rootTransition;
};
