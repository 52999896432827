import { NgModule } from '@angular/core';
import { PLAN_SERVICE } from './plan-service.token';
import { PlanService } from './plan.service';

@NgModule({
  providers: [
    {
      provide: PLAN_SERVICE,
      useClass: PlanService,
    },
  ],
})
export class PlanServiceModule {}
