import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';

export class SymptomDetailNavigationRequest extends NavigateToScreenRequest<
  Record<string, string>
> {
  //#region Constructor

  constructor(symptomId: string) {
    super(ScreenCodes.SYMPTOM_DETAIL, {
      id: symptomId,
    });
  }

  //#endregion
}
