import { NgModule } from '@angular/core';
import { APPOINTMENT_SERVICE } from './appointment-service.token';
import { AppointmentService } from './appointment.service';
import { ContainerServiceModule } from '../container';

@NgModule({
  providers: [
    {
      provide: APPOINTMENT_SERVICE,
      useClass: AppointmentService,
    },
  ],
  imports: [ContainerServiceModule],
})
export class AppointmentServiceModule {}
