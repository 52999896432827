export enum ChemotherapyAdministrations {
  ORAL_BD = 'BD',
  ORAL_OD = 'OD',
  ORAL_TID = 'TID',
  INJECTION_SQ = 'SQ',
  INJECTION_IM = 'IM',
  INJECTION_IV = 'IV',
  INJECTION_IC = 'IC',
  REST = 'N/A',
}
