import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from '@angular/core';
import {
  ITreatmentCycleTime,
  ITreatmentScheduleDay,
  ITreatmentScheduleDayPart,
  ITreatmentScheduleOptions,
} from '@mobile-data-access-interfaces';
import { catchError, debounceTime, of, Subject, Subscription } from 'rxjs';
import { ResizeSensor } from 'css-element-queries';
import Konva from 'konva';

@Component({
  selector: 'ncis-treatment-schedule',
  templateUrl: 'treatment-schedule.component.html',
  styleUrls: ['treatment-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentScheduleComponent implements AfterViewInit {
  //#region Properties

  protected readonly _subscription = new Subscription();

  // Line width in pixel
  private __options?: ITreatmentScheduleOptions;

  // Size of a block.
  private __blockSize?: number;

  private __stage?: Konva.Stage;

  // Size of konva container.
  private _size?: { width: number; height: number };

  private __resizeSensor?: ResizeSensor;

  // Subject to draw canvas.
  private __draw$: Subject<void>;

  //#endregion

  //#region Accessors

  @Input()
  public set options(options: ITreatmentScheduleOptions | undefined) {
    this.__options = options;
  }

  public get options(): ITreatmentScheduleOptions | undefined {
    return this.__options;
  }

  public get blocks(): number {
    if (!this.__options || !this.__options.blocks) {
      return 7;
    }

    return this.__options.blocks;
  }

  public get gap(): number {
    if (!this.__options || !this.__options.gap) {
      return 4;
    }

    return this.__options.gap;
  }

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _ngZone: NgZone,
    protected readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    this.__draw$ = new Subject<void>();
  }

  //#endregion

  //#region Life cycle hooks

  public ngAfterViewInit(): void {
    const drawSubscription = this.__draw$
      .pipe(
        debounceTime(250),
        catchError(() => of(void 0))
      )
      .subscribe(() => {
        this._ngZone.runOutsideAngular(() => {
          if (!this.options || !this.__blockSize) {
            return;
          }

          this.__stage?.clearCache();
          this.__stage?.destroyChildren();

          // Get the treatment day.
          const treatments = this._getTreatmentDays(this.options.lines);

          // Calculate the number of rows.
          let rows = Math.floor(this.options.cycle / this.options.blocks);
          if (this.options.cycle % this.options.blocks !== 0) {
            rows += 1;
          }

          const drawingContainer = (
            this._elementRef.nativeElement as HTMLElement
          ).querySelector('.treatment-schedule')!;
          const height = this.__blockSize * rows + this.gap * (rows - 1) + 1;
          this.__stage = new Konva.Stage({
            container: drawingContainer as HTMLDivElement,
            width: drawingContainer.clientWidth,
            height: height,
          });

          // Create a layer to place controls onto.
          const layer = new Konva.Layer();
          const group = new Konva.Group();

          let day = 1;
          for (let row = 0; row < rows; row++) {
            const y = row * this.__blockSize + row * this.gap;
            for (let column = 0; column < this.blocks; column++) {
              const x = column * this.__blockSize + column * this.gap;
              const treatment = treatments.find((m) => m.day === day);

              // Default rectangle.
              if (!treatment || treatment.maxLayer < 1) {
                const rectangle = new Konva.Rect({
                  width: this.__blockSize,
                  height: this.__blockSize,
                  x,
                  y,
                  fill: this.__options?.color,
                  cornerRadius: this.__options?.blockRadius,
                });

                group.add(rectangle);
              } else {
                const baseHeight = this.__blockSize / treatment.parts.length;
                for (const [index, part] of treatment.parts.entries()) {
                  const xPart = x;
                  const yPart = y + baseHeight * index;
                  const childRectangle = new Konva.Rect({
                    fill: part.color,
                    x: xPart,
                    y: yPart,
                    width: this.__blockSize,
                    height: baseHeight,
                  });

                  const corners = [0, 0, 0, 0];
                  if (index === treatment.parts.length - 1) {
                    corners[2] = this.__options?.blockRadius || 0;
                    corners[3] = this.__options?.blockRadius || 0;
                  }

                  if (index === 0) {
                    corners[0] = this.__options?.blockRadius || 0;
                    corners[1] = this.__options?.blockRadius || 0;
                  }

                  childRectangle.setAttr('cornerRadius', corners);
                  group.add(childRectangle);
                }
              }

              day++;
            }
          }

          if (this.options?.alignRight) {
            const groupWidth =
              this.options.blocks * this.options.blockSize! +
              this.options.gap! * (this.options.blocks - 1);
            const xGroup = drawingContainer.clientWidth - groupWidth;
            group.setAttr('x', xGroup);
          }
          layer.add(group);

          this.__stage.add(layer);
        });
      });
    this._subscription.add(drawSubscription);

    // Listen to the wrapper size change.
    this.__resizeSensor = new ResizeSensor(
      this._elementRef.nativeElement,
      (size) => {
        const blockSize =
          (size.width - (this.blocks - 1) * this.gap) / this.blocks;
        this.__blockSize = Math.min(
          blockSize,
          this.__options?.blockSize || blockSize
        );
        this._size = size;
        this.__draw$.next();
      }
    );
  }

  //#endregion

  //#region Internal methods

  protected _getTreatmentDays(
    lines: ITreatmentCycleTime[]
  ): ITreatmentScheduleDay[] {
    const treatmentDays: ITreatmentScheduleDay[] = [];
    for (const line of lines) {
      for (let dayIndex = 0; dayIndex < (line.days || []).length; dayIndex++) {
        const value = line.days[dayIndex];
        if (value == null) {
          continue;
        }

        const actualDay = dayIndex + 1;
        let treatmentDay = treatmentDays.find((x) => x.day === actualDay);
        if (!treatmentDay) {
          treatmentDay = {
            day: actualDay,
            parts: [],
            maxLayer: 0,
          };
          treatmentDays.push(treatmentDay);
        }

        const treatmentDayPart: ITreatmentScheduleDayPart = {
          color: line.color,
          layer: treatmentDay.parts.length,
        };
        treatmentDay.parts.push(treatmentDayPart);
        treatmentDay.maxLayer = treatmentDay.parts.length;
      }
    }

    return treatmentDays;
  }

  //#endregion
}
