export enum ScreenCodes {
  DASHBOARD = 'level1',
  ONBOARDING = 'onboarding',
  TRACKER = 'tracker',
  HOME = 'home',
  TRACKER_RECORD = 'tracker-record',
  TRACKER_WARNING = 'tracker-warning',
  PAXMAN = 'paxman',
  PAXMAN_MEMBER = 'paxman_member',
  PAXMAN_INTRODUCTION = 'paxman_introduction',

  CHEMOTHERAPY = 'level2',
  CHEMOTHERAPY_DIAGNOSIS = 'diagnosis_detail',
  CHEMOTHERAPY_PROCEDURE = 'procedure',
  CHEMOTHERAPY_APPOINTMENT = 'appointment_detail',
  CHEMOTHERAPY_RADIATION = 'chemotherapy-radiation',
  MEDICATION = 'medication',
  DRUG_DETAIL = 'level3',
  SYMPTOMS = 'treatment-symptoms',
  SYMPTOM = 'symptom',
  SYMPTOM_DETAIL = 'symptom-detail',
  SESSION_EXPIRED = 'expired',
  RESOURCE_NOT_FOUND = 'resource-not-found',
  DETAILED_ARTICLE = 'detailed-article',
  OAUTH = 'oauth-callback',
  BOOKMARKS = 'bookmarks',
  MAINTENANCE = 'maintenance',
  FORBIDDEN = 'forbidden',
}
