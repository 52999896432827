import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[description]',
})
export class ScheduleCardDescriptionDirective {
  //#region Constructor

  public constructor(public readonly templateRef: TemplateRef<string>) {}

  //#endregion
}
