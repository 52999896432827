import { INavigatorService } from './navigator-service.interface';
import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { INavigator, NavigatorMapping } from '@mobile-data-access-interfaces';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';
import { format } from 'date-fns';

@Injectable()
export class NavigatorService implements INavigatorService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public loadAsync(): Observable<INavigator<keyof NavigatorMapping>[]> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const uri = `${endpoint}/navigator/load`;
        const queryStringParams: Record<string, string> = {};
        queryStringParams['timezone'] = timezone;
        queryStringParams['deviceDate'] = format(new Date(), 'yyyy-MM-dd');
        const queryString = Object.keys(queryStringParams)
          .map((key) => `${key}=${encodeURIComponent(queryStringParams[key])}`)
          .join('&');
        const fullUrl = [`${uri}`, queryString].join('?');
        return this._httpClient
          .get<IApiResult<INavigator<keyof NavigatorMapping>[]>>(fullUrl)
          .pipe(map((res) => res.data));
      })
    );
  }
  //#endregion
}
