<div
  class="banner-card"
  [class.clickable]="clickable"
  [class.cursor-pointer]="clickable"
>
  <ng-container *ngIf="!loading; else skeletonTemplate">
    <div [aspectRatio]="4 / 3" class="banner-card__image">
      <img *ngIf="data.imageUrl" [alt]="data.title" [src]="data.imageUrl" />
    </div>
    <ng-container *ngIf="data.category || data.title || data.description">
      <div class="banner-card__information">
        <ncis-category
          *ngIf="data.category"
          [title]="data.category"
        ></ncis-category>
        <h3 *ngIf="data.title">{{ data.title }}</h3>
        <p [innerHTML]="(data.navDescription || data.description || 'N/A') | toPlainText" class="line-clamp-3"></p>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #skeletonTemplate>
    <div [aspectRatio]="4 / 3" class="banner-card__image"></div>
    <div class="banner-card__information">
      <ncis-skeleton class="w-16 h-6"></ncis-skeleton>
      <ncis-skeleton class="w-[100px] h-[22px]"></ncis-skeleton>
      <ncis-skeleton class="w-[300px] h-[60px]"></ncis-skeleton>
    </div>
  </ng-template>
</div>
