import {Inject, Injectable} from "@angular/core";
import {IPaxmanComponentState} from "./paxman.state";
import {
    ARTICLE_SERVICE,
    BOOKMARK_SERVICE,
    IArticleService,
    IBookmarkService,
    IPaxmanService,
    IUiService,
    IUserService,
    PAXMAN_SERVICE,
    UI_SERVICE,
    USER_SERVICE
} from "@mobile-data-access-services";
import {ActivatedRoute} from "@angular/router";
import {ChemotherapyBaseComponentStore} from "../base";
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    EMPTY,
    exhaustMap, finalize,
    forkJoin,
    map,
    mergeMap,
    of,
    pipe,
    tap
} from "rxjs";
import {IArticle, IPaxmanTimeLineStage} from "@mobile-data-access-interfaces";
import {PaxmanMemberNavigationRequest} from "@mobile-data-access-models";
import {ISmartNavigatorService, ISpinnerService, SMART_NAVIGATOR_SERVICE, SPINNER_SERVICE} from "@ui-tool/core";
import {Store} from "@ngrx/store";
import { IRootState, PaxmanActions, UserActions } from "../../../features";
import {ArticleCategories, SpinnerContainerIds} from "@mobile-data-access-enums";

@Injectable()
export class PaxmanComponentStore extends ChemotherapyBaseComponentStore<IPaxmanComponentState> {

    public readonly joinPaxman = this.effect<never>(
        pipe(
            exhaustMap(() => {

                return this._uiService.showToastAsync({
                    duration: 5000,
                    color: 'primary',
                    message: 'ACTIVATING_PAXMAN',
                    position: 'top'
                }, 'PAXMAN_PAGE').pipe(
                    mergeMap(toastId => {
                        const displaySpinnerRequestId = this._spinnerService.displaySpinner(SpinnerContainerIds.APPLICATION);
                        return this._paxManService.activationAsync().pipe(
                            mergeMap(() => {
                                return this._userService.getProfileAsync().pipe(
                                    tap(profile => this._store.dispatch(UserActions.saveProfile({data: profile}))),
                                    tap(() => this._store.dispatch(PaxmanActions.saveActivation({ data: true })))
                            );
                            }),
                            mergeMap(() => {
                                this._uiService.dismissToast(toastId);
                                return this._uiService.showToastAsync({
                                    duration: 5000,
                                    color: 'success',
                                    message: 'ACTIVATED_PAXMAN_SUCCESSFULLY',
                                    position: 'top'
                                }, 'PAXMAN_PAGE');
                            }),
                            mergeMap(() => {
                                this._uiService.dismissToast(toastId);
                                const navigationRequest = new PaxmanMemberNavigationRequest();
                                return this._navigationRequest.navigateToScreenAsync(navigationRequest);
                            }),
                            catchError(exception => {
                                console.error(exception);
                                return this._uiService.showToastAsync({
                                    duration: 5000,
                                    color: 'danger',
                                    message: 'SOMETHING_WRONG_WHILE_ACTIVATING_PAXMAN',
                                    position: 'top'
                                }, 'PAXMAN_PAGE');
                            }),
                            finalize(() => this._spinnerService.deleteSpinner(SpinnerContainerIds.APPLICATION, displaySpinnerRequestId))
                        )
                    })
                )
            })
        )
    );

    //#endregion Constructor
    //#region Constructor
    protected readonly _articleIds$ = new BehaviorSubject<string[]>([]);

    public constructor(
        @Inject(PAXMAN_SERVICE)
        protected readonly _paxManService: IPaxmanService,
        @Inject(SPINNER_SERVICE)
        protected readonly _spinnerService: ISpinnerService,
        @Inject(ARTICLE_SERVICE)
        protected readonly _articleService: IArticleService,
        @Inject(USER_SERVICE)
        protected readonly _userService: IUserService,
        @Inject(SMART_NAVIGATOR_SERVICE)
        protected readonly _navigationRequest: ISmartNavigatorService,
        @Inject(BOOKMARK_SERVICE)
        protected readonly _bookmarkService: IBookmarkService,
        @Inject(UI_SERVICE)
        protected readonly _uiService: IUiService,
        protected override readonly _activatedRoute: ActivatedRoute,
        protected readonly _store: Store<IRootState>
    ) {
        super(_activatedRoute, {
            id: null,
            timelineArticlesLoading: false,
            timelineArticles: null,
            hairRetention: null,
            hairRetentionLoading: false,
            timelineSummary: null,
            timelineSummaryLoading: false,
            stories: null,
            storiesLoading: false,
            activeStage: 0
        });
    }

    public setup(): void {
    }

    public initIntroduction(): void {
        this.load("stories", () => this._paxManService.getPatientStoriesAsync());
        // this.load('hairRetention', () => this._paxManService.getHairRetentionAsync());
    }

    public initMember(): void {
        this.load("timelineSummary", () =>
            this._paxManService.getTimelineAsync()
                .pipe(
                    mergeMap((data) => {
                        return forkJoin(
                            data.stages.map((stage) => {
                                const ids = stage.articles.map((e) => e.id);
                                if (!ids.length) return of(stage);
                                return this._articleService.getViewedListByIdsAndCategoryAsync(ids, ArticleCategories.PAXMAN_ARTICLE)
                                    .pipe(
                                        map((res) => {
                                            stage.articles = stage.articles.map((ar) => ({
                                                ...ar,
                                                viewed: res.some((e) => e.itemId == ar.id && e.viewed)
                                            }));
                                            return stage;
                                        }),
                                        tap(() => this.updateProperty("timelineSummaryLoading", false)),
                                        catchError(() => {
                                            this.updateProperty("timelineSummaryLoading", false);
                                            return of(stage);
                                        })
                                    );
                            })
                        ).pipe(
                            map((stages: IPaxmanTimeLineStage[]) => {
                                this._articleIds$.next(
                                    stages
                                        .reduce((acc, cur) => acc.concat(cur.articles), [] as IArticle[])
                                        .map((item) => item.id)
                                );
                                return {
                                    stages
                                };
                            }),
                            tap(() => this.updateProperty("timelineSummaryLoading", false)),
                            catchError(() => {
                                this.updateProperty("timelineSummaryLoading", false);
                                return of(data);
                            })
                        );
                    })
                )
        );
        this.load("timelineArticles", () => combineLatest([
            this.select((state) => state.activeStage),
            this.select((state) => state.timelineSummary)
        ]).pipe(
            mergeMap(([activeStage, timelineSummary]) => {
                if (activeStage == null || timelineSummary == null) {
                    this.updateProperty("timelineArticlesLoading", false);
                    return of(null);
                }
                this.updateProperty("timelineArticlesLoading", true);
                const stage = timelineSummary.stages[activeStage];
                if (!stage) {
                    this.updateProperty("timelineArticlesLoading", false);
                    return of(null);
                }
                return of(stage.articles).pipe(
                    map(() => stage.articles.map((item) => ({
                        title: item.title,
                        author: item.author,
                        imageUrl: item.imageUrl,
                        id: item.id,
                        category: item.category,
                        hashTag: item.hashTags.join(" ")
                    } as IArticle))),
                    tap((e) => this.updateProperty("timelineArticlesLoading", false))
                );
            }),
            catchError((err) => {
                console.error(err);
                return EMPTY;
            })
        ));
    }

    //#endregion

}
