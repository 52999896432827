<div class="overview">
  <ng-container *ngIf="!loading; else loadingTemplate">
    <ng-container *ngIf="data">
      <ng-container *ngIf="strapiRenderTitle; else normalRenderTitle">
        <ncis-strapi *ngIf="data.title" [content]="data.title" [noPadding]="true"></ncis-strapi>
      </ng-container>
      <ng-template #normalRenderTitle>
        <h3 *ngIf="data.title || title">{{ data.title || title || 'N/A' }}</h3>
      </ng-template>
      <ng-container *ngIf="data.description">
        <ng-container *ngIf="strapiRenderDescription; else normalRenderDescription">
          <ncis-strapi *ngIf="data.description" [content]="data.description" [noPadding]="true"></ncis-strapi>
        </ng-container>
        <ng-template #normalRenderDescription>
          <p
            [innerHTML]="data.description | unescape | toTrustedHtml"
          ></p>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loadingTemplate>
    <ncis-skeleton class="h-[22px] w-1/2"></ncis-skeleton>
    <ncis-skeleton class="h-[66px] w-full"></ncis-skeleton>
  </ng-template>
</div>
