import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFeatureFlagState } from './feature-flag.state';
import { featureFlagStoreKey } from './feature-flag.reducer';

export class FeatureFlagSelector {
  //#region Properties

  public static readonly featureFlags = createSelector(
    createFeatureSelector<IFeatureFlagState>(featureFlagStoreKey),
    (state) => state.featureFlags
  );

  //#endregion
}
