import { Inject, Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IFeatureFlagService } from './feature-flag-service.interface';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { IApiResult } from '@shared-interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class FeatureFlagService implements IFeatureFlagService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getAsync(): Observable<{ code: string; enabled: boolean }[]> {
    return this._endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const apiUrl = `${endpoint}/v1/feature-flags`;
        return this._httpClient.get<
          IApiResult<{ code: string; enabled: boolean }[]>
        >(apiUrl);
      }),
      map(({ data }) => data)
    );
  }

  //#endregion
}
