<ng-container [ngSwitch]="loading">

  <ng-container *ngSwitchCase="true">
    <div class="article-card">
      <div class="article-card__image">
        <ncis-skeleton class="w-[100%] h-[100%]"></ncis-skeleton>
      </div>

      <div class="article-card__detail loading">
        <h2 class="article-card__detail__title line-clamp-3">
          <ncis-skeleton class="w-[100%] h-[1rem]"></ncis-skeleton>
        </h2>

        <div class="article-card__detail__description">
          <div class="article-card__detail__description__author">
            <ncis-skeleton class="w-[100%] h-[1rem]"></ncis-skeleton>
          </div>
          <span class="article-card__detail__description__hashtags"
          >
            <ncis-skeleton class="w-[100%] h-[1rem]"></ncis-skeleton>
          </span
          >
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="data">
      <div (click)="visited.emit()" class="article-card">
        <span *ngIf="viewed" class="article-card__viewed">{{'VIEWED' | transloco}}</span>
        <div class="article-card__image">
          <img [attr.data-test-id]="'imageUrl'" *ngIf="!loading" [alt]="data.title" [src]="data.imageUrl" />
        </div>

        <div *ngIf="bookmarked" class="article-card__bookmark">
          <ncis-common-icon
                  [folder]="IconFolders.GENERAL"
                  [name]="Icons.BOOKMARK_ACTIVE"
                  class="h-[26px] text-ncis-neutral-800"
          ></ncis-common-icon>
        </div>

        <div class="article-card__detail">
          <h2 [attr.data-test-id]="'article-title'" class="article-card__detail__title line-clamp-3">
            {{ data.title || 'N/A' }}
          </h2>

          <div class="article-card__detail__description">
            <div [attr.data-test-id]="'author'" class="article-card__detail__description__author">
              <b>By</b> <span>{{ data.author || 'N/A' }}</span>
            </div>
            <span [attr.data-test-id]="'hashTag'" class="article-card__detail__description__hashtags"
            >{{ data.hashTag || 'N/A' }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>


