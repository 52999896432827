import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: 'img[hiddenImageOnError]',
})
export class HiddenImageOnErrorDirective implements AfterViewInit {
  //#region Properties

  @Output()
  public readonly hasError: EventEmitter<never> = new EventEmitter<never>();

  @Output()
  public readonly hasValue: EventEmitter<[number, number]> = new EventEmitter<
    [number, number]
  >();

  //#endregion

  //#region Constructor
  public constructor(
    protected readonly _elementRef: ElementRef<HTMLImageElement>,
    protected readonly _viewContainerRef: ViewContainerRef
  ) {}

  //#endregion

  //#region Life cycle hooks

  public ngAfterViewInit(): void {
    this._elementRef.nativeElement.onload = (e) => {
      const { offsetHeight, offsetWidth } = this._elementRef.nativeElement;
      this.hasValue.emit([offsetWidth, offsetHeight]);
    };

    this._elementRef.nativeElement.onerror = () => {
      this._elementRef.nativeElement.hidden = true;
      this.hasError.emit();
    };
  }

  //#endregion
}
