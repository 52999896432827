import { NgModule } from '@angular/core';
import { SideEffectsComponent } from './side-effects.component';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslocoModule } from '@ngneat/transloco';
import { IconModule } from '@shared-components';
import { SkeletonModule } from '../../../shared';
import { SymptomHorizontalModule } from '../../symptom';

const MODULES = [
  CommonModule,
  InlineSVGModule,
  TranslocoModule,
  SkeletonModule,
  IconModule,
  SymptomHorizontalModule,
];

@NgModule({
  declarations: [SideEffectsComponent],
  exports: [SideEffectsComponent],
  imports: [MODULES],
})
export class SideEffectsModule {}
