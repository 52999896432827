import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ICircularProgressOptions } from '@mobile-data-access-interfaces';
import { ResizeSensor } from 'css-element-queries';
import {
  debounceTime,
  delay,
  of,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'ncis-circular-progress',
  templateUrl: 'circular-progress.component.html',
  styleUrls: ['circular-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircularProgressComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  //#region Properties

  private __options?: ICircularProgressOptions;

  // Host center point.
  private __hostCenter?: { x: number; y: number };

  // Instance that senses the resize operation.
  private __resizeSensor?: ResizeSensor;

  // Mark for changes.
  private __markForCheck$: Subject<void>;

  // Trigger stroke dash offset calculation.
  private __calculateStrokeDashOffset$: Subject<void>;

  // Size of svg drawing board.
  private __size = 0;

  // Radius of circle.
  private __radius = 0;

  private __pathTransform: string | null = null;

  // Diameter of circle.
  // This is for stroke dash array
  private __circlePerimeter: number = 0;

  // Value to calculate percentage.
  private __value = 0;

  // Total value a circle represents.
  private __totalValue = 100;

  // Stroke dash offset.
  private __circleDashOffset = 0;

  protected readonly _subscription = new Subscription();

  //#endregion

  //#region Accessors

  @Input()
  public set options(value: ICircularProgressOptions | undefined) {
    this.__options = value;
  }

  public get options(): ICircularProgressOptions | undefined {
    return this.__options;
  }

  public get hostCenter(): { x: number; y: number } | undefined {
    return this.__hostCenter;
  }

  public get size(): number {
    return this.__size;
  }

  public get radius(): number {
    return this.__radius;
  }

  public get pathTransform(): string | null {
    return this.__pathTransform;
  }

  // Circle diameter.
  public get circlePerimeter(): number {
    return this.__circlePerimeter;
  }

  public get circleStrokeDashoffset(): number {
    return this.__circleDashOffset;
  }

  @Input()
  public set totalValue(value: number) {
    this.__totalValue = value;
    this.__calculateStrokeDashOffset$.next();
  }

  @Input()
  public set value(value: number) {
    this.__value = value;
    this.__calculateStrokeDashOffset$.next();
  }

  //#endregion

  //#region Constructor

  public constructor(
    protected readonly _elementRef: ElementRef,
    protected readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    this.__pathTransform = '';

    this.__markForCheck$ = new Subject<void>();
    this.__calculateStrokeDashOffset$ = new Subject<void>();
  }

  //#endregion

  //#region Life cycle hooks

  public ngOnInit(): void {
    const markForCheckSubscription = this.__markForCheck$
      .pipe(
        switchMap(() => {
          return of(void 0).pipe(
            tap(() => this._changeDetectorRef.markForCheck())
          );
        })
      )
      .subscribe();
    this._subscription.add(markForCheckSubscription);

    const calculateStrokeDashOffsetSubscription =
      this.__calculateStrokeDashOffset$
        .pipe(
          debounceTime(250),
          delay(100),
          switchMap(() => {
            return of(void 0).pipe(
              // delay(250),
              tap(() => {

                if (this.__totalValue === 0 || this.__totalValue === undefined || this.__totalValue === null) {
                  this.__circleDashOffset = this.__circlePerimeter;
                } else {
                  this.__circleDashOffset =
                    (1 - this.__value / this.__totalValue) *
                    this.__circlePerimeter;
                }

                this.__markForCheck$.next();
              })
            );
          })
        )
        .subscribe();
    this._subscription.add(calculateStrokeDashOffsetSubscription);
  }

  public ngAfterViewInit(): void {
    // Watch the host element resize.
    this.__resizeSensor = new ResizeSensor(
      this._elementRef.nativeElement,
      (size) => {
        const dimensionSize =
          this.__options?.preferredDimension === 'height'
            ? size.height
            : size.width;
        const centerPoint = dimensionSize / 2;
        this.__hostCenter = {
          x: centerPoint,
          y: centerPoint,
        };
        this.__size = dimensionSize;
        this.__radius = dimensionSize / 2 - (this.__options?.lineWidth || 0);
        this.__pathTransform = `rotate(-90 ${centerPoint} ${centerPoint})`;
        this.__circlePerimeter = 2 * Math.PI * this.__radius;
        this.__calculateStrokeDashOffset$.next();
        this.__markForCheck$.next();
      }
    );
  }

  public ngOnDestroy(): void {
    this.__resizeSensor?.detach();
    this._subscription?.unsubscribe();
  }

  //#endregion

  //#region Methods

  //#endregion
}
