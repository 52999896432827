import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAuthenticationState } from './authentication.state';
import { authenticationStoreKey } from './authentication.reducer';

const authenticationStoreSelector = createFeatureSelector<IAuthenticationState>(
  authenticationStoreKey
);

const status = createSelector(
  authenticationStoreSelector,
  (state) => state.status
);

export const authenticationSelectors = {
  status,
};
