import { IRegimenCard, ITreatment, ITreatmentWithCycle } from "@mobile-data-access-interfaces";
import { ChemotherapyAdministrations, COLORS } from "@mobile-data-access-enums";
import {IRegimensCard} from '../../interfaces/src/cards/regimens-card.interface';

export class TreatmentResolve {
  //#region Constructor

  public constructor(
    private readonly __data: ITreatment
  ) { }

  //#endregion

  //#region Methods

  public getSchedule(): ITreatmentWithCycle {
    const regimens = this.getRegimens();
    return {
      ...this.__data,
      groups: [...regimens]
    };
  }

  public getRegimens(): IRegimenCard[] {
    const groups: IRegimenCard[] = this.__data.groups
      .filter((e) => e.administration !== ChemotherapyAdministrations.REST && !!e.days);
    const notEmptyDays = Array.from({length: this.__data.cycle}).map((_, i) => {
      const isNotEmpty = groups
        .filter(
          (group) => group.days.some((day) => day == (i + 1).toString())
        ).length;
      return {
        isNotEmpty,
        day: (i + 1).toString()
      }
    });
    const emptyDays = notEmptyDays.filter((item) => !item.isNotEmpty).map((item) => item.day);

    if (emptyDays.length) {
      const rest = this.__data.groups.find((group) => group.administration === ChemotherapyAdministrations.REST);
      groups.push({
        ...(rest || {}),
        days: emptyDays,
        duration: rest?.duration ? rest.duration : 'CHEMOTHERAPY_DETAIL_PAGE.NO_TREATMENT',
        color: rest?.color ? rest.color : COLORS.TREATMENT_REST_AT_HOME,
      } as IRegimenCard);
    }
    return groups;
  }

  public getCycle(): IRegimensCard {
    const regimens = this.getRegimens();
    return {
      daysPerCycle: this.__data.cycle,
      regimens: regimens
    }
  }

  //#endregion
}
