import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IOnboardingState } from './onboarding.state';
import {
  CHEMOTHERAPY_SERVICE,
  IChemotherapyService,
  IOnboardingService,
  IQrCodeService,
  ITermsAndConditionsService,
  IUserService,
  ONBOARDING_SERVICE,
  QR_CODE_SERVICE,
  TERMS_AND_CONDITIONS_SERVICE,
  USER_SERVICE,
} from '@mobile-data-access-services';
import { Store } from '@ngrx/store';
import {
  IRootState,
  PaxmanActions,
  UserActions,
} from '@mobile-data-access-stores';
import {
  catchError,
  delay,
  EMPTY,
  finalize,
  firstValueFrom, from,
  interval,
  mergeMap,
  of,
  pipe,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs';
import {
  IOnboardingFinish,
  IOnboardingQrCodeContext,
} from '@mobile-data-access-interfaces';
import {
  OnboardingBeginSteps,
  OnboardingRequestHeaderAttributes,
  OnboardingStatuses,
  OnboardingSteps,
  SpinnerContainerIds,
  SystemMessageCodes,
  TimeSpans
} from '@mobile-data-access-enums';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import {
  CloseWebviewNativeMethod,
  DashboardNavigationRequest,
} from '@mobile-data-access-models';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';
@Injectable()
export class OnboardingComponentStore extends ComponentStore<IOnboardingState> {
  //#region Constructor
  public override  destroy$ = new Subject<void>();
  public readonly TimeSpans = TimeSpans;
  private __intervalSubscription?: Subscription;

  public ionViewDidEnter(): void {
    this.destroy$ = new Subject<void>();
  }
  //#region Effects
  public readonly finishAsync = this.effect<{
    data: IOnboardingFinish;
    cb: () => void;
  }>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(({ data, cb }) =>
        this._onboardingService.finishAsync(data).pipe(
          delay(400),
          tap(() => {
            sessionStorage.removeItem(
              OnboardingRequestHeaderAttributes.X_PIN_CODE
            );
            this.updateValue('loading', false);
          }),
          switchMap(() => {
            return this._userService.getProfileAsync().pipe(
              tap((profile) => {
                this._store.dispatch(
                  PaxmanActions.saveActivation({
                    data: profile.activatedPaxman,
                  })
                );
                this._store.dispatch(
                  UserActions.saveProfile({ data: profile })
                );
              }),
              switchMap(() => {
                return this._navigationService
                  .navigateToScreenAsync(new DashboardNavigationRequest())
                  .pipe(tap(() => cb()));
              })
            );
          }),
          catchError((err) => {
            console.error(err);
            this.updateValue('loading', false);
            return EMPTY;
          })
        )
      )
    )
  );

  public readonly loadTermsAndConditions = this.effect<never>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(() => {
        return this._termsAndConditionsService
          .getTermsAndConditionsAsync()
          .pipe(
            tap((e) => this.updateValue('termsAndConditions', e)),
            catchError((err) => {
              console.error(err);
              return EMPTY;
            }),
            finalize(() => {
              this.updateValue('loading', false);
            })
          );
      })
    )
  );
  //#endregion

  //#region Constructor
  public readonly acceptTermsAndConditions = this.effect<never>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(() => {
        return this._termsAndConditionsService
          .acceptTermsAndConditionsAsync()
          .pipe(
            mergeMap(() => {
              return this._userService.getProfileAsync();
            }),
            tap((profile) => {
              this._store.dispatch(
                PaxmanActions.saveActivation({ data: profile.activatedPaxman })
              );
              this._store.dispatch(UserActions.saveProfile({ data: profile }));
            }),
            tap(() => {
              this.loadDiseaseAsync(() =>
                this.updateValue(
                  'key',
                  OnboardingBeginSteps.CHOOSE_YOUR_DISEASE
                )
              );
            }),
            catchError((err) => {
              console.error(err);
              return EMPTY;
            }),
            finalize(() => this.updateValue('loading', false))
          );
      })
    )
  );

  //#endregion
  public readonly loadDiseaseAsync = this.effect<() => void>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap((cb) =>
        this._onboardingService.getDiseasesAsync().pipe(
          tap((e) => this.updateValue('items', e)),
          tap(() => cb()),
          delay(400),
          tap(() => this.updateValue('loading', false)),
          catchError((err) => {
            this.updateValue('loading', false);
            console.error(err);
            return EMPTY;
          })
        )
      )
    )
  );

  public readonly beginAsync = this.effect<never>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(() =>
        this._onboardingService.beginAsync().pipe(
          tap(() => {
            this.updateValue('loading', false);
            this.updateValue('key', OnboardingBeginSteps.ENTER_PIN_CODE);
          }),
          catchError((err) => {
            console.error(err);
            this.updateValue('loading', false);
            return EMPTY;
          })
        )
      )
    )
  );
  public readonly unlockAsync = this.effect<{ data: string; cb: () => void }>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(({ data, cb }) =>
        this._onboardingService.unlockAsync(data).pipe(
          tap(() => {
            cb();
            sessionStorage.setItem(
              OnboardingRequestHeaderAttributes.X_PIN_CODE,
              data
            );
            const isOnboarded =
              this.state.data!.status === OnboardingStatuses.ONBOARDED;
            if (isOnboarded) {
              this.updateValue(
                'key',
                OnboardingBeginSteps.CHOOSE_YOUR_TREATMENT
              );
              this.updateValue('loading', false);
            } else {
              if (this.state.acceptTermCondition) {
                this.loadDiseaseAsync(() =>
                  this.updateValue(
                    'key',
                    OnboardingBeginSteps.CHOOSE_YOUR_DISEASE
                  )
                );
              } else {
                this.updateValue(
                  'key',
                  OnboardingBeginSteps.TERMS_AND_CONDITIONS
                );
              }
            }
          }),
          catchError((exception) => {
            this.updateValue('loading', false);
            cb();

            const asyncHandler = async () => {
              if (
                exception?.error?.code === 'TOO_MANY_PIN_CODE_INPUT' &&
                exception?.error?.meta
              ) {
                this.__stopCountDown();
                const waitTime = new Date(exception.error.meta.waitTime);
                let waitTimeInMinutes = Math.ceil((waitTime.getTime() - Date.now()) / TimeSpans.MINUTE_TO_MILLISECOND);
                const updateErrorMessageAsync = async () => {
                  const errorTransloco = await firstValueFrom(
                    this._translocoService.selectTranslate(
                      'MESSAGE_ERROR_PIN_CODE',
                      { nextRetryTime: `${waitTimeInMinutes} minute${waitTimeInMinutes > 1 ? 's' : ''}` },
                      { scope: 'ONBOARDING_PAGE' }
                    )
                  );
                  this.updateValue('invalidPinCodeMessage', `${errorTransloco}`);
                }
                await updateErrorMessageAsync();
                this.__intervalSubscription = interval(TimeSpans.MINUTE_TO_MILLISECOND).pipe(
                  takeWhile(() => waitTimeInMinutes > 0),
                  takeUntil(this.destroy$),
                  mergeMap(() => {
                    waitTimeInMinutes = Math.max(0, Math.ceil((waitTime.getTime() - Date.now()) / TimeSpans.MINUTE_TO_MILLISECOND));
                    return from(updateErrorMessageAsync());
                  })
                ).subscribe();
              } else {
                await this.showError('ERROR_WRONG_PIN', 'ONBOARDING_PAGE');
              }
            }

            return from(asyncHandler())
              .pipe(
                mergeMap(() => EMPTY)
              );
          })
        )
      )
    )
  );
  public readonly validPilCodeAsync = this.effect<{
    pilCode: string;
    cb: (data: string) => void;
  }>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(({ pilCode, cb }) =>
        this._chemotherapyService.getByPilCodeAsync(pilCode).pipe(
          tap((data) => cb(data)),
          catchError(async (err) => {
            await this.showError('ERROR_WRONG_PIL', 'ONBOARDING_PAGE');
            console.error(err);
            return EMPTY;
          }),
          finalize(() => this.updateValue('loading', false))
        )
      )
    )
  );
  public readonly loadItemByDiseaseAsync = this.effect<{
    key: string;
    id: number;
    cb: () => void;
  }>(
    pipe(
      tap(() => this.updateValue('loading', true)),
      switchMap(({ key, id, cb }) =>
        this._onboardingService.getItemByDiseaseAsync(key, [id]).pipe(
          tap((e) => this.updateValue('items', e)),
          tap(() => cb()),
          delay(400),
          tap(() => this.updateValue('loading', false)),
          catchError((err) => {
            this.updateValue('loading', false);
            console.error(err);
            return EMPTY;
          })
        )
      )
    )
  );

  public readonly scanQrCode = this.effect<IOnboardingQrCodeContext>(
    pipe(
      switchMap((context) => {
        const displaySpinnerRequestId = this._spinnerService.displaySpinner(
          SpinnerContainerIds.APPLICATION
        );
        return this._qrCodeService.captureAsync().pipe(
          tap((code) => {
            if (context.resultHandler) {
              context.resultHandler(
                true,
                SystemMessageCodes.QR_SCANNED_SUCCESSFULLY,
                code
              );
            }

            this._spinnerService.deleteSpinner(
              SpinnerContainerIds.APPLICATION,
              displaySpinnerRequestId
            );
          }),
          catchError((exception) => {
            if (context.resultHandler) {
              context.resultHandler(false, `${exception?.messageCode}`);
            }
            this._spinnerService.deleteSpinner(
              SpinnerContainerIds.APPLICATION,
              displaySpinnerRequestId
            );
            return of(void 0);
          })
        );
      })
    )
  );

  public async showError(key: string, scope: string): Promise<void> {
    const toastInstance = await this._toastController.create({
      color: 'danger',
      position: 'top',
      duration: 5000,
    });
    toastInstance.message = await firstValueFrom(
      this._translocoService.selectTranslate(key, {}, { scope })
    );
    await toastInstance.present();
  }

  public constructor(
    @Inject(ONBOARDING_SERVICE)
    protected readonly _onboardingService: IOnboardingService,
    @Inject(CHEMOTHERAPY_SERVICE)
    protected readonly _chemotherapyService: IChemotherapyService,
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    protected readonly _store: Store<IRootState>,
    @Inject(QR_CODE_SERVICE)
    protected readonly _qrCodeService: IQrCodeService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _toastController: ToastController,
    protected readonly _translocoService: TranslocoService,
    @Inject(RPC_SERVICE) protected readonly _rpcService: IRpcService,
    @Inject(TERMS_AND_CONDITIONS_SERVICE)
    protected readonly _termsAndConditionsService: ITermsAndConditionsService,
    @Inject(SPINNER_SERVICE) protected readonly _spinnerService: ISpinnerService
  ) {
    super({
      loading: false,
      key: OnboardingSteps.LANDING_PAGE,
      items: [],
      data: null,
      termsAndConditions: null,
      acceptTermCondition: false,
      invalidPinCodeMessage: null,
    });
  }


  public async closeWebView(): Promise<void> {
    const displaySpinnerRequestId = this._spinnerService.displaySpinner(
      SpinnerContainerIds.APPLICATION
    );

    try {
      await firstValueFrom(
        this._rpcService.sendRequestAsync(
          new CloseWebviewNativeMethod(),
          5 * 1000
        )
      );
    } catch (exception) {
      this._spinnerService.deleteSpinner(
        SpinnerContainerIds.APPLICATION,
        displaySpinnerRequestId
      );

      const toastInstance = await this._toastController.create({
        color: 'danger',
        position: 'top',
        duration: 5000,
      });
      toastInstance.message = await firstValueFrom(
        this._translocoService.selectTranslate(
          'SOMETHING_WENT_WRONG_WHILE_CLOSING_WEB_VIEW',
          {},
          { scope: 'SYSTEM_MESSAGE' }
        )
      );
      await toastInstance.present();
    } finally {
      this._spinnerService.deleteSpinner(
        SpinnerContainerIds.APPLICATION,
        displaySpinnerRequestId
      );
    }
  }

  private __stopCountDown(): void {
    if (!this.__intervalSubscription) {
      return;
    }
    this.__intervalSubscription.unsubscribe();
    this.__intervalSubscription = undefined;
  }

  public ionViewDidLeave(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.__stopCountDown();
  }

  //#endregion

  //#region Settles

  public get state(): IOnboardingState {
    return this.get();
  }

  public updateValue<TKey extends keyof IOnboardingState>(
    key: TKey,
    value: IOnboardingState[TKey]
  ): void {
    const updater = this.updater<IOnboardingState[TKey]>((state, value) => ({
      ...state,
      [key]: value,
    }));

    updater(value);
  }

  //#endregion
}
