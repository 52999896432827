import { NgOtpInputComponent } from "ng-otp-input";
import { Directive } from "@angular/core";

@Directive()
export class OtpOverride {

  public constructor(
    public readonly target: NgOtpInputComponent
  ) { }

  public ifValidKeyCode(event: KeyboardEvent): boolean {
    const inp = event.key;
    return this.validateNumber(inp);
  }

  public validateNumber(val: string): boolean {
    if (!val) return false;

    const allowFn = (code: string): boolean => {
      return this.target.config.allowKeyCodes ? this.target.config.allowKeyCodes.includes(code) : true;
    };

    const keys = val.split("");
    let i = 0;

    while(i < keys.length) {
      const key = keys[i];
      if (!/^\d*\.?\d*$/.test(key) && !allowFn(key.charCodeAt(0).toString())) {
        return false;
      }
      i++;
    }

    return true;
  }
}
