import {Inject, Injectable} from "@angular/core";
import {IPaxmanService} from "./paxman-service.interface";
import {
    IArticle,
    IPaxman,
    IPaxmanHairRetention,
    IPaxmanInfo,
    IPaxmanStory,
    IPaxmanTimeLineSummary
} from "@mobile-data-access-interfaces";
import {map, mergeMap, Observable} from "rxjs";
import {API_ENDPOINT_RESOLVER, IApiEndpointResolver} from "@mobile-data-access-resolvers";
import {HttpClient} from "@angular/common/http";
import {IApiResult} from "@shared-interfaces";
import {ArticleCategories} from '@mobile-data-access-enums';

@Injectable()
export class PaxmanService implements IPaxmanService {

    //#region Constructor

    public constructor(
        @Inject(API_ENDPOINT_RESOLVER)
        protected readonly _endpointResolver: IApiEndpointResolver,
        protected readonly _httpClient: HttpClient
    ) {
    }

    //#endregion

    //#region Methods

    public checkAvailabilityAsync(): Observable<IPaxman> {
        return this._getDataAsync<IPaxman>('availability');
    }

    public checkActivationAsync(): Observable<IPaxman> {
        return this._getDataAsync<IPaxman>('activation');
    }

    public getHairRetentionAsync(): Observable<IPaxmanHairRetention> {
        return this._getDataAsync<IPaxmanHairRetention>('detail/paxman_hair_retention');
    }

    public getInfoAsync(): Observable<IPaxmanInfo> {
        return this._getDataAsync<IPaxmanInfo & { tags: string[] }>('info')
            .pipe(
                map((e) => ({
                    ...e,
                    tags: (e.tags as string[]).map((title) => ({
                        title
                    }))
                }))
            );
    }

    public getPatientStoriesAsync(): Observable<IPaxmanStory[]> {
        return this._getDataAsync<IPaxmanStory[]>('detail/paxman_patient_stories');
    }

    public getTimelineAsync(): Observable<IPaxmanTimeLineSummary> {
        return this._getDataAsync<IPaxmanTimeLineSummary>('detail/paxman_timeline', {
            deviceTime: new Date().toISOString()
        })
            .pipe(
                map(timelineSummary => {
                    for (const stage of timelineSummary.stages) {
                        for (const article of stage.articles) {
                            article.category = ArticleCategories.PAXMAN_ARTICLE;
                        }
                    }
                    return timelineSummary;
                })
            );
    }

    public activationAsync(): Observable<never> {
        return this._endpointResolver.loadEndPointAsync('', '').pipe(
            mergeMap((baseUrl) => {
                const apiUrl = `${baseUrl}/paxman/activation`;

                return this._httpClient
                    .post<never>(apiUrl, {});
            })
        );
    }

    public getArticleByIdAsync(id: string): Observable<IArticle> {
        return this._getUrlAsync(`paxman/detail/paxman_article_detail`)
            .pipe(
                mergeMap((apiUrl) => {
                    const httpParams: Record<string, string> = {};
                    httpParams["id"] = id;

                    return this._httpClient
                        .get<IApiResult<IArticle>>(apiUrl, {params: httpParams})
                        .pipe(map(({data}) => data));
                }),
                map(article => {
                    article.category = ArticleCategories.PAXMAN_ARTICLE;
                    return article;
                })
            );
    }

    public getRelatedTopicDetailByIdAsync(id: string): Observable<IArticle> {
        return this._getUrlAsync(`paxman/detail/paxman_related_topic_detail`)
            .pipe(
                mergeMap((apiUrl) => {
                    const httpParams: Record<string, string> = {};
                    httpParams["id"] = id;

                    return this._httpClient
                        .get<IApiResult<IArticle>>(apiUrl, {params: httpParams})
                        .pipe(map(({data}) => data));
                }),
                map(article => {
                    article.category = ArticleCategories.PAXMAN_ARTICLE_RELATED_TOPIC;
                    return article;
                })
            );
    }

    //#endregion Methods

    //#region Internal Methods

    protected _getDataAsync<T>(containerCode: string, params?: Record<string, string>): Observable<T> {
        const url = `paxman/${containerCode}`;
        return this._getUrlAsync(url).pipe(
            mergeMap((apiUrl) => {

                return this._httpClient
                    .get<IApiResult<T>>(apiUrl, {
                        params
                    })
                    .pipe(map(apiUrl => {
                        return apiUrl?.data;
                    }));
            })
        );
    }

    protected _getUrlAsync(suffix: string): Observable<string> {
        return this._endpointResolver.loadEndPointAsync("", "").pipe(
            map((baseUrl) => {
                return `${baseUrl}/${suffix}`;
            })
        );
    }

    //#endregion Internal Methods
}
