import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HtmlContents } from '@mobile-data-access-enums';
import { IMedicationDrug } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-medication-drug-card',
  templateUrl: './medication-drug-card.component.html',
  styleUrls: ['./medication-drug-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicationDrugCardComponent {
  //#region Properties
  @Input()
  public data: IMedicationDrug | null = null;

  @Input()
  public loading = false;

  public readonly HtmlContents = HtmlContents;

  public get isHidden(): boolean {
    if (!this.loading) {
      return !this.data;
    }

    return false;
  }
  //#endregion
}
