import { Inject, Injectable } from '@angular/core';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, Observable } from 'rxjs';
import { IContainerService } from './container-service.interface';
import { Any } from '@shared-types';
import { IApiResult } from '@shared-interfaces';

@Injectable()
export class ContainerService implements IContainerService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public loadDataAsync<T, P extends Any>(
    containerCode: string,
    payload?: P
  ): Observable<T> {
    return this._apiEndpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((apiUrl: string) => {
        const fullUrl = `${apiUrl}/container/${containerCode}`;
        return this._httpClient.post<IApiResult<T>>(fullUrl, payload);
      }),
      map((apiResult) => {
        return apiResult.data;
      })
    );
  }

  //#endregion
}
