import { createAction, props } from '@ngrx/store';
import { IBookmarkCard } from '@mobile-data-access-interfaces';

export class BookmarkActions {
  //#region Properties

  public static readonly getBookmarks = createAction('[BOOKMARK] LOAD DATA');

  public static readonly changeBookmarks = createAction(
    '[BOOKMARK] CHANGE BOOKMARKS',
    props<{ bookmarks: IBookmarkCard[] }>()
  );

  public static readonly changeLoadingStatus = createAction(
    '[BOOKMARKS] Change loading status',
    props<{ loading: boolean }>()
  );

  public static readonly totalRecords = createAction(
    '[BOOKMARKS] Count total records',
    props<{ totalRecords: number }>()
  );

  //#endregion
}
