import { NgModule } from '@angular/core';
import { BannerCardComponent } from './banner-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { ToSystemTimeModule } from '@mobile-ui-pipes';
import { CategoryModule, SkeletonModule } from '../../shared';
import { ToTrustedHtmlPipeModule } from '@ui-tool/core';
import { TranslocoModule } from '@ngneat/transloco';
import {AspectRatioDirectiveModule, ToPlainTextPipeModule} from '@shared-directives';
import { UnescapePipeModule } from "@shared-pipes";

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ToSystemTimeModule,
    CategoryModule,
    SkeletonModule,
    ToTrustedHtmlPipeModule,
    TranslocoModule,
    AspectRatioDirectiveModule,
    UnescapePipeModule,
    ToPlainTextPipeModule,
  ],
  declarations: [BannerCardComponent],
  exports: [BannerCardComponent],
})
export class BannerCardModule {}
