import { NgModule } from '@angular/core';
import { TrackerEffectCardComponent } from './tracker-effect-card.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { AssetModule, CategoryModule, SkeletonModule } from '../../shared';
import { HiddenImageOnErrorModule } from '@shared-directives';
import { TranslocoModule } from '@ngneat/transloco';
import {
  ToAppointmentTimeModule,
  ToAppointmentTimePipe,
  ToSystemPlusTimeAsyncPipe,
  ToSystemPlusTimeAsyncPipeModule,
} from '@mobile-ui-pipes';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    CategoryModule,
    SkeletonModule,
    HiddenImageOnErrorModule,
    AssetModule,
    TranslocoModule,
    ToSystemPlusTimeAsyncPipeModule,
    ToAppointmentTimeModule,
  ],
  providers: [ToSystemPlusTimeAsyncPipe, ToAppointmentTimePipe],
  declarations: [TrackerEffectCardComponent],
  exports: [TrackerEffectCardComponent],
})
export class TrackerEffectCardModule {}
