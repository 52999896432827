import {
  AfterViewInit,
  Directive,
  Injector,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { IDynamicMap } from './dynamic-directive.interface';
import {
  CONTAINER_CODE,
  DYNAMIC_DIRECTIVE_DATA,
  SCREEN_CODE,
} from './dynamic-directive.token';

@Directive({
  selector: '[dynamic]',
})
export class DynamicDirective<T> implements AfterViewInit {
  //#region Properties

  @Input()
  public code = '';

  @Input()
  public screenCode = '';

  @Input()
  public data: T | undefined;

  @Input()
  public map: IDynamicMap = {};

  @Input()
  public assignData: boolean = false;

  //#endregion

  //#region Constructor

  public constructor(protected readonly viewContainerRef: ViewContainerRef) {}

  //#endregion

  //#region Methods

  public ngAfterViewInit(): void {
    try {
      const componentType = this.map[this.code];

      if (!componentType) {
        this.viewContainerRef.clear();
        return;
      }

      const injector = Injector.create({
        providers: [
          {
            provide: CONTAINER_CODE,
            useValue: this.code,
          },
          {
            provide: SCREEN_CODE,
            useValue: this.screenCode,
          },
          {
            provide: DYNAMIC_DIRECTIVE_DATA,
            useValue: this.data,
          },
        ],
      });

      const componentRef = this.viewContainerRef.createComponent(
        componentType,
        {
          injector,
        }
      );

      if (this.assignData) {
        componentRef.instance['data'] = this.data;
      }

      componentRef.hostView.detectChanges();
    } catch (err) {
      console.error(err);
    }
  }

  //#endregion
}
