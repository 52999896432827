import { ISystemService } from './system-service.interface';
import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';
import {
  GoToAppSettingPageNativeMethod,
  MarkPwaReadyMethod,
} from '@mobile-data-access-models';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { SystemStatuses } from '@mobile-data-access-enums';
import { HttpClient } from '@angular/common/http';
import { APP_SETTINGS_SERVICE, IAppSettingsService } from '../app-settings';

@Injectable()
export class SystemService implements ISystemService {
  //#region Constructor

  public constructor(
    @Inject(RPC_SERVICE) protected readonly _rpcService: IRpcService,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endPointResolver: IApiEndpointResolver,
    @Inject(APP_SETTINGS_SERVICE)
    protected readonly _appSettingsService: IAppSettingsService,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public goToAppSettings(): Observable<void> {
    return this._rpcService.sendRequestAsync(
      new GoToAppSettingPageNativeMethod(),
      30 * 1000
    );
  }

  public getStatusAsync(): Observable<SystemStatuses> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/system/status`;
        return this._httpClient.get<{ status: SystemStatuses }>(apiUrl);
      }),
      map(({ status }) => status)
    );
  }

  public markPwaReadyAsync(): Observable<void> {
    return this._rpcService.sendRequestAsync(
        new MarkPwaReadyMethod(),
        30 * 1000
    );
  }

  //#endregion
}
