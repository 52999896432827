<div class="meeting-goal-card">
  <div class="meeting-goal-card__container">
    <!--Paragraph-->
    <div class="meeting-goal-card__container__paragraph" [ngSwitch]="loading">
      <ng-container *ngSwitchCase="true">
        <ncis-skeleton></ncis-skeleton>
        <ncis-skeleton></ncis-skeleton>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="flex gap-x-3">
          <div
            class="flex items-center h-[22px]"
            inlineSVG="ncis-assets/icons/general/bullet-point.svg"
          ></div>
          <p>
            <b>{{ data.title || 'N/A' }}:</b> {{ data.description || 'N/A' }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
