import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ITreatmentSymptomsService, TREATMENT_SYMPTOMS_SERVICE } from "@mobile-data-access-services";
import { SymptomsComponentStore } from "@mobile-data-access-stores";
import { TranslocoService } from "@ngneat/transloco";
import { ToastController } from "@ionic/angular";
import { SymptomsQueryParams } from "@mobile-data-access-models";

@Injectable()
export class SymptomsGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(TREATMENT_SYMPTOMS_SERVICE)
    protected readonly _treatmentSymptomService: ITreatmentSymptomsService,
    protected readonly _toastController: ToastController,
    protected readonly _translateService: TranslocoService,
    protected readonly _componentStore: SymptomsComponentStore
  ) { }

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    const queryParam = activatedRouteSnapshot.queryParams as SymptomsQueryParams;
    const id = queryParam.id;
    const type = queryParam.type;

    if (!id || !id.length) {
      return false;
    }

    if (!type || !type.length) {
      return false;
    }

    return of(true);

  }

  //#endregion
}
