import { Inject, Injectable } from '@angular/core';
import { NavigatorHealthTrackerState } from './navigator-health-tracker.state';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { NavigatorBaseComponentStore } from '../base';
import {
  NavigatorContainerCodes,
  ScreenCodes,
} from '@mobile-data-access-enums';
import { NavController } from '@ionic/angular';

@Injectable()
export class NavigatorHealthTrackerComponentStore extends NavigatorBaseComponentStore<
  NavigatorContainerCodes.HEALTH_TRACKER,
  NavigatorHealthTrackerState
> {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected override readonly _navigationService: ISmartNavigatorService,
    protected override readonly _navController: NavController
  ) {
    super(_navigationService, _navController, ScreenCodes.CHEMOTHERAPY);
  }

  //#endregion
}
