import { Inject, Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { ITrackerState } from "./tracker.state";
import {
  ISideEffectService,
  ISymptomService,
  SIDE_EFFECT_SERVICE,
  SYMPTOM_SERVICE
} from "@mobile-data-access-services";
import { BehaviorSubject, catchError, debounceTime, EMPTY, finalize, forkJoin, map, pipe, switchMap, tap, combineLatest } from "rxjs";

@Injectable()
export class TrackerComponentStore extends ComponentStore<ITrackerState> {
  //#region Properties
  public readonly emptyMessage$ = new BehaviorSubject<string>("");
  public readonly keySearch$ = new BehaviorSubject<string>("");
  public readonly activeTrackers$ = combineLatest([
    this.select((state) => state.activeTrackers),
    this.keySearch$.asObservable()
  ])
    .pipe(
      map(([ activeTrackers, key ]) => activeTrackers?.filter((item) =>
        (item.title || "").toLowerCase().includes(key)
      ))
    );

  public readonly symptomTrackers$ = combineLatest([
    this.select((state) => state.symptomTrackers),
    this.keySearch$.asObservable()
  ])
    .pipe(
      map(([ symptomTrackers, key ]) => symptomTrackers?.filter((item) =>
        (item.title || "").toLowerCase().includes(key)
      ))
    );
  //#endregion

  public readonly listenSearch = this.effect<never>(
    pipe(
      switchMap(() =>
        this.select((state) => state.key).pipe(
          debounceTime(250),
          tap((key) => this.keySearch$.next(key))
        )
      )
    )
  );
  //#region Constructor
  public readonly init = this.effect<never>(
    pipe(
      switchMap(() => {
        this.patchState({
          loading: true
        });
        return forkJoin([
          this._sideEffectService.getEffectAsync(),
          this._symptomService.getLatestEffectAsync()
        ]).pipe(
          tap(([symptomTrackers, activeTrackers]) => {
            this.patchState((state) => ({
              ...state,
              activeTrackers,
              symptomTrackers
            }));
            this.checkEmpty("success");
          }),
          catchError(() => {
            this.patchState((state) => ({
              activeTrackers: [],
              symptomTrackers: [],
              key: ""
            }));
            this.checkEmpty("failed");
            return EMPTY;
          }),
          finalize(() => {
            this.patchState({
              loading: false
            });
            this.listenSearch();
          })
        );
      })
    )
  );

  //#endregion

  //#region Methods

  public constructor(
    @Inject(SIDE_EFFECT_SERVICE)
    protected readonly _sideEffectService: ISideEffectService,
    @Inject(SYMPTOM_SERVICE)
    protected readonly _symptomService: ISymptomService
  ) {
    super({
      symptomTrackers: [],
      activeTrackers: [],
      key: "",
      loading: false
    });
  }

  public search(key: string | null): void {
    this.patchState({
      key: key || ""
    });
  }

  public checkEmpty(message: string): void {
    const { activeTrackers, symptomTrackers } = this.get();
    this.emptyMessage$.next((!activeTrackers?.length && !symptomTrackers?.length) ? message : "");
  }

  //#endregion
}
