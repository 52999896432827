import { createReducer, on } from "@ngrx/store";
import { PaxmanActions } from "./paxman.action";
import { paxmanStoreInitialState } from "./paxman.adapter";

export const paxmanStoreKey = 'paxman';

export const paxmanReducer = createReducer(
  paxmanStoreInitialState,
  on(PaxmanActions.saveAvailable, (state, action) => ({
    ...state,
    available: action.data,
    checked: true
  })),
  on(PaxmanActions.saveInfo, (state, action) => ({
    ...state,
    info: action.data,
  })),
  on(PaxmanActions.saveActivation, (state, action) => ({
    ...state,
    activated: action.data,
  }))
);
