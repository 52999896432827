import { Route, RouterModule } from '@angular/router';
import {
  AppGuard,
  AuthenticatedUserGuard,
  HomeGuard,
  NotUnderMaintenanceGuard,
  PurgeAuthenticationGuard,
  UnauthenticatedUserGuard,
  UnderMaintenanceGuard,
} from '@mobile-logics-guards';

const routes: Route[] = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'session-expired',
        canActivate: [NotUnderMaintenanceGuard, UnauthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.SessionExpireModule),
      },
      {
        path: 'home',
        canActivate: [
          NotUnderMaintenanceGuard,
          AuthenticatedUserGuard,
          HomeGuard,
        ],
        loadChildren: () => import('./modules').then((m) => m.HomeModule),
      },
      {
        path: 'onboarding',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () => import('./modules').then((m) => m.OnboardingModule),
      },
      {
        path: 'bookmarks',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () => import('./modules').then((m) => m.BookmarksModule),
      },
      {
        path: 'article',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.ArticleDetailModule),
      },
      {
        path: 'paxman',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () => import('./modules').then((m) => m.PaxmanModule),
      },
      {
        path: 'chemotherapy',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.ChemotherapyModule),
      },
      {
        path: 'drug-detail',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () => import('./modules').then((m) => m.DrugDetailModule),
      },
      {
        path: 'symptoms',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () => import('./modules').then((m) => m.SymptomsModule),
      },
      {
        path: 'symptom',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.SymptomDetailModule),
      },
      {
        path: 'tracker',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.TrackerRecordModule),
      },
      {
        path: 'tracker-warning',
        canActivate: [NotUnderMaintenanceGuard, AuthenticatedUserGuard],
        loadChildren: () =>
          import('./modules').then((m) => m.TrackerWarningModule),
      },
      {
        path: 'oauth-callback',
        canActivate: [NotUnderMaintenanceGuard, PurgeAuthenticationGuard],
        loadChildren: () =>
          import('./screens').then((m) => m.OAuthCallbackModule),
      },
      {
        path: '404',
        canActivate: [NotUnderMaintenanceGuard],
        loadChildren: () => import('./screens').then((m) => m.NotFoundModule),
      },
      {
        path: '403',
        loadChildren: () => import('./screens').then((m) => m.ForbiddenModule),
      },
      {
        path: 'maintenance',
        canActivate: [UnderMaintenanceGuard],
        loadChildren: () =>
          import('./modules/maintenance').then((m) => m.MaintenanceModule),
      },
    ],
  },
];

export const AppRoutes = RouterModule.forRoot(routes, {
  enableTracing: false,
  onSameUrlNavigation: 'reload',
});
