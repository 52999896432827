import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import {IRegimenCard, IRegimensCard} from '@mobile-data-access-interfaces';

declare type IItemContext = {
  color: string
}

declare type IDayContext = {
  day: number;
  items: IItemContext[]
}

@Component({
  selector: 'ncis-detailed-cycle',
  templateUrl: './detailed-cycle.component.html',
  styleUrls: ['./detailed-cycle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: "CHEMOTHERAPY_DETAIL_PAGE",
        alias: "CHEMOTHERAPY_DETAIL_PAGE"
      }
    },
  ]
})
export class DetailedCycleComponent {

  //#region Properties

  private __dayContexts: IDayContext[] = [];

  //#endregion

  //#region Accessors

  @Input()
  public loading = false;

  @Input()
  public set data(value: IRegimensCard | undefined) {
    if (!value || value.daysPerCycle < 1) {
      this.__dayContexts = [];
      return;
    }

    const dayContexts: IDayContext[] = [];
    for (let day = 1; day <= value.daysPerCycle; day++) {

      const dailyRegimens = value.regimens
          .filter(regimen => regimen.days.indexOf(`${day}`) !== -1);
      const dayContext: IDayContext = {day: day, items: dailyRegimens};
      dayContext.day = day;
      dayContext.items = dailyRegimens;
      dayContexts.push(dayContext);
    }

    this.__dayContexts = dayContexts;
  }

  public show = false;

  public get dayContexts(): IDayContext[] {
    return this.__dayContexts;
  }

  //#endregion

}
