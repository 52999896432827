import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "../../../shared";
import { DetailedCycleComponent } from "./detailed-cycle.component";
import { TranslocoModule } from "@ngneat/transloco";

const MODULES = [
  CommonModule,
  ButtonModule
];

@NgModule({
  declarations: [DetailedCycleComponent],
  exports: [DetailedCycleComponent],
  imports: [MODULES, TranslocoModule]
})
export class DetailedCycleModule {}
