import { NgModule } from '@angular/core';
import { NAVIGATOR_SERVICE } from './navigator-service.token';
import { NavigatorService } from './navigator.service';

@NgModule({
  providers: [
    {
      provide: NAVIGATOR_SERVICE,
      useClass: NavigatorService,
    },
  ],
})
export class NavigatorServiceModule {}
