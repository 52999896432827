import { NavigateToScreenRequest } from '@ui-tool/core';
import { ScreenCodes } from '@mobile-data-access-enums';
import { IDrugDetailQueryParams } from '@mobile-data-access-interfaces';

export class DrugDetailNavigationRequest extends NavigateToScreenRequest<
  Record<string, any>
> {
  //#region Constructor

  public constructor(queryParams: IDrugDetailQueryParams) {
    super(
      ScreenCodes.DRUG_DETAIL,
      {},
      {
        queryParams: {
          id: queryParams.id,
          source: queryParams.source,
        },
      }
    );
  }

  //#endregion
}
