import { NgModule } from '@angular/core';
import { RecommendationMenuItem } from './recommendation.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared-components';
import { AspectRatioDirectiveModule } from '@shared-directives';
import { StrapiModule } from "../../shared";
import { ToTrustedHtmlPipeModule } from "@ui-tool/core";
import { UnescapePipeModule } from "@shared-pipes";

@NgModule({
  declarations: [RecommendationMenuItem],
  imports: [CommonModule, IconModule, AspectRatioDirectiveModule, StrapiModule, ToTrustedHtmlPipeModule, UnescapePipeModule],
  exports: [RecommendationMenuItem],
})
export class RecommendationModule {}
