import { NgModule } from '@angular/core';
import { MeetingGoalCardComponent } from './meeting-goal-card.component';
import { CommonModule } from '@angular/common';
import { ToSymptomNumberModule } from '@mobile-ui-pipes';
import { SkeletonModule } from '../../shared/skeleton/skeleton.module';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  imports: [
    CommonModule,
    ToSymptomNumberModule,
    SkeletonModule,
    InlineSVGModule,
  ],
  declarations: [MeetingGoalCardComponent],
  exports: [MeetingGoalCardComponent],
})
export class MeetingGoalCardModule {}
