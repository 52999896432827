<ng-container *ngIf="!loading; else skeletonTemplate">
  <ng-container *ngIf="data?.length">
    <div class="related-topics">
      <h2>{{ title || 'N/A' }}</h2>
      <div class="w-full">
        <swiper [config]="swiperOptions">
          <ng-container *ngFor="let topic of data">
            <ng-template swiperSlide>
              <ncis-related-topic-card
                [attr.data-test-id]="'related-topic-card-' + topic.id"
                (click)="clickTopicEvent.emit(topic)"
                [data]="topic"
              ></ncis-related-topic-card>
            </ng-template>
          </ng-container>
        </swiper>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeletonTemplate>
  <div class="related-topics">
    <ncis-skeleton class="w-72 h-[22px]"></ncis-skeleton>
    <div class="w-full">
      <swiper [config]="swiperOptions">
        <ng-container *ngFor="let item of [1,2,3,4]">
          <ng-template swiperSlide>
            <ncis-related-topic-card
              [loading]="true"
            ></ncis-related-topic-card>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </div>
</ng-template>
