import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMeetingGoalCard } from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-meeting-goal-card',
  templateUrl: './meeting-goal-card.component.html',
  styleUrls: ['./meeting-goal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingGoalCardComponent {
  //#region Properties

  @Input()
  public index = 0;

  @Input()
  public data!: IMeetingGoalCard;

  @Input()
  public loading = false;

  //#endregion
}
