import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { LocalForageService } from 'ngx-localforage';
import { StorageKeys } from '@mobile-data-access-enums';

@Injectable()
export class PurgeAuthenticationGuard implements CanActivate {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _localForage: LocalForageService
  ) {}

  //#endregion

  //#region Methods

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._localForage
      .removeItem(StorageKeys.AUTHENTICATION_RESULT)
      .pipe(map(() => true));
  }

  //#endregion
}
