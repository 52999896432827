import { Inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable } from 'rxjs';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import { HttpClient } from '@angular/common/http';
import { IApiResult } from '@shared-interfaces';
import { IArticle } from '@mobile-data-access-interfaces';
import { IProcedureService } from './procedure-service.interface';
import {
  ArticleCategories,
  ChemotherapyArticleTypes,
  ProcedureArticleCodes,
  ProcedureControlCodes,
} from '@mobile-data-access-enums';

@Injectable()
export class ProcedureService implements IProcedureService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endPointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getArticleDetailAsync(id: string): Observable<IArticle> {
    return this._getDataAsync<IArticle>(ProcedureArticleCodes.ARTICLE, id);
  }

  public getRelatedTopicDetailAsync(
    id: string,
    hostCategory: ArticleCategories
  ): Observable<IArticle> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        let apiUrl = '';
        const httpParams: Record<string, string> = {};
        httpParams['id'] = id;
        apiUrl = `${baseUrl}/surgery/detail/procedure_related_topics_detail`;
        httpParams['source'] = `${hostCategory}`;

        return this._httpClient
          .get<IApiResult<IArticle>>(apiUrl, { params: httpParams })
          .pipe(map(({ data }) => data));
      }),
      map((article) => {
        article.category = ArticleCategories.PROCEDURE_ARTICLE_RELATED_TOPIC;
        return article;
      })
    );
  }

  public getWhatNextDetailAsync(id: string): Observable<IArticle> {
    return this._getDataAsync<IArticle>(ProcedureArticleCodes.WHAT_NEXT, id);
  }

  //#endregion

  //#region Chemo

  public getChemoAsync<T>(
    id: string,
    code: ProcedureControlCodes
  ): Observable<T> {
    return this._getDataAsync<T>(code, id);
  }

  public getArticlesByProcedureIdAsync(
    procedureId: string
  ): Observable<IArticle[]> {
    return this._getDataAsync<IArticle[]>(
      ProcedureControlCodes.ARTICLES,
      procedureId
    ).pipe(
      map((articles) => {
        return articles.map((article) => ({
          ...article,
          category: ArticleCategories.PROCEDURE_ARTICLE,
        }));
      })
    );
  }

  //#endregion Chemo

  //#region Internal methods

  protected _getDataAsync<T>(containerCode: string, id: string): Observable<T> {
    return this._endPointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((baseUrl) => {
        const apiUrl = `${baseUrl}/surgery/detail/${containerCode}`;
        const httpParams: Record<string, string> = {};
        httpParams['id'] = id;

        return this._httpClient
          .get<IApiResult<T>>(apiUrl, { params: httpParams })
          .pipe(map(({ data }) => data));
      })
    );
  }

  //#endregion
}
