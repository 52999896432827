import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import {
  IHealthTrackerCard,
  ISymptomCard,
} from '@mobile-data-access-interfaces';

@Component({
  selector: 'ncis-health-tracker-card',
  templateUrl: './health-tracker-card.component.html',
  styleUrls: ['./health-tracker-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthTrackerCardComponent {
  //#region Properties

  @Input()
  public data!: IHealthTrackerCard;

  @Input()
  public loading = false;

  @Output()
  public readonly selectItemEvent: EventEmitter<ISymptomCard>;

  public readonly CommonIconFolder = CommonIconFolderEnum;
  public readonly CommonIconGeneral = CommonIconGeneralEnum;

  //#endregion

  //#region Constructor

  public constructor() {
    this.selectItemEvent = new EventEmitter<ISymptomCard>();
  }

  //#endregion
}
