import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IBookmarkCard } from "@mobile-data-access-interfaces";

@Component({
  selector: 'bookmark-card',
  templateUrl: './bookmark-card.component.html',
  styleUrls: ['./bookmark-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkCardComponent {

  //#region Properties
  @Output()
  public readonly bookmarkDetailClick = new EventEmitter<never>();

  @Output()
  public readonly unBookmarkClick = new EventEmitter<never>();

  @Input()
  public data: IBookmarkCard | null = null;

  @Input()
  public loading = false;

  @Input()
  public category = ''

  //#endregion

  //#region Methods

  public unBookmark(): void {
    this.loading = true;
    this.unBookmarkClick.emit();
  }

  //#endregion Methods
}
