import { ISideEffectService } from './side-effect-service.interface';
import { Inject, Injectable } from '@angular/core';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@mobile-data-access-resolvers';
import {
  IDrugDetailSideEffect,
  ITrackerEffect,
  ITrackerEffectServe, ITrackerRecord
} from "@mobile-data-access-interfaces";
import { map, mergeMap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SideEffectService implements ISideEffectService {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {}

  //#endregion

  //#region Methods

  public getEffectAsync(): Observable<ITrackerEffect[]> {
    return this.endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const uri = `${endpoint}/side-effects`;
        return this._httpClient.get<{ data: ITrackerEffect[] }>(uri);
      }),
      map((res) => res?.data || [])
    );
  }

  public getRecordByIdAsync(
    id: string
  ): Observable<ITrackerRecord> {
    return this.endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const uri = `${endpoint}/side-effect/${id}/severe`;
        return this._httpClient.get<{ data: ITrackerRecord }>(uri);
      }),
      map((res) => res?.data)
    );
  }

  public getByDrugIdAsync(drugId: string): Observable<IDrugDetailSideEffect> {
    return this.endpointResolver.loadEndPointAsync('', '').pipe(
      mergeMap((endpoint) => {
        const uri = `${endpoint}/symptom/drug/symptoms`;
        return this._httpClient.post<{ data: IDrugDetailSideEffect }>(uri, {
          id: drugId,
        });
      }),
      map((data) => data.data)
    );
  }

  //#endregion
}
