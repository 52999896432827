import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { IPaxmanState } from './paxman.state';
export const paxmanAdapter: EntityAdapter<never> =
  createEntityAdapter<never>();

export const paxmanStoreInitialState: IPaxmanState =
  paxmanAdapter.getInitialState({
    info: null,
    available: false,
    activated: false,
    checked: false
  });
