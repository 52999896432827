import {ChangeDetectionStrategy, Component, Inject, Input,} from '@angular/core';
import {ISmartNavigatorService, SMART_NAVIGATOR_SERVICE} from '@ui-tool/core';
import {SymptomDetailNavigationRequest, SymptomsNavigationRequest,} from '@mobile-data-access-models';
import {take} from 'rxjs';
import {ISideEffect, ISymptomCard} from '@mobile-data-access-interfaces';
import {NavController} from '@ionic/angular';
import {SymptomHostTypes} from '@mobile-data-access-enums';

@Component({
  selector: 'ncis-side-effects',
  templateUrl: './side-effects.component.html',
  styleUrls: ['./side-effects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideEffectsComponent {
  //#region Properties

  @Input()
  public title: string;

  @Input()
  public viewAllTitle = '';

  @Input()
  public data: ISideEffect | undefined;

  @Input()
  public loading = false;

  @Input()
  public hiddenInformation = false;

  @Input()
  public hiddenViewAll = false;

  @Input()
  public hostType: SymptomHostTypes = SymptomHostTypes.TREATMENT;

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    protected readonly _navController: NavController
  ) {
    this.title = '';
  }

  //#endregion

  //#region Methods

  public viewAllSymptoms(): void {

    if (!this.data) return;

    const navigationRequest = new SymptomsNavigationRequest(`${this.data.id}`, this.hostType);
    this._navigationService
      .navigateToScreenAsync(navigationRequest)
      .pipe(take(1))
      .subscribe();
  }

  public goToSymptomDetail(symptom: ISymptomCard): void {
    const navigationRequest = new SymptomDetailNavigationRequest(symptom.id);
    const urlTree = this._navigationService.buildUrlTree(
      navigationRequest.code,
      navigationRequest.routeParams,
      navigationRequest.extras
    );
    void this._navController.navigateForward(urlTree);
  }

  //#endregion
}
