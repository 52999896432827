import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { compareDesc, getDaysInMonth } from "date-fns";

@Component({
  selector: "ncis-datetime-day",
  templateUrl: "./day.component.html",
  styleUrls: ["./day.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayComponent implements OnChanges {

  //#region Properties

  @Output()
  public readonly select = new EventEmitter<Date>();

  @Input()
  public currentDate: Date = new Date();

  @Input()
  public selectedDate: Date = new Date();

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  public weeks: Record<"year" | "month" | "day", number>[][] = [[], [], [], [], [], []];

  public readonly weekDays: string[] = ["S", "M", "T", "W", "T", "F", "S"];

  public get current(): Record<"year" | "month" | "day", number> {
    return this.resolveMonthAndYear({
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    });
  }

  public get selected(): Record<"year" | "month" | "day", number> {
    return this.resolveMonthAndYear({
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    });
  }

  public get lastMonth(): Record<"year" | "month" | "day", number> {
    return this.resolveMonthAndYear({
      year: this.current.year,
      month: this.current.month - 1,
      day: 1
    });
  }

  public get nextMonth(): Record<"year" | "month" | "day", number> {
    return this.resolveMonthAndYear({
      year: this.current.year,
      month: this.current.month + 1,
      day: 1
    });
  }

  public get startFrom(): number {
    return new Date(this.current.year, this.current.month - 1, 1).getDay();
  }

  public get daysInMonth(): number {
    return getDaysInMonth(new Date(this.current.year, this.current.month - 1));
  }

  public get listDayInMonth(): Record<"year" | "month" | "day", number>[] {
    return this.toList(this.daysInMonth).map((day) => ({
      day,
      month: this.current.month,
      year: this.current.year
    }));
  }

  public get daysInLastMonth(): number {
    return getDaysInMonth(new Date(this.lastMonth.year, this.lastMonth.month - 1));
  }

  public get listDayInLastMonth(): Record<"year" | "month" | "day", number>[] {
    return this.toList(this.daysInLastMonth).map((day) => ({
      day,
      month: this.lastMonth.month,
      year: this.lastMonth.year
    }));
  }

  public get daysInNextMonth(): number {
    return getDaysInMonth(new Date(this.nextMonth.year, this.nextMonth.month - 1));
  }

  public get listDayInNextMonth(): Record<"year" | "month" | "day", number>[] {
    return this.toList(this.daysInNextMonth).map((day) => ({
      day,
      month: this.nextMonth.month,
      year: this.nextMonth.year
    }));
  }

  //#endregion

  //#region Life cycle

  public ngOnChanges(): void {
    this.weeks = [[], [], [], [], [], []];
    this.resolveFirstWeek();
    this.resolveOtherWeeks();
  }

  //#endregion

  //#region Methods

  public resolveFirstWeek(): void {
    const listDayInLastMonth = this.listDayInLastMonth
      .slice(this.daysInLastMonth - this.startFrom);
    const listDayInMonth = this.listDayInMonth
      .slice(0, 7 - this.startFrom);

    this.weeks[0] = listDayInLastMonth.concat(listDayInMonth);
  }

  public resolveOtherWeeks(): void {
    const maxDays = this.daysInMonth - (7 - this.startFrom);
    const listDayInMiddleMonth = this.listDayInMonth.slice(this.daysInMonth - (maxDays));
    const needDaysInNextMonth = maxDays > 28 ? 35 - maxDays : 28 - maxDays;
    const days = listDayInMiddleMonth.concat(this.listDayInNextMonth.slice(0, needDaysInNextMonth));
    for (let i = 1; i <= 5; i++) {
      for (let j = 0; j < 7; j++) {
        const day = days[((i - 1) * 7) + j];
        if (day) {
          this.weeks[i].push(day);
        }
      }
    }
  }

  public toList(length: number): number[] {
    return Array.from({ length }).map((e, i) => i + 1);
  }

  public toDay(value: Record<"year" | "month" | "day", number>): Date {
    return new Date(value.year, value.month - 1, value.day);
  }

  public isCurrent(value: Record<"year" | "month" | "day", number>): boolean {
    const current = new Date();
    return current.getDate() === value.day && current.getFullYear() === value.year && current.getMonth() + 1 === value.month;
  }

  public isSelected(value: Record<"year" | "month" | "day", number>): boolean {
    return this.selectedDate.getDate() === value.day && this.selectedDate.getFullYear() === value.year && this.selectedDate.getMonth() + 1 === value.month;
  }

  public isDisabled(value: Record<"year" | "month" | "day", number>): boolean {
    const day = new Date(value.year, value.month - 1, value.day);

    return (this.min ? compareDesc(this.min, day) < 0 : false) || (this.max ? compareDesc(this.max, day) > 0 : false);
  }

  public resolveMonthAndYear(value: Record<"year" | "month" | "day", number>): Record<"year" | "month" | "day", number> {
    return {
      day: value.day,
      month: value.month < 1 ? 12 : (value.month > 12 ? 1 : value.month),
      year: value.month < 1 ? value.year - 1 : (value.month > 12 ? value.year + 1 : value.year)
    };
  }

  //#endregion
}
