import { NgModule } from '@angular/core';
import { TREATMENT_SYMPTOMS_SERVICE } from './treatment-symptoms.token';
import { TreatmentSymptomsService } from './treatment-symptoms.service';
import { ContainerServiceModule } from '../container';

@NgModule({
  providers: [
    {
      provide: TREATMENT_SYMPTOMS_SERVICE,
      useClass: TreatmentSymptomsService,
    },
  ],
  imports: [ContainerServiceModule],
})
export class TreatmentSymptomsServiceModule {}
