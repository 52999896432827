import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IRootState, PaxmanActions, UserActions } from '../../../features';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { IOAuthCallbackState } from './o-auth-callback.state';
import { oAuthCallbackState } from './o-auth-callback.constants';
import {
  catchError,
  delay,
  EMPTY,
  filter,
  finalize,
  firstValueFrom,
  from,
  interval,
  map,
  mergeMap,
  of,
  OperatorFunction,
  pipe,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
  IUserService,
  USER_SERVICE,
} from '@mobile-data-access-services';
import {
  CloseWebviewNativeMethod,
  DashboardNavigationRequest,
  OAuthCallbackQueryParams,
} from '@mobile-data-access-models';
import { LocalForageService } from 'ngx-localforage';
import {
  AuthenticationStatuses,
  AuthenticationTokenLocation,
  SpinnerContainerIds,
  SsoProviders,
  StorageKeys,
} from '@mobile-data-access-enums';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { IOpenAuthenticationResult } from '@mobile-data-access-interfaces';
import { TranslocoService } from '@ngneat/transloco';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';
import { DOCUMENT } from '@angular/common';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class OAuthCallbackStore extends ComponentStore<IOAuthCallbackState> {
  //#region Constructor

  public readonly authenticate = this.effect<never>(
    switchMap(() =>
      this._activatedRoute.queryParams.pipe(
        take(1),
        delay(1500),
        tap(() =>
          this.patchState({
            authenticationStatus: AuthenticationStatuses.AUTHENTICATING,
            progressPercentage: 0,
          })
        ),
        map((queryParams) => queryParams as OAuthCallbackQueryParams),
        switchMap((queryParams) => {
          return this._authenticationService.getTokenLocationAsync().pipe(
            mergeMap((location) => {
              if (!location) {
                return throwError(
                  () => 'MISSING_TOKEN_LOCATION_FROM_APP_SETTINGS'
                );
              }
              let idToken: string | undefined;

              if (location === AuthenticationTokenLocation.COOKIE) {
                idToken = this.__getCookieValue('idToken');
              } else if (location === AuthenticationTokenLocation.BOTH) {
                idToken =
                  queryParams.idToken || this.__getCookieValue('idToken');

                if (!idToken) {
                  return throwError(
                    () => 'MISSING_ID_TOKEN_FROM_QUERY_OR_COOKIE'
                  );
                }
              }

              if (idToken) {
                const cloneQueryParams = cloneDeep(queryParams);
                cloneQueryParams['idToken'] = idToken;
                return of(cloneQueryParams);
              }

              return of(queryParams);
            }),
            mergeMap((params) => {
              if (!params) {
                return throwError(
                  () => 'MISSING_TOKEN_LOCATION_FROM_APP_SETTINGS'
                );
              }
              if (
                params.idToken &&
                params.provider != null &&
                params.provider.toLowerCase() === SsoProviders.NUHS
              ) {
                return of(params).pipe(this.sso());
              }
              return of(params).pipe(this.oauth());
            })
          );
        }),
        mergeMap((authenticationResult) => {
          return interval(30)
            .pipe(take(99))
            .pipe(
              map((count) => ({
                progressPercentage: count + 1,
                authenticationResult,
              }))
            );
        }),
        tap(({ progressPercentage }) =>
          this.patchState({ progressPercentage })
        ),
        filter(({ progressPercentage }) => progressPercentage === 99),
        mergeMap(({ authenticationResult }) => {
          return this._localForageService.setItem(
            StorageKeys.AUTHENTICATION_RESULT,
            authenticationResult
          );
        }),
        mergeMap(() => {
          return this._userService.getProfileAsync();
        }),
        tap((profile) => {
          this._store.dispatch(
            PaxmanActions.saveActivation({ data: profile.activatedPaxman })
          );
          this._store.dispatch(UserActions.saveProfile({ data: profile }));
        }),
        mergeMap(() => {
          this._changeAuthenticationStatus(AuthenticationStatuses.SUCCESS);
          const navigationRequest = new DashboardNavigationRequest();
          return this._navigationService.navigateToScreenAsync(
            navigationRequest
          );
        }),
        catchError(() => {
          return of(void 0).pipe(
            delay(1500),
            mergeMap(() => {
              this._changeAuthenticationStatus(AuthenticationStatuses.FAILED);
              return EMPTY;
            })
          );
        })
      )
    )
  );

  //#endregion

  //#region Methods
  public readonly closeWebView = this.effect<never>(
    pipe(
      switchMap(() => {
        let displaySpinnerRequestId = this._spinnerService.displaySpinner(
          SpinnerContainerIds.APPLICATION
        );

        return this._rpcService
          .sendRequestAsync(new CloseWebviewNativeMethod(), 5 * 1000)
          .pipe(
            catchError(() => {
              const asyncHandler = async () => {
                const toastInstance = await this._toastController.create({
                  color: 'danger',
                  position: 'top',
                  duration: 5000,
                });
                toastInstance.message = await firstValueFrom(
                  this._translateService.selectTranslate(
                    'SOMETHING_WENT_WRONG_WHILE_CLOSING_WEB_VIEW',
                    {},
                    { scope: 'SYSTEM_MESSAGE' }
                  )
                );
                await toastInstance.present();
              };
              return from(asyncHandler());
            }),
            finalize(() => {
              this._spinnerService.deleteSpinner(
                SpinnerContainerIds.APPLICATION,
                displaySpinnerRequestId
              );
            })
          );
      })
    )
  );
  protected readonly _changeAuthenticationStatus = this.updater<string>(
    (state, authenticationStatus) => ({
      ...state,
      authenticationStatus,
    })
  );

  public constructor(
    @Inject(DOCUMENT) protected readonly _document: Document,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(RPC_SERVICE)
    protected readonly _rpcService: IRpcService,
    protected readonly _activatedRoute: ActivatedRoute,
    protected readonly _store: Store<IRootState>,
    protected readonly _localForageService: LocalForageService,
    protected readonly _translateService: TranslocoService,
    protected readonly _toastController: ToastController
  ) {
    super(oAuthCallbackState);
  }

  public oauth(): OperatorFunction<
    OAuthCallbackQueryParams,
    IOpenAuthenticationResult
  > {
    return pipe(
      filter(
        (queryParams) =>
          queryParams.provider != null && queryParams.code != null
      ),
      mergeMap(({ provider, code }) =>
        this._authenticationService.getOpenAuthenticationResultAsync(
          provider,
          code
        )
      )
    );
  }

  public sso(): OperatorFunction<
    OAuthCallbackQueryParams,
    IOpenAuthenticationResult
  > {
    return pipe(
      mergeMap(({ idToken }) =>
        this._authenticationService.validateSsoTokenAsync(idToken).pipe(
          map(() => {
            return {
              idToken: idToken,
              accessToken: idToken,
            } as IOpenAuthenticationResult;
          })
        )
      )
    );
  }

  private __getCookieValue(name: string): string | undefined {
    const value = `; ${this._document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()!.split(';').shift()!;
    }
    return undefined;
  }

  //#endregion
}
