import { Inject, Injectable } from '@angular/core';
import { NavigatorRadiationState } from './navigator-radiation.state';
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import {
  NavigatorContainerCodes,
  ScreenCodes,
} from '@mobile-data-access-enums';
import { NavigatorBaseComponentStore } from '../base';
import { NavController } from '@ionic/angular';

@Injectable()
export class NavigatorRadiationComponentStore extends NavigatorBaseComponentStore<
  NavigatorContainerCodes.RADIATION,
  NavigatorRadiationState
> {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected override readonly _navigationService: ISmartNavigatorService,
    protected override readonly _navController: NavController
  ) {
    super(
      _navigationService,
      _navController,
      ScreenCodes.CHEMOTHERAPY_RADIATION
    );
  }

  //#endregion
}
