import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonIconComponent } from './icon.component';
import { InlineSVGModule } from 'ng-inline-svg-2';

const MODULES = [CommonModule, InlineSVGModule];
const COMPONENTS = [CommonIconComponent];
@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class IconModule {}
