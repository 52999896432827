import {ModuleWithProviders, NgModule} from '@angular/core';
import {UI_SERVICE, UiService} from '@mobile-data-access-services';
import {IonicModule} from '@ionic/angular';

@NgModule({
    imports: [
        IonicModule
    ]
})
export class UiServiceModule {

    //#region Static methods

    public static forRoot(): ModuleWithProviders<UiServiceModule> {
        return {
            ngModule: UiServiceModule,
            providers: [
                {
                    provide: UI_SERVICE,
                    useClass: UiService
                }
            ]
        }
    }

    //#endregion

}
