import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable, throwError, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationActions, IRootState, UserActions } from "@mobile-data-access-stores";
import { ISmartNavigatorService, SMART_NAVIGATOR_SERVICE } from '@ui-tool/core';
import { AuthenticationNavigationRequest } from '@mobile-data-access-models';
import { DOCUMENT } from '@angular/common';
import { Languages, ScreenCodes, StorageKeys } from '@mobile-data-access-enums';
import { LocalForageService } from 'ngx-localforage';
import { IOpenAuthenticationResult } from '@mobile-data-access-interfaces';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  //#region Constructor

  public constructor(
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(DOCUMENT) protected readonly _document: Document,
    protected readonly _localForageService: LocalForageService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this._localForageService
      .getItem(StorageKeys.LANGUAGE)
      .pipe(
        mergeMap((language: string) => {
            const headers: Record<string, string> = {};
            headers['X-Language'] = language || Languages.EN;
            const clonedRequest = request.clone({
                reportProgress: true,
                setHeaders: headers,
            });

            return next.handle(clonedRequest);
        })
      );
  }

  //#endregion
}
