import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { ComponentStore } from '@ngrx/component-store';
import { IMaintenanceState } from './maintenance.state';
import {
  catchError,
  finalize,
  firstValueFrom,
  from,
  pipe,
  switchMap,
} from 'rxjs';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
  IUserService,
  USER_SERVICE,
} from '@mobile-data-access-services';
import { CloseWebviewNativeMethod } from '@mobile-data-access-models';
import { LocalForageService } from 'ngx-localforage';
import { SpinnerContainerIds } from '@mobile-data-access-enums';
import {
  ISmartNavigatorService,
  ISpinnerService,
  SMART_NAVIGATOR_SERVICE,
  SPINNER_SERVICE,
} from '@ui-tool/core';
import { TranslocoService } from '@ngneat/transloco';
import { IRpcService, RPC_SERVICE } from '@message-bus/core';

@Injectable()
export class MaintenanceComponentStore extends ComponentStore<IMaintenanceState> {
  //#region Constructor

  public constructor(
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(USER_SERVICE)
    protected readonly _userService: IUserService,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(SPINNER_SERVICE)
    protected readonly _spinnerService: ISpinnerService,
    @Inject(RPC_SERVICE)
    protected readonly _rpcService: IRpcService,
    protected readonly _activatedRoute: ActivatedRoute,
    protected readonly _translateService: TranslocoService,
    protected readonly _toastController: ToastController
  ) {
    super({});
  }

  //#endregion

  //#region Methods

  public readonly closeWebView = this.effect<never>(
    pipe(
      switchMap(() => {
        let displaySpinnerRequestId = this._spinnerService.displaySpinner(
          SpinnerContainerIds.APPLICATION
        );

        return this._rpcService
          .sendRequestAsync(new CloseWebviewNativeMethod(), 5 * 1000)
          .pipe(
            catchError(() => {
              const asyncHandler = async () => {
                const toastInstance = await this._toastController.create({
                  color: 'danger',
                  position: 'top',
                  duration: 5000,
                });
                toastInstance.message = await firstValueFrom(
                  this._translateService.selectTranslate(
                    'SOMETHING_WENT_WRONG_WHILE_CLOSING_WEB_VIEW',
                    {},
                    { scope: 'SYSTEM_MESSAGE' }
                  )
                );
                await toastInstance.present();
              };
              return from(asyncHandler());
            }),
            finalize(() => {
              this._spinnerService.deleteSpinner(
                SpinnerContainerIds.APPLICATION,
                displaySpinnerRequestId
              );
            })
          );
      })
    )
  );

  //#endregion
}
