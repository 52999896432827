<ng-container *ngFor="let article of articles; let i = index">
  <ncis-article-card
          [attr.data-test-id]="'article'"
          [class.cursor-pointer]="ableToSelectArticle(article)"
          (visited)="selectArticle(article)"
          [data]="article"
          [viewed]="keyToViewed[toKey(article.category, article.id)]"
          [loading]="loading"
  >
  </ncis-article-card>
</ng-container>
<ng-template #skeletonTemplate>
  <ng-container *ngFor="let item of [1,2,3]">
    <ncis-article-card [loading]="true"></ncis-article-card>
  </ng-container>
</ng-template>
