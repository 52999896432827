import {
  Inject,
  Injectable,
  LOCALE_ID,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { format } from 'date-fns';
import { StringNullablePrimitive } from '@shared-types';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'toAppointmentTime',
})
export class ToAppointmentTimePipe implements PipeTransform {
  //#region Properties

  private __offset = '+800';

  //#endregion

  //#region Constructor

  public constructor(@Inject(LOCALE_ID) private readonly __localeId: string) {}

  //#endregion

  //#region Methods

  public transform(
    startTime: Date | StringNullablePrimitive,
    endTime: Date | StringNullablePrimitive,
    separator: string = '•',
    breakPoint: string = ' - '
  ): string {
    if (!startTime) {
      return '';
    }
    const actualStartTime = new Date(startTime);
    const parameters = [];
    parameters.push(
      formatDate(
        actualStartTime,
        "dd MMM yyyy • hh:mmaaaaa'm'",
        this.__localeId,
        this.__offset
      )
    );

    return parameters.join(breakPoint).split('•').join(separator);
  }

  //#endregion
}
