import { createReducer, on } from '@ngrx/store';
import { AuthenticationActions } from './authentication.action';
import { authenticationStoreInitialState } from './authentication.adapter';

export const authenticationStoreKey = 'authentication';

export const authenticationReducer = createReducer(
  authenticationStoreInitialState,
  on(AuthenticationActions.granted, (state, action) => ({
    ...state,
    isLogin: true,
  })),
  on(AuthenticationActions.expired, (state, action) => ({
    ...state,
    isLogin: false,
  }))
);
