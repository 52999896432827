import { NgModule } from "@angular/core";
import { InteractionCardComponent } from "./interaction-card.component";
import { CommonModule } from "@angular/common";
import { CategoryModule } from "../../shared";

@NgModule({
  declarations: [InteractionCardComponent],
  imports: [
    CommonModule,
    CategoryModule
  ],
  exports: [InteractionCardComponent]
})
export class InteractionCardModule { }
