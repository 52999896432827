<article *ngIf="!isHidden" class="medication-drug">
  <header>
    <ng-container *ngIf="!loading; else skeletonTemplate">
      <h5 [class.!text-ncis-neutral-600]="!data?.isRecent" [innerHTML]="data?.name ? (data.name | unescapeHtmlAsync: HtmlContents.DRUG__NAME | async) : ''"></h5>
    </ng-container>
    <ng-template #skeletonTemplate>
      <ncis-skeleton class="h-6 w-24"></ncis-skeleton>
    </ng-template>
  </header>
  <main>
    <ul>
      <li class="left">
        <span>{{'MEDICATION_PAGE.DRUG_AMOUNT_TITLE' | transloco}}</span>
        <ng-container *ngIf="!loading; else skeletonTemplate">
          <strong [class.!text-ncis-neutral-600]="!data?.isRecent">{{(data?.amount || '') + ' ' + (data?.unit || '')}}</strong>
        </ng-container>
      </li>
      <li class="right">
        <span>{{'MEDICATION_PAGE.DRUG_DISPENSED_DATE_TITLE' | transloco}}</span>
        <ng-container *ngIf="!loading; else skeletonTemplate">
          <strong [class.!text-ncis-neutral-600]="!data?.isRecent">{{(data?.dispensedDate || '') | date: 'dd MMM yyyy'}}</strong>
        </ng-container>
      </li>
      <li class="left">
        <span>{{'MEDICATION_PAGE.DRUG_DOSAGE_TITLE' | transloco}}</span>
        <ng-container *ngIf="!loading; else skeletonTemplate">
          <strong [class.!text-ncis-neutral-600]="!data?.isRecent" [innerHTML]="data?.dosage ? (data.dosage | unescapeHtmlAsync: HtmlContents.DRUG__DOSAGE | async) : ''"></strong>
        </ng-container>
      </li>
      <li class="right">
        <span>{{'MEDICATION_PAGE.DRUG_FREQUENCY_TITLE' | transloco}}</span>
        <ng-container *ngIf="!loading; else skeletonTemplate">
          <strong [class.!text-ncis-neutral-600]="!data?.isRecent">{{data?.frequency || ''}}</strong>
        </ng-container>
      </li>
    </ul>
    <ng-template #skeletonTemplate>
      <ncis-skeleton class="h-[22px] w-16"></ncis-skeleton>
    </ng-template>
  </main>
</article>
