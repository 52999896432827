import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IScheduleCard } from '@mobile-data-access-interfaces';
import { CommonIconFolderEnum, CommonIconGeneralEnum } from '@shared-enums';
import { ScheduleCardTimeDirective } from './directives/schedule-card-time.directive';
import { ScheduleCardCategoryDirective } from './directives';
import { ScheduleCardDescriptionDirective } from './directives/schedule-card-description.directive';
import {PlaceholderImages} from '@mobile-data-access-enums';

@Component({
  selector: 'ncis-schedule-card',
  templateUrl: './schedule-card.component.html',
  styleUrls: ['./schedule-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleCardComponent {
  //#region Properties

  @Input()
  public data!: IScheduleCard;

  @Input()
  public loading = false;

  @Input()
  public clickable = false;

  @ContentChild(ScheduleCardTimeDirective, { static: true, read: TemplateRef })
  public readonly timeTemplate!: TemplateRef<HTMLElement>;

  public readonly CommonGeneralIcon = CommonIconGeneralEnum;
  public readonly CommonFolderIcon = CommonIconFolderEnum;

  public readonly PlaceHolderImages = PlaceholderImages;

  @ContentChild(ScheduleCardCategoryDirective, { static: false })
  public categoryDirective: ScheduleCardCategoryDirective | undefined;

  @ContentChild(ScheduleCardDescriptionDirective, { static: false })
  public descriptionDirective: ScheduleCardDescriptionDirective | undefined;

  //#endregion
}
