import { IDrugDetailService } from "./drug-detail-service.interface";
import { Inject, Injectable } from "@angular/core";
import {
  IArticle,
  IBasicInfo,
  IDrugDetailItem,
  IDrugDetailTreatment,
  IOverview,
  IRelatedTopicCard,
  ISideEffect,
  ISymptom,
  ITag,
  ITopicCard
} from "@mobile-data-access-interfaces";
import { map, mergeMap, Observable } from "rxjs";
import { ArticleCategories, DrugDetailArticleTypes, DrugDetailControlCodes } from "@mobile-data-access-enums";
import { CONTAINER_SERVICE, IContainerService } from "../container";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { IApiResult } from "@shared-interfaces";

@Injectable()
export class DrugDetailService implements IDrugDetailService {

  //#region Constructor

  public constructor(
    @Inject(CONTAINER_SERVICE)
    protected readonly _containerService: IContainerService,
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _endpointResolver: IApiEndpointResolver,
    protected readonly _httpClient: HttpClient
  ) {
  }

  //#endregion


  //#region Methods

  public getBasicInfoByDrugIdAsync(id: string): Observable<IBasicInfo> {
    const payload = { id };
    return this._containerService.loadDataAsync<IBasicInfo>(DrugDetailControlCodes.HEADER_DRUG, payload);
  }

  public getOverviewByDrugIdAsync(id: string): Observable<IOverview> {
    const payload = { id };
    return this._containerService.loadDataAsync<IBasicInfo>(DrugDetailControlCodes.OVERVIEW, payload);
  }

  public getArticlesByDrugIdAsync(id: string): Observable<IArticle[]> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/container/level3_article`;
          return this._httpClient.post<IApiResult<IArticle[]>>(apiUrl, { id })
            .pipe(
              map(apiResult => (apiResult?.data || []))
            );
        }),
        map(articles => {
          for (const article of articles) {
            switch (article.type) {
              case DrugDetailArticleTypes.PRECAUTION:
                article.category = ArticleCategories.DRUG_DETAIL_GENERAL_PRECAUTION;
                break;

              case DrugDetailArticleTypes.DOCTOR:
                article.category = ArticleCategories.DRUG_DETAIL_ARTICLE_DOCTOR;
                break;

              case DrugDetailArticleTypes.ORAL:
                article.category = ArticleCategories.DRUG_DETAIL_ORAL_MED;
                break;

              case DrugDetailArticleTypes.OCCASIONAL_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_OCCASION_SIDE_EFFECT;
                break;

              case DrugDetailArticleTypes.RARE_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_RARE_SIDE_EFFECT;
                break;

              case DrugDetailArticleTypes.LONG_TERM_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT;
                break;
            }
          }

          return articles;
        })
      );
  }

  public getSymptomArticlesByDrugIdAsync(id: string): Observable<IArticle[]> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/symptom/drug/articles`;
          const httpParams: Record<string, string> = {};
          httpParams["drugId"] = id;
          return this._httpClient.get<IApiResult<IArticle[]>>(apiUrl, { params: httpParams })
            .pipe(
              map(apiResult => (apiResult?.data || []))
            );
        }),
        map(articles => {
          for (const article of articles) {
            switch (article.type) {
              case DrugDetailArticleTypes.PRECAUTION:
                article.category = ArticleCategories.DRUG_DETAIL_GENERAL_PRECAUTION;
                break;

              case DrugDetailArticleTypes.DOCTOR:
                article.category = ArticleCategories.DRUG_DETAIL_ARTICLE_DOCTOR;
                break;

              case DrugDetailArticleTypes.ORAL:
                article.category = ArticleCategories.DRUG_DETAIL_ORAL_MED;
                break;

              case DrugDetailArticleTypes.OCCASIONAL_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_OCCASION_SIDE_EFFECT;
                break;

              case DrugDetailArticleTypes.RARE_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_RARE_SIDE_EFFECT;
                break;

              case DrugDetailArticleTypes.LONG_TERM_SIDE_EFFECT:
                article.category = ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT;
                break;
            }
          }

          return articles;
        })
      );
  }

  public getArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/chemotherapy_drug/article_by_id/${id}`;
          return this._httpClient.get<IArticle>(apiUrl);
        })
      );
  }

  public getDrugPrecautionArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_precautions`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_GENERAL_PRECAUTION;
          return article;
        })
      );
  }

  public getDrugDoctorArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_doctor`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_ARTICLE_DOCTOR;
          return article;
        })
      );
  }

  public getDrugOralArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_oral`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_ORAL_MED;
          return article;
        })
      );
  }

  public getDrugOccasionalSideEffectArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_ose`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_OCCASION_SIDE_EFFECT;
          return article;
        })
      );
  }

  public getDrugRareSideEffectArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_rse`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_RARE_SIDE_EFFECT;
          return article;
        })
      );
  }

  public getDrugLongTermSideEffectArticleByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const queryParams: Record<string, string> = {};
          queryParams["id"] = id;

          const apiUrl = `${baseUrl}/symptom/drug/article_detail_ltse`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl, { params: queryParams });
        }),
        map(apiResult => apiResult.data),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_LONG_TERM_SIDE_EFFECT;
          return article;
        })
      );
  }

  public getRelatedTopicDetailByIdAsync(id: string): Observable<IArticle> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/chemotherapy_drug/related_topic_detail?id=${id}`;
          return this._httpClient.get<IApiResult<IArticle>>(apiUrl);
        }),
        map(apiResult => apiResult?.data || {}),
        map(article => {
          article.category = ArticleCategories.DRUG_DETAIL_RELATED_TOPIC;
          return article;
        })
      );
  }

  public getSideEffectByDrugIdAsync(id: string): Observable<ISideEffect> {
    const payload = { id };
    return this._containerService
      .loadDataAsync<ISideEffect>(DrugDetailControlCodes.SIDE_EFFECT, payload)
      .pipe(
        map(sideEffect => ({
          ...sideEffect,
          id
        }))
      );
  }


  public getRelatedTopicsAsync(id: string): Observable<IRelatedTopicCard[]> {
    const payload = { id };
    return this._containerService
      .loadDataAsync<IRelatedTopicCard[]>(DrugDetailControlCodes.RELATED_TOPICS, payload);
  }

  public getSymptomsByDrugIdAsync(id: string): Observable<ISymptom[]> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const httpParams: Record<string, string> = {};
          httpParams["drugId"] = id;
          const apiUrl = `${baseUrl}/symptom/drug/symptoms`;
          return this._httpClient.get<IApiResult<ISymptom[]>>(apiUrl, { params: httpParams });
        }),
        map(apiResult => apiResult.data)
      );
  }

  public getTagsAsync(id: string): Observable<ITag[]> {
    return this._endpointResolver
      .loadEndPointAsync("", "")
      .pipe(
        mergeMap(baseUrl => {
          const apiUrl = `${baseUrl}/symptom/drug/tags`;
          const httpParams: Record<string, string> = {};
          httpParams["drugId"] = id;
          return this._httpClient.get<IApiResult<ITag[]>>(apiUrl, { params: httpParams })
            .pipe(
              map(apiResult => (apiResult?.data || {}))
            );
        })
      );
  }

  public getTopicsAsync(id: string): Observable<ITopicCard[]> {
    const payload = { id };
    return this._containerService.loadDataAsync<ITopicCard[]>(
      DrugDetailControlCodes.HEADER_TOPICS,
      payload
    );
  }

  public getTreatmentAsync(
    id: string
  ): Observable<IDrugDetailItem & IDrugDetailTreatment> {
    const payload = { id };
    return this._containerService.loadDataAsync<IDrugDetailItem & IDrugDetailTreatment>(DrugDetailControlCodes.HEADER_DRUG, payload);
  }

  //#endregion
}
