import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[category]',
})
export class ScheduleCardCategoryDirective {
  //#region Constructor

  public constructor(public readonly templateRef: TemplateRef<string>) {}

  //#endregion
}
