import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { compareDesc } from "date-fns";

@Component({
  selector: 'ncis-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DateTimeComponent implements OnChanges {

  //#region Properties

  @Output()
  public readonly selectedDateChange = new EventEmitter<Date>();

  @Output()
  public readonly activeChange = new EventEmitter<boolean>();

  @Input()
  public active = false;

  @Input()
  public selectedDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  public currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);

  //#endregion

  //#region Life cycle

  public ngOnChanges(): void {
    if (this.selectedDate) {
      this.currentDate = this.selectedDate;
    }
  }

  //#endregion

  //#region Methods

  public select(value: Date): void {
    if ((this.min ? compareDesc(this.min, value) < 0 : false) || (this.max ? compareDesc(this.max, value) > 0 : false)) {
      return;
    }
    this.selectedDate = value;
    this.selectedDateChange.emit(value)
  }

  public prev(): void {
    const prev = new Date(this.currentDate);
    prev.setMonth(prev.getMonth() - 1);
    if (this.min && compareDesc(this.min, prev) < 0) {
      return;
    }

    this.currentDate = prev;
  }

  public next(): void {
    const next = new Date(this.currentDate);
    next.setMonth(next.getMonth() + 1);
    if (this.max && compareDesc(this.max, next) > 0) {
      return;
    }

    this.currentDate = next;
  }

  //#endregion

}
