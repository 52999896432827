import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  AppSettingsServiceModule,
  APP_SETTINGS_SERVICE,
} from '@mobile-data-access-services';
import { buildAppSettingFactory } from './app-settings.factory';

@NgModule({
  imports: [AppSettingsServiceModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: buildAppSettingFactory,
      multi: true,
      deps: [APP_SETTINGS_SERVICE],
    },
  ],
})
export class AppSettingsFactoryModule {}
