<div class="datetime-navigation">
  <span>
    {{monthWords[current.month - 1]}} {{current.year}}
  </span>
  <div class="datetime-navigation__actions">
    <button (click)="prev.emit()">
      <ncis-common-icon class="h-5 w-5 text-ncis-neutral-800" extraClass="transform -rotate-180" folder="general" name="right-arrow"></ncis-common-icon>
    </button>
    <button (click)="next.emit()">
      <ncis-common-icon class="h-5 w-5 text-ncis-neutral-800" folder="general" name="right-arrow"></ncis-common-icon>
    </button>
  </div>
</div>
