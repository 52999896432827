import { Inject, Injectable } from "@angular/core";
import { IChemotherapyService } from "./chemotherapy-service.interface";
import { API_ENDPOINT_RESOLVER, IApiEndpointResolver } from "@mobile-data-access-resolvers";
import { HttpClient } from "@angular/common/http";
import { map, mergeMap, Observable } from "rxjs";
import { IApiResult } from "@shared-interfaces";

@Injectable()
export class ChemotherapyService implements IChemotherapyService {
  //#region Constructor

  public constructor(
      @Inject(API_ENDPOINT_RESOLVER)
      protected readonly _endpointResolver: IApiEndpointResolver,
      protected readonly _httpClient: HttpClient
  ) {
  }


  //#endregion

  //#region Methods

  public getByPilCodeAsync(pilCode: string): Observable<string> {
    return this._getUrlAsync(`chemotherapy/by-pil-code/${pilCode}`).pipe(
      mergeMap((apiUrl) => {

        return this._httpClient
          .get<IApiResult<Record<'name', string>>>(apiUrl)
          .pipe(
            map(({data}) => data.name)
          );
      })
    );
  }

  //#endregion

  //#region Internal Methods

  protected _getUrlAsync(suffix: string): Observable<string> {
    return this._endpointResolver.loadEndPointAsync("", "").pipe(
      map((baseUrl) => {
        return `${baseUrl}/${suffix}`;
      })
    );
  }

  //#endregion
}
