import { NgModule } from "@angular/core";
import { WhatNextComponent } from "./what-next.component";
import { CommonModule } from "@angular/common";
import { ToAnyModule } from "@shared-pipes";
import { ToTrustedResourceUrlPipeModule } from "@ui-tool/core";
import { InlineSVGModule } from "ng-inline-svg-2";
import { TranslocoModule } from "@ngneat/transloco";
import { ButtonModule, SkeletonModule } from "../../../shared";

@NgModule({
  imports: [
    CommonModule,
    ToAnyModule,
    ToTrustedResourceUrlPipeModule,
    InlineSVGModule,
    TranslocoModule,
    ButtonModule,
    SkeletonModule
  ],
  declarations: [WhatNextComponent],
  exports: [WhatNextComponent],
})
export class WhatNextModule {}
