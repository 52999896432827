import {
  FEATURE_SENTINEL_SERVICE,
  IFeatureSentinelService,
  IRequirementHandler,
} from '@ui-tool/core';
import { Observable, of } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FeatureCodes, RequirementNames } from '@mobile-data-access-enums';

@Injectable()
export class AbleToUseMedicationModuleRequirementHandler
  implements IRequirementHandler
{
  //#region Properties

  public readonly name = RequirementNames.ABLE_TO_USE_MEDICATION;

  //#endregion

  //#region Constructor

  public constructor(
    @Inject(FEATURE_SENTINEL_SERVICE)
    protected readonly _featureSentinelService: IFeatureSentinelService,
    private readonly __store: Store
  ) {}

  //#endregion

  //#region Methods

  public shouldRequirementMetAsync(): Observable<boolean> {
    return this._featureSentinelService
      .ableToAccessFeaturesAsync([
        FeatureCodes.FF__MEDICATION_MODULE,
      ])
      .pipe(catchError(() => of(false)));
  }

  //#endregion
}
